import {authenticatedRequest} from "../APIGateway/gateway";
import {defaultQueryForGetData, getErrorMessages, queryString} from "../utils";
import commonActions from "./commonActions";
import {abbreviations} from "../components/DocumentsTab/utils";

const documentsActions = {
	getDocuments(objectType, objectId, queries = defaultQueryForGetData) {
		return dispatch => {
			dispatch({
				type: 'SET_DOCUMENTS_LOADING',
				payload: true
			});
			authenticatedRequest({
				url: `GetDocuments`,
				method: 'get',
				paths: [objectType, objectId],
				queryParams: queryString(queries)
			}).then((response) => {
				dispatch({
					type: 'SET_DOCUMENTS',
					payload: response.data
				});
			}, (error) => {
				dispatch({
					type: 'SET_DOCUMENTS_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	},
	uploadDocuments(objectType, objectId, payload, documentType) {
		return async dispatch => {
			try {
				return authenticatedRequest({
					url: `UploadDocuments`,
					method: 'post',
					paths: [objectType, objectId],
					data: payload,
					queryParams: queryString({document_type: documentType}),
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	},
	uploadNewVersionOfDocument(objectType, objectId, payload, documentType, documentId) {
		return async (dispatch) => {
			try {
				return authenticatedRequest({
					url: `UploadNewVersionOfDocument`,
					method: 'post',
					paths: [objectType, objectId, documentId],
					data: payload,
					queryParams: queryString({document_type: documentType}),
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	},
	deleteDocument(objectType, objectId, documentId) {
		return async (dispatch) => {
			try {
				return authenticatedRequest(
					{
						url: `DeleteDocument`,
						method: 'delete',
						paths: [objectType, objectId, documentId],
					}
				);
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	},
	getTypesOfDocuments() {
		return dispatch => {
			dispatch({
				type: 'SET_TYPES_OF_DOCUMENTS_LOADING',
				payload: true
			})
			authenticatedRequest({
				url: `GetTypesOfDocuments`,
				method: 'get',
			}).then((response) => {
				dispatch({
					type: 'SET_TYPES_OF_DOCUMENTS',
					payload: [...response.data?.map(talent => {
						talent.label = talent.type?.replace('_', ' ')
						talent.value = talent.type;
						talent.textTransform = abbreviations.includes(talent.type) ? 'uppercase' : 'capitalize';
						return talent;
					}),
						// {
						// 	label: 'add new',
						// 	value: 'add new',
						// }
					]
				});
			}, (error) => {
				dispatch({
					type: 'SET_TYPES_OF_DOCUMENTS_LOADING',
					payload: false
				})
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	},
	editDocument(objectType, objectId, documentId, payload) {
		return async (dispatch) => {
			try {
				return authenticatedRequest(
					{
						url: `EditDocument`,
						method: 'patch',
						paths: [objectType, objectId, documentId],
						data: payload,
					}
				);
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	}
}

export default documentsActions;