import { statusOptions, validationSchemaForRequiredField } from "../../utils";
import * as yup from "yup";
import moment from "moment";

import { ReactComponent as GeneralIcon } from "../../assets/Icons/Assessment.svg";
import { ReactComponent as TeamIcon } from "../../assets/Icons/AccountCircle.svg";
import { ReactComponent as EconomicIcon } from "../../assets/Icons/AttachMoney.svg";
import { ReactComponent as TimeLogsIcon } from "../../assets/Icons/Dns.svg";
import { ReactComponent as InvoicingIcon } from "../../assets/Icons/Book.svg";
// import {ReactComponent as PaymentsIcon} from "../../assets/Icons/Group.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/Icons/Dashboard.svg";
// import {ReactComponent as ClientContactsIcon} from "../../assets/Icons/SupervisorAccount.svg";

export const projectsTableColumns = [
  {
    title: "Project Name",
    dataIndex: "name",
    key: "project_name",
    bold: false,
    sortKey: "name",
  },
  {
    title: "Client Name",
    dataIndex: "client_company_name",
    key: "name",
    bold: true,
    sortKey: "client_company_name",
  },
  // {
  // 	title: 'Project Code',
  // 	dataIndex: 'code',
  // 	key: 'project_code',
  // 	bold: false
  // },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    bold: false,
    sortKey: "start_date",
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    bold: false,
    sortKey: "end_date",
  },
  {
    title: "MSA",
    dataIndex: "is_msa_required",
    key: "msa",
    bold: false,
    sortKey: "msa",
  },
  {
    title: "PO",
    dataIndex: "is_sow_required",
    key: "po",
    bold: false,
    sortKey: "po",
  },
  {
    title: "SOW",
    dataIndex: "is_po_required",
    key: "sow",
    bold: false,
    sortKey: "sow",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    bold: false,
    sortKey: "status",
  },
  {
    title: "Team",
    dataIndex: "talents",
    key: "talents",
    bold: false,
    sortKey: "contractors_count",
  },
  // {
  // 	title: 'Invoice Date',
  // 	dataIndex: 'invoice_date',
  // 	key: 'invoice_date',
  // 	bold: false
  // },
  // {
  // 	title: 'Invoices Status',
  // 	dataIndex: 'invoices_status',
  // 	key: 'invoices_status',
  // 	bold: false
  // },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    bold: false,
  },
];

export const inputsCreateNewProject = [
  {
    label: "Client Company",
    name: "client_company_id",
    type: "select",
    options: [],
    required: true,
    customWidth: "1 1 calc(50% - 6px)",
    margin: "0 0 12px",
  },
  {
    label: "Status",
    placeholder: "Select status",
    name: "status",
    type: "select",
    required: false,
    options: statusOptions,
    classNameContainer: "status",
    defaultValue: "draft",
    customWidth: "1 1 calc(50% - 6px)",
    margin: "0 0 12px",
  },
  {
    label: "Project Code",
    name: "code",
    type: "text",
    textTransform: "uppercase",
    margin: "0 0 12px",
    customWidth: "1 1 calc(50% - 6px)",
    disabled: true,
  },
  // {
  // 	label: 'Invoicing',
  // 	placeholder: 'Select invoicing',
  // 	name: 'invoicing_type',
  // 	type: 'select',
  // 	required: false,
  // 	customWidth: '1 1 calc(50% - 6px)',
  // 	margin: '0 0 12px',
  // 	options: [
  // 		{
  // 			value: 'once_a_month',
  // 			label: 'once a month',
  // 			_id: 'once_a_month',
  // 		},
  // 		{
  // 			value: 'fixed_number',
  // 			label: 'fixed number',
  // 			_id: 'fixed_number',
  // 		},
  // 	],
  // },
  {
    label: "Invoicing",
    name: "invoicing_type",
    type: "invoicing_type",
    required: true,
    customWidth: "1 1 calc(50% - 15vw)",
  },
  {
    label: "",
    name: "invoicing_fixed_number",
    type: "number",
    required: false,
    customWidth: "1 1 13.5vw",
    classNameContainer: "small-input",
    maxLength: 2,
    forbiddenKeys: ["e", "E", "-", "ArrowUp", "ArrowDown", ".", ","],
  },
  {
    label: "Project Name",
    name: "name",
    type: "text",
    required: true,
    customWidth: "1 1 calc(50% - 6px)",
    margin: "0 0 12px",
  },
  {
    label: "Estimated Project Value",
    name: "project_value",
    type: "number",
    required: true,
    customWidth: "1 1 calc(50% - 6px)",
    margin: "0 0 12px",
    specialSymbol: "$",
    maxLength: 9,
    maxDigitsAfterDot: 2,
  },
  {
    label: "Start Date",
    name: "start_date",
    type: "date",
    required: true,
    customWidth: "1 1 calc(25% - 36px)",
    margin: "0 0 12px",
  },
  {
    label: "End Date",
    name: "end_date",
    type: "date",
    required: true,
    customWidth: "1 1 calc(25% - 36px)",
    margin: "0 0 12px",
  },
  {
    label: "Invoicing Date",
    name: "invoicing_date",
    type: "number",
    required: false,
    customWidth: "1 1 calc(25% - 36px)",
    margin: "0 0 12px",
  },
  {
    label: "",
    name: "empty_row",
    customWidth: "1 1 calc(50% - 33px)",
    margin: "0 0 12px",
  },
  {
    label: "MSA Is Required",
    name: "is_msa_required",
    type: "checkbox",
    required: false,
    customWidth: "none",
    maxWidth: "calc(20% - 36px)",
    margin: "auto 30px 12px 0",
    textTransformLabel: "none",
  },
  {
    label: "PO Is Required",
    name: "is_po_required",
    type: "checkbox",
    required: false,
    customWidth: "none",
    maxWidth: "calc(20% - 36px)",
    margin: "auto 30px 12px 0",
    textTransformLabel: "none",
  },
  {
    label: "SOW Is Required",
    name: "is_sow_required",
    type: "checkbox",
    required: false,
    customWidth: "none",
    maxWidth: "calc(20% - 36px)",
    margin: "auto 30px 12px 0",
    textTransformLabel: "none",
  },
  // {
  // 	label: 'NDA Is Required',
  // 	name: 'is_nda_required',
  // 	type: 'checkbox',
  // 	required: false,
  // 	customWidth: 'none',
  // 	maxWidth: 'calc(20% - 36px)',
  // 	margin: 'auto 30px 12px 0',
  // 	textTransformLabel: 'none',
  // },
  {
    label: "Description",
    name: "description",
    type: "textarea",
    required: false,
    rows: 3,
    customWidth: "100%",
  },
  {
    label: "Scope of Work",
    name: "scope_description",
    type: "textarea",
    required: false,
    rows: 6,
    customWidth: "100%",
    textTransformLabel: "auto",
  },
  {
    label: "Deliverables ",
    name: "deliverables",
    type: "textarea",
    required: false,
    rows: 2,
    customWidth: "100%",
  },
];

export const schemaCreateNewProject = yup.object().shape({
  client_company_id: yup
    .string()
    .required("Client Company is required")
    .transform((value) => (typeof value === "object" ? value?._id : value)),
  name: yup.string().required("Project Name is required"),
  start_date: yup
    .string()
    .required("Start Date is required")
    .transform((value) => {
      return value ? moment(value).format("M/D/Y") : value;
    })
    .test("is-required", "Start Date is required", function (value) {
      return (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value !== "Invalid date"
      );
    }),
  end_date: yup
    .string()
    .required("End Date is required")
    .transform((value) => (value ? moment(value).format("M/D/Y") : value))
    .test(
      "is-after-start",
      "End Date must be after Start Date",
      function (value) {
        const startDate = this.resolve(yup.ref("start_date"));
        return moment(value, "M/D/YYYY").isAfter(moment(startDate, "M/D/YYYY"));
      }
    ),
  invoicing_type: validationSchemaForRequiredField("Invoicing"),
  //validation for invoicing_fixed_number: if invoicing_type === 'fixed_number' then invoicing_fixed_number is required; invoicing_fixed_number must be a number and greater than 0
  invoicing_fixed_number: yup
    .number()
    .required("Invoicing Fixed Number is required")
    .min(1, "Invoicing Fixed Number must be greater than 0"),
  status: yup
    .string()
    .transform((value) => (typeof value === "object" ? value.value : value)),
  project_value: yup.string().required("Project Value is required"),
  is_po_required: yup.boolean(),
  is_sow_required: yup.boolean(),
});

export const projectsTabs = [
  {
    name: "General",
    icon: <GeneralIcon />,
    padding: "0 24px 24px",
  },
  {
    name: "Team",
    icon: <TeamIcon />,
    height: "calc(100% - 40px)",
  },
  {
    name: "Economics",
    icon: <EconomicIcon />,
    height: "calc(100% - 40px)",
    padding: "0 24px 24px",
    overflowY: "scroll",
  },
  {
    name: "Time Logs",
    icon: <TimeLogsIcon />,
    height: "calc(100% - 40px)",
  },
  {
    name: "Invoicing",
    icon: <InvoicingIcon />,
    height: "calc(100% - 40px)",
  },
  // {
  // 	name: 'Payments',
  // 	icon: <PaymentsIcon/>,
  // },
  {
    name: "Documents",
    icon: <DocumentsIcon />,
  },
  // {
  // 	name: 'Client Contacts',
  // 	icon: <ClientContactsIcon/>,
  // }
];
