import * as S from "./styled";
import {useEffect, useState} from "react";
import {Controller} from 'react-hook-form';
import {handleKeyDown, reducingNumber} from "../../utils";

const maxNumber = 1000000000;

const NumberInput = (
	{
		props,
		disabledInputs,
		forbiddenKeys = ['e', 'E', '-', 'ArrowUp', 'ArrowDown']
	}
) => {
	const {
		specialSymbol,
		type,
		setValue,
		name,
		defaultValue,
		control,
		padding,
		error,
		maxLength,
		maxDigitsAfterDot,
		getValues,
		clearErrors
	} = props;
	const [valueState, setValueState] = useState('');
	const isOnceAMonthInvoicing = getValues('invoicing_type') === 'once_a_month';
	
	useEffect(() => {
		if (isOnceAMonthInvoicing) {
			setValue(name, getValues(name) || 1);
			setValueState(getValues(name) || 1);
			//clear error state
			clearErrors('invoicing_fixed_number');
		}
	}, [isOnceAMonthInvoicing, getValues, setValue, name, clearErrors]);
	useEffect(() => {
		if (defaultValue || defaultValue === 0) {
			setValue(name, defaultValue);
			setValueState(defaultValue);
		} else {
			setValue(name, null);
		}
	}, [defaultValue, setValue, name, type]);
	const onChange = (e, field) => {
		const value = e.target.value;
		//max two digits after dot
		if (maxDigitsAfterDot && value?.includes('.') && value?.split('.')[1]?.length > maxDigitsAfterDot) return;
		if (maxLength && value.split('.')[0]?.length > maxLength) return;
		if (field.onChange && value < maxNumber) {
			field.onChange(value === '' ? null : (value < 0 ? value * -1 : value * 1));
			setValueState(value < 0 ? value * -1 : value);
			if (name === 'external_daily_rate') {
				setValue('external_hourly_rate', reducingNumber(value / 8));
			}
			if (name === 'internal_daily_rate') {
				setValue('internal_hourly_rate', reducingNumber(value / 8));
			}
		}
	};
	return (
		<Controller
			control={control}
			name={name}
			render={({field}) => (
				<S.InputWrap isDisabled={props.disabled}>
					<S.Input
						{...field}
						type={type}
						onChange={(e) => onChange(e, field)}
						value={valueState}
						padding={specialSymbol ? '10.5px 12px 10.5px 25px' : padding}
						error={error}
						disabled={props?.disabled || disabledInputs?.includes(name)}
						onKeyDown={(e) => handleKeyDown(e, forbiddenKeys)}
						onWheel={(e) => e.target.blur()}
					/>
					{/*for the MVP we always the show $ currency*/}
					{/*{currency && specialSymbol?*/}
					{/*	<S.SpecialSymbol>{getCurrency(currency)}</S.SpecialSymbol> : null*/}
					{/*}*/}
					{
						specialSymbol &&
						<S.SpecialSymbol className='special-symbol'>{specialSymbol}</S.SpecialSymbol>
					}
				</S.InputWrap>
			)
			}
		/>
	)
}

export default NumberInput;