import { components } from "react-select";
import {Label} from "../FormInput/styled";
import * as S from './styled';
import {CheckBox} from "../Checkbox/styled";

const OptionWithCheckbox = (props) => {
	const {isSelected, label, value} = props;
	return (
			<S.CustomOption
				alignItems='center'
			>
				<components.Option {...props}>
					<CheckBox
						type="checkbox"
						checked={isSelected}
						onChange={() => null}
					/>{" "}
					<Label className={value}>
					<span>
						{label}
					</span>
					</Label>
				</components.Option>
			</S.CustomOption>
	);
};

export default OptionWithCheckbox;