import * as S from './styled';
import PropTypes from "prop-types";
import {ReactComponent as Plus} from "../../assets/Icons/plus.svg";

const primaryHandler = (e) => {
	console.log('primary button clicked', e)
};

const Button = (
	{
		ghost = false,
		label = '',
		handleClick = primaryHandler,
		withIcon = false,
		fullWidth = false,
		padding = '12px 14px',
		flexDirection = 'row',
		gap = '10px',
		fontSize = '14px',
		minWidth = 'auto',
		icon= <Plus/>,
		buttonColor = 'var(--up-main-blue)',
		buttonLabelColor = 'var(--up-white)',
		width = 'auto',
		sizeIcon = '20px',
		borderRadius= '4px',
		...props
	}
) => {
	return (
		<S.Button
			as="button"
			ghost={ghost}
			onClick={handleClick}
			fullWidth={fullWidth}
			padding={padding}
			flexDirection={flexDirection}
			gap={gap}
			fontSize={fontSize}
			minWidth={minWidth}
			buttonColor={buttonColor}
			buttonLabelColor={buttonLabelColor}
			width={width}
			sizeIcon={sizeIcon}
			borderRadius={borderRadius}
			{...props}
		>
			{label}
			{withIcon && icon}
		</S.Button>
	)
}

Button.propTypes = {
	ghost: PropTypes.bool,
	label: PropTypes.string,
	handleClick: PropTypes.func,
	withIcon: PropTypes.bool,
	fullWidth: PropTypes.bool,
	padding: PropTypes.string,
	flexDirection: PropTypes.string,
	gap: PropTypes.string,
	fontSize: PropTypes.string,
	minWidth: PropTypes.string,
	icon: PropTypes.node,
	buttonColor: PropTypes.string,
	buttonLabelColor: PropTypes.string,
	width: PropTypes.string,
	sizeIcon: PropTypes.string,
	borderRadius: PropTypes.string,
}
export default Button;