import { talentStatusOptions, validationSchemaForEmail } from "../../utils";
import * as yup from "yup";

export const talentsTableColumns = [
  {
    title: "Full Name",
    dataIndex: "full_name",
    key: "name",
    bold: true,
  },
  {
    title: "Linkedin",
    dataIndex: "linkedin_account",
    key: "linkedin",
    bold: false,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    bold: false,
  },
  {
    title: "Hourly Rate",
    dataIndex: "hourly_rate",
    key: "hourly_rate",
    bold: false,
  },
  {
    title: "Daily Rate",
    dataIndex: "daily_rate",
    key: "daily_rate",
    bold: false,
  },
  {
    title: "CV attached",
    dataIndex: "cv_attached",
    key: "cv_attached static-medium-width",
    bold: false,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    bold: false,
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    bold: false,
  },
];

export const inputsCreateTalent = [
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    required: true,
    customWidth: "1 1 calc(50% - 6px)",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    required: true,
    customWidth: "1 1 calc(50% - 6px)",
  },
  {
    label: "Linkedin",
    name: "linkedin_account",
    type: "text",
    required: false,
    customWidth: "1 1 calc(50% - 6px)",
  },
  {
    label: "Email",
    name: "email",
    type: "text",
    required: true,
    customWidth: "1 1 calc(50% - 6px)",
  },
  {
    label: "Internal Daily Rate",
    name: "internal_daily_rate",
    type: "number",
    required: true,
    customWidth: "1 1 calc(50% - 6px)",
    maxDigitsAfterDot: 2,
    maxLength: 5,
  },
  {
    label: "Status",
    name: "status",
    placeholder: "Select status",
    type: "select",
    required: false,
    options: talentStatusOptions,
    defaultValue: "available",
    customWidth: "1 1 calc(50% - 6px)",
  },
  {
    label: "Internal Hourly Rate",
    name: "internal_hourly_rate",
    type: "text",
    required: false,
    disabled: true,
    customWidth: "1 1 calc(50% - 6px)",
  },
  {
    label: "Tags",
    name: "tags",
    type: "text",
    required: false,
    disabled: false,
    customWidth: "1 1 calc(50% - 6px)",
  },
];

export const schemaCreateNewTalent = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: validationSchemaForEmail(),
  internal_daily_rate: yup.number().required("Daily rate is required"),
  status: yup
    .string()
    .transform((value) => (typeof value === "object" ? value.value : value)),
  linkedin_account: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .matches(
      /^(https?:\/\/)?(www\.)[a-z0-9-]+(\.[a-z]{2,6})+([/\w.-]*)*\/?$/,
      "Please enter the correct link format"
    )
    .nullable(),
});
