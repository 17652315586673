import {authenticatedRequest} from "../APIGateway/gateway";
import commonActions from "./commonActions";
import {defaultQueryForGetData, getErrorMessages, getFilterQuery, queryString} from "../utils";
import {push} from "connected-react-router";
// import {push} from "connected-react-router";

const projectsActions = {
	getProjectsData(payload = defaultQueryForGetData) {
		return (dispatch, getState) => {
			const state = getState();
			const {clientFilter, statusFilter, sortBy} = state.projectsPageReducer;
			const filterData = [];
			if (clientFilter?.values?.length > 0) {
				filterData.push(clientFilter)
			}
			if (statusFilter?.values?.length > 0) {
				filterData.push(statusFilter)
			}
			dispatch({
				type: 'SET_PROJECTS_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetProjectsList',
				method: 'get',
				queryParams: queryString({...payload, 'sort_by': sortBy}) + getFilterQuery('project', filterData),
			}).then((response) => {
				dispatch({
					type: 'SET_PROJECTS_DATA',
					payload: response.data,
				});
			})
				.catch((error) => {
						dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					}
				);
		}
	},
	getClientsNames() {
		return dispatch => {
			dispatch({
				type: 'SET_CLIENTS_NAMES_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetClientsNames',
				method: 'get',
			}).then((response) => {
					dispatch({
						type: 'SET_CLIENTS_NAMES',
						payload: response.data?.length > 0 ? response.data.map(client => {
							return {...client, value: client.name, label: `${client.name} (${client?.code})`,}
						}).sort((a, b) => a.name.localeCompare(b.name)) : response.data,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_CLIENTS_NAMES_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	setActiveClientName(clientName) {
		return dispatch => {
			if (clientName) {
				dispatch(this.getProjectsCodes(clientName));
			} else {
				dispatch({
					type: 'SET_ACTIVE_CLIENT_NAME',
					payload: null,
				});
			}
		}
	},
	getProjectsCodes(clientName) {
		return dispatch => {
			dispatch({
				type: 'SET_PROJECTS_CODES_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetProjectsCodes',
				method: 'get',
				paths: [clientName._id],
				
			}).then((response) => {
					dispatch({
						type: 'SET_ACTIVE_CLIENT_NAME',
						payload: {...clientName, codes: response.data},
					});
					dispatch({
						type: 'SET_PROJECTS_CODES_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_PROJECTS_CODES_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	createNewProject(projectData) {
		return async (dispatch) => {
			dispatch({
				type: 'SET_PROJECTS_CREATE_LOADING',
				payload: true,
			});
			
			try {
				const response = await authenticatedRequest({
					url: 'CreateNewProject',
					method: 'post',
					data: projectData,
				});
				
				dispatch({
					type: 'SET_PROJECTS_CREATE_LOADING',
					payload: false,
				});
				dispatch(commonActions.setPopupMessage('Project created successfully', 'success'));
				
				return response?.data?._id;
			} catch (error) {
				dispatch({
					type: 'SET_PROJECTS_CREATE_LOADING',
					payload: false,
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		};
	},
	
	updateProject(projectData, id, isUpdateEconomicsType = false) {
		return (dispatch) => {
			if (isUpdateEconomicsType) {
				dispatch({
					type: 'SET_ECONOMICS_DATA_LOADING',
					payload: true,
				})
			} else {
				dispatch({
					type: 'SET_PROJECTS_CREATE_LOADING',
					payload: true,
				});
				projectData.project_value = projectData.project_value * 1;
				projectData.currency = 'USD'
			}
			authenticatedRequest({
				url: 'UpdateProject',
				method: 'patch',
				data: projectData,
				paths: [id],
			}).then(() => {
					if (!isUpdateEconomicsType) {
						dispatch({
							type: 'SET_PROJECTS_CREATE_LOADING',
							payload: false,
						});
					}
					dispatch(this.getProjectData(id));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_PROJECTS_CREATE_LOADING',
						payload: false,
					});
				}
			);
		};
	},
	getProjectData(id) {
		return (dispatch) => {
			dispatch({
				type: 'SET_PROJECT_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetProjectData',
				method: 'get',
				paths: [id]
			}).then((response) => {
				dispatch(this.setProjectData(response.data));
			}).catch((error) => {
				if (error?.response?.data?.errors?.project_id[0] === 'Value error, Invalid objectid') {
					dispatch(push('/not-found'));
					window.location.reload();
				} else {
					dispatch({
						type: 'SET_PROJECT_DATA_LOADING',
						payload: false,
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			});
		}
	},
	setProjectData(projectData) {
		return dispatch => {
			dispatch({
				type: 'SET_PROJECT_DATA',
				payload: projectData,
			});
		}
	},
	updateProjectStatus(status, id) {
		return (dispatch, getState) => {
			const state = getState();
			const {projectsData} = state.projectsPageReducer;
			const {searchInputValue} = state.commonReducer;
			
			authenticatedRequest({
				url: 'UpdateProject',
				method: 'patch',
				data: status,
				paths: [id],
			}).then(() => {
					dispatch(this.getProjectsData({
						'query_str': searchInputValue,
						'page': projectsData?.page,
						'page_size': projectsData?.page_size,
					}));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	setClientFilter(data) {
		return dispatch => {
			dispatch({
				type: 'SET_CLIENT_FILTER',
				payload: data,
			});
		}
	},
	setStatusFilter(data) {
		return dispatch => {
			dispatch({
				type: 'SET_STATUS_FILTER',
				payload: data,
			});
		}
	},
	deleteProject(id) {
		return (dispatch, getState) => {
			const state = getState();
			const {projectsData} = state.projectsPageReducer;
			const {searchInputValue} = state.commonReducer;
			authenticatedRequest({
				url: 'DeleteProject',
				method: 'delete',
				paths: [id],
			}).then(() => {
					dispatch(commonActions.setPopupMessage('Project Successfully Deleted', 'success'));
					dispatch(this.getProjectsData({
						'query_str': searchInputValue,
						'page': projectsData?.page,
						'page_size': projectsData?.page_size,
					}));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	getTeamData(id, queries = defaultQueryForGetData) {
		return dispatch => {
			dispatch({
				type: 'SET_TEAM_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetTeamData',
				method: 'get',
				paths: [id],
				queryParams: queryString(queries),
			}).then((response) => {
				dispatch({
					type: 'SET_TEAM_DATA',
					payload: response.data,
				});
			}).catch((error) => {
				dispatch({
					type: 'SET_TEAM_DATA_LOADING',
					payload: false,
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	},
	deleteContractorFromTeam(projectId, contractorId) {
		return (dispatch, getState) => {
			const state = getState();
			const {teamData} = state.projectsPageReducer;
			authenticatedRequest({
				url: 'DeleteContractorFromTeam',
				method: 'delete',
				paths: [projectId, contractorId],
			}).then(() => {
					dispatch(commonActions.setPopupMessage('Talent Successfully Deleted', 'success'));
					dispatch(this.getTeamData(projectId, {
						'query_str': '',
						'page': teamData?.page,
						'page_size': teamData?.page_size,
					}));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	addContractorToTeam(payload, projectId, contractorId) {
		return dispatch => {
			dispatch({
				type: 'SET_ASSIGN_CONTRACTOR_LOADING',
				payload: true,
			});
			
			authenticatedRequest({
				url: 'AddContractorToTeam',
				method: 'post',
				data: payload,
				paths: [projectId, contractorId],
			}).then(() => {
					dispatch({
						type: 'SET_ASSIGN_CONTRACTOR_SUCCESS',
						payload: true,
					});
					dispatch({
						type: 'SET_ASSIGN_CONTRACTOR_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_ASSIGN_CONTRACTOR_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	resetSuccessAssignContractor() {
		return dispatch => {
			dispatch({
				type: 'SET_ASSIGN_CONTRACTOR_SUCCESS',
				payload: false,
			});
		}
	},
	sortByProjects(sortBy) {
		return dispatch => {
			dispatch({
				type: 'SET_PROJECTS_SORT_BY',
				payload: sortBy,
			});
		}
	}
}
export default projectsActions;