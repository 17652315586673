import {RadioButtonWrapper} from "./styled";
import {forwardRef} from "react";

const RadioButton = forwardRef((
	{
		label,
		selectedValue,
		value,
		handleChangeType,
		name,
		margin = '0',
		disabled = false,
	}, ref
) => {
	return (
		<RadioButtonWrapper checked={selectedValue === value} margin={margin} disabled={disabled}>
			<input
				type="radio"
				checked={selectedValue === value}
				id={value}
				name={name}
				onChange={()=>handleChangeType(value)}
				disabled={disabled}
				ref={ref}
			/>
			{label}
		</RadioButtonWrapper>
	);
})

export default RadioButton;