import {memo, useState} from "react";
import {EconomicsTableWrapper, WrapperWithBg} from "../styled";
import {AccordionHeader} from "../../../../../components/commonStyled";
import {ReactComponent as Chevron} from "../../../../../assets/Icons/chevronDown.svg";

import TextBox from "../../../../../components/TextBox";
import {configStylesForEconomicsTable, sumCostOfAllItems, sumItems, talentsSummaryTableColumns} from "../utils";
import Table from "../../../../../components/Table";
import Row from "./Row";
import {TotalTd} from "../../../../../components/Table/styled";
import {reducingNumber} from "../../../../../utils";

const TalentsSummary = memo((
	{
		talents,
		professionalFees,
		expenses,
		profitSharing,
		grossProfitMargin,
		sumExpenses
	}
) => {
	const [isOpened, setIsOpened] = useState(true);
	const sumProfessionalFees = reducingNumber(sumCostOfAllItems(professionalFees));
	const sumProfitSharing = reducingNumber(sumItems(profitSharing, 'percent_from_gross_profit') * grossProfitMargin / 100);
	//total professional fees + expenses + profit sharing
	const total = talents.reduce((acc, item) => {
		acc += reducingNumber(sumCostOfAllItems(professionalFees.filter(prof => prof?.contractor_id === item?.contractor_id)));
		acc += reducingNumber(sumItems(expenses.filter(exp => exp?.contractor_id === item?.contractor_id), 'price'));
		const findProfitSharing = profitSharing.find(prof => prof?.contractor_id === item?.contractor_id);
		acc += findProfitSharing ? reducingNumber((findProfitSharing?.percent_from_gross_profit * grossProfitMargin) / 100) : 0;
		return acc;
	}, 0);
	
	return (
		<WrapperWithBg
			as='section'
			minHeight={'auto'}
			flexDirection='column'
			gap='25px'
			margin='20px 0 0'
			padding={'24px 0 12px'}
			isOpened={isOpened}
		>
			<AccordionHeader
				as='div'
				alignItems='center'
				isOpened={`${isOpened}`}
				onClick={() => setIsOpened(!isOpened)}
				padding={'0 12px'}
			>
				<TextBox
					size={'lg'}
					weight={'medium'}
					lineHeight={'36px'}
				>
					Summary
				</TextBox>
				<Chevron/>
			</AccordionHeader>
			<EconomicsTableWrapper
				tdBackground={'var(--up-main-grayish)'}
				widthColumn={'19%'}
			>
				<Table
					childrenHeader={
						talentsSummaryTableColumns.map((column, index) => {
							return <th key={column.key + '-' + index} className={column.key}
												 style={{textAlign: column?.textAlign || 'left'}}>
								{column.title}
							</th>
						})
					}
					data={talentsSummaryTableColumns}
					{...configStylesForEconomicsTable}
				>
					{
						talents.map((row, index) => (
							<Row
								key={row?.contractor_id || index}
								row={row}
								index={index}
								professionalFees={professionalFees}
								expenses={expenses}
								profitSharing={profitSharing}
								grossProfitMargin={grossProfitMargin}
							/>
						))
					}
					<tr>
						<td className={'with-bg'}></td>
						<td className={'with-bg'}>
							<TotalTd>
								Total: $ {sumProfessionalFees}
							</TotalTd>
						</td>
						<td className={'with-bg'}>
							<TotalTd>
								Total: $ {reducingNumber(sumExpenses)}
							</TotalTd>
						</td>
						<td className={'with-bg'}>
							<TotalTd>
								Total: $ {sumProfitSharing}
							</TotalTd>
						</td>
						<td className={'with-bg'}>
							<TotalTd>
								Total: $ {reducingNumber(total)}
							</TotalTd>
						</td>
					</tr>
				</Table>
			</EconomicsTableWrapper>
		</WrapperWithBg>
	)
});

export default TalentsSummary;