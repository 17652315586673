import styled from 'styled-components';
import {keyframes} from 'styled-components'

const slideDown = keyframes`
  from {
    transform: translate(50%,-100%);
  }
  to {
    transform: translate(50%, 0);
    opacity: 1;
  }
`;

export const PopUpMessageWrap = styled.div`
  display: flex;
  width: 566px;
  min-height: 56px;
  gap: 16px;
  align-items: center;
  border-radius: 4px;
  padding: 10px 12px;
  position: absolute;
  right: 50%;
  top: 34px;
	transform: translateX(50%);
	z-index: 99999;
  background: ${({type}) =>
          type === 'success' ?
                  'var(--up-main-success)' :
                  type === 'information' ?
                          'var(--up-main-info)' :
                          type === 'warning' ?
                                  'var(--up-main-orange)' :
                                  'var(--up-main-red)'
  };
  animation-name: ${slideDown};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
`;

export const IconClose = styled.svg`
  margin-left: auto;
  cursor: pointer;
  width: 24px;
  min-width: 24px;
`;

export const IconWrap = styled.div`
	position: absolute;
	left: 0;
	top: -7px;
	transform: translateY(-50%);
`;