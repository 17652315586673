import {TableBody, TableHeader, TableWrapper} from "./styled";
import PropTypes from "prop-types";

import NoData from "../NoData";

const Table = (
	{
		childrenHeader,
		children,
		data,
		noDataText= 'There are no such results',
		height = 'calc(100% - 68px)',
		width = 'auto',
		headerColor = ' var(--up-blue-p100)',
		tableColor = 'var(--up-white)',
		withStickyHeader = true,
		tableBodyColor = 'transparent',
		withHoverEffect = true,
		tableBorderRadius = '0',
		boxShadow = 'inset 0px -1px 0px var(--up-page-bg)',
		borderSpacing = '0 10px',
		headerWithBorder = false,
		overflow = 'auto',
		border = 'none',
		customWidth = null,
		heightBanner = '53vh',
	}
) => {
	return (
		<div style={{height: height, overflow: overflow, width: width}}>
			<TableWrapper
				tableColor={tableColor}
				withStickyHeader={withStickyHeader}
				tableBorderRadius={tableBorderRadius}
				borderSpacing={borderSpacing}
				overflow={overflow}
				border={border}
				customWidth={customWidth}
			>
				<TableHeader
					headerColor={headerColor}
					withStickyHeader={withStickyHeader}
					boxShadow={boxShadow}
					headerWithBorder={headerWithBorder}
				>
					<tr>
						{childrenHeader}
					</tr>
				</TableHeader>
				<TableBody
					tableBodyColor={tableBodyColor}
					withHoverEffect={withHoverEffect}
					withStickyHeader={withStickyHeader}
				>
					{children}
				</TableBody>
			</TableWrapper>
			{data.length === 0 && <NoData text={noDataText} margin='0' height={heightBanner}/>}
		</div>
	)
}
Table.propTypes = {
	childrenHeader: PropTypes.node.isRequired,
	children: PropTypes.node.isRequired,
	data: PropTypes.array.isRequired,
	noDataText: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	headerColor: PropTypes.string,
	tableColor: PropTypes.string,
	withStickyHeader: PropTypes.bool,
	tableBodyColor: PropTypes.string,
	withHoverEffect: PropTypes.bool,
	tableBorderRadius: PropTypes.string,
	boxShadow: PropTypes.string,
	borderSpacing: PropTypes.string,
	headerWithBorder: PropTypes.bool,
	overflow: PropTypes.string,
}
export default Table;