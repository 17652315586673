import styled from "styled-components";
import { FlexBox } from "../commonStyled";
import { TextBox } from "../TextBox/styled";

export const CardInfo = styled(FlexBox)`
  padding: 16px;
  background-color: var(--up-white);
  border-radius: 6px;
  box-shadow: var(--main-box-shadow);
  border: 1px solid var(--up-main-light-grey);
  cursor: pointer;
  flex-wrap: wrap;
  //min-width: 355px;
  //flex: 0 calc(33.333% - 8px);
  transition: all 0.15s ease-in 0s;
  * {
    cursor: pointer;
  }
  &:hover {
    border-color: var(--up-main-info);
  }
`;

export const Tooltip = styled.div`
  width: 164px;
  background-color: var(--up-white);
  border: 1px solid var(--up-page-bg);
  box-shadow: var(--main-box-shadow);
  -webkit-box-shadow: 0px 6px 6px 3px rgba(42, 118, 207, 0.12);
  border-radius: 4px;
  padding: 10px 16px;
  opacity: 1;
  transition: opacity 0.15s ease-in 0s;
  transform: translate(-40%, -15px);
  overflow: hidden;
  //&:hover {
  //  box-shadow: 0 6px 6px 3px rgba(42, 118, 207, 0.12);
  //}
  .arrow {
    display: none;
  }
`;

export const TooltipItem = styled(FlexBox)`
  cursor: pointer;
  padding: 10px 0;
  span {
    text-align: left;
  }
  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    path {
      fill: var(--up-main-dark);
    }
  }
  &:hover {
    svg {
      path {
        fill: var(--up-main-info);
      }
    }
    span {
      color: var(--up-main-info);
    }
  }
`;

export const TooltipContainer = styled.div`
  &.tooltip-container {
    padding: 0 15px;
    width: ${(props) => props.width || "164px"};
    box-shadow: var(--main-box-shadow);
    border: 1px solid var(--up-page-bg);
  }
`;

export const AddressText = styled(TextBox)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const uploadedButton = styled.div`
  width: 100px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 2;
  display: flex;
  border-width: 1px;
  border-style: solid;
  border-color: var(--up-main-green);
  div {
	color: var(--up-main-green);
  }
  border-radius: 6px;
  padding: 4px;
  svg {
    width: 15px;
    height: 15px;
    min-width: 15px;
  }
  svg {
	path {
	  fill: var(--up-main-green);
	}
  }
  &:hover {
	background: var(--up-main-info);
    border-color: var(--up-white);
    color: var(--up-white);
    div {
      color: var(--up-white);
    }
    svg {
      path {
        fill: var(--up-white);
      }
    }
  }
`;
