import {BreadCrumbItem, BreadcrumbsWrap} from "./styled";
import {ReactComponent as Chevron} from "../../assets/Icons/chevronRight.svg";
import {NavLink} from "react-router-dom";

const Breadcrumbs = (
	{
		breadcrumbs,
		breadcrumbsRef,
	}
) => {
	return (
		<BreadcrumbsWrap alignItems={'center'} flexWrap={'wrap'} ref={breadcrumbsRef}>
			{breadcrumbs.map(({match, breadcrumb, isLoading}, index) => {
				return (
					isLoading ? '' :
						<BreadCrumbItem key={match.pathname} isLastItem={(index + 1 === breadcrumbs.length).toString()}>
							{(match.pathname !== '/' && index > 1) && <Chevron/>}
							<NavLink to={match.pathname} title={breadcrumb?.props?.children}>
								<span>{breadcrumb}</span>
							</NavLink>
						</BreadCrumbItem>
				)
			})}
		</BreadcrumbsWrap>
	)
}

export default Breadcrumbs;