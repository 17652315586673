import {FormInputWrap, Input, SpecialSymbol} from "../../../../components/FormInput/styled";
import {getErrorMessages, handleKeyDown, reducingNumber} from "../../../../utils";
import {useDispatch} from "react-redux";
import timeLogsActions from "../../../../actions/timeLogsActions";
import commonActions from "../../../../actions/commonActions";
import {usePermission} from "../../../../hooks/usePermission";

const TimeLogRow = (
	{
		timeLog,
		setTimeLogState,
		timeLogsState,
		index,
		projectId,
		timeLogId
	}
) => {
	const dispatch = useDispatch();
	const isPermittedEditTimeLog = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']);
	const updateState = (value, indexRow, key) => {
		const cloneExpenses = JSON.parse(JSON.stringify(timeLogsState));
		cloneExpenses[indexRow][key] = value;
		setTimeLogState(cloneExpenses);
	}
	const handleChange = (e, key) => {
		const value = e.target.value;
		let stringNumber = value.toString();
		let decimalPlaces = (stringNumber.split('.')[1] || '').length;
		if (decimalPlaces > 2) return;
		if (value * 1 > 9999) return;
		updateState(value * 1, index, key);
	};
	
	const handleApply = (key) => {
		dispatch(timeLogsActions.editTimeLogReport(projectId, timeLogId, timeLog?._id, {[key]: timeLog[key]}))
			.then((response) => {
				updateState(response?.data[key], index, key)
			})
			.catch((error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				updateState(timeLog[key], index, key);
			});
	};
	return (
		<tr>
			<td>{timeLog?.name}</td>
			<td>
				<FormInputWrap>
					<SpecialSymbol color={'var(--up-main-grey)'}>h</SpecialSymbol>
					<Input
						value={timeLog?.logged_hours || ''}
						type={'number'}
						onChange={(e) => handleChange(e, 'logged_hours')}
						placeholder={'0'}
						placeholderColor={'var(--up-main-dark)'}
						padding={'7.5px 8px 7.5px 24px'}
						width={'80px'}
						disabled={!isPermittedEditTimeLog}
						onBlur={() => handleApply('logged_hours')}
						onKeyDown={(e)=>handleKeyDown(e,  ['e', 'E', '-', '.', ',', 'ArrowUp', 'ArrowDown'])}
						onWheel={(e) => e.target.blur()}
					/>
				</FormInputWrap>
			</td>
			<td>$ {reducingNumber(timeLog?.daily_rate / 8)}</td>
			<td>$ {reducingNumber(timeLog?.daily_rate)}</td>
			<td style={{fontWeight: '500', textAlign: 'right'}}>$ {reducingNumber(reducingNumber(timeLog?.daily_rate / 8) * timeLog?.logged_hours || 0)}</td>
		</tr>
	)
}

export default TimeLogRow;