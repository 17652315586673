const initialState = {
	invoicingData: null,
	invoicingDataLoading: false,
};

export default function invoicingTabReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_INVOICING_DATA": {
			return {
				...state,
				invoicingData: action.payload,
			}
		}
		case "SET_INVOICING_DATA_LOADING": {
			return {
				...state,
				invoicingDataLoading: action.payload
			}
		}
		default:
			return state;
	}
}