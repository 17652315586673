import {ReactComponent as Chevron} from "../../assets/Icons/chevronDown.svg";
import * as S from './styled';

const DropdownArrowIcon = (
	{
		menuIsOpen,
		icon = <Chevron/>,
		size = '20px',
		customHeight = null,
	}
)=> {
	return (
		<S.DropdownArrowIcon
			menuIsOpen={menuIsOpen}
			size={size}
			customHeight={customHeight}
		>
			{icon}
		</S.DropdownArrowIcon>
	)
}

export default DropdownArrowIcon;