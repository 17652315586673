import {useSelector} from "react-redux";
import {usePermission} from "../../hooks/usePermission";

import NoData from "../../components/NoData";
import ProjectsList from "./ProjectsList";

const ProjectsWrapper = () => {
	const {projectsData, clientsNames} = useSelector(state => state.projectsPageReducer);
	const isPermittedCreateNewProject = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']);
	const clientsAreAvailable = clientsNames?.length > 0;
	return (
		<>
			{
				clientsAreAvailable && projectsData?.total > 0 ?
					<ProjectsList
						isPermittedCreateNewProject={isPermittedCreateNewProject}
					/>
					 :
					<NoData
						isPermittedButton={isPermittedCreateNewProject}
						text={clientsAreAvailable ? 'You have no Projects yet' : `You have no Clients yet. Please go to the Clients page to create a client first.`}
						buttonText={clientsAreAvailable ? 'Project' : 'Go to the Clients page'}
						isNavButton={true}
						navLink={clientsAreAvailable ? '/projects/create-project' : '/clients'}
						icon={clientsAreAvailable}
					/>
			}
		</>
	)
}

export default ProjectsWrapper;