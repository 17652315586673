import {
  talentStatusOptions,
  validationSchemaForEmail,
  validationSchemaForPhoneNumber,
  validationSchemaForRequiredField,
} from "../../../utils";
import * as yup from "yup";

export const activeTalentInputs = [
  {
    label: "Logo",
    name: "logo",
    type: "file",
    required: false,
    customWidth: "100%",
  },
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    required: true,
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    required: true,
  },

  {
    label: "Bank",
    name: "bank_name",
    type: "text",
    blockTitle: "Payment Information",
  },
  {
    label: "Role",
    name: "role",
    type: "select",
    blockTitle: "Additional Information",
    options: [
      {
        label: "Contractor",
        value: "contractor",
      },
      {
        label: "Employee",
        value: "employee",
      },
    ],
  },
  {
    label: "Payment Number",
    name: "payment_number",
    type: "text",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: talentStatusOptions,
  },
  {
    label: "Payment Name",
    name: "payment_name",
    type: "text",
  },
  {
    label: "Linkedin Profile",
    name: "linkedin_account",
    type: "text",
  },
  {
    label: "Internal Daily Rate",
    name: "internal_daily_rate",
    type: "number",
    customWidth: "1 1 calc(25% - 36px)",
    specialSymbol: "$",
    required: true,
    maxDigitsAfterDot: 2,
    maxLength: 5,
  },
  {
    label: "Internal Hourly Rate",
    name: "internal_hourly_rate",
    type: "text",
    customWidth: "1 1 calc(25% - 36px)",
    disabled: true,
    specialSymbol: "$",
  },
  {
    label: "Phone Number",
    name: "phone_number",
    type: "text_mask",
    required: false,
    mask: "+9 (999) 999-9999",
  },
  {
    label: "External Daily Rate",
    name: "external_daily_rate",
    type: "number",
    customWidth: "1 1 calc(25% - 36px)",
    specialSymbol: "$",
    maxDigitsAfterDot: 2,
    maxLength: 5,
  },
  {
    label: "External Hourly Rate",
    name: "external_hourly_rate",
    type: "text",
    customWidth: "1 1 calc(25% - 36px)",
    disabled: true,
    specialSymbol: "$",
    maxDigitsAfterDot: 2,
  },
  {
    label: "Email",
    name: "email",
    type: "text",
    required: true,
  },
  {
    label: "Tags",
    name: "tags",
    type: "text",
    required: false,
  },
  {
    label: " ",
    name: "documents",
    type: "documents",
    required: false,
    blockTitle: "Documents",
    customWidth: "100%",
  },
];

export const schemaActiveTalentInputs = yup.object().shape({
  first_name: validationSchemaForRequiredField("First name"),
  last_name: validationSchemaForRequiredField("Last name"),
  phone_number: validationSchemaForPhoneNumber(),
  email: validationSchemaForEmail(),
  status: yup
    .string()
    .transform((value) => (typeof value === "object" ? value?.value : value)),
  role: yup
    .string()
    .transform((value) => (typeof value === "object" ? value?.value : value)),
  internal_daily_rate: validationSchemaForRequiredField("Internal Daily Rate"),
  // validation for linkedin_account should be like www.domain.com or http://www.domain.com or https://www.domain.com or '' (empty string)
  linkedin_account: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .matches(
      /^(https?:\/\/)?(www\.)[a-z0-9-]+(\.[a-z]{2,6})+([/\w.-]*)*\/?$/,
      "Please enter the correct link format"
    )
    .nullable(),
});
