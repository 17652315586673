import {useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {usePermission} from "../../../../hooks/usePermission";
import talentsActions from "../../../../actions/talentsActions";
import projectsActions from "../../../../actions/projectsActions";
import {ReactComponent as IconCat} from "../../../../assets/Icons/assignContractor.svg";

import Loader from "../../../../components/Loader";
import NoData from "../../../../components/NoData";
import ModalWrap from "../../../../components/ModalWrap";
import AssignContractor from "./AssignContractor";
import TeamList from "./TeamList";

const TeamTab = (
	{
		projectId
	}
) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const isPermittedManageProjectTeam = usePermission(
		['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']
	);
	const {talentsNames, talentsNamesLoading} = useSelector(state => state.talentsPageReducer);
	const {teamData, teamDataLoading} = useSelector(state => state.projectsPageReducer);
	useLayoutEffect(() => {
		dispatch(talentsActions.getTalentsNames());
		dispatch(projectsActions.getTeamData(projectId));
	}, [dispatch, projectId]);
	if (talentsNamesLoading || teamDataLoading) return <Loader position='relative' width='100%' height='100%'/>;
	return (
		<>
			{
				teamData?.total > 0 ?
					<TeamList
						teamData={teamData}
						isPermittedManageProjectTeam={isPermittedManageProjectTeam}
						projectId={projectId}
						setShowModal={setShowModal}
					/> :
					<NoData
						isPermittedButton={isPermittedManageProjectTeam}
						handleClick={() => setShowModal(true)}
						buttonText='Assign Talent'
						text='You don`t have a team yet'
						margin={'0'}
					/>
			}
			{showModal &&
				<ModalWrap
					children={
						<AssignContractor
							talentsNames={talentsNames}
							closeModal={() => setShowModal(false)}
							projectId={projectId}
						/>
					}
					close={() => setShowModal(false)}
					icon={<IconCat/>}
					leftPositionIcon='-45px'
					topPositionIcon='25px'
					widthIcon='134px'
					heightIcon='134px'
				/>}
		</>
	);
}
export default TeamTab;