import {defaultQueryForGetData, getErrorMessages, getFilterQuery, queryString} from "../utils";
import {authenticatedRequest} from "../APIGateway/gateway";
import commonActions from "./commonActions";

const timeLogsActions = {
	getTimeLogsData(projectId, queries = defaultQueryForGetData) {
		return (dispatch, getState) => {
			const {selectedFilterOption} = getState().timeLogsTabReducer;
			const filterData = [];
			if (selectedFilterOption?.values?.length > 0) {
				filterData.push(selectedFilterOption)
			}
			dispatch({
				type: 'SET_TIME_LOGS_DATA_LOADING',
				payload: true
			});
			authenticatedRequest({
				url: 'GetTimeLogsData',
				method: 'get',
				paths: [projectId],
				queryParams: queryString({...queries, page_size: 1000}) + getFilterQuery('invoice_time_log', filterData)
			}).then((response) => {
					if (!selectedFilterOption?.values?.length > 0) {
						const filterOptions = response.data?.items?.map((item) => {
								return {
									label: 'Invoice №' + item?.number,
									value: item?.invoice_id
								}
							}
						);
						dispatch({
							type: 'SET_FILTER_OPTIONS_LIST',
							payload: filterOptions
						});
					}
					dispatch({
						type: 'SET_TIME_LOGS_DATA',
						payload: response.data
					})
				}, (error) => {
					console.log('error===>', error);
					dispatch({
						type: 'SET_TIME_LOGS_DATA_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	setFilters(data) {
		return dispatch => {
			dispatch({
				type: 'SET_SELECTED_FILTER_OPTION',
				payload: data,
			});
		}
	},
	addTimeLogExpense(projectId, timeLogId, payload) {
		return async (dispatch) => {
			try {
				return await authenticatedRequest({
					url: `AddTimeLogExpense`,
					method: 'post',
					paths: [projectId, timeLogId],
					data: payload,
				});
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	},
	editTimeLogExpense(projectId, timeLogId, expenseId, payload) {
		return async (dispatch) => {
			try {
				return await authenticatedRequest({
					url: `EditTimeLogExpense`,
					method: 'patch',
					paths: [projectId, timeLogId, expenseId],
					data: payload,
				});
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	},
	deleteTimeLogExpense(projectId, timeLogId, expenseId) {
		return async (dispatch) => {
			try {
				return await authenticatedRequest({
					url: `DeleteTimeLogExpense`,
					method: 'delete',
					paths: [projectId, timeLogId, expenseId],
				});
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	},
	editTimeLogReport(projectId, timeLogId, timeLogReportId, payload) {
		return async (dispatch) => {
			try {
				return await authenticatedRequest({
					url: `EditTimeLogReport`,
					method: 'patch',
					paths: [projectId, timeLogId, timeLogReportId],
					data: payload,
				});
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	}
}

export default timeLogsActions;