
const initialState = {
	activePage: '',
	posts: null,
	loginSuccess: false,
	authLoading: false,
	requestForPasswordRecoveryWasSent: false,
	requestForPasswordRecoveryError: null,
	passwordSuccessfullyChanged: false,
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_ACTIVE_PAGE_IN_ROUT": {
			return {
				...state,
				activePage: action.payload
			}
		}
		case "SET_POSTS": {
			return {
				...state,
				posts: action.payload
			}
		}
		case "SET_LOGIN_SUCCESS": {
			return {
				...state,
				loginSuccess: action.payload
			}
		}
		case "SET_AUTH_LOADING": {
			return {
				...state,
				authLoading: action.payload
			}
		}
		case "SET_REQUEST_FOR_RECOVERY": {
			return {
				...state,
				requestForPasswordRecoveryWasSent: action.payload
			}
		}
		case "SET_REQUEST_FOR_RECOVERY_ERROR": {
			return {
				...state,
				requestForPasswordRecoveryError: action.payload
			}
		}
		case "SET_PASSWORD_SUCCESSFULLY_CHANGED": {
			return {
				...state,
				passwordSuccessfullyChanged: action.payload
			}
		}
		default: return state;
	}
}