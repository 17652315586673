import styled, {css} from "styled-components";
import {FlexBox} from "../../../../components/commonStyled";

export const WrapperWithBg = styled(FlexBox)`
  border-radius: 6px;
  background: var(--up-blue-p100);
  border: 1px solid ${({borderColor}) => borderColor || 'var(--up-light-blue-p400)'};
  min-height: ${({minHeight}) => minHeight || 'calc(100% - 66px)'};
  padding: ${({padding}) => padding || '40px 0 0'};
  margin: ${({margin}) => margin || ' 30px 0 0'};
  overflow: ${({overflow}) => overflow || 'hidden'};
	transition: all 0.3s ease-in-out;

  ${(props) => {
    if (!props.isOpened) {
      return css`
				min-height: auto;
        height: ${props?.heightInClosed || '85px'};
      `;
    }
  }}
`;

export const EconomicsTableWrapper = styled.div`
  padding: ${({padding}) => padding || '0 12px'};
  width: 100%;

  table {
    box-shadow: var(--main-box-shadow);

    td {
      width: ${({widthColumn}) => widthColumn || '19%'};
      padding: ${({paddingTd}) => paddingTd || '7px 4px'};
      border-bottom: 1px solid var(--up-white);

      &:first-child {
        padding-left: 16px;
      }

      &.with-bg {
        background: ${({tdBackground}) => tdBackground || 'var(--up-white)'};
      }

      &:nth-last-child(2) {
        padding-right: 16px;
      }

    }

    tr {
      &.with-border-radius {
        td {
          &:first-child {
            border-radius: 0 0 0 6px;
          }

          &:last-child {
            border-radius: 0 0 6px 0;
          }
        }
      }
    }
  }
`;

