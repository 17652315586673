import styled from "styled-components";
import {FlexBox} from "../commonStyled";

export const AccountBlock = styled(FlexBox)`
	padding: 10px 14px;
	cursor: pointer;
	h2, h3, div, img, svg {
    cursor: pointer;
  }
	&:hover {
		h2, h3 {
			color: var(--up-light-blue-p400);
		}
  }
	`;

export const AccountInfo = styled(FlexBox)`
	`;