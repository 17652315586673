import {memo} from "react";
import {FlexBox} from "../../../../../components/commonStyled";
import HeaderTitle from "../../../../../components/HeaderTitle";
import {reducingNumber} from "../../../../../utils";

const SummaryBlock = memo((
	{
		isDisabledBudget,
		projectBudget,
		contractorCost,
		grossProfitMargin,
		grossProfitMarginPercent,
		setProjectBudget,
		editMode,
		sumExpenses,
		sumExpensesUnReimbursed
	}
) => {
	const gross = `${grossProfitMargin} (${grossProfitMarginPercent}%)`;
	return (
		<FlexBox justifyContent={'space-around'} width={'100%'} padding={'50px 12px 20px'} gap={'20px 0'} flexWrap={'wrap'}>
			<HeaderTitle
				label='Project Budget'
				disabled={!editMode}
				value={projectBudget}
				isInput={!isDisabledBudget}
				setValue={setProjectBudget}
				inputFontSize={'26px'}
			/>
			<HeaderTitle
				label='Contractor Cost'
				disabled={true}
				value={contractorCost}
				inputFontSize={'26px'}
			/>
			<HeaderTitle
				label='Reimbursed Expenses'
				disabled={true}
				value={reducingNumber(sumExpenses - sumExpensesUnReimbursed) + ''}
				inputFontSize={'26px'}
			/>
			<HeaderTitle
				label='Unreimbursed Expenses '
				disabled={true}
				value={reducingNumber(sumExpensesUnReimbursed)}
				inputFontSize={'26px'}
			/>
			<HeaderTitle
				label='Gross Profit (Margin)'
				disabled={true}
				type={'text'}
				value={gross}
				maxWidth={'400px'}
				inputFontSize={'26px'}
			/>
		</FlexBox>
	)
})

export default SummaryBlock;