import {useEffect, useRef, Children, useState,} from "react";
import {CountOfHiddenItems, SelectValueContainer, ValueContainerWrap} from "./styled";
import {components} from "react-select";

const ValueContainer = (props) => {
	const {children} = props;
	const itemsRef = useRef([]);
	const wrapRef = useRef(null);
	const [countOfHiddenItems, setCountOfHiddenItems] = useState(0);
	const [leftPositionCountOfHiddenItems, setLeftPositionCountOfHiddenItems] = useState(0);
	const optionsSelected = props?.selectProps?.value;
	
	const checkPos = () => {
		const wrapRightPosition = wrapRef?.current?.offsetWidth + wrapRef?.current?.offsetLeft;
		let count = 0;
		let left = 0;
		const updatedItems = itemsRef?.current?.map((item) => {
				if (item && item.style) {
					item.style.display = 'inline-flex';
				}
				return item;
			}
		);
		updatedItems?.forEach((item) => {
			const itemRightPosition = item?.offsetWidth + item?.offsetLeft;
			if (item) {
				if (itemRightPosition > wrapRightPosition || itemRightPosition === 0) {
					count = count + 1;
					if (item && item.style) {
						item.style.display = 'none';
					}
				} else {
					if (!isNaN(itemRightPosition)) {
						left = itemRightPosition;
					}
					if (item && item.style) {
						item.style.display = 'inline-flex';
					}
				}
			}
		})
		setCountOfHiddenItems(count);
		setLeftPositionCountOfHiddenItems(left === 0 ? 12 : left);
	};
	
	useEffect(() => {
		checkPos()
	}, [optionsSelected])
	
	const childrenWithRef = Children.map(children, (child, index) => {
		return (
			<SelectValueContainer
				title={child?.props?.children}
				className={child?.props?.data?.value}
				ref={child?.key === 'placeholder' || child?.props.role === 'combobox' ? null : el => itemsRef.current[index] = el}
			>
				{child}
			</SelectValueContainer>
		);
	});
	
	return (
		<components.ValueContainer {...props} >
			<ValueContainerWrap ref={wrapRef}>
				{childrenWithRef}
				{countOfHiddenItems > 0 && <CountOfHiddenItems left={leftPositionCountOfHiddenItems}>
					+{countOfHiddenItems}
				</CountOfHiddenItems>}
			</ValueContainerWrap>
		</components.ValueContainer>
	);
};

export default ValueContainer;