import commonActions from "./commonActions";
import {defaultQueryForGetData, getErrorMessages, getFilterQuery, queryString} from "../utils";
import {authenticatedRequest} from "../APIGateway/gateway";
import {inputsAssignContractor} from "../pages/Projects/components/TeamTab/utils";
import {push} from "connected-react-router";

const talentsActions = {
	getTalentsData(queries = defaultQueryForGetData) {
		return (dispatch, getState) => {
			const state = getState();
			const {talentStatusFilter} = state.talentsPageReducer;
			const filterData = [];
			if (talentStatusFilter?.values?.length > 0) {
				filterData.push(talentStatusFilter)
			}
			dispatch({
				type: 'SET_TALENTS_DATA_LOADING',
				payload: true
			});
			
			authenticatedRequest({
				url: 'GetTalentsData',
				method: 'get',
				queryParams: queryString(queries) + getFilterQuery('contractor', filterData) + '&sort_by=full_name'
			}).then((response) => {
					dispatch({
						type: 'SET_TALENTS_DATA',
						payload: response.data
					})
				}, (error) => {
					console.log('error===>', error);
					dispatch({
						type: 'SET_TALENTS_DATA_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	getTalentsNames(isProjectTeam = false, projectId = null) {
		return (dispatch, getState) => {
			dispatch({
				type: 'SET_TALENTS_NAMES_LOADING',
				payload: true
			});
			
			authenticatedRequest({
				url: isProjectTeam ? 'GetProjectTeam' : 'GetTalentsNames',
				method: 'get',
				paths: isProjectTeam ? [projectId] : []
			}).then((response) => {
					const sortedTalents = response?.data?.sort((a, b) => a.full_name.localeCompare(b.full_name));
					dispatch({
						type: 'SET_TALENTS_NAMES',
						payload: sortedTalents
					})
					inputsAssignContractor[0].options = sortedTalents?.map(talent => {
						talent.label = talent.full_name
						talent.value = talent._id;
						return talent;
					});
					
				}, (error) => {
					dispatch({
						type: 'SET_TALENTS_NAMES_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	editTalent(talentId, data, updateOfActiveTalent = false, projectId = null, isProjectsTalent = false) {
		return (dispatch, getState) => {
			dispatch({
				type: 'SET_UPDATE_TALENT_DETAILS_LOADING',
				payload: true
			});
			const state = getState();
			const {talentsData} = state.talentsPageReducer;
			const {searchInputValue} = state.commonReducer;
			
			authenticatedRequest({
				url: isProjectsTalent ? 'UpdateProjectsTalent' : 'EditTalent',
				method: 'patch',
				data,
				paths: isProjectsTalent ? [projectId, talentId] : [talentId]
			}).then(() => {
					dispatch({
						type: 'SET_UPDATE_TALENT_DETAILS_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(isProjectsTalent ? 'Talent updated successfully' : 'Talent updated successfully', 'success'));
					if (updateOfActiveTalent) {
						dispatch(this.getTalentDetails(talentId, projectId, isProjectsTalent));
					} else {
						dispatch(this.getTalentsData({
								query_str: searchInputValue,
								page: talentsData?.page,
								page_size: talentsData?.page_size,
							})
						);
					}
				}, (error) => {
					console.log('error===>', error);
					dispatch({
						type: 'SET_UPDATE_TALENT_DETAILS_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	setTalentStatusFilter(data) {
		return dispatch => {
			dispatch({
				type: 'SET_TALENT_STATUS_FILTER',
				payload: data
			})
		}
	},
	createNewTalent(data) {
		return dispatch => {
			dispatch({
				type: 'SET_CREATE_NEW_TALENT_LOADING',
				payload: true
			});
			
			authenticatedRequest({
				url: 'CreateNewTalent',
				method: 'post',
				data
			}).then(() => {
					dispatch({
						type: 'SET_CREATE_NEW_TALENT_SUCCESS',
						payload: true
					});
					dispatch(commonActions.setPopupMessage('Talent created successfully', 'success'));
				}, (error) => {
					dispatch({
						type: 'SET_CREATE_NEW_TALENT_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	resetAccessCreateNewTalent() {
		return dispatch => {
			dispatch({
				type: 'SET_CREATE_NEW_TALENT_SUCCESS',
				payload: false
			});
		}
	},
	deleteTalent(talentId) {
		return (dispatch, getState) => {
			const state = getState();
			const {talentsData} = state.talentsPageReducer;
			const {searchInputValue} = state.commonReducer;
			dispatch({
				type: 'SET_TALENTS_DATA_LOADING',
				payload: true
			});
			
			authenticatedRequest({
				url: 'DeleteTalent',
				method: 'delete',
				paths: [talentId]
			}).then(() => {
					dispatch({
						type: 'SET_TALENTS_DATA_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage('Talent deleted successfully', 'success'));
					dispatch(this.getTalentsData({
							query_str: searchInputValue,
							page: talentsData?.page,
							page_size: talentsData?.page_size,
						})
					);
				}, (error) => {
					dispatch({
						type: 'SET_TALENTS_DATA_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	},
	getTalentDetails(talentId, projectId = null, isProjectsTalent = false) {
		return dispatch => {
			dispatch({
				type: 'SET_TALENT_DETAILS_LOADING',
				payload: true
			});
			
			authenticatedRequest({
				url: isProjectsTalent ? 'GetProjectsTalentDetails' : 'GetTalentDetails',
				method: 'get',
				paths: isProjectsTalent ? [projectId, talentId] : [talentId]
			}).then((response) => {
					dispatch({
						type: 'SET_TALENT_DETAILS',
						payload: response.data
					});
				}, (error) => {
					if (error?.response?.data?.errors?.contractor_id[0] === 'Value error, Invalid objectid') {
						dispatch(push('/not-found'));
						window.location.reload();
					} else {
						dispatch({
							type: 'SET_TALENT_DETAILS_LOADING',
							payload: false
						});
						dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					}
				}
			);
		}
	}
}

export default talentsActions;