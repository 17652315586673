import * as CS from '../commonStyled';

import TextBox from "../TextBox";
import Button from "../Button";
import PropTypes from "prop-types";
import {ButtonClose} from "../ModalWrap/styled";
import {ReactComponent as IconClose} from "../../assets/Icons/close.svg";

const ConfirmationModal = (
	{
		closeModal,
		handleConfirm,
		title = 'Log Out',
		message = 'Are you sure you want to log out?',
		buttonText = 'Log Out'
	}
) => {
	return (
		<div>
			<ButtonClose onClick={closeModal}><IconClose/></ButtonClose>
			<TextBox as='h2' size='md' weight='medium' marginBottom='14px'>{title}</TextBox>
			<TextBox as='p' size='sm' marginBottom='24px' lineHeight='20px' style={{wordBreak: 'break-word'}}>{message}</TextBox>
			<CS.FlexBox justifyContent='space-between'>
				<Button handleClick={closeModal} ghost={true} label='Cancel'/>
				<Button handleClick={handleConfirm} label={buttonText}/>
			</CS.FlexBox>
		</div>
	)
}
ConfirmationModal.propTypes = {
	closeModal: PropTypes.func,
	logout: PropTypes.func,
}
export default ConfirmationModal;