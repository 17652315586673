export const AddressMap = (paths= []) => {
	return {
		'LogIn': '/api/v1/auth/token',
		'RefreshToken': '/api/v1/auth/refresh',
		'LogOut': '/api/v1/auth/logout',
		'GetUserData': '/api/v1/auth/me',
		'PasswordRecoveryInvite': '/api/v1/auth/password-recovery',
		'PasswordRecoverySetPassword': '/api/v1/auth/password-recovery/set-password',
		'PasswordRecoveryTokenVerify': '/api/v1/auth/password-recovery/token-verify',
		'CreateClient': '/api/v1/clients/',
		'GetClientsList': '/api/v1/clients/',
		'SetupUser': '/api/v1/users/profile-setup/user',
		'SetupContractor': '/api/v1/users/profile-setup/contractor',
		'DeleteClient': `/api/v1/clients/${paths[0]}`,
		'GetClientsNames': '/api/v1/clients/names',
		'GetProjectsCodes': `/api/v1/clients/${paths[0]}/projects/codes`,
		'CreateNewProject': '/api/v1/projects/',
		'GetProjectsList': '/api/v1/projects/',
		'GetProjectData': `/api/v1/projects/${paths[0]}`,
		'UpdateProject': `/api/v1/projects/${paths[0]}`,
		'GetClientDetails': `/api/v1/clients/${paths[0]}`,
		'DeleteProject': `/api/v1/projects/${paths[0]}`,
		'EditClientDetails': `/api/v1/clients/${paths[0]}`,
		'CreateContact': `/api/v1/clients/${paths[0]}/contacts/`,
		'GetClientContactsList': `/api/v1/clients/${paths[0]}/contacts/`,
		'DeleteContact': `/api/v1/clients/${paths[0]}/contacts/${paths[1]}`,
		'EditContact': `/api/v1/clients/${paths[0]}/contacts/${paths[1]}`,
		'GetDocuments': `/api/v1/${paths[0]}/${paths[1]}/documents/`,
		'UploadDocuments': `/api/v1/${paths[0]}/${paths[1]}/documents/upload/`,
		'GetTalentsData': '/api/v1/users/contractors/',
		'UploadNewVersionOfDocument': `/api/v1/${paths[0]}/${paths[1]}/documents/${paths[2]}`,
		'DeleteDocument': `/api/v1/${paths[0]}/${paths[1]}/documents/${paths[2]}`,
		'EditTalent': `/api/v1/users/contractors/${paths[0]}`,
		'CreateNewTalent': '/api/v1/users/contractors/invite',
		'DeleteTalent': `/api/v1/users/contractors/${paths[0]}`,
		'GetTalentDetails': `/api/v1/users/contractors/${paths[0]}`,
		'ExportAllClientContacts': `/api/v1/clients/${paths[0]}/contacts/export/contacts`,
		'ExportClientContact': `/api/v1/clients/${paths[0]}/contacts/export/contacts/${paths[1]}`,
		'GetTeamData': `/api/v1/projects/${paths[0]}/contractors`,
		'DeleteContractorFromTeam': `/api/v1/projects/${paths[0]}/contractors/${paths[1]}`,
		'GetProjectsTalentDetails': `/api/v1/projects/${paths[0]}/contractors/${paths[1]}`,
		'UpdateProjectsTalent': `/api/v1/projects/${paths[0]}/contractors/${paths[1]}`,
		'GetTalentsNames': '/api/v1/users/contractors/names/',
		'AddContractorToTeam': `/api/v1/projects/${paths[0]}/contractors/${paths[1]}`,
		'GetEconomicsData': `/api/v1/projects/${paths[0]}/economic/${paths[1]}`,
		'GetExpensesCategories': `/api/v1/projects/${paths[0]}/economic/expenses-categories`,
		'UpdateEconomicsData': `/api/v1/projects/${paths[0]}/economic/${paths[1]}`,
		'DownloadEconomicsFile': `/api/v1/projects/${paths[0]}/economic/${paths[1]}/xlsx`,
		'GetProjectTeam': `/api/v1/projects/${paths[0]}/contractors/names`,
		'GetInvoices': `/api/v1/projects/${paths[0]}/invoices/`,
		'AddInvoice': `/api/v1/projects/${paths[0]}/invoices/`,
		'EditInvoice': `/api/v1/projects/${paths[0]}/invoices/${paths[1]}`,
		'DeleteInvoice': `/api/v1/projects/${paths[0]}/invoices/${paths[1]}`,
		'GetProfileData': `/api/v1/users/${paths[0]}/profile`,
		'EditProfileData': `/api/v1/users/${paths[0]}/profile`,
		'GetTimeLogsData': `/api/v1/projects/${paths[0]}/invoice_logs/`,
		'AddTimeLogExpense': `/api/v1/projects/${paths[0]}/invoice_logs/${paths[1]}/expenses`,
		'EditTimeLogExpense': `/api/v1/projects/${paths[0]}/invoice_logs/${paths[1]}/expenses/${paths[2]}`,
		'DeleteTimeLogExpense': `/api/v1/projects/${paths[0]}/invoice_logs/${paths[1]}/expenses/${paths[2]}`,
		'EditTimeLogReport': `/api/v1/projects/${paths[0]}/invoice_logs/${paths[1]}/time_logs/${paths[2]}`,
		'GetTypesOfDocuments': `/api/v1/documents/types`,
		'EditDocument': `/api/v1/${paths[0]}/${paths[1]}/documents/${paths[2]}`,
		'GetTeamMembers': `/api/v1/users/`,
		'InviteUser': `/api/v1/users/invite`,
		'DeleteUser': `/api/v1/users/${paths[0]}`,
		'GetTeamMemberData': `/api/v1/users/${paths[0]}`,
		'EditTeamMemberData': `/api/v1/users/${paths[0]}`,
	}
}