import * as S from './styled';
import {ReactComponent as IconCat} from "../../assets/Icons/pencil-sharpener.svg";
import PropTypes from "prop-types";
import Loader from "../Loader";

const Modal = (
	{
		icon = <IconCat/>,
		width = '400px',
		paddingTop = '30px',
		topPositionIcon = '50px',
		leftPositionIcon = '-20px',
		children = null,
		// close,
		loading = false,
		widthIcon = 'auto',
		heightIcon = 'auto',
		heightModalContent = '80vh',
		overflow = null
	}
) => {
	return (
		<S.ModalWrap>
			<S.ModalContent width={width} paddingTop={paddingTop} heightModalContent={heightModalContent} overflow={overflow}>
					<S.IconWrap
						topPositionIcon={topPositionIcon}
						leftPositionIcon={leftPositionIcon}
						widthIcon={widthIcon}
						heightIcon={heightIcon}
					>
						{icon}
					</S.IconWrap>
					{loading && <Loader height='100%' width='100%' position='absolute' background={'var(--up-white)'}/> }
					{children}
			</S.ModalContent>
		</S.ModalWrap>
	);
};
Modal.propTypes = {
	icon: PropTypes.node,
	width: PropTypes.string,
	paddingTop: PropTypes.string,
	topPositionIcon: PropTypes.string,
	leftPositionIcon: PropTypes.string,
	close: PropTypes.func,
	children: PropTypes.node,
}
export default Modal;