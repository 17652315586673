const initialState = {
	talentsData: null,
	talentsDataLoading: false,
	talentStatusFilter: null,
	createNewTalentLoading: false,
	createNewTalentSuccess: false,
	talentDetails: null,
	talentDetailsLoading: false,
	updateTalentDetailsLoading: false,
	talentsNames: null,
	talentsNamesLoading: false,
};

export default function projectsPageReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_TALENTS_DATA": {
			return {
				...state,
				talentsData: action.payload,
				talentsDataLoading: false,
			}
		}
		case "SET_TALENTS_DATA_LOADING": {
			return {
				...state,
				talentsDataLoading: action.payload
			}
		}
		case "SET_TALENT_STATUS_FILTER": {
			return {
				...state,
				talentStatusFilter: action.payload
			}
		}
		case "SET_CREATE_NEW_TALENT_LOADING": {
			return {
				...state,
				createNewTalentLoading: action.payload
			}
		}
		case "SET_CREATE_NEW_TALENT_SUCCESS": {
			return {
				...state,
				createNewTalentLoading: false,
				createNewTalentSuccess: action.payload,
			}
		}
		case "SET_TALENT_DETAILS": {
			return {
				...state,
				talentDetails: action.payload,
				talentDetailsLoading: false,
			}
		}
		case "SET_TALENT_DETAILS_LOADING": {
			return {
				...state,
				talentDetailsLoading: action.payload
			}
		}
		case "SET_UPDATE_TALENT_DETAILS_LOADING": {
			return {
				...state,
				updateTalentDetailsLoading: action.payload
			}
		}
		case "SET_TALENTS_NAMES": {
			return {
				...state,
				talentsNames: action.payload,
				talentsNamesLoading: false,
			}
		}
		case "SET_TALENTS_NAMES_LOADING": {
			return {
				...state,
				talentsNamesLoading: action.payload
			}
		}
		default:
			return state;
	}
}