import {WrapperWithBg} from "../Economics/styled";

import TimeLog from "./TimeLog";
import TextBox from "../../../../components/TextBox";
import ExpensesWrapper from "./ExpensesWrapper";

const Invoice = (
	{
		data,
		projectId
	}
) => {
	return (
		<>
			{
				data?.items.map((item, index) => (
						<WrapperWithBg
							as='section'
							flexDirection='column'
							gap='12px'
							minHeight={'auto'}
							borderColor={'var(--up-main-light-grey)'}
							margin={'17px 0 24px'}
							padding={'10px 12px'}
							key={item?._id}
							isOpened={'true'}
							overflow={'visible'}
						>
							<TextBox as='h2' size='md' weight='medium' color='var(--up-main-blue)' lineHeight='28px'>
								Invoice №{item?.number}
							</TextBox>
							<TimeLog timeLog={item?.time_logs} projectId={projectId} timeLogId={item?._id}/>
							<ExpensesWrapper expenses={item?.expenses} projectId={projectId} timeLogId={item?._id}/>
						</WrapperWithBg>
					)
				)
			}
		</>
	)
}

export default Invoice;