import {useState} from "react";
import authActions from "../../actions/authActions";
import {useDispatch} from "react-redux";
import * as S from './styled';

import {ReactComponent as IconExit} from "../../assets/Icons/exit.svg";

import ModalWrap from "../ModalWrap";
import ConfirmationModal from "../modals/ConfirmationModal";
import Account from "../Account";
import moment from "moment";

const LogOut = () => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	
	const handleLogout = () => {
		dispatch(authActions.redirectToLogin());
		localStorage.setItem('logoutSignal', moment().format('YYYY-MM-DD HH:mm:ss'));
	};
	const handleShowModal = () => {
		setShowModal(true);
	};
	const handleCloseModal = () => {
		setShowModal(false);
	}
	return (
		<>
			<S.LogOut as='a' gap='18px' alignItems='center' width='100%' onClick={handleShowModal}>
				<IconExit/>
				Log out
			</S.LogOut>
			<Account />
			{showModal &&
			<ModalWrap
				paddingTop='75px'
				children={<ConfirmationModal handleConfirm={handleLogout} closeModal={handleCloseModal}/>}
				close={handleCloseModal}
			/>}
		</>
	)
}

export default LogOut;