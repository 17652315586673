import styled from 'styled-components';
import {FlexBox} from "../commonStyled";

export const LogOut = styled(FlexBox)`
	margin-top: auto;
	padding: 0 20px 25px 20px;
	font-size: 18px;
  color: var(--up-blue-p100);
	position: relative;
	cursor: pointer;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 16px;
		width: calc(100% - 32px);
		border-bottom: 1px solid var(--up-blue-p100);
	}
  &:hover {
    color: var(--up-blue-p200);
    svg {
      path {
        fill: var(--up-blue-p200);
      }
    }
  }

  &:active {
    color: var(--up-secondary--light-blue);
    svg {
      path {
        fill: var(--up-secondary--light-blue);
      }
    }
  }
`;