import styled from 'styled-components';

export const Button = styled.button`
  display: inline-flex;
	align-items: center;
	justify-content: center;
  background-color: ${({ghost, buttonColor}) => ghost ? 'transparent' : buttonColor};
  border: 1px solid var(--up-main-blue);
  padding: ${({padding})=> padding};
  border-radius: ${({borderRadius}) => borderRadius};
  font-size: ${({fontSize}) => fontSize || '14px'};
  color: ${({ghost, buttonLabelColor}) => ghost ? 'var(--up-main-blue)' : buttonLabelColor};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
  //transition: all .15s ease-in 0s;
	min-width: ${({minWidth}) => minWidth};
  width: ${({fullWidth, width}) => fullWidth ? '100%' : width};
	gap: ${({gap}) => gap};
	flex-direction: ${({flexDirection}) => flexDirection || 'row'};
	
	svg {
		width:${({sizeIcon}) => sizeIcon};
		min-width: ${({sizeIcon}) => sizeIcon};
		height: ${({sizeIcon}) => sizeIcon};
		path {
			fill: ${({ghost, buttonLabelColor}) => ghost ? 'var(--up-main-blue)' : buttonLabelColor};
		}
	}

  &:not(:disabled) {
    &:hover,
    &:focus {
      outline: none;
      background-color: ${({ghost}) => ghost ? 'var(--up-blue-p100)' : ' var(--up-main-blue-hover)'};
      border-color: ${({ghost}) => ghost ? 'currentColor' : 'var(--up-main-blue-hover)'};
    }
    &:active {
      background-color: ${({ghost}) => ghost ? 'var(--up-light-blue-p400)' : 'var(--up-main-dark-blue)'};
      border-color: ${({ghost}) => ghost ? 'currentColor' : 'var(--up-main-dark-blue)'};
    }
	}
 
	
  //@media (max-width: 768px) {
  //  padding: 12px 32px;
  //}
	
  &:disabled {
		border-color: var(--up-main-grey);
		color: var(--up-main-grey);
		cursor: not-allowed;
		path {
			fill: var(--up-main-grey);
		}
  }
`;
