import { useEffect, useState, useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaCreateNewProject, inputsCreateNewProject } from "../../utils";
import { FlexBox, StickyHeader } from "../../../../components/commonStyled";
import { useDispatch, useSelector } from "react-redux";
import projectsActions from "../../../../actions/projectsActions";
import { generateNextCode, isFormChanged } from "../../../../utils";
import { ButtonEdit, GroupButtons, PaymentType } from "../../styled";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Pen } from "../../../../assets/Icons/pen.svg";

import TextBox from "../../../../components/TextBox";
import GroupInputs from "../../../../components/GroupInputs";
import Button from "../../../../components/Button";
import { usePermission } from "../../../../hooks/usePermission";
import Loader from "../../../../components/Loader";
import ModalWrap from "../../../../components/ModalWrap";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

const GeneralInformation = (
  {
    editMode,
    setEditMode,
    clientsNames = [],
    isCreateProject,
    projectData = {},
    projectID
  }
) => {
  const [fields, setFields] = useState(inputsCreateNewProject);
  const isPermittedUpdateProject = usePermission(
    ['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    resetField,
    reset,
    watch,
    clearErrors
  } = useForm({
    resolver: yupResolver(schemaCreateNewProject)
  });
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { activeClientName, projectsCreateLoading } = useSelector(state => state.projectsPageReducer);
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    let payload = data
    if (data.invoicing_date?.value) {
      payload = { ...data, invoicing_date: data.invoicing_date.value }
    }
    if (isCreateProject) {
      const projectId = await dispatch(projectsActions.createNewProject(payload));
      if (projectId) {
        navigate(`/projects/${projectId}`);
      }
    } else {
      dispatch(projectsActions.updateProject(payload, projectID));
      setEditMode(false);
    }
  };
  useLayoutEffect(() => {
    if (clientsNames) {
      inputsCreateNewProject[0].options = clientsNames;
    }
  }, [clientsNames]);
  useEffect(() => {
    if (activeClientName) {
      const nextCode = generateNextCode(activeClientName.codes, activeClientName.code);
      resetField('code', { defaultValue: nextCode });
    }
  }, [activeClientName, resetField]);
  useEffect(() => {
    return () => {
      dispatch(projectsActions.setActiveClientName(null));
    }
  }, [dispatch]);

  const checkIfDisabled = () => {
    if (isCreateProject) {
      return false;
    } else {
      return !editMode && !isCreateProject;
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (isFormChanged(getValues(), projectData)) {
      setShowModal(true);
    } else {
      setEditMode(false);
    }
  };

  const handleEditMode = (e) => {
    if (!editMode) {
      setEditMode(true);
    } else {
      handleCancel(e)
    }
  };

  const handleConfirm = () => {
    setShowModal(false);
    if (isCreateProject) {
      navigate('/projects');
    } else {
      reset();
      setEditMode(false);
      dispatch(projectsActions.getProjectData(projectID));
    }
  };

  const invoicingType = watch('invoicing_type');
  useEffect(() => {
    if (invoicingType === 'once_a_month') {
      setFields(pre => pre.map(e => e.name === 'invoicing_date' ? { ...e, disabled: false } : e))
    } else {
      setFields(pre => pre.map(e => e.name === 'invoicing_date' ? { ...e, disabled: true } : e))
    }

  }, [invoicingType])

  return (
    <>
      <StickyHeader justifyContent={'space-between'}>
        <TextBox as='h1' size='md' weight='medium' color='dark' lineHeight='28px'>
          General Information
          {projectData.owner_full_name && (
            <span>Owner - {projectData.owner_full_name}</span>
          )}
        </TextBox>
        <FlexBox alignItems='center' gap='12px'>
          {
            projectData?.payment_type &&
            <PaymentType>{projectData?.payment_type === 'fixed_fee' ? 'Fixed Fee' : 'Staffing'}</PaymentType>
          }
          {
            (!isCreateProject && isPermittedUpdateProject) &&
            <ButtonEdit
              as='button'
              onClick={handleEditMode}
              editMode={editMode}
              alignItems='center'
              justifyContent='center'
            >
              <Pen />
            </ButtonEdit>
          }
        </FlexBox>
      </StickyHeader>
      {projectsCreateLoading ?
        <Loader position='relative' width='100%' height='75vh' background={'var(--up-white)'} /> :
        <FlexBox margin={'30px 0 0'} as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'
          position='static'>
          <GroupInputs
            inputs={fields}
            errors={errors}
            register={register}
            control={control}
            gap='12px 12px'
            marginTopForError='20px'
            setValue={setValue}
            getValues={getValues}
            defaultData={projectData}
            disabled={checkIfDisabled()}
            width={'100%'}
            clearErrors={clearErrors}
          />
          {(isCreateProject || editMode) && <GroupButtons justifyContent='space-between' width='100%'>
            <Button type='cancel' label='Cancel' ghost={true} handleClick={handleCancel} />
            <Button type='send' label='Save' />
          </GroupButtons>}
        </FlexBox>
      }
      {showModal &&
        <ModalWrap
          paddingTop='75px'
          children={
            <ConfirmationModal
              handleConfirm={handleConfirm}
              closeModal={() => setShowModal(false)}
              title={isCreateProject ? 'Close the creation process' : 'Close the editing process'}
              message={
                isCreateProject ?
                  'Newly provided data will be lost. Do you want to proceed?' :
                  'Are you sure that you want to close edit process without saving?'
              }
              buttonText='Yes, Close'
            />
          }
          close={() => setShowModal(false)}
        />}
    </>
  );
}

export default GeneralInformation;
