import * as S from "../../pages/SetUp/styled";
import * as CS from "../commonStyled";
import { useState } from "react";
import { TextBox } from "../TextBox/styled";
import FormInput from "../FormInput";

const GroupInputs = (
	{
		inputs,
		register,
		width,
		control,
		setValue,
		getValues,
		errors = {},
		defaultData = {},
		disabled = false,
		fullWidthInput = false,
		gap = '24px 20px',
		marginTopForError = '24px',
		justifyContent = 'flex-start',
		clearErrors,
		previewDoc
	}
) => {
	const [disabledInputs, setDisabledInputs] = useState([]);
	const getDefaultValueCheckbox = (input) => {
		return typeof defaultData[input.name] === 'boolean' ? defaultData[input.name] : true;
	};
	return (
		<CS.FlexBox flexWrap='wrap' gap={gap} justifyContent={justifyContent} width={width}>
			{inputs.map((input, index) => {
				const value = input?.type === 'checkbox' ?
					getDefaultValueCheckbox(input)
					: input?.type === 'number' ? defaultData[input?.name] :
						defaultData[input?.name] || input?.defaultValue || '';

				if (input?.isHidden) return null;
				//empty row is used for spacing between inputs
				if (input?.name === 'empty_row') return <S.FormInputBox
					key={input.name + index}
					customWidth={input.customWidth}
					margin={input.margin}
				/>;

				return <S.FormInputBox
					key={input.name + index}
					fullWidthInput={fullWidthInput}
					customWidth={input.customWidth}
					margin={input.margin}
				>
					{
						input?.blockTitle &&
						<TextBox as='h3' weight='medium' color='dark' lineHeight='20px' marginBottom='24px'>
							{input?.blockTitle}
						</TextBox>
					}
					<FormInput
						{...input}
						register={register}
						errors={errors}
						marginTopForError={marginTopForError}
						control={control}
						setValue={setValue}
						getValues={getValues}
						currency={defaultData['currency'] || null}
						disabledInputs={disabledInputs}
						setDisabledInputs={setDisabledInputs}
						disabled={input.disabled || disabled}
						isRequired={input?.required}
						placeholder={input?.placeholder || input?.label}
						defaultValue={value}
						clearErrors={clearErrors}
						previewDoc={previewDoc}
					/>
				</S.FormInputBox>
			})}
		</CS.FlexBox>
	)
}

export default GroupInputs;