import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import reducer from "./reducers/reducers";

const history = createBrowserHistory();

const middleware = applyMiddleware(
	routerMiddleware(history),
	thunk, createLogger({
	collapsed: true
}))

export default createStore(reducer, middleware);