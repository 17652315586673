import {useState, useEffect} from 'react';
import {InputFile, InputFileLabel, InputFileWrap, Logo} from "./styled";
import {ReactComponent as Plus} from "../../assets/Icons/plus.svg";
import {useDispatch} from "react-redux";
import commonActions from "../../actions/commonActions";
import {FlexBox} from "../commonStyled";
import {acceptableLogoFormats, maxSizeLogo} from "../../utils";
import AvatarCrop from "../LogoCrop";
import ModalWrap from "../ModalWrap";

const InputUploadLogo = (
	{props}
) => {
	const {defaultValue} = props;
	const dispatch = useDispatch();
	const [image, setImage] = useState(null);
	const [imageForEditor, setImageForEditor] = useState(null);
	const [showModal, setShowModal] = useState(false);
	useEffect(() => {
		if (defaultValue) {
			setImage(defaultValue);
			setImageForEditor(defaultValue);
		} else {
			setImage(null);
			setImageForEditor(null);
		}
	}, [defaultValue]);
	
	const handleChange = (e) => {
		const value = e.target.files[0];
		if (value) {
			if (value.size > maxSizeLogo) {
				dispatch(commonActions.setPopupMessage('File size is too big', 'error'));
			} else if (!acceptableLogoFormats.includes(value?.type)) {
				dispatch(commonActions.setPopupMessage('File format is not acceptable', 'error'));
			} else {
				setImageForEditor(URL.createObjectURL(value));
				setShowModal(true);
			}
		}
	};
	const handleCloseModal = (e) => {
		e.preventDefault();
		setShowModal(false);
	};
	
	const handleShowModal = (e) => {
		e.preventDefault();
		setShowModal(true);
	};
	return (
		<>
			<InputFileWrap>
				<InputFile
					type="file"
					accept="image/png, image/jpg, image/jpeg, image/svg+xml, image/svg"
					onChange={handleChange}
					id={props.name}
					filename={image}
					disabled={props.disabled}
					onClick={image ? handleShowModal : () => console.log('click')}
				/>
				<InputFileLabel as='label' htmlFor={props.name} gap='12px' alignItems='center'>
					<Logo>
						{image ?
							<img src={image} alt={props.label}/> :
							<Plus/>
						}
					</Logo>
					{!props.disabled && <FlexBox flexDirection='column' gap='4px'>
						<span className='label-text'>{image ? 'Edit Logo' : 'Add Logo'}</span>
						<span className='label-description'>jpg, jpeg, png, svg (max 5 mb)</span>
					</FlexBox>}
				</InputFileLabel>
			</InputFileWrap>
			{showModal &&
				<ModalWrap
					icon={null}
					paddingTop='24px'
					close={handleCloseModal}
					width={'600px'}
					heightModalContent={'95vh'}
					overflow={'auto'}
					children={
						<AvatarCrop
							src={imageForEditor}
							handleCloseModal={handleCloseModal}
							setValue={props.setValue}
							name={props.name}
							setImage={setImage}
							setSrc={setImageForEditor}
						/>
					}
				/>
			}
		</>
	)
}

export default InputUploadLogo;