import * as yup from "yup";

export const inputsAssignContractor = [
	{
		label: 'Talent Name',
		name: 'contractor',
		type: 'select',
		options: [],
		required: true,
	},
	{
		label: 'Time Limitation, h',
		name: 'limitation_hours',
		type: 'number',
		// required: true,
		textTransformLabel: 'auto',
	},
	{
		label: 'Internal Daily Rate',
		name: 'internal_daily_rate',
		type: 'text',
		disabled: true,
		specialSymbol: '$',
		disabledColor: 'var(--up-main-grey)',
	},
	{
		label: 'External Daily Rate',
		name: 'external_daily_rate',
		type: 'number',
		specialSymbol: '$',
		maxDigitsAfterDot: 2,
		maxLength: 5
		// required: true,
	}
];

export const schemaAssignContractor  = yup.object().shape({
		contractor: yup
			.mixed()
			.test('is-object', 'Please select an option', (value) => {
				// Custom validation function
				return typeof value === 'object' && value !== null;
			})
			.required('Contractor is required'),
		// limitation_hours: yup.string().required('Time Limitation is required'),
		// external_daily_rate: yup.string().required('External Daily Rate is required'),
	}
);