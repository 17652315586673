import PropTypes from "prop-types";
import * as S from './styled';
import * as CS from '../commonStyled';
import TextBox from "../TextBox";
import {ReactComponent as CloseIcon} from "../../assets/Icons/close.svg";
import {ReactComponent as Icon} from "../../assets/Icons/catForPopup.svg";

const initialState = {
	message: 'message',
	type: 'success' | 'error' | 'warning' | 'information',
};

const primaryHandler = (e) => {
	console.log('primary button clicked', e)
};

const PopUpMessage = ({popupMessage = initialState, handleClosePopupMessage = primaryHandler}) => {
	const {message, type} = popupMessage;
	return (
		message && <S.PopUpMessageWrap type={type}>
			<S.IconWrap as={Icon}/>
			<CS.FlexBox alignItems='center' gap='5px'>
				<TextBox as={'span'} size='sm' weight='medium' textTransform='capitalize' color='var(--up-white)'>
					{type + ':  '}
				</TextBox>
				<TextBox as={'span'} size='sm' weight='medium' color='var(--up-white)'>
					{message}
				</TextBox>
			</CS.FlexBox>
			<S.IconClose as={CloseIcon} onClick={handleClosePopupMessage}/>
		</S.PopUpMessageWrap>
	)
}

PopUpMessage.propTypes = {
	popupMessage: PropTypes.shape({
		message: PropTypes.string,
		type: PropTypes.string,
	}),
	handleClosePopupMessage: PropTypes.func.isRequired,
}
export default PopUpMessage;