import { useRef, useState } from "react";
import { FlexBox } from "../commonStyled";
import { useParams } from "react-router-dom";
import { formDataToArray } from "../../utils";
import { useSelector } from "react-redux";

import FileUploaderInput from "../FileUploaderInput";
import Document from "../Document";
import UploadContainerWithDrugAndDrop from "../UploadContainerWithDrugAndDrop";
import moment from "moment";

const ListOfAttachedDocuments = ({ props }) => {
	const { talentId } = useParams();
	const { defaultValue, setValue, disabled, name } = props;

	const [oldDocuments, setOldDocuments] = useState(defaultValue);
	const [newDocuments, setNewDocuments] = useState([]);
	const { userData } = useSelector(state => state.commonReducer);
	const uploadInput = useRef(null);
	const handleUpload = async (formData) => {
		const newUploadedDocuments = await formDataToArray(formData);
		const combinedDocuments = [...newDocuments, ...newUploadedDocuments];
		setNewDocuments(combinedDocuments);
		updateFormState(combinedDocuments);
	};
	const updateFormState = (documents) => {
		setValue(name, documents);
	};

	return (
		<FlexBox flexWrap={'wrap'} width={'100%'} gap={'12px'} as='ul' >
			{
				oldDocuments.concat(newDocuments).map((item, index) => {
					const name = item?.name || item?.filename;
					const userName = item?.created_user_fullname || userData?.full_name;
					const dateUploaded = moment(item?.created_at || new Date(), 'MM/DD/YYYYTHH:mm:ssZ').format('MM/DD/YYYY');
					const format = item?.format || name?.split('.').pop();
					return <Document
						key={index + '-' + name}
						item={item}
						format={format}
						parentId={talentId}
						scope={'contractors'}
						disabled={disabled}
						setUploadedDocuments={setOldDocuments}
						setDocuments={setNewDocuments}
						newDocuments={newDocuments}
						updateFormState={updateFormState}
						userName={userName}
						dateUploaded={dateUploaded}
						isOldDocument={item?._id} // if the document was added in another editing session, it has _id and is therefore considered old
						previewDoc={props?.previewDoc}
					/>
				}
				)
			}
			{disabled ? null : <FlexBox style={{ width: '100%', }} as={'li'}>
				<UploadContainerWithDrugAndDrop
					uploadInputRef={uploadInput}
					actionOfParentComponent={handleUpload}
					marginTop='0'
					height='150px'
					disabled={disabled}
					title={'Upload Document'}
				/>
				<FileUploaderInput
					ref={uploadInput}
					reducerAction={handleUpload}
				/>
			</FlexBox>}
		</FlexBox>
	);
}

export default ListOfAttachedDocuments;