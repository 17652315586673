import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import commonActions from "../actions/commonActions";
import {useRoutes} from "react-router-dom";
import PopUpMessage from "../components/PopUpMessage";
import Loader from "../components/Loader";
import Routes from "./Routes";

const MainLayout = () => {
	const dispatch = useDispatch();
	const {authLoading} = useSelector(state => state.authReducer);
	const {popupMessage} = useSelector(state => state.commonReducer);
	
	useEffect(() => {
		if (popupMessage) {
			const timer = setTimeout(() => {
				dispatch(commonActions.closePopupMessage());
			}, 5000);
			
			return () => {
				clearTimeout(timer);
			};
		}
	}, [popupMessage, dispatch]);
	
	const handleClosePopupMessage = () => {
		dispatch(commonActions.closePopupMessage());
	};
	const generatedRoutes = useRoutes(Routes());
	if (authLoading) {
		return <Loader/>
	}
	return (
		<>
			{generatedRoutes}
			{popupMessage ?
				<PopUpMessage popupMessage={popupMessage} handleClosePopupMessage={handleClosePopupMessage}/> : null}
		</>
	)
}

export default MainLayout;