import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import clientsActions from "../../../../actions/clientsActions";
import {usePermission} from "../../../../hooks/usePermission";
import {ReactComponent as IconCat} from "../../../../assets/Icons/crateElCat.svg";

import ModalWrap from "../../../../components/ModalWrap";
import ModalSuccessfully from "../../../../components/modals/modalSuccessfully";
import CreateNewClientContact from "../../../../components/modals/CreateNewClientContact";
import ContactsList from "./ContactsList";

const ContactsWrapper = (
	{
		activeClientID,
	}
) => {
	const {
		createContactLoading,
		createNewContactSuccess
	} = useSelector(state => state.clientsPageReducer);
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const isPermittedManageContact = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']);
	const isPermittedForNotes = usePermission(['super_admin', 'company_admin']);
	const closeModal = () => {
		if (createNewContactSuccess) dispatch(clientsActions.getClientContacts(activeClientID));
		setShowModal(false);
		dispatch(clientsActions.clearSuccessCreateNewContact());
	};
	
	return (
		<>
			<ContactsList
				activeClientID={activeClientID}
				isPermittedForNotes={isPermittedForNotes}
				isPermittedManageContact={isPermittedManageContact}
				setShowModal={setShowModal}
			/>
			{showModal &&
				<ModalWrap
					children={
						createNewContactSuccess ?
							<ModalSuccessfully handleClick={closeModal} title='Successfully '
																 message='Contact Successfully Created'/>
							:
							<CreateNewClientContact
								closeModal={closeModal}
								isPermittedForNotes={isPermittedForNotes}
								slug={activeClientID}
							/>
					}
					close={closeModal}
					icon={<IconCat/>}
					leftPositionIcon='calc(100% - 80px)'
					topPositionIcon='25px'
					loading={createContactLoading}
					width={'600px'}
				/>}
		</>
	)
}
export default ContactsWrapper;