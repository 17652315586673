import styled from "styled-components";

export const LogoLight = styled.svg`
	//width: 500px;
`;

export const Form = styled.form`
  width: 480px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  background: var(--up-white);
  border-radius: 6px;
  align-items: center;
`;


export const Link = styled.a`
	display: block;
	width: 100%;
	text-align: ${({textalign}) => textalign};
	font-weight: ${({bold}) => bold ? '500' : '400'};
	color: var(--up-secondary-blue);
	font-size: ${({fontSize}) => fontSize || '12px'};
	text-decoration: none;
`;

