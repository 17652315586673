export const invoicingTableColumns = [
	{
		title: 'Invoice №',
		dataIndex: 'invoice_number',
		key: 'invoice_number',
	},
	{
		title: 'Type',
		dataIndex: 'type',
		key: 'type',
	},
	{
		title: 'Month',
		dataIndex: 'month',
		key: 'month',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Invoice Date',
		dataIndex: 'payment_date',
		key: 'payment_date',
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		key: 'due_date',
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
	},
	{
		title: 'Document',
		dataIndex: 'document',
		key: 'document',
	},
	{
		title: '',
		dataIndex: 'action',
		key: 'action trash-icon',
	}
	];

export const statusOptionsInvoicing = [
	{
		value: 'draft',
		label: 'Draft',
		_id: 'draft'
	},
	{
		value: 'sent',
		label: 'Sent',
		_id: 'sent'
	},
	{
		value: 'unpaid',
		label: 'Unpaid',
		_id: 'unpaid'
	},
	{
		value: 'pre_paid',
		label: 'Pre-paid',
		_id: 'pre-paid'
	},
	{
		value: 'paid',
		label: 'Paid',
		_id: 'paid'
	}
];