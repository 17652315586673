export const timeLogsTableColumns = [
	{
		title: 'Team Member',
		dataIndex: 'contractor_name',
		key: 'name',
	},
	{
		title: 'Logged Time',
		dataIndex: 'logged_time',
		key: 'logged_time',
	},
	{
		title: 'External Hourly Rate',
		dataIndex: 'hourly_rate',
		key: 'hourly_rate',
	},
	{
		title: 'External Daily Rate',
		dataIndex: 'daily_rate',
		key: 'daily_rate',
	},
	{
		title: 'Total',
		dataIndex: 'total',
		key: 'total',
		textAlign: 'right'
	},
	// {
	// 	title: '',
	// 	dataIndex: 'action',
	// 	key: 'action trash-icon',
	// }
]

export const initialExpenseTimeLog = {
	contractor_id: null,
	name: '',
	expense_type: '',
	amount: 0,
	report: null,
	reimbursed: false,
	price: 0,
}