import {Container, FlexBox, StatusDropdown} from "../../../components/commonStyled";
import {talentStatusFilterOptions} from "../../../utils";
import talentsActions from "../../../actions/talentsActions";
import {useDispatch, useSelector} from "react-redux";

import CustomSelect from "../../../components/SelectDropdown/CustomSelect";
import Search from "../../../components/Search";
import Button from "../../../components/Button";
import TalentsTable from "./TalentsTable";
import CustomPagination from "../../../components/Pagination";
import TextBox from "../../../components/TextBox";

const TalentsWrapper = (
	{
		isPermittedManageTalent,
		talentsData,
		setShowModal
	}
) => {
	const dispatch = useDispatch();
	const {searchInputValue} = useSelector(state => state.commonReducer);
	const {talentStatusFilter} = useSelector(state => state.talentsPageReducer);
	const onPageChange = (data) => {
		dispatch(talentsActions.getTalentsData(data));
	};
	const applyStatusFilter = (data) => {
		dispatch(talentsActions.setTalentStatusFilter(data));
		dispatch(talentsActions.getTalentsData({
			page: 1,
			page_size: talentsData.page_size,
			query_str: searchInputValue
		}));
	};
	
	return (
		<FlexBox as='div' flexDirection='column' height='100%' width='100%' padding='20px 20px 0'>
			<Container overflow='hidden'>
				<FlexBox justifyContent='space-between' alignItems='flex-start' margin='0 0 24px' gap={'20px'}>
					<TextBox as='h2' size='md' weight='medium' lineHeight='35px' style={{whiteSpace: 'nowrap'}}>
						All Talents
					</TextBox>
					<FlexBox gap='12px' flexWrap='wrap-reverse' justifyContent='flex-end'>
						<FlexBox gap='12px'>
							<StatusDropdown width='210px'>
								<CustomSelect
									name={'status'}
									ref={null}
									options={talentStatusFilterOptions}
									isSearchable={false}
									classNameContainer={`status`}
									isMulti={true}
									handleOnChange={(value) => console.log(value)}
									closeMenuOnSelect={false}
									placeholder='Filter by Status'
									handleOnBlur={applyStatusFilter}
									optionSelectedFromReducer={talentStatusFilter?.values}
									customHeight='36px'
								/>
							</StatusDropdown>
						</FlexBox>
						<FlexBox gap='12px'>
							<Search
								onPageChange={onPageChange}
								page={talentsData?.page}
								page_size={talentsData?.page_size}
								placeholder='Search by Name'
							/>
							{isPermittedManageTalent &&
									<Button
										label='Invite Talent'
										gap='12px'
										withIcon={true}
										padding='7px 34px'
										flexDirection='row-reverse'
										fontSize='16px'
										handleClick={() => setShowModal(true)}
									/>
							}
						</FlexBox>
					</FlexBox>
				</FlexBox>
				<TalentsTable
					data={talentsData?.items}
					isPermittedManageTalent={isPermittedManageTalent}
				/>
			</Container>
			{talentsData?.items.length > 0 &&
				<CustomPagination
					totalItems={talentsData?.items_count}
					pageSize={talentsData?.page_size}
					page={talentsData?.page}
					onPageChange={onPageChange}
				/>}
		</FlexBox>
	)
}

export default TalentsWrapper;