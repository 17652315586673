import React from 'react';
import {useDispatch} from "react-redux";
import commonActions from "../../actions/commonActions";
//max size of file 100mb
const maxSize = 100 * 1024 * 1024;
const FileUploaderInput = React.forwardRef((
	{
		reducerAction,
		multiple = true,
		accept = '*',
		uploadOnlyOneFile = false, //it is needed for uploading only one file but formData is needed
	}, ref
) => {
	const dispatch = useDispatch();
	const handleUpload = (e) => {
		e.stopPropagation();
		const selectedFiles = e.target.files;
		if (multiple || uploadOnlyOneFile) {
			const formData = new FormData();
			let namesOfTooBigFiles = [];
			for (let i = 0; i < selectedFiles.length; i++) {
				if (selectedFiles[i]?.size > maxSize) {
					namesOfTooBigFiles.push(selectedFiles[i]?.name);
				} else {
					formData.append('files', selectedFiles[i]);
				}
			}
			if (namesOfTooBigFiles.length) {
				dispatch(commonActions.setPopupMessage(
					`The following files are too big: ${namesOfTooBigFiles.join(', ')}. The maximum file size is 100MB.`,
					'error'
				))
			}
			if (formData.getAll('files').length === 0) return;
			setTimeout(() => {
				reducerAction(formData);
			}, 2000);
		} else {
			if (selectedFiles[0]?.size > maxSize) {
				dispatch(commonActions.setPopupMessage(
					`The file ${selectedFiles[0].name} is too big. The maximum file size is 100MB.`,
					'error'
				))
			} else {
				reducerAction({file: selectedFiles[0]});
			}
		}
	};
	return (
		<input
			type='file'
			accept={accept}
			multiple={multiple}
			style={{display: 'none'}}
			ref={ref}
			onChange={handleUpload}
		/>
	);
});

export default FileUploaderInput;