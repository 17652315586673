import {useEffect, memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FlexBox} from "../../components/commonStyled";
import clientsActions from "../../actions/clientsActions";
import commonActions from "../../actions/commonActions";
import {Outlet, useLocation, useOutletContext} from "react-router-dom";

import Loader from "../../components/Loader";

const Clients = memo(() => {
		const {clientsDataLoading} = useSelector(state => state.clientsPageReducer);
		const dispatch = useDispatch();
		const {pathname} = useLocation();
		const isMainPage = pathname === '/clients';
		const [breadcrumbsRef] = useOutletContext();
		
		useEffect(() => {
			if (isMainPage) {
				dispatch(clientsActions.getClientsList());
			}
			return () => {
				dispatch(commonActions.setSearchInputValue(''));
				dispatch(commonActions.setActiveTab(0));
			}
		}, [dispatch, isMainPage]);
		
		const headerHeight = breadcrumbsRef?.current?.offsetHeight || 70;

		return (
			<>
				<FlexBox justifyContent='center' height={`calc(100% - ${headerHeight}px)`} width='100%'>
					{clientsDataLoading ? <Loader width='100%'/> : <Outlet/>}
				</FlexBox>
			</>
		)
	}
)

export default Clients;