import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as CS from "../commonStyled";

import {FlexBox} from "../commonStyled";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch, useSelector} from "react-redux";
import clientsActions from "../../actions/clientsActions";
import {inputsCreateNewClient, schemaCreateNewClient} from "../../pages/Clients/utils";
import {ButtonClose} from "../ModalWrap/styled";
import {ReactComponent as IconClose} from "../../assets/Icons/close.svg";
import {isEmptyForm} from "../../utils";

import GroupInputs from "../GroupInputs";
import Button from "../Button";
import TextBox from "../TextBox";
import ModalSuccessfully from "./modalSuccessfully";
import ModalWrap from "../ModalWrap";
import ConfirmationModal from "./ConfirmationModal";

const CreateNewClientModal = (
	{
		closeModal
	}
) => {
	const {createNewClientSuccess, clientsData} = useSelector(state => state.clientsPageReducer);
	const {register, handleSubmit, formState: {errors}, control, setValue, getValues} = useForm({
		resolver: yupResolver(schemaCreateNewClient)
	});
	const dispatch = useDispatch();
	const [showWarningModal, setShowWarningModal] = useState(false);
	
	const onSubmit = (data) => {
		const payload = {
			'name': data.name,
			'code': data.code.toUpperCase().split('_')[0],
			"industry": data.industry?.value,
			"phone_number": data.phone_number,
		}
		dispatch(clientsActions.createNewClient(payload));
	};
	
	useEffect(() => {
		return () => {
			dispatch(clientsActions.resetAccessCreateNewClient());
		}
	}, [dispatch]);
	
	const handleCancel = (e) => {
		e.preventDefault();
		if (isEmptyForm(getValues())) {
			closeModal();
		} else {
			setShowWarningModal(true);
		}
	};
	
	const getClientsDataAfterCreate = () => {
		dispatch(clientsActions.getClientsList({
			'page': clientsData?.page,
			'page_size': clientsData?.page_size,
		}));
		closeModal();
	};
	
	return (
		<>
			<ButtonClose onClick={handleCancel}><IconClose/></ButtonClose>
			{createNewClientSuccess ?
				<ModalSuccessfully handleClick={getClientsDataAfterCreate} title='Successfully '
													 message='Client Company Successfully Created'/>
				: <><TextBox as='h2' size='md' weight='medium' marginBottom='24px'>Create Client Company</TextBox>
					<FlexBox as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'>
						<GroupInputs
							inputs={inputsCreateNewClient}
							errors={errors}
							register={register}
							control={control}
							fullWidthInput={true}
							gap='17px'
							marginTopForError='20px'
							setValue={setValue}
							getValues={getValues}
						/>
						<CS.FlexBox justifyContent='space-between' width='100%'>
							<Button type='cancel' label='Cancel' handleClick={handleCancel} ghost={true}/>
							<Button type='send' label='Save'/>
						</CS.FlexBox>
					</FlexBox></>}
			{
				showWarningModal &&
				<ModalWrap
					paddingTop='75px'
					close={() => setShowWarningModal(false)}
					children={
						<ConfirmationModal
							handleConfirm={closeModal}
							closeModal={() => setShowWarningModal(false)}
							title='Close the creation process'
							message={`All new data won’t be stored. Are you sure you want to proceed?`}
							buttonText='Yes, Close'
						/>
					}
				/>
			}
		</>
	)
}

export default CreateNewClientModal;