import {useSelector} from 'react-redux';
import Clients from './Clients';
import {Navigate} from 'react-router-dom';
import TaskDashboard from './TaskDashboard';
import ProjectsWrapper from './Projects/ProjectsWrapper';
import ActiveProject from './Projects/components/ActiveProject';
import Talents from './Talents';
import Payments from './Payments';
import Invoices from './Invoices';
import Profile from './Profile';
import AuthorizationLayout from './Authurization/AuthorizationLayout';
import Home from './Home/HomeLayout';
import ProjectsLayout from './Projects';
import RecoveryPassword from './Authurization/RecoveryPassword';
import SignIn from './Authurization/SignIn';
import NewPasswordForm from './Authurization/NewPasswordForm';
import NotFoundPage from './NotFoundPage';
import ActiveClientTabs from "./Clients/components/ActiveClientTabs";
import ClientsWrapper from "./Clients/ClientsWrapper";
import ActiveContact from "./Clients/components/Contacts/ActiveContact";
import ContractorProjects from "./ContractorProjects";
import ActiveTalent from "./Talents/components/ActiveTalent";
import TalentsList from "./Talents/TalentsList";
import ActiveProjectWrapper from "./Projects/components/ActiveProjectWrapper";
import ActiveClientWrap from "./Clients/components/ActiveClientWrap";
import ActiveTeamMember from "./TeamMembers/ActiveTeamMember";
import TeamMembers from "./TeamMembers";
import TeamMembersList from "./TeamMembers/TeamMembersList";

const Routes = () => {
	const {isContractor, userData} = useSelector((state) => state.commonReducer);
	const {projectData, projectDataLoading, projectsData} = useSelector((state) => state.projectsPageReducer);
	const {clientDetails, clientDetailsLoading, clientContacts} = useSelector(state => state.clientsPageReducer);
	const {talentDetails, talentDetailsLoading} = useSelector(state => state.talentsPageReducer);
	const {profileData, profileDataLoading} = useSelector(state => state.profilePageReducer);
	const userRole = userData?.role;
	const foundContact = ({ match })=> clientContacts?.items?.find(contact => contact._id === match.params.contactId);
	const foundProject = ({ match })=> projectsData?.items?.find(project => project._id === match.params.projectId);
	const getActiveProjectChildren = () => [
		{index: true, element: <ActiveProject/>},
		{
			path: ':talentId',
			element: <ActiveTalent isProjectsTalent={true}/>,
			breadcrumb: talentDetails?.full_name || null,
			props: {isLoading: talentDetailsLoading},
		},
	];
	
	const getProfileChildren = () => [
		{index: true, element: <TeamMembersList/>},
		{
			path: ':teamMemberId',
			element: <ActiveTeamMember/>,
			breadcrumb: profileData?.full_name || null,
			props: {isLoading: profileDataLoading},
		},
		];
	const getProjectsChildren = () => [
		{index: true, element: <ProjectsWrapper/>,},
		{
			path: ':projectId',
			element: <ActiveProjectWrapper/>,
			breadcrumb: (props) => foundProject(props)?.name || projectData?.name || 'Create Project',
			props: {isLoading: projectDataLoading},
			children: getActiveProjectChildren(),
		},
	];
	
	const getClientChildren = () => [
		{index: true, element: <ClientsWrapper/>},
		{
			path: ':slug',
			element: <ActiveClientWrap/>,
			breadcrumb: clientDetails?.name,
			props: {isLoading: clientDetailsLoading},
			children: getClientContactsChildren(),
		},
	];
	
	const getClientContactsChildren = () => [
		{index: true, element: <ActiveClientTabs/>},
		{
			path: ':contactId',
			element: <ActiveContact/>,
			breadcrumb: (props) => foundContact(props)?.name || null,
		}
	];
	
	const getTalentChildren = () => [
		{index: true, element: <TalentsList/>},
		{
			path: ':talentId',
			element: <ActiveTalent/>,
			breadcrumb: talentDetails?.full_name || null,
			props: {isLoading: talentDetailsLoading},
		}
	];
	const getChildrenHome = () =>
		isContractor
			? [
				{
					path: '/',
					element: <Navigate to={'projects'}/>,
					name: 'Projects',
					breadcrumb: '',
				},
				{
					path: 'projects',
					element: <ContractorProjects/>,
					name: 'Projects',
					breadcrumb: 'Projects',
					children: getProjectsChildren().map(child => ({...child})),
				},
				{
					path: 'profile',
					element: <Profile/>,
					name: 'Profile',
					breadcrumb: 'Profile',
				},
			]
			: [
				{
					path: '/',
					element: <Navigate to={'clients'}/>,
					name: 'Clients',
				},
				{
					path: 'task-dashboard',
					element: <TaskDashboard/>,
					name: 'Task Dashboard',
					breadcrumb: 'Task Dashboard',
				},
				{
					path: 'clients',
					element: <Clients/>,
					name: 'Clients',
					breadcrumb: 'Clients',
					children: getClientChildren().map(child => ({...child})),
				},
				{
					path: 'projects',
					element: userRole === 'staffer' ? <NotFoundPage/> : <ProjectsLayout/>,
					name: 'Projects',
					breadcrumb: 'Projects',
					children: getProjectsChildren().map(child => ({...child})),
				},
				{
					path: 'talents',
					element: <Talents/>,
					name: 'Talents',
					breadcrumb: 'Talents',
					children: getTalentChildren().map(child => ({...child})),
				},
				{
					path: 'payments',
					element: <Payments/>,
					name: 'Payments',
					breadcrumb: 'Payments',
				},
				{
					path: 'invoices',
					element: <Invoices/>,
					name: 'Invoices',
					breadcrumb: 'Invoices',
				},
				{
					path: 'profile',
					element: <Profile/>,
					name: 'Profile',
					breadcrumb: 'Profile',
				},
				{
					path: 'team',
					element: userRole !== 'super_admin' &&  userRole !== 'company_admin' ? <NotFoundPage/> : <TeamMembers/>,
					name: 'Team',
					breadcrumb: 'Team',
					children: getProfileChildren().map(child => ({...child})),
				}
			];
	
	return [
		{
			path: '/auth',
			element: <AuthorizationLayout/>,
			name: 'Authorization',
			children: [
				{
					path: '/auth',
					element: <Navigate to="/auth/login"/>,
				},
				{
					path: 'login',
					element: <SignIn/>,
				},
				{
					path: 'recovery-password',
					element: <RecoveryPassword/>,
				},
				{
					path: 'set-password',
					element: (
						<NewPasswordForm title="Set Password" isInvite={true} successMessage="Password was saved"/>
					),
				},
				{
					path: 'new-password',
					element: <NewPasswordForm title="New Password"/>,
				},
			],
		},
		{
			path: '/',
			element: <Home/>,
			name: 'Home',
			children: getChildrenHome().map(child => ({...child})), // Map over the array and create new objects
			breadcrumb: '',
		},
		{
			path: '*',
			element: <NotFoundPage/>,
		},
	];
};

export default Routes;
