import {useSelector} from "react-redux";
import * as S from "./styled";

import TextBox from "../TextBox";
import Avatar from "../Avatar";
import {getRoleName} from "../../utils";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader";

const Account = () => {
	const {userData, userDataLoading} = useSelector(state => state.commonReducer);
	const navigate = useNavigate();
	if (userDataLoading) return <Loader position={'relative'} width={'100%'} height={'74px'} />
	return (
		<S.AccountBlock alignItems='center' gap='14px' onClick={()=>navigate('profile')} title={'Open Profile'}>
			<Avatar src={userData?.logo} name={userData?.full_name}/>
			<S.AccountInfo flexDirection='column' gap='10px'>
				<TextBox as='h2' weight='medium' size='xmd' color='var(--up-blue-p100)'>{userData?.full_name || ''}</TextBox>
				<TextBox as='h3' color='var(--up-blue-p100)' textTransform='capitalize'>{getRoleName(userData?.role) || ''}</TextBox>
			</S.AccountInfo>
		</S.AccountBlock>
	);
}

export default Account;