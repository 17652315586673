import styled from "styled-components";
import {FlexBox} from "../commonStyled";

export const ButtonArrows = styled(FlexBox)`
	z-index: 1;
	svg {
		width: 12px;
		height: 12px;
		&:first-child {
			transform: rotate(180deg);
			path {
				fill: ${({asc}) => asc ? 'var(--up-main-blue)' : 'var(--up-main-grey)'};
			}
    }
		&:last-child {
			margin-top: -6px;
			path {
				fill: ${({desc}) => desc ? 'var(--up-main-blue)' : 'var(--up-main-grey)'};
      }
    }
	}
`;