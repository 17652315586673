import {FlexBox} from "../../../../components/commonStyled";
import Button from "../../../../components/Button";
import {initialExpense, initialProfitSharing} from "./utils";
import {memo} from "react";

const NavButtons = memo((
	{
		editMode,
		expenses,
		setExpenses,
		profitSharing,
		setProfitSharing
	}
)=>{
	return(
	 <>
			<FlexBox
				justifyContent='space-between'
				width='100%'
				gap={'12px'}
				padding={'0 12px'}
			>
				<Button
					flexDirection='row-reverse'
					type='cancel'
					fullWidth={true}
					withIcon={true}
					label='Add Expenses'
					ghost={true}
					borderRadius='16px'
					disabled={!editMode || expenses?.length > 0}
					onClick={() => setExpenses([initialExpense])}
					padding={'13.5px 14px'}
				/>
				<Button
					flexDirection='row-reverse'
					type='send'
					fullWidth={true}
					withIcon={true}
					ghost={true}
					label='Add Profit Sharing'
					borderRadius='16px'
					disabled={!editMode || profitSharing?.length > 0}
					onClick={() => setProfitSharing([initialProfitSharing])}
					padding={'13.5px 14px'}
				/>
			</FlexBox>
		</>
	)
})

export default NavButtons;