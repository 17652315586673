const initialState = {
	clientsData: null,
	clientsDataLoading: false,
	clientsListError: null,
	createNewClientLoading: false,
	createNewClientSuccess: false,
	clientDetails: null,
	clientDetailsLoading: false,
	editClientLoading: false,
	clientContacts: null,
	clientContactsLoading: false,
	createContactLoading: false,
	createNewContactSuccess: false,
	editModeContact: false,
};

export default function clientsPageReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_CLIENTS_DATA": {
			return {
				...state,
				clientsData: action.payload,
				clientsDataLoading: false,
			}
		}
		case "SET_CLIENTS_DATA_LOADING": {
			return {
				...state,
				clientsDataLoading: action.payload
			}
		}
		case "SET_CREATE_NEW_CLIENT_LOADING": {
			return {
				...state,
				createNewClientLoading: action.payload
			}
		}
		case "SET_CREATE_NEW_CLIENT_SUCCESS": {
			return {
				...state,
				createNewClientLoading: false,
				createNewClientSuccess: action.payload,
			}
		}
		case "SET_CLIENT_DETAILS": {
			return {
				...state,
				clientDetails: action.payload,
				clientDetailsLoading: false,
			}
		}
		case "SET_CLIENT_DETAILS_LOADING": {
			return {
				...state,
				clientDetailsLoading: action.payload
			}
		}
		case "SET_EDIT_CLIENT_LOADING": {
			return {
				...state,
				editClientLoading: action.payload
			}
		}
		case "SET_CLIENT_CONTACTS": {
			return {
				...state,
				clientContacts: action.payload,
				clientContactsLoading: false,
			}
		}
		case "SET_CLIENT_CONTACTS_LOADING": {
			return {
				...state,
				clientContactsLoading: action.payload
			}
		}
		case "SET_CREATE_CONTACT_LOADING": {
			return {
				...state,
				createContactLoading: action.payload
			}
		}
		case "SET_CREATE_NEW_CONTACT_SUCCESS": {
			return {
				...state,
				createContactLoading: false,
				createNewContactSuccess: action.payload,
			}
		}
		case "SET_EDIT_MODE_CONTACT": {
			return {
				...state,
				editModeContact: action.payload
			}
		}
		default: return state;
	}
}