import { authenticatedRequest } from "../APIGateway/gateway";
import commonActions from "./commonActions";
import {
	defaultQueryForGetData,
	exportDataToComputer,
	getErrorMessages,
	queryString
} from "../utils";
import { push } from "connected-react-router";

const clientsActions = {
	createNewClient(payload) {
		return dispatch => {
			dispatch({
				type: 'SET_CREATE_NEW_CLIENT_LOADING',
				payload: true
			})
			authenticatedRequest({
				url: 'CreateClient',
				method: 'post',
				data: payload
			}).then(() => {
				dispatch({
					type: 'SET_CREATE_NEW_CLIENT_SUCCESS',
					payload: true
				})
			}, (error) => {
				dispatch({
					type: 'SET_CREATE_NEW_CLIENT_LOADING',
					payload: false
				})

				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
			);
		}
	},
	resetAccessCreateNewClient() {
		return dispatch => {
			dispatch({
				type: 'SET_CREATE_NEW_CLIENT_SUCCESS',
				payload: false
			})
		}
	},
	getClientsList(queries = defaultQueryForGetData) {
		return dispatch => {
			dispatch({
				type: 'SET_CLIENTS_DATA_LOADING',
				payload: true
			});

			authenticatedRequest({
				url: 'GetClientsList',
				method: 'get',
				queryParams: queryString(queries)
			}).then((response) => {
				dispatch({
					type: 'SET_CLIENTS_DATA',
					payload: response.data
				})
			}, (error) => {
				dispatch({
					type: 'SET_CLIENTS_DATA_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
			);
		}
	},
	deleteClient(id) {
		return (dispatch, getState) => {
			const state = getState();
			const { clientsData } = state.clientsPageReducer;
			const { searchInputValue } = state.commonReducer;

			authenticatedRequest(
				{
					url: `DeleteClient`,
					method: 'delete',
					paths: [id],
				}
			).then(() => {
				dispatch(commonActions.setPopupMessage('Client Company Successfully Deleted', 'success'));
				dispatch(this.getClientsList({
					'query_str': searchInputValue,
					'page': clientsData?.page,
					'page_size': clientsData?.page_size,
				}));
			}, (error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
			);
		}
	},
	getClientDetails(id) {
		return dispatch => {
			dispatch({
				type: 'SET_CLIENT_DETAILS_LOADING',
				payload: true
			});

			authenticatedRequest({
				url: `GetClientDetails`,
				method: 'get',
				paths: [id],
			}).then((response) => {
				dispatch({
					type: 'SET_CLIENT_DETAILS',
					payload: response.data
				})
			}, (error) => {
				if (error?.response?.data?.errors?.client_company_id[0] === 'Value error, Invalid objectid') {
					dispatch(push('/not-found'));
					window.location.reload();
				} else {
					dispatch({
						type: 'SET_CLIENT_DETAILS_LOADING',
						payload: false
					});
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			}
			);
		}
	},
	updateClient(id, payload) {
		return dispatch => {
			dispatch({
				type: 'SET_EDIT_CLIENT_LOADING',
				payload: true
			});

			authenticatedRequest({
				url: `EditClientDetails`,
				method: 'patch',
				paths: [id],
				data: payload
			}).then(() => {
				dispatch({
					type: 'SET_EDIT_CLIENT_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage('Client Company Successfully Updated', 'success'));
				dispatch(this.getClientDetails(id));
			}, (error) => {
				dispatch({
					type: 'SET_EDIT_CLIENT_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
			);
		}
	},
	createNewContact(payload, id) {
		return dispatch => {
			dispatch({
				type: 'SET_CREATE_CONTACT_LOADING',
				payload: true
			});
			authenticatedRequest({
				url: `CreateContact`,
				method: 'post',
				paths: [id],
				data: payload
			}).then(() => {
				dispatch({
					type: 'SET_CREATE_NEW_CONTACT_SUCCESS',
					payload: true
				});
				dispatch(commonActions.setPopupMessage('Contact Successfully Created', 'success'));
				dispatch(this.getClientDetails(id));
			}, (error) => {
				dispatch({
					type: 'SET_CREATE_CONTACT_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
			);
		}
	},
	getClientContacts(id, queries = defaultQueryForGetData) {
		return dispatch => {
			dispatch({
				type: 'SET_CLIENT_CONTACTS_LOADING',
				payload: true
			});
			authenticatedRequest({
				url: `GetClientContactsList`,
				method: 'get',
				paths: [id],
				queryParams: queryString(queries)
			}).then((response) => {
				dispatch({
					type: 'SET_CLIENT_CONTACTS',
					payload: response.data
				});
			}, (error) => {
				dispatch({
					type: 'SET_CLIENT_CONTACTS_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	},
	clearSuccessCreateNewContact() {
		return dispatch => {
			dispatch({
				type: 'SET_CREATE_NEW_CONTACT_SUCCESS',
				payload: false
			});
		}
	},
	deleteContact(clientId, contactId) {
		return dispatch => {
			authenticatedRequest(
				{
					url: `DeleteContact`,
					method: 'delete',
					paths: [clientId, contactId],
				}
			).then(() => {
				dispatch(commonActions.setPopupMessage('Contact Successfully Deleted', 'success'));
				dispatch(this.getClientContacts(clientId));
			}, (error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
			);
		}
	},
	editContact(clientId, contactId, payload) {
		return dispatch => {
			dispatch({
				type: 'SET_CREATE_CONTACT_LOADING',
				payload: true
			});
			authenticatedRequest(
				{
					url: `EditContact`,
					method: 'patch',
					paths: [clientId, contactId],
					data: payload
				}
			).then(() => {
				dispatch({
					type: 'SET_CREATE_CONTACT_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage('Contact Successfully Updated', 'success'));
				dispatch(this.getClientContacts(clientId));
			}, (error) => {
				dispatch({
					type: 'SET_CREATE_CONTACT_LOADING',
					payload: false
				});
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
			);
		}
	},
	setEditModeContact(status) {
		return dispatch => {
			dispatch({
				type: 'SET_EDIT_MODE_CONTACT',
				payload: status
			});
		}
	},
	exportAllContacts(id) {
		return dispatch => {
			authenticatedRequest({
				url: `ExportAllClientContacts`,
				method: 'get',
				paths: [id],
				isBlob: true,
			}).then((response) => {
				exportDataToComputer(response);
				dispatch(commonActions.setPopupMessage('Exported Successfully', 'success'));
			}, (error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	},
	exportContact(clientId, contactId) {
		return dispatch => {
			authenticatedRequest({
				url: `ExportClientContact`,
				method: 'get',
				paths: [clientId, contactId],
				isBlob: true,
			}).then((response) => {
				exportDataToComputer(response);
				dispatch(commonActions.setPopupMessage('Exported Successfully', 'success'));
			}, (error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	}
}

export default clientsActions;