import styled from 'styled-components';
import {FlexBox} from "../commonStyled";

export const BreadcrumbsWrap = styled(FlexBox)`
  padding: 19.5px 20px;
  background: var(--up-white);
  border-bottom: 1px solid var(--up-main-light-grey);
`;

export const BreadCrumbItem = styled.div`
  display: inline-flex;
  align-items: center;
	cursor: ${({isLastItem}) => isLastItem === 'true' ? 'default' : 'pointer'};
	max-width: 35%;
	overflow: hidden;
	
	* {
    cursor: ${({isLastItem}) => isLastItem === 'true' ? 'default' : 'pointer'};
  }
  a {
    text-decoration: none;
		width: 100%;
		overflow: hidden;

    >span {
      display: inline-block;
      font-size: 20px;
      line-height: 28px;
      font-weight: ${props => props.isLastItem === 'true' ? '400' : '500'};
      color: ${props => props.isLastItem === 'true' ? 'var(--up-main-dark)' : 'var(--up-main-dark-blue)'};
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
			span {
				font-weight: inherit;
				color: inherit;
				font-size: inherit;
				line-height: inherit;
				
			}
    }
  }
	
  svg {
		min-width: 24px;
    path {
      fill: ${props => props.isLastItem === 'true' ? 'var(--up-main-dark)' : 'var(--up-main-dark-blue)'};
    }
  }
`;

