import styled, { css } from "styled-components";
import Select from "react-select";
import { FlexBox } from "../commonStyled";

export const SelectContainer = styled.div`
	cursor: pointer;
	* {
		cursor: pointer;
	}
  &.disabled {
    cursor: text;
    opacity: 0.85;
		* {
			cursor: text;
		}
  }
  &.container-select {
    &.status {
      .custom-select {
        &__control {
          height: 38px;
					width: 100%;
					overflow: hidden;
        }

        &__value-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 12px;
					&--is-multi {
						padding-right: 35px;
						width: 100%;
						overflow: auto!important;
						//width: calc(100% - 50px);
					}
        }
        ${({ isMulti }) => isMulti && css`
					&__placeholder {
						color: var(--up-main-dark);
					}
					&__option {
						padding: 0!important;
						width: 100% !important;
						justify-content: flex-start!important;
						cursor: pointer;
          }
          &__menu-list > div > div {
            background: none!important;
          }
  		`}

        &__menu-list > div > div,
        &__single-value {
          width: auto;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: var(--up-main-dark);
          padding: 4px 6px;
          background: var(--up-main-light-grey);
          border-radius: 6px;
          margin: 0;
        }
      
      }

      &.draft {
        .custom-select__single-value {
          background: var(--up-main-light-grey);
          color: var(--up-main-dark);
        }
      }

      &.in_progress,
			&.paid,
			&.assigned {
        .custom-select__single-value {
          background: var(--up-main-light-green);
          color: var(--up-main-green);
        }
      }
			
			&.sent,
			&.pre_paid,
      &.completed,
			&.executed {
        .custom-select__single-value {
          background: #FFD390;
          color: #B0722F;
        }
      }

      &.closed,
			&.unpaid,
      &.voided {
        .custom-select__single-value {
          background: var(--up-main-light-red);
          color: var(--up-main-dark-red);
        }
      }

			&.active {
				.custom-select__single-value {
          color: #0092B9;
          background: #D6FBF9;
        }
			}

      .custom-select__menu-list {
        > div {
          > div {
            &.draft {
              background: var(--up-main-light-grey);
              color: var(--up-main-dark);
            }

            &.in_progress,
            &.paid,
            &.assigned {
              background: var(--up-main-light-green);
              color: var(--up-main-green);
            }

            &.sent,
            &.pre_paid,
            &.completed,
            &.executed {
                background: #FFD390;
                color: #B0722F;
            }

            &.closed,
						&.unpaid,
            &.voided {
              background: var(--up-main-light-red);
              color: var(--up-main-dark-red);
            }
            &.active {
              color: #0092B9;
              background: #D6FBF9;
            }
          }
        }
      }
    }
  }
`;
export const CustomSelect = styled(Select)`
  &:hover,
  &:focus {
    border-color: var(--up-main-red) !important;
  }
	
  .custom-select {
		&__value-container {
			.custom-select__single-value {
        text-transform: ${({ textTransform }) => textTransform};
				line-height: 20px;
			}
    }
		&__menu {
			max-height: 200px;
			overflow: hidden;
			&-list {
        max-height: 200px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-width: 1px ;
        }
			}
		}
    &__control {
      &--is-disabled {
        background: var(--up-white) !important;

        .custom-select__single-value {
          color: var(--up-main-dark);
        }
      }
    }
  }

  .custom-select__menu {
    z-index: 2;
		box-shadow: none;
		border: 1px solid var(--up-main-info);
  }

  ${({ error }) => error && css`
    .custom-select__control {
      border-color: var(--up-main-red);
    }
  `}
  ${({ customHeight }) => customHeight && css`
    .custom-select__control {
      height: ${customHeight} !important;
      min-height: ${customHeight} !important;
    }

    .custom-select__value-container {
      padding-left: 8px !important;
    }

    .custom-select__menu-list {
      > div {
        padding-left: 8px !important;
      }
    }
  `}
  .custom-select__clear-indicator {
    display: none;
    padding-left: 0;
    padding-right: 0;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: var(--up-main-dark);
      }
    }
  }
`;

export const CustomOption = styled.div`
  font-family: 'Rubik', sans-serif !important;
  color: ${({ isSelected }) => isSelected ? 'var(--up-main-blue)!important' : 'var(--up-main-dark)!important'};
  background: ${({
  isSelected,
  isFocused
}) => isSelected ? '#E3F2FF!important' : isFocused ? 'var(--up-blue-p100)!important' : 'transparent!important'};
  font-weight: ${({ isSelected }) => isSelected ? '500!important' : '400!important'};
  font-size: 14px !important;
  height: 40px !important;
  padding: 0 16px !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
	width: 100%;
	overflow: hidden;
  div {
    color: inherit;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
  }

  &:hover,
  &:focus {
    background: ${({ isSelected }) => isSelected ? '#E3F2FF!important' : 'var(--up-blue-p100)!important'};
  }
	
	label {
		width: 100%;
		overflow: hidden;
	}
`;

export const DropdownArrowIcon = styled.div`
  margin-right: 12px;

  ${({ customHeight }) => customHeight && css`
    margin-right: 8px;
  `}
  svg {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    transform: ${({ menuIsOpen }) => menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

export const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '40px',
    boxShadow: "none",
    '&:hover': {
      borderColor: 'var(--up-main-blue)',
    },
    '&:focus': {
      borderWidth: '1px',
      borderColor: 'var(--up-main-blue)',
    },
  }),
};

export const SelectValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
	.custom-select__multi-value {
		max-width: 135px;
		> div {
      //add ... if text is too long
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &.draft {
    .custom-select__multi-value {
      background: var(--up-main-light-grey);
			
      > div {
        color: var(--up-main-dark);
      }
    }
  }

  &.active {
    .custom-select__multi-value {
      background: #D6FBF9;
      > div {
        color: #0092B9;
      }
    }
  }

  &.in_progress,
  &.paid,
  &.assigned {
    .custom-select__multi-value {
      background: var(--up-main-light-green);

      > div {
        color: var(--up-main-green);
      }
    }
  }
  &.sent,
  &.pre_paid,
  &.completed,
  &.executed {
    .custom-select__multi-value {
      background: #FFD390;

      > div {
        color: #B0722F;
      }
    }
  }
	&.unpaid,
  &.closed,
	&.voided {
    .custom-select__multi-value {
      background: var(--up-main-light-red);

      > div {
        color: var(--up-main-dark-red);
      }
    }
  }

  .custom-select__multi-value {
    margin: 0;
    background: var(--up-page-bg);
    border-radius: 6px;
    padding: 4px 6px;

    > div {
      padding: 0;
      color: var(--up-main-grey);
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }

    .custom-select__multi-value__remove {
      min-width: 14px;
			
      &:hover {
        background: none;

        svg {
          path {
            fill: var(--up-main-red);
          }
        }
      }
    }

    svg {
      cursor: pointer;

      path {
        fill: var(--up-main-grey);
      }
    }
  }
`;

export const CountOfHiddenItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--up-page-bg);
  border-radius: 6px;
  color: var(--up-main-grey);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 4px 6px;
  position: absolute;
  top: 50%;
	left: ${({ left }) => left ? left + 4 + 'px' : 0};
  transform: translateY(-50%);
	z-index: 9999;
	cursor: pointer;
`;

export const ValueContainerWrap = styled.div`
  border-radius: 4px;
  display: flex;
  gap: 8px;
  width: 100%;
  //position: relative;
	overflow: hidden;
	justify-content: flex-start;
	height: 26px;
`;

export const AddNewItemWrap = styled(FlexBox)`
	&:hover {
		input {
			border-color: var(--up-main-blue);
		}
  }
`;