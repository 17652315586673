import {useLayoutEffect, useState} from "react";
import {usePermission} from "../../../hooks/usePermission";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FlexBox, ImgWithAbsolutePosition, StickyHeader} from "../../../components/commonStyled";
import {useDispatch, useSelector} from "react-redux";
import clientsActions from "../../../actions/clientsActions";
import {ButtonEdit, GroupButtons} from "../../Projects/styled";
import {inputsClientDetails, schemaClientDetails} from "../utils";
import {ReactComponent as Cat} from "../../../assets/Icons/catHi.svg";
import {ReactComponent as Pen} from "../../../assets/Icons/pen.svg";

import TextBox from "../../../components/TextBox";
import Button from "../../../components/Button";
import GroupInputs from "../../../components/GroupInputs";
import Loader from "../../../components/Loader";
import ModalWrap from "../../../components/ModalWrap";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import {isFormChanged} from "../../../utils";

const Profile = (
	{
		editMode,
		setEditMode,
		slug
	}
) => {
	const {clientDetails, clientDetailsLoading, editClientLoading} = useSelector(state => state.clientsPageReducer);
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const isPermittedUpdateClient = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']);
	const {register, handleSubmit, formState: {errors}, control, setValue, getValues, reset} = useForm({
		resolver: yupResolver(schemaClientDetails)
	});
	
	useLayoutEffect(() => {
		if (!clientDetails && !clientDetailsLoading) {
			dispatch(clientsActions.getClientDetails(slug));
		}
	}, [dispatch, clientDetails, slug, clientDetailsLoading]);
	
	const onSubmit = (data) => {
		Object.keys(data).forEach(key => {
				if (data[key] === '' && key !== 'logo') {
					data[key] = null;
				} else if (key === 'zip_code') {
					data[key] = data[key]?.toString();
				}
			}
		);
		dispatch(clientsActions.updateClient(slug, data));
		reset();
		setEditMode(false);
	};
	
	const handleConfirm = () => {
		reset();
		dispatch(clientsActions.getClientDetails(slug));
		setShowModal(false);
		setEditMode(false);
	};
	
	const handleCancel = (e) => {
		e.preventDefault();
		if (isFormChanged(getValues(), clientDetails)) {
			setShowModal(true);
		} else {
			setEditMode(false);
		}
	};
	const handleEditMode = (e) => {
		if (editMode) {
			handleCancel(e);
		} else {
			setEditMode(true);
		}
	}
	if (clientDetailsLoading || !clientDetails) return <Loader width='100%' height='calc(100vh - 300px)'/>
	return (
		<>
			<StickyHeader justifyContent={'space-between'} alignItems='center' width='100%' overflow='hidden'>
				<TextBox
					as='h1'
					size='md'
					weight='medium'
					color='dark'
					lineHeight='28px'
					style={{
						width: 'calc(100% - 60px)',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
					title={clientDetails?.name}
				>
					{clientDetails?.name}
				</TextBox>
				<FlexBox alignItems='center' gap='12px'>
					{isPermittedUpdateClient &&
						<ButtonEdit
							as='button'
							onClick={handleEditMode}
							editMode={editMode}
							alignItems='center'
							justifyContent='center'
						>
							<Pen/>
						</ButtonEdit>
					}
				</FlexBox>
			</StickyHeader>
			<FlexBox margin={'10px 0 0'} as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'
							 position='relative'>
				{editClientLoading && <Loader position='absolute' width='100%' height='100%' background={'var(--up-white)'}/>}
				<GroupInputs
					inputs={inputsClientDetails}
					errors={errors}
					register={register}
					control={control}
					gap='12px 12px'
					marginTopForError='20px'
					setValue={setValue}
					getValues={getValues}
					defaultData={clientDetails}
					disabled={!editMode}
				/>
				{editMode && <GroupButtons justifyContent='space-between' width='100%'>
					<Button type='cancel' label='Cancel' handleClick={handleCancel} ghost={true}/>
					<Button type='send' label='Save'/>
				</GroupButtons>}
			</FlexBox>
			<ImgWithAbsolutePosition as={Cat} top={'80px'} right={'auto'} left={'650px'} zindex={'12'}/>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					children={
						<ConfirmationModal
							handleConfirm={handleConfirm}
							closeModal={() => setShowModal(false)}
							title={'Close the client editing mode'}
							message={
								'All changes you added will be lost. Do you want to proceed?'
							}
							buttonText='Yes, Close'
						/>
					}
					close={() => setShowModal(false)}
				/>}
		</>
	)
}

export default Profile;