import styled from "styled-components";
import {css} from 'styled-components';

export const TableWrapper = styled.table`
  width: 100%;
  border-spacing: ${props => props.borderSpacing};
  background: ${props => props.tableColor};
  border-collapse: separate;
  border-radius: ${props => props.tableBorderRadius};
  border: ${props => props.border};
`;

export const TableHeader = styled.thead`
  ${(props) => {
    if (props.withStickyHeader) {
      return css`
        position: sticky;
        top: 10px;
        z-index: 1;

        tr {
          top: -10px;
        }
      `;
    }
  }}
  tr {
    position: relative;

    th {
      background-color: ${props => props.headerColor};
      box-shadow: ${props => props.boxShadow};
      text-align: left;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: var(--up-main-grey);
      padding: 15px 16px;
			cursor: pointer;
			
			&:hover {
				color: var(--up-main-blue);
				svg {
					path {
						fill: var(--up-main-blue);
					}
        }
      }
			
			@media (max-width: 1500px) {
				padding: 15px 8px;
      }
			span {
				color: inherit;
				position: relative;
				display: inline-flex;
				align-items: center;
				gap: 5px;
				font-size: inherit;
				line-height: inherit;
				font-weight: inherit;
        //svg {
        //  path {
        //    fill: var(--up-main-grey);
        //  }
        //}
				&.active {
					color: var(--up-main-blue);
        }
			}

      ${(props) => {
        if (props.headerWithBorder) {
          return css`
            border: 1px solid var(--up-main-light-grey);
            border-right: none;
            border-left: none;

            &:first-child {
              border-left: 1px solid var(--up-main-light-grey);
            }

            &:last-child {
              border-right: 1px solid var(--up-main-light-grey);
            }
          `;
        }
      }}
      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }

      &.action {
        padding: 15px 0;
        min-width: 24px;

        &.trash-icon {
          width: 34px;
					min-width: 34px;
        }
      }

      &.static-large-width {
        width: 140px;
      }

      &.static-medium-width {
        width: 105px;
      }

      &.static-small-width {
        width: 70px;
      }
    }
  }
`;

export const TableBody = styled.tbody`
  position: relative;
  background: ${props => props.tableBodyColor};

  ${(props) => {
    if (props.withStickyHeader) {
      return css`
        top: -10px;
      `;
    }
  }}
  tr {
    ${(props) => {
      if (props.withHoverEffect) {
        return css`
          cursor: pointer;

          &:hover {
            background-color: var(--up-blue-p100);
          }
        `;
      }
    }}
    td {
      font-size: 14px;
      line-height: 18px;
      color: var(--up-main-dark);
      padding: 0 16px;
      height: 48px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 1500px) {
        padding: 0 8px;
      }

      a {
        color: var(--up-main-info);
        text-decoration: none;
      }

      .name {
        display: block;
        width: 100%;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.paragraph {
        white-space: normal;
				* {
					line-height: 18px;
				}
        .paragraph-block {
          font-size: inherit;
          line-height: inherit;
          color: inherit;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
					img {
						display: none;
						&:before {
							content: '(included image)';
						}
					}
        }
      }

      &.bold {
        font-weight: 500;
      }

      &.action {
        padding: 0 0;
        text-align: right;

        &.trash-icon {
          cursor: pointer;
          width: 34px;
        }
      }

      &.static-medium-width {
        width: 100px;
      }

      &.static-small-width {
        width: 70px;
      }
    }
  }
`;

export const TotalTd = styled.td`
	font-weight: 500;
	text-align: right;
	color: var(--up-main-blue)!important;
	`;