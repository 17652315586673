import {FlexBox} from "../commonStyled";
import TextBox from "../TextBox";
import {Input} from "./styled";

const HeaderTitle = (
	{
		isInput = false,
		value = '',
		type = 'text',
		label,
		disabled,
		labelColor = 'var(--up-main-grey)',
		labelSize='xxmd',
		inputFontSize = '36px',
		mask="$ 999999999",
		maxWidth = '250px',
		width = 'fit-content',
		setValue,
		isPercent = false,
		margin = '0'
	}
) => {
	const handleChange = e=> {
		let inputValue = e.target.value?.replace(/[^\d.]/g, '');
		if (inputValue?.includes('.') && inputValue?.split('.')[1]?.length > 2) return;
		if (inputValue.split('.')[0]?.length > 9) return;
		setValue(inputValue)
	}
	return (
		<FlexBox
			flexDirection={'column'}
			gap={'16px'}
			alignItems={'center'}
			style={{width: width, maxWidth: maxWidth, minWidth: '150px'}}
			margin={margin}
		>
			{
				isInput ?
					<>
						<Input
								disabled={disabled}
								type={type}
								inputFontSize={inputFontSize}
								value={'$ ' + value}
								onChange={handleChange}
							/>
						<TextBox  as={'label'} color={labelColor} size={labelSize}>{label}</TextBox>
					</>
					:
					<>
						<TextBox
							as={'h2'}
							color={'var(--up-main-blue)'}
							customSize={inputFontSize}
							weight={'medium'}
							lineHeight={'40px'}
						>
							{isPercent ? `${value || '0'} %` : `$ ${value || '0'}`}
						</TextBox>
						<TextBox as={'h2'} color={labelColor} size={labelSize}>{label}</TextBox>
					</>
			}
		</FlexBox>
	)
}

export default HeaderTitle;