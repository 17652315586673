import styled from 'styled-components';

export const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--up-main-bg-modal);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ModalContent = styled.div`
  width: ${({width}) => width};
  height: auto;
  background: var(--up-white);
  border-radius: 4px;
  padding: ${({paddingTop}) => paddingTop} 24px 24px 24px;
  position: relative;
  max-height: ${({heightModalContent}) => heightModalContent};
  display: flex;
  flex-direction: column;
	overflow: ${({overflow}) => overflow};
`;

export const IconWrap = styled.div`
  position: absolute;
  top: ${({topPositionIcon}) => topPositionIcon};
  left: ${({leftPositionIcon}) => leftPositionIcon};
  transform: translate(0, -100%);
  z-index: 5;

  svg {
    width: ${({widthIcon}) => widthIcon || 'auto'};
    height: ${({heightIcon}) => heightIcon || 'auto'};
  }

`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 2;

  svg {
    path {
      fill: var(--up-main-light-grey);
    }
  }
`;