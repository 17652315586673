import {useState, useEffect, useMemo, useLayoutEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GroupButtons} from "../../styled";
import economicsActions from "../../../../actions/economicsActions";
import {
	checkIsNotEditData,
	initialProfessionalFee,
	sumCostOfAllItems,
	sumItems
} from "./utils";
import talentsActions from "../../../../actions/talentsActions";
import {reducingNumber} from "../../../../utils";

import EconomicForms from "./EconomicForms";
import Header from "./Header";
import SummaryBlock from "./ProfessiomalFees/SummaryBlock";
import TalentsSummary from "./TalentsSummary";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import ModalWrap from "../../../../components/ModalWrap";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

const Economics = (
	{
		projectId,
		projectData,
		editMode,
		setEditMode
	}
) => {
	const dispatch = useDispatch();
	const {payment_type} = projectData;
	const {economicsData, economicsDataLoading} = useSelector(state => state.economicsTabReducer);
	const {projectsCreateLoading} = useSelector(state => state.projectsPageReducer);
	const {talentsNames, talentsNamesLoading} = useSelector(state => state.talentsPageReducer);
	
	const initProfessionalFees = useMemo(() => {
		return economicsData?.professional_fees?.length > 0 ? economicsData?.professional_fees : [initialProfessionalFee];
	}, [economicsData?.professional_fees]);
	
	const [showModal, setShowModal] = useState(false);
	const [projectBudget, setProjectBudget] = useState(economicsData?.project_budget || 0);
	const [professionalFees, setProfessionalFees] = useState(initProfessionalFees);
	const [expenses, setExpenses] = useState(economicsData?.expenses || []);
	const [profitSharing, setProfitSharing] = useState(economicsData?.profit_sharing || []);
	//list of uniq selected users for profit sharing, professional fees and expenses
	const selectedTalentsInAllModules = useMemo(() => {
		return [...professionalFees, ...expenses, ...profitSharing].reduce((acc, item) => {
			const isExist = acc?.find(accItem => accItem?.contractor_id === item?.contractor_id);
			if (!isExist && item?.contractor_id) {
				acc.push({contractor_id: item?.contractor_id, name: item?.name});
			}
			return acc;
		}, [])
	}, [professionalFees, expenses, profitSharing]);
	
	const unReimbursedExpenses = expenses?.filter(expense => !expense?.reimbursed || expense?.expense_type === 'sales_commission' || expense?.expense_type === 'consulting_fees') || [];
	const isStaffingModel = payment_type === 'staffing_model';
	const contractorCost = reducingNumber(sumCostOfAllItems(professionalFees));
	const contractorExternalCost = reducingNumber(sumCostOfAllItems(professionalFees, 'external_daily_rate'));
	const sumExpenses = reducingNumber(sumItems(expenses || [], 'price'));
	const sumExpensesUnReimbursed = reducingNumber(sumItems(unReimbursedExpenses, 'price'));
	const staffingProjectBudget = reducingNumber(contractorExternalCost + sumExpenses);
	const grossProfitMargin = isStaffingModel && staffingProjectBudget === 0 ? 0 :
		reducingNumber((isStaffingModel ? contractorExternalCost : projectBudget) - (contractorCost + sumExpensesUnReimbursed));
	const grossProfitMarginPercent = isStaffingModel && staffingProjectBudget === 0 ? 0 :
		reducingNumber(grossProfitMargin * 100 / (isStaffingModel ? staffingProjectBudget : projectBudget));
	const updateStates = useCallback(() => {
		setProjectBudget(economicsData?.project_budget || 0);
		setProfessionalFees(initProfessionalFees);
		setExpenses(economicsData?.expenses || []);
		setProfitSharing(economicsData?.profit_sharing || []);
	}, [economicsData, initProfessionalFees]);
	
	//it's for check if data was changed
	const isEmptyProfessionalFeesRow = professionalFees.length === 1 &&
		!professionalFees[0]?.contractor_id &&
		!professionalFees[0]?.internal_daily_rate &&
		!professionalFees[0]?.external_daily_rate &&
		!professionalFees[0]?.of_days_allowed;
	const stateData = {
		_id: economicsData?._id,
		project_budget: projectBudget,
		payment_type: economicsData?.payment_type,
		professional_fees: isEmptyProfessionalFeesRow ? [] : professionalFees,
		expenses: expenses,
		profit_sharing: profitSharing,
	};
	
	useLayoutEffect(() => {
		dispatch(economicsActions.getExpensesCategories(projectId));
		dispatch(economicsActions.getEconomicsData(projectId, payment_type));
		dispatch(talentsActions.getTalentsNames(true, projectId));
	}, [projectId, payment_type, dispatch]);
	
	useEffect(() => {
		updateStates();
	}, [updateStates]);
	const handleSubmit = (e) => {
		e.preventDefault();
		const {_id, payment_type} = economicsData
		const data = {
			_id,
			payment_type,
			project_id: projectId,
			project_budget: projectBudget,
			professional_fees: professionalFees.filter(item => item?.contractor_id),
			expenses: expenses?.filter(item => item?.contractor_id && item?.expense_type),
			//map and add the total payment necessary for the backend
			profit_sharing: profitSharing.filter(item => item?.contractor_id).map(item => {
				return {
					...item,
					total_payment: reducingNumber((grossProfitMargin * item?.percent_from_gross_profit / 100))
				}
			})
		}
		if (!checkIsNotEditData(stateData, economicsData)) {
			dispatch(economicsActions.updateEconomicsData(projectId, payment_type, data));
		}
		setEditMode(false);
	};
	const handleCancelCreate = (e) => {
		e.preventDefault();
		if (checkIsNotEditData(stateData, economicsData)) {
			setEditMode(false);
		} else {
			setShowModal(true);
		}
	}
	const handleConfirm = () => {
		setShowModal(false);
		setEditMode(false);
		updateStates();
	};
	if (
		economicsDataLoading ||
		talentsNamesLoading ||
		projectsCreateLoading
	) return <Loader position={'absolute'} width={'100%'} height={'100%'}/>;
	return <>
		<div style={{minHeight: 'calc(100% - 63px)', minWidth: '900px'}}>
			<Header
				projectId={projectId}
				payment_type={payment_type}
				isStaffingModel={isStaffingModel}
				handleCancelCreate={handleCancelCreate}
				editMode={editMode}
				setEditMode={setEditMode}
			/>
			<SummaryBlock
				projectBudget={isStaffingModel ? staffingProjectBudget : projectBudget}
				isDisabledBudget={isStaffingModel}
				contractorCost={contractorCost}
				grossProfitMargin={grossProfitMargin}
				grossProfitMarginPercent={grossProfitMarginPercent}
				setProjectBudget={setProjectBudget}
				editMode={editMode}
				sumExpenses={sumExpenses}
				sumExpensesUnReimbursed={sumExpensesUnReimbursed}
			/>
			<TalentsSummary
				talents={selectedTalentsInAllModules}
				professionalFees={professionalFees}
				expenses={expenses}
				profitSharing={profitSharing}
				grossProfitMargin={grossProfitMargin}
				sumExpenses={sumExpenses}
			/>
			<EconomicForms
				projectBudget={projectBudget}
				setProjectBudget={setProjectBudget}
				contractorCost={contractorCost}
				grossProfitMargin={grossProfitMargin}
				grossProfitMarginPercent={grossProfitMarginPercent}
				professionalFees={professionalFees}
				setProfessionalFees={setProfessionalFees}
				expenses={expenses}
				setExpenses={setExpenses}
				profitSharing={profitSharing}
				setProfitSharing={setProfitSharing}
				editMode={editMode}
				setEditMode={setEditMode}
				talentsNames={talentsNames}
				sumExpenses={sumExpenses}
				staffingProjectBudget={staffingProjectBudget}
				isStaffingModel={isStaffingModel}
				sumExpensesUnReimbursed={sumExpensesUnReimbursed}
			/>
		</div>
		{editMode && <GroupButtons justifyContent='space-between' width='100%' style={{marginTop: 'auto'}}>
			<Button type='cancel' label='Cancel' ghost={true} handleClick={handleCancelCreate}/>
			<Button type='send' label='Save' onClick={handleSubmit}/>
		</GroupButtons>}
		{showModal &&
			<ModalWrap
				paddingTop='75px'
				children={
					<ConfirmationModal
						handleConfirm={handleConfirm}
						closeModal={() => setShowModal(false)}
						title={'Close the editing process'}
						message={
							'Are you sure that you want to close edit process without saving?'
						}
						buttonText='Yes, Close'
					/>
				}
				close={() => setShowModal(false)}
			/>}
	</>;
}

export default Economics;