import {useParams} from "react-router-dom";
import {useEffect} from "react";
import profileActions from "../../actions/profileActions";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader";
import Form from "../Profile/Form";
import {Container} from "../../components/commonStyled";
import {usePermission} from "../../hooks/usePermission";

const ActiveTeamMember = () => {
	const {teamMemberId} = useParams();
	const dispatch = useDispatch();
	const {profileData, profileDataLoading} = useSelector(state => state.profilePageReducer);
	const isPermittedManageTeamMembers = usePermission(['super_admin', 'company_admin']);
	
	useEffect(() => {
		dispatch(profileActions.getProfileData(teamMemberId, true));
	}, [dispatch, teamMemberId]);
	
	if (profileDataLoading || !profileData) return <Loader width={'100%'} height={'calc(100% - 72px)'}/>
	return (
		<Container
			margin='20px 20px 0'
			width='800px'
			position='relative'
			height={'auto'}
			overflow='visible'
			padding='0 24px 24px'
		>
			<Form
				profileData={profileData}
				userId={teamMemberId}
				imgPosition={{top: '70px', left: '420px'}}
				isTeamMember={true}
				hasPermission={isPermittedManageTeamMembers}
			/>
		</Container>
	);
}

export default ActiveTeamMember;