import {useEffect} from "react";
import {Outlet, useLocation, useOutletContext} from "react-router-dom";
import {FlexBox} from "../../components/commonStyled";
import {useDispatch, useSelector} from "react-redux";
import projectsActions from "../../actions/projectsActions";
import commonActions from "../../actions/commonActions";

import Loader from "../../components/Loader";

const ProjectsLayout = () => {
	const [breadcrumbsRef] = useOutletContext();
	const dispatch = useDispatch();
	const {projectsDataLoading, clientsNamesLoading} = useSelector(state => state.projectsPageReducer);
	const {pathname} = useLocation();
	const isMainPage = pathname === '/projects';
	useEffect(() => {
		if (isMainPage) {
			dispatch(projectsActions.getProjectsData());
			dispatch(projectsActions.getClientsNames())
		}
		return ()=> {
			dispatch(commonActions.setSearchInputValue(''));
		}
	}, [dispatch, isMainPage]);
	const headerHeight = breadcrumbsRef?.current?.offsetHeight || 70;
	return (
		<>
			<FlexBox justifyContent='flex-start' height={`calc(100% - ${headerHeight}px)`} width='100%'>
				{projectsDataLoading || clientsNamesLoading ? <Loader width='100%'/> : <Outlet/>}
			</FlexBox>
		</>
	);
}

export default ProjectsLayout;