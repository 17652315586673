import styled from "styled-components";

export const Input = styled.input`
	width: 100%;
	text-align: center;
	font-size: ${({inputFontSize}) => inputFontSize};
	color: var(--up-main-blue);
	font-weight: 500;
	background: var(--up-white);
  border-radius: 6px;
  border: 1px solid var(--up-main-light-grey);
	height: 40px;
	
	&::placeholder {
    color: var(--up-main-blue);
	}
	
	&:disabled {
		background: none;
		border: none;
	}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance:textfield;
  }
`;