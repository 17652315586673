import {push} from 'connected-react-router';
import commonActions from "./commonActions";
import {authenticatedRequest} from "../APIGateway/gateway";
import {getErrorMessages} from "../utils";
import moment from "moment";
// import {AddressMap} from "../APIGateway/addressMap";

const authActions = {
	login(payload) {
		return (dispatch) => {
			dispatch({
				type: 'SET_AUTH_LOADING',
				payload: true
			})
			authenticatedRequest({
				url:'LogIn',
				method: 'post',
				data: payload,
				headers:
			{
				"Content-Type"
			:
				"application/x-www-form-urlencoded"
			}
		}
			).then((response) => {
				localStorage.setItem('access_token', response.data.access_token);
				localStorage.setItem('refresh_token', response.data.refresh_token);
				localStorage.setItem('loginSignal', moment().format('YYYY-MM-DD HH:mm:ss'));
				dispatch(push('/home'));
				dispatch(commonActions.setPopupMessage('Login was successful', 'success'));
				dispatch({
					type: 'SET_AUTH_LOADING',
					payload: false
				})
			}).catch((error) => {
				dispatch({
					type: 'SET_AUTH_LOADING',
					payload: false
				})
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	},
	
	setLoginSuccess(payload) {
		return {
			type: 'SET_LOGIN_SUCCESS',
			payload: payload
		}
	},
	
	redirectToLogin() {
		return dispatch => {
			dispatch({
				type: 'SET_AUTH_LOADING',
				payload: true
			})
			const accessToken = localStorage.getItem('access_token');
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			authenticatedRequest(
				{
					url: 'LogOut',
					method: 'get',
					queryParams: {token: accessToken}
				}
			).then((response) => {
					dispatch(push('/auth/login'));
					dispatch({
						type: 'SET_AUTH_LOADING',
						payload: false
					})
				}
			).catch((error) => {
				dispatch({
					type: 'SET_AUTH_LOADING',
					payload: false
				});
				}
			);
		}
	},
	
	requestToRecoveryPassword(payload) {
		return dispatch => {
			authenticatedRequest({
				url: 'PasswordRecoveryInvite',
				method: 'post',
				data: payload
			}).then(() => {
				dispatch({
					type: 'SET_REQUEST_FOR_RECOVERY',
					payload: true
				});
			}).catch((error) => {
				dispatch({
					type: 'SET_REQUEST_FOR_RECOVERY_ERROR',
					payload: error.message
				})
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		}
	},
	
	resetRequestToRecoveryPassword() {
		return (dispatch) => {
			dispatch({
				type: 'SET_REQUEST_FOR_RECOVERY',
				payload: false
			});
			dispatch({
				type: 'SET_REQUEST_FOR_RECOVERY_ERROR',
				payload: null
			});
		}
	},
	setNewPassword(payload, successMessage) {
		return dispatch => {
			dispatch({
				type: 'SET_AUTH_LOADING',
				payload: true
			})
			authenticatedRequest({
				url: 'PasswordRecoverySetPassword',
				method: 'post',
				data: payload
			}).then(() => {
				dispatch({
					type: 'SET_REQUEST_FOR_RECOVERY',
					payload: false
				});
				dispatch({
					type: 'SET_REQUEST_FOR_RECOVERY_ERROR',
					payload: null
				});
				dispatch({
					type: 'SET_AUTH_LOADING',
					payload: false
				})
				dispatch(commonActions.setPopupMessage(successMessage, 'success'));
				dispatch(this.passwordSuccessfullyChanged(true));
				dispatch(push('/auth/login'));
			}).catch((error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				dispatch({
					type: 'SET_AUTH_LOADING',
					payload: false
				})
				dispatch({
					type: 'SET_REQUEST_FOR_RECOVERY_ERROR',
					payload: error.message
				})
				return error
			});
		}
	},
	passwordSuccessfullyChanged(status) {
		return dispatch => {
			dispatch({
				type: 'SET_PASSWORD_SUCCESSFULLY_CHANGED',
				payload: status
			});
		}
	},
}
export default authActions;