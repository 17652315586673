const initialState = {
	timeLogsData: null,
	timeLogsDataLoading: false,
	filterOptions: [],
	selectedFilterOption: null,
}

export default function timeLogsTabReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_TIME_LOGS_DATA": {
			return {
				...state,
				timeLogsData: action.payload,
				timeLogsDataLoading: false,
			}
		}
		case "SET_TIME_LOGS_DATA_LOADING": {
			return {
				...state,
				timeLogsDataLoading: action.payload
			}
		}
		case "SET_FILTER_OPTIONS_LIST": {
			return {
				...state,
				filterOptions: action.payload
			}
		}
		case "SET_SELECTED_FILTER_OPTION": {
			return {
				...state,
				selectedFilterOption: action.payload
			}
		}
		default: {
			return state;
		}
	}
}