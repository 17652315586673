import {useState} from "react";
import {invoicingTableColumns} from "./utils";
import {useDispatch} from "react-redux";
import invoicingActions from "../../../../actions/invoicingActions";

import Table from "../../../../components/Table";
import Row from "./Row";
import ModalWrap from "../../../../components/ModalWrap";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

const InvoicingTable = ({invoices, isFixedNumber, projectId}) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const dispatch = useDispatch();
	const handleDeleteInvoice = () => {
		dispatch(invoicingActions.deleteInvoice(selectedInvoice?.project_id, selectedInvoice._id));
		setSelectedInvoice(null);
		handleCloseModal();
	};
	
	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedInvoice(null);
	};
	
	return (
		<>
			<Table
				childrenHeader={
					invoicingTableColumns.map((column, index) => {
						if (column.key === 'month' && isFixedNumber) return null;
						return <th key={column.key + '-' + index} className={column.key}>
							{column.title}
						</th>
					})
				}
				data={invoices}
				withHoverEffect={false}
				headerWithBorder={true}
			>
				{
					invoices.map((invoice, index) => (
						<Row
							key={invoice._id}
							index={index}
							row={invoice}
							isFixedNumber={isFixedNumber}
							handleDelete={() => {
								setSelectedInvoice(invoice);
								setShowModal(true);
							}}
							projectId={projectId}
						/>
					))
				}
			</Table>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={handleCloseModal}
					children={
						<ConfirmationModal
							handleConfirm={handleDeleteInvoice}
							closeModal={handleCloseModal}
							title='Delete Invoice'
							message={`Are you sure you want to remove the Invoice №${selectedInvoice?.number}? All the related data will be lost.`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</>
	)
}

export default InvoicingTable;