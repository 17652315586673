import HeaderTitle from "../../../../../components/HeaderTitle";
import {FlexBox} from "../../../../../components/commonStyled";
import {memo} from "react";

const SumOfItems = memo((
	{
		profitMargin,
		projectProfit
	}
) => {
	return (
		<FlexBox width={'100%'} padding={'20px 12px 0'}>
			<div style={{
				width: '50%',
			}}>
				<HeaderTitle
					label='Project Profit'
					disabled={true}
					value={projectProfit}
					inputFontSize={'48px'}
					margin={'0 30px 0 auto'}
					maxWidth={'none'}
				/>
			</div>
			<div
				style={{
					width: '50%',
				}}
			>
				<HeaderTitle
					isPercent={true}
					label='Profit Margin'
					disabled={true}
					value={profitMargin}
					inputFontSize={'48px'}
					margin={'0 0 0 30px'}
					maxWidth={'none'}
				/>
			</div>
		
		</FlexBox>
	)
})

export default SumOfItems;