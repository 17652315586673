import {useLayoutEffect, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import talentsActions from "../../actions/talentsActions";
import {usePermission} from "../../hooks/usePermission";
import {FlexBox} from "../../components/commonStyled";
import {Outlet, useLocation, useOutletContext} from "react-router-dom";

import Loader from "../../components/Loader";
import commonActions from "../../actions/commonActions";

const Talents = () => {
	const dispatch = useDispatch();
	const [breadcrumbsRef] = useOutletContext();
	const isPermittedManageTalent = usePermission(
		['super_admin', 'company_admin', 'contract_specialist', 'controller', 'staffer']
	);

	const {talentsDataLoading, updateTalentDetailsLoading} = useSelector(state => state.talentsPageReducer);
	const {pathname} = useLocation();
	
	const isMainPage = pathname === '/talents';
	useLayoutEffect(() => {
		if (isMainPage) {
			dispatch(talentsActions.getTalentsData());
		}
	}, [dispatch, isMainPage]);
	useEffect(()=> {
		return () => {
			dispatch(commonActions.setSearchInputValue(''));
		}
	},[dispatch]);
	const headerHeight = breadcrumbsRef?.current?.offsetHeight || 70;
	return (
		<FlexBox height={`calc(100% - ${headerHeight}px)`} width='100%'>
			{talentsDataLoading || updateTalentDetailsLoading  ?
				<Loader width='100%' height='100%'/> :
				<Outlet
					context={
						{isPermittedManageTalent}
				}
				/>
			}
		</FlexBox>
	);
}

export default Talents;