import {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {inputsCreateTalent, schemaCreateNewTalent} from "../utils";
import {FlexBox} from "../../../components/commonStyled";
import * as CS from "../../../components/commonStyled";
import {useDispatch, useSelector} from "react-redux";
import {isEmptyForm} from "../../../utils";
import talentsActions from "../../../actions/talentsActions";

import TextBox from "../../../components/TextBox";
import GroupInputs from "../../../components/GroupInputs";
import Button from "../../../components/Button";
import ModalWrap from "../../../components/ModalWrap";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import ModalSuccessfully from "../../../components/modals/modalSuccessfully";

const ModalCreateNewTalent = (
	{
		close
	}
) => {
	const {
		register,
		handleSubmit,
		formState: {errors},
		control,
		setValue,
		getValues,
		} =
		useForm({
		resolver: yupResolver(schemaCreateNewTalent)
	});
	const [showWarningModal, setShowWarningModal] = useState(false);
	const {createNewTalentSuccess, talentsData} = useSelector(state => state.talentsPageReducer);
	const {searchInputValue} = useSelector(state => state.commonReducer);
	const dispatch = useDispatch();
	
	const onSubmit = (data) => {
		dispatch(talentsActions.createNewTalent(data));
	}
	const handleCancel = (e) => {
		e.preventDefault();
		if (isEmptyForm(getValues(), ['status'])) {
			close();
		} else {
			setShowWarningModal(true);
		}
	};
	
	const getTalentsDataAfterCreate = () => {
		dispatch(talentsActions.getTalentsData({
				page: 1,
				page_size: talentsData?.page_size || 12,
				query_str: searchInputValue
		}));
		close();
		dispatch(talentsActions.resetAccessCreateNewTalent());
	}
	return (
		<>
		{createNewTalentSuccess ?
			<ModalSuccessfully handleClick={getTalentsDataAfterCreate} title='Successfully '
												 message='Talent Successfully Invited'/>
			: <>
				<TextBox as='h2' size='md' weight='medium' marginBottom='24px'>Invite Talent</TextBox>
				<FlexBox as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'>
					<GroupInputs
						inputs={inputsCreateTalent}
						errors={errors}
						register={register}
						control={control}
						fullWidthInput={false}
						gap='12px'
						marginTopForError='20px'
						setValue={setValue}
						getValues={getValues}
					/>
					<CS.FlexBox justifyContent='space-between' width='100%'>
						<Button type='cancel' label='Cancel' handleClick={handleCancel} ghost={true}/>
						<Button type='send' label='Save'/>
					</CS.FlexBox>
				</FlexBox>
			</>
		}
			{
				showWarningModal &&
				<ModalWrap
					paddingTop='75px'
					close={() => setShowWarningModal(false)}
					children={
						<ConfirmationModal
							handleConfirm={close}
							closeModal={() => setShowWarningModal(false)}
							title='Close the creation process'
							message={`All new data won’t be stored. Are you sure you want to proceed?`}
							buttonText='Yes, Close'
						/>
					}
				/>
			}
		</>
	)
}

export default ModalCreateNewTalent;