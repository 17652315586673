const initialState = {
	economicsData: null,
	economicsDataLoading: false,
	expensesCategories: null,
	expensesCategoriesLoading: false,
};

export default function economicsTabReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_ECONOMICS_DATA": {
			return {
				...state,
				economicsData: action.payload,
				economicsDataLoading: false,
			}
		}
		case "SET_ECONOMICS_DATA_LOADING": {
			return {
				...state,
				economicsDataLoading: action.payload
			}
		}
		case "SET_EXPENSES_CATEGORIES": {
			return {
				...state,
				expensesCategories: action.payload,
				expensesCategoriesLoading: false,
			}
		}
		case "SET_EXPENSES_CATEGORIES_LOADING": {
			return {
				...state,
				expensesCategoriesLoading: action.payload
			}
		}
		default:
			return state;
	}
}