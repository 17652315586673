import {memo, useState} from "react";
import {AccordionHeader, FlexBox} from "../../../../components/commonStyled";
import {ReactComponent as Chevron} from "../../../../assets/Icons/chevronDown.svg";

import ProfessionalFees from "./ProfessiomalFees";
import SumOfItems from "./ProfessiomalFees/SumOfItems";
import NavButtons from "./NavButtons";
import Expenses from "./Expenses";
import SumBlockOfExpenses from "./Expenses/SumBlockOfExpenses";
import ProfitSharing from "./ProfitSharing";
import TextBox from "../../../../components/TextBox";
import {WrapperWithBg} from "./styled";

const EconomicForms = memo((
	{
		grossProfitMargin,
		grossProfitMarginPercent,
		professionalFees,
		setProfessionalFees,
		expenses,
		setExpenses,
		profitSharing,
		setProfitSharing,
		editMode,
		setEditMode,
		talentsNames,
		sumExpenses,
		isStaffingModel,
	}
) => {
	const [isOpened, setIsOpened] = useState(true);
	
	return (
		<WrapperWithBg
			as='section'
			flexDirection='column'
			gap='25px'
			margin='20px 0 0'
			padding={'24px 0 12px'}
			isOpened={isOpened}
		>
			<FlexBox
				padding={'0 12px'}
				width={'100%'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<AccordionHeader
					as='div'
					alignItems='center'
					isOpened={`${isOpened}`}
					onClick={() => setIsOpened(!isOpened)}
				>
					<TextBox
						size={'lg'}
						weight={'medium'}
						lineHeight={'28px'}
					>
						Budget Planning
					</TextBox>
					<Chevron/>
				</AccordionHeader>
			</FlexBox>
			<FlexBox
				as={'div'}
				flexDirection='column'
				gap='20px'
				width='100%'
				padding='0 0 40px'
			>
				<ProfessionalFees
					isStaffingModel={isStaffingModel}
					professionalFees={professionalFees}
					setProfessionalFees={setProfessionalFees}
					editMode={editMode}
					talentsNames={talentsNames}
				/>
				<SumOfItems
					projectProfit={grossProfitMargin}
					profitMargin={grossProfitMarginPercent}
				/>
				<NavButtons
					editMode={editMode}
					setEditMode={setEditMode}
					expenses={expenses}
					setExpenses={setExpenses}
					profitSharing={profitSharing}
					setProfitSharing={setProfitSharing}
				/>
				{expenses?.length > 0 &&
					<FlexBox flexDirection='column' gap='20px' width='100%' padding={'0 12px'}>
						<Expenses
							expenses={expenses}
							setExpenses={setExpenses}
							editMode={editMode}
							talentsNames={talentsNames}
						/>
						<SumBlockOfExpenses expenses={expenses} total={sumExpenses}/>
					</FlexBox>
				}
				{
					profitSharing?.length > 0 &&
					<ProfitSharing
						profitSharing={profitSharing}
						setProfitSharing={setProfitSharing}
						editMode={editMode}
						grossProfitMargin={grossProfitMargin}
						talentsNames={talentsNames}
					/>
				}
			</FlexBox>
		</WrapperWithBg>
	)
})

export default EconomicForms;