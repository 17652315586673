import styled from "styled-components";
import {FlexBox} from "../commonStyled";

export const TabsWrap = styled(FlexBox)`
  z-index: 1;
  transform: ${({translateX}) => `translateX(${translateX}px)`};
  transition: transform 0.3s ease-in-out;
`;
export const Tab = styled(FlexBox)`
  padding: ${({active}) => active ? '10px 10px;' : '8px 10px;'};
  color: ${({active, disabled}) => disabled && !active ? 'var(--up-main-grey)' : 'var(--up-secondary-blue)'};
  font-size: 12px;
  line-height: 18px;
  background: ${({active, disabled}) => active ? 'var(--up-white)' : disabled ? 'var(--up-main-light-grey)' : 'var(--up-light-blue-p400)'};
  border-radius: 4px 4px 0 0;
  font-weight: ${({active}) => active ? '500' : '400'};
  visibility: ${({isHidden}) => isHidden ? 'hidden' : 'visible'};
  z-index: 1;
  min-height: 35px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  border: ${({disabled, active}) =>
          disabled && active ?
                  '1px solid var(--up-main-blue)' :
									disabled ? '1px solid var(--up-main-light-grey)' :
									active ? '1px solid var(--up-light-blue-p400)' :
                  '1px solid var(--up-light-blue-p400)' };
  border-bottom: ${({disabled, active}) =>  active ? '1px solid var(--up-white)' : disabled ? '1px solid var(--up-main-blue)' : '1px solid var(--up-light-blue-p400)'};

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
	
	svg {
		width: 18px;
		height: 18px;
		
		path {
			fill: ${({active, disabled}) => disabled && !active ? 'var(--up-main-grey)' : 'var(--up-secondary-blue)'};
		}
	}
`;

export const CarouselWrap = styled(FlexBox)`
  overflow: hidden;
	position: relative;
	bottom: -1px;
`;

export const CarouselButtonWrapper = styled(FlexBox)`
  height: 40px;
  position: relative;
  overflow: hidden;
  min-width: 40px;
  z-index: 2;
  background: var(--up-page-bg);
  bottom: 1px;
`;

export const CarouselButtonWrapperRight = styled(FlexBox)`
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  min-width: 40px;
  z-index: 2;
  bottom: 1px;
  background: var(--up-page-bg);
`;

export const CarouselButtonShadow = styled.div`
  height: 100%;
  width: 4px;
  min-width: 4px;
  background: var(--up-page-bg);
`;


export const CarouselButton = styled(Tab)`
  width: 36px;
  z-index: 2;
  padding: 9px 10px;
  position: relative;
  cursor: pointer;
	border-bottom: none;
	min-height: 34px;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      background: var(--up-white);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    transform: ${({rotate}) => rotate ? 'rotate(180deg)' : 'rotate(0)'};

    path {
      fill: ${({disabled}) => disabled ? 'var(--up-main-grey)' : 'var(--up-secondary-blue)'};
    }
  }
`;

export const CarouselButtonNext = styled(CarouselButton)`
  //position: absolute;
  //right: 0;
  //bottom: -1px;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  height: 100%;
  //  width: 15px;
  //  left: -16.5px;
  //  top: 0;
  //  background: linear-gradient(90deg, rgba(235,241,245,0) 0%, rgba(235,241,245,1) 100%);
  //}
`;