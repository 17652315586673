import styled, {css} from "styled-components";

export const Textarea = styled.textarea`
  width: 100%;
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  padding: ${({padding}) => padding || '20px 27px'};
  border: 1px solid var(--up-main-light-grey);
  border-radius: 4px;
  resize: none;
  word-break: break-word;
  white-space: pre-line;
  overflow-x: hidden;

  &:disabled {
    cursor: text;
    border-color: var(--up-page-bg);
    background: transparent;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      outline: none;
      border-color: var(--up-main-blue);
    }
  }
`;

export const TextareaWrapper = styled.div`

  .quill {
    display: flex;
    flex-direction: column-reverse;

    ${(props) => {
      if (!props.disabled) {
        return css`
          &:hover {
            .ql-container,
            .ql-toolbar {
              border-color: var(--up-main-blue) !important;
            }

            .ql-container {
              border-bottom-color: var(--up-page-bg) !important;
            }
          }
        `;
      }
    }}
    .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    	top: auto;
			bottom: 100%;
		}
    .ql-snow .ql-picker.ql-expanded .ql-picker-label {
			z-index: 1;
    }
    .ql-container {
      border: 1px solid var(--up-page-bg) !important;
      border-radius: 4px 4px 0 0 !important;
      height: 200px;

      ${(props) => {
        if (props.isFocused) {
          return css`
            border-color: var(--up-main-blue) !important;
            border-bottom-color: var(--up-page-bg) !important;
          `;
        }
      }}
      .ql-editor {
				overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-width: 1px;
        }

        padding: 20px 25px !important;

        * {
          line-height: 1.5 !important;
        }
      }
    }

    .ql-toolbar {
      pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
      border: 1px solid var(--up-page-bg) !important;
      border-top: none !important;
      border-radius: 0 0 4px 4px !important;

      ${(props) => {
        if (props.isFocused) {
          return css`
            border-color: var(--up-main-blue) !important;
          `;
        }
      }}
      .ql-formats {
        border-right: 1px solid var(--up-page-bg);
        padding-right: 15px;

      }
    }
  }

  .ql-picker {
    * {
      color: var(--up-main-grey);

      &:hover {
        color: var(--up-main-blue);

        .ql-stroke {
          stroke: var(--up-main-blue) !important;
        }
      }
    }

    .ql-stroke {
      stroke: var(--up-main-grey) !important;
    }
  }

  button {
    svg {
      path {
        &:not(.ql-fill) {
          stroke: var(--up-main-grey) !important;
        }
      }

      line {
        stroke: var(--up-main-grey) !important;
      }
    }

    .ql-fill {
      fill: var(--up-main-grey) !important;
    }

    &:hover {
      svg {
        path {
          &:not(.ql-fill) {
            stroke: var(--up-main-blue) !important;
          }
        }

        line {
          stroke: var(--up-main-blue) !important;
        }
      }

      .ql-fill {
        fill: var(--up-main-blue) !important;
      }
    }
  }
}
`;