import {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useLayoutEffect} from "react";
import TextBox from "../../../../components/TextBox";
import {Container, FlexBox} from "../../../../components/commonStyled";
import {ReactComponent as Pen} from "../../../../assets/Icons/pen.svg";
import {ButtonEdit, GroupButtons} from "../../../Projects/styled";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {inputsCreateNewContact, schemaCreateNewContact} from "../../utils";
import GroupInputs from "../../../../components/GroupInputs";
import Button from "../../../../components/Button";
import commonActions from "../../../../actions/commonActions";
import Loader from "../../../../components/Loader";
import clientsActions from "../../../../actions/clientsActions";
import {isFormChanged} from "../../../../utils";
import ModalWrap from "../../../../components/ModalWrap";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import {usePermission} from "../../../../hooks/usePermission";

const ActiveContact = () => {
	const {contactId} = useParams();
	const {
		register,
		handleSubmit,
		formState: {errors},
		control,
		setValue,
		getValues,
		reset
	} = useForm({
		resolver: yupResolver(schemaCreateNewContact)
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isPermittedForNotes = usePermission(['super_admin', 'company_admin']);
	const isPermittedManageContact = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']);
	const {clientContacts, createContactLoading, editModeContact} = useSelector(state => state.clientsPageReducer);
	const [contact, setContact] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [activeClientID] = useOutletContext();
	const [reload, setReload] = useState(false);
	
	useLayoutEffect(() => {
		if (clientContacts?.items.length > 0) {
			setContact(clientContacts.items.find(item => item._id === contactId));
		} else {
			navigate(`../`);
		}
	}, [clientContacts, contactId, navigate]);
	
	useEffect(() => {
		dispatch(commonActions.setCustomSizeOfModal({width: '827px', height: 'auto'}));
		return () => {
			dispatch(commonActions.setCustomSizeOfModal(null));
			dispatch(clientsActions.setEditModeContact(false));
		}
	}, [dispatch]);
	
	const handleEditMode = (e) => {
		if (!editModeContact) {
			dispatch(clientsActions.setEditModeContact(true));
		} else {
			handleCancelModal(e)
		}
	};
	
	const handleCancelModal = (e) => {
		e.preventDefault();
		if (isFormChanged(getValues(), contact)) {
			setShowModal(true);
		} else {
			dispatch(clientsActions.setEditModeContact(false));
		}
	};
	
	const onSubmit = (data) => {
		dispatch(clientsActions.editContact(activeClientID, contact._id, data));
		dispatch(clientsActions.setEditModeContact(false));
	};
	
	const handleConfirmExit = () => {
		reset();
		dispatch(clientsActions.setEditModeContact(false));
		setShowModal(false);
		setReload(true);
		setTimeout(() => {
			setReload(false);
		}, 100);
	};
	
	if (reload) return <Loader width='100%' height='100%' position='absolute' background={'var(--up-white)'}/>;
	return (
		<Container
			height='auto'
			width='827px'
			minWidth='827px'
		>
			{createContactLoading && <Loader width='100%' height='100%' position='absolute' background={'var(--up-white)'}/>}
			<FlexBox justifyContent={'space-between'}>
				<TextBox as='h1' size='md' weight='medium' color='dark' lineHeight='28px'>
					{contact?.name}
				</TextBox>
				{isPermittedManageContact && <ButtonEdit
					as='button'
					onClick={handleEditMode}
					editMode={editModeContact}
					alignItems='center'
					justifyContent='center'
				>
					<Pen/>
				</ButtonEdit>}
			</FlexBox>
			<FlexBox margin={'10px 0 0'} as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'
							 position='relative'>
				{contact && <GroupInputs
					inputs={isPermittedForNotes ? inputsCreateNewContact : inputsCreateNewContact.filter(item => item.name !== 'notes')}
					errors={errors}
					register={register}
					control={control}
					gap='12px 12px'
					marginTopForError='20px'
					setValue={setValue}
					getValues={getValues}
					defaultData={contact}
					disabled={!editModeContact}
					width='100%'
				/>}
				{editModeContact && <GroupButtons justifyContent='space-between' width='100%'>
					<Button type='cancel' label='Cancel' handleClick={handleEditMode} ghost={true}/>
					<Button type='send' label='Save'/>
				</GroupButtons>}
			</FlexBox>
			{showModal &&
			<ModalWrap
				paddingTop='75px'
				children={
					<ConfirmationModal
						handleConfirm={handleConfirmExit}
						closeModal={() => setShowModal(false)}
						title={'Close the editing process'}
						message={
							'Are you sure that you want to close edit process without saving?'
						}
						buttonText='Yes, Close'
					/>
				}
				close={() => setShowModal(false)}
			/>}
		</Container>
	);
}

export default ActiveContact;