import styled from "styled-components";
import {FlexBox} from "../commonStyled";

export const CustomPagination = styled(FlexBox)`
	width: ${({width}) => width};
	height: 48px;
  background: #FFFFFF;
  border-top: 1px solid var(--up-main-light-grey);
  box-shadow: var(--main-box-shadow);
	position: ${({position}) => position};
	right: 0;
	bottom: 0;
`;
export const Paginate = styled.div`
	ul {
		display: flex;
		gap: 27px;
		
		a {
			text-decoration: none;
			color: var(--up-main-blue);
			font-size: 12px;
		}
    li {
      &:not(&.disabled) {
        svg {
          path {
            fill: var(--up-main-blue);
          }
        }
      }
			&.active {
				position: relative;
				
				&:after {
					content: '';
					position: absolute;
					width: 24px;
					height: 24px;
          border-radius: 4px;
					border: 1px solid var(--up-main-blue);
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
        }
				a {
					font-weight: 500;
					color: var(--up-main-grey);
				}
      }
    }
	}
`;

export const ArrowPagination = styled.div`
	transform: ${({rotate}) => rotate ? 'rotate(180deg)' : 'rotate(0)'};
	
	`;
export const PaginationSelectDropdown = styled.div`
	width: 40px;
	.custom-select__single-value,
	.custom-select__input-container{
		font-size: 12px;
		text-align: center;
	}
	.custom-select__control {
		height: 28px;
		min-height: 28px;
	}
	.custom-select__value-container {
		padding: 0;
	}
	.custom-select__indicators {
		> div{
			margin-right: 8px;
			height: 100%;
			display: flex;
			align-items: center;
		}
	}
	.custom-select__menu-list {
		>div{
			font-size: 12px!important;
			height: 25px!important;
			padding: 0!important;
			justify-content: center!important;
			
			>div {
				font-size: inherit;
			}
		}
  }
`;