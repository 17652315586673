import * as CS from '../../components/commonStyled';
import * as S from './styled';
import {ReactComponent as LogoDark} from "../../assets/Icons/Logo-dark.svg";
import {ReactComponent as Cat} from "../../assets/Icons/setUpCat.svg";
import {useSelector} from "react-redux";

import TextBox from "../../components/TextBox";
import SetUpContactor from "./SetUpContactor";
import SetUpAllUsers from "./SetUpAllUsers";
import Loader from "../../components/Loader";

const SetUpLayout = () => {
	const {isContractor, userData, setUpLoading} = useSelector(state => state.commonReducer);
	// setUpUserData
	return (
		<CS.SectionWithBg>
			<S.Container>
				<S.LogoWrap>
					<LogoDark/>
				</S.LogoWrap>
				{setUpLoading && <Loader position='absolute' width='100%' height='100%' background={'var(--up-white)'}/> }
				<TextBox as='h1' size='lg' weight='medium' textAlign='center' lineHeight='32px'>
					Welcome! <br/>
					Let’s start your set up
				</TextBox>
				{
					isContractor ?
						<SetUpContactor userData={userData}/> :
						<SetUpAllUsers userData={userData}/>
				}
				<CS.ImgWithAbsolutePosition as={Cat} top={'103.5%'} right={'150px'}/>
			</S.Container>
		</CS.SectionWithBg>
	)
}

export default SetUpLayout;