import * as yup from "yup";
import {validationSchemaForEmail, validationSchemaForPassword, validationSchemaForRepeatPassword} from "../../utils";

export const schemaSignIn = yup.object().shape({
	email: validationSchemaForEmail(),
	password: validationSchemaForPassword(),
});

export const schemaNewPassword = yup.object().shape({
	password: validationSchemaForPassword(),
	'confirm Password': validationSchemaForRepeatPassword(),
});