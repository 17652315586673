import {ReactComponent as Chevron} from "../../assets/Icons/chevronDown.svg";
import {ButtonArrows} from "./styled";

const SortArrows = (
	{
		asc,
		desc,
	}
) => {
	return (
		<ButtonArrows
			as='button'
			flexDirection='column'
			asc={asc}
			desc={desc}
		>
			<Chevron/>
			<Chevron/>
		</ButtonArrows>
	);
}

export default SortArrows;