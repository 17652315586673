import {useRef} from "react";
import {Outlet} from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {useSelector} from "react-redux";
import * as S from "./styled";
import {FlexBox} from "../../components/commonStyled";

import NavMenu from "../../components/NavMenu";
import SetUpLayout from "../SetUp/SetUpLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Routes from "../Routes";

const HomeLayout = () => {
	const {needOnBoarding, userData} = useSelector(state => state.commonReducer);
	const breadcrumbs = useBreadcrumbs(Routes());
	const breadcrumbsRef = useRef(null);
	if (needOnBoarding) {
		return <SetUpLayout/>
	}
	return (
		<>
			{
				userData && <FlexBox>
					<NavMenu/>
					<S.Page>
							<Breadcrumbs
								breadcrumbs={breadcrumbs}
								breadcrumbsRef={breadcrumbsRef}
							/>
						<Outlet context={[breadcrumbsRef]}/>
					</S.Page>
				</FlexBox>
			}
		</>
	);
};

export default HomeLayout;
