import {useState} from "react";
import {usePermission} from "../../hooks/usePermission";
import {useDispatch, useSelector} from "react-redux";
import {FlexBox} from "../../components/commonStyled";
import profileActions from "../../actions/profileActions";
import {ReactComponent as CatHi} from '../../assets/Icons/catHi.svg';

import ModalWrap from "../../components/ModalWrap";
import TextBox from "../../components/TextBox";
import Search from "../../components/Search";
import Button from "../../components/Button";
import ModalAddTeamMember from "./ModalAddTeamMember";

const Header = ({teamMembers})=> {
	const {addNewTeamMemberLoading} = useSelector(state => state.profilePageReducer);
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const isPermittedManageTeamMembers = usePermission(['super_admin', 'company_admin']);
	const onPageChange = (data) => {
		console.log('data', data);
		dispatch(profileActions.getTeamMembers(data));
	};
	
	return (
		<FlexBox justifyContent='space-between' alignItems='flex-start' margin='0 0 24px' gap={'20px'}>
			<TextBox as='h2' size='md' weight='medium' lineHeight='35px' style={{whiteSpace: 'nowrap'}}>
				Team
			</TextBox>
			<FlexBox gap='12px' flexWrap='wrap-reverse' justifyContent='flex-end'>
				{/*<FlexBox gap='12px'>*/}
				{/*	<StatusDropdown width='210px'>*/}
				{/*		<CustomSelect*/}
				{/*			name={'status'}*/}
				{/*			ref={null}*/}
				{/*			options={talentStatusFilterOptions}*/}
				{/*			isSearchable={false}*/}
				{/*			classNameContainer={`status`}*/}
				{/*			isMulti={true}*/}
				{/*			handleOnChange={(value) => console.log(value)}*/}
				{/*			closeMenuOnSelect={false}*/}
				{/*			placeholder='Filter by Status'*/}
				{/*			handleOnBlur={applyStatusFilter}*/}
				{/*			optionSelectedFromReducer={talentStatusFilter?.values}*/}
				{/*			customHeight='36px'*/}
				{/*		/>*/}
				{/*	</StatusDropdown>*/}
				{/*</FlexBox>*/}
				<FlexBox gap='12px'>
					<Search
						onPageChange={onPageChange}
						page={teamMembers?.page}
						page_size={teamMembers?.page_size}
						placeholder='Search by Name'
					/>
					{isPermittedManageTeamMembers &&
						<Button
							label='Add Team Member'
							gap='12px'
							withIcon={true}
							padding='7px 34px'
							flexDirection='row-reverse'
							fontSize='16px'
							handleClick={() => setShowModal(true)}
						/>
					}
				</FlexBox>
			</FlexBox>
			{
				showModal &&
				<ModalWrap
					children={<ModalAddTeamMember handleCancel={() => setShowModal(false)}/>}
					close={() => setShowModal(false)}
					icon={<CatHi/>}
					leftPositionIcon='calc(100% - 80px)'
					topPositionIcon='25px'
					loading={addNewTeamMemberLoading}
				/>
			}
		</FlexBox>
	)
}

export default Header;