import * as S from "./styled";
import {ReactComponent as Eye} from "../../assets/Icons/eye.svg";
import {ReactComponent as EyeSlash} from "../../assets/Icons/eye-slash.svg";
import {splitCodeValue} from "../../utils";
import {useEffect, useState, useCallback} from "react";

const RegularInput = ({props}) => {
	const {specialSymbol, type, setValue, label, getValues, name, defaultValue, padding, disabledColor} = props;
	const [showPassword, setShowPassword] = useState(false);
	const isPasswordInput = type === 'password';
	const isInputProjectCode = label === 'Project Code';
	const setValueForHourlyRates = useCallback(() => {
		const externalDailyRate = getValues('external_daily_rate');
		const internalDailyRate = getValues('internal_daily_rate');
		if (name === 'external_hourly_rate' && externalDailyRate) {
			setValue('external_hourly_rate', externalDailyRate/ 8);
		}
		if (name === 'internal_hourly_rate' && internalDailyRate) {
			setValue('internal_hourly_rate', internalDailyRate/ 8);
		}
	}, [getValues, setValue, name])
	useEffect(()=> {
		if (setValue) {
			if ((defaultValue)) {
				setValue(name, defaultValue);
			} else {
				setValue(name, null);
			}
			if (name === 'external_hourly_rate' || name === 'internal_hourly_rate') setValueForHourlyRates();
		}
	}, [defaultValue, setValue, name, type, setValueForHourlyRates]);
	
	return (
			<S.InputWrap disabledColor={disabledColor}>
				<S.Input
					{...props}
					type={showPassword ? 'text' : type}
					className={isInputProjectCode ? 'code' : ''}
					padding={specialSymbol ? '10.5px 12px 10.5px 25px' : padding}
				/>
				{/*for the MVP we always the show $ currency*/}
				{/*{currency && specialSymbol?*/}
				{/*	<S.SpecialSymbol>{getCurrency(currency)}</S.SpecialSymbol> : null*/}
				{/*}*/}
				{
					specialSymbol &&
					<S.SpecialSymbol>{specialSymbol}</S.SpecialSymbol>
				}
				{isPasswordInput && (
					<S.ShowPasswordButton onClick={() => setShowPassword(!showPassword)}>
						{showPassword ? <Eye/> : <EyeSlash/>}
					</S.ShowPasswordButton>
				)}
				{(isInputProjectCode && (getValues(name) || defaultValue)) &&
				<S.Code gap='6px' alignItems='center'>
					<span>{splitCodeValue((getValues(name) || defaultValue))[0]}</span>
					<span className='tag'>{splitCodeValue((getValues(name) || defaultValue))[1]}</span>
				</S.Code>
				}
			</S.InputWrap>
	)
}

export default RegularInput;