import {Outlet, useOutletContext} from "react-router-dom";
import {FlexBox} from "../../components/commonStyled";
import {useEffect} from "react";
import commonActions from "../../actions/commonActions";
import {useDispatch} from "react-redux";

const TeamMembers = () => {
	const [breadcrumbsRef] = useOutletContext();
	const headerHeight = breadcrumbsRef?.current?.offsetHeight || 70;
	const dispatch = useDispatch();
	useEffect(()=> {
		return () => {
			dispatch(commonActions.setSearchInputValue(''));
		}
	},[dispatch]);
	return (
			<FlexBox as='div'  height={`calc(100% - ${headerHeight}px)`} flexDirection='column' width='100%'>
			 	<Outlet/>
			</FlexBox>
	)
}

export default TeamMembers;