import {useEffect, useState} from "react";
import {forwardRef} from 'react';
import {Controller} from 'react-hook-form';

import CustomSelect from "./CustomSelect";

const SelectDropdown = forwardRef((
	{
		name,
		options,
		control,
		defaultValue,
		icon,
		setValue,
		placeholderItem,
		classNameContainer,
		handleOnChange,
		getValues,
		disabled,
		error,
		isMulti,
		closeMenuOnSelect
	},
	ref
) => {
	const [optionsState, setOptionsState] = useState(options);
	
	useEffect(() => {
		if (options) {
			setOptionsState(options);
		}
	}, [options]);
	
	useEffect(() => {
		if (defaultValue) {
			const foundDefaultValue = optionsState.find(option => option.value.toString() === defaultValue.toString() || option?._id === defaultValue);
			if (foundDefaultValue) {
				setValue(
					name,
					foundDefaultValue
				)
			} else {
				const uniqOption = {
					value: defaultValue,
					label: defaultValue.charAt(0).toUpperCase() + defaultValue.slice(1).replace('_', ' '),
					_id: defaultValue
				};
				setOptionsState(state => [...state, uniqOption]);
				setValue(name, uniqOption);
			}
		} else {
			setValue(name, null);
		}
	}, [defaultValue, setValue, name, optionsState]);
	
	return (
		<Controller
			control={control}
			name={name}
			render={({field}) => (
				<CustomSelect
					field={field}
					options={optionsState}
					name={name}
					ref={ref}
					defaultValue={defaultValue}
					icon={icon}
					control={control}
					placeholder={placeholderItem}
					classNameContainer={classNameContainer}
					handleOnChange={handleOnChange}
					disabled={disabled}
					error={error}
					isMulti={isMulti}
					closeMenuOnSelect={closeMenuOnSelect}
					setValue={setValue}
				/>
			)}
		/>
	);
});


export default SelectDropdown;
