import {useState} from "react";
import * as S from './styled';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {inputsSetUpContractor, schemaSetUpContractor} from "./utils";
import {useDispatch} from "react-redux";
import commonActions from "../../actions/commonActions";
import * as CS from "../../components/commonStyled";

import Steps from "../../components/Steps";
import GroupInputs from "../../components/GroupInputs";
import Button from "../../components/Button";

const SetUpContactor = ({userData}) => {
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);
	const {register, handleSubmit,formState: {errors}, trigger, control, setValue, getValues} = useForm({
		resolver: yupResolver(schemaSetUpContractor),
		mode: 'onChange'
	});

	const onSubmit = (data) => {
		dispatch(commonActions.setUpContactorData(data));
	};
	
	const handleNextStep = (e, step) => {
		e.preventDefault();
		trigger(inputsSetUpContractor[activeStep].map(item=>item.name)).then(isValid=> {
			if (isValid) {
				setActiveStep( prevState => step || prevState + 1);
			}
		});
	};
	
	const handlePrevStep = (e) => {
		e.preventDefault();
		setActiveStep(prevState => prevState - 1);
	};
	return (
		<>
			<Steps
				marginTop='60px'
				activeStep={activeStep}
				handleNextStep={handleNextStep}
				handlePrevStep={handlePrevStep}
			/>
			<S.FormWrap as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'>
				<GroupInputs
					inputs={inputsSetUpContractor[activeStep]}
					errors={errors}
					register={register}
					justifyContent={'flex-end'}
					control={control}
					defaultData={userData || {}}
					setValue={setValue}
					getValues={getValues}
				/>
				{activeStep === 0 ?
				<CS.FlexBox justifyContent='flex-end' width='100%'>
					<Button label='Next' handleClick={handleNextStep}/>
				</CS.FlexBox> :
					<CS.FlexBox justifyContent='space-between' width='100%'>
						<Button label='Back' handleClick={handlePrevStep} ghost={true}/>
						<Button label='Save' type='send'/>
					</CS.FlexBox>}
			</S.FormWrap>
		</>
	)
}

export default SetUpContactor;