import {FlexBox, GridBox, Tag} from "../../../../components/commonStyled";
import projectsActions from "../../../../actions/projectsActions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import CardInfo from "../../../../components/CardInfo";
import CustomPagination from "../../../../components/Pagination";
import SecondDescriptionForCard from "./SecondDescriptionForCard";
import Button from "../../../../components/Button";
import TextBox from "../../../../components/TextBox";

const TeamList = (
	{
		teamData,
		isPermittedManageProjectTeam,
		projectId,
		setShowModal
	}
) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const onPageChange = (data) => {
		dispatch(projectsActions.getTeamData(projectId, data))
	};
	
	const handleDelete = (id) => {
		dispatch(projectsActions.deleteContractorFromTeam(projectId, id));
	};
	const handleClick = (id) => {
		navigate(`${id}`);
	};
	return (
		<>
			<FlexBox justifyContent={'space-between'}>
				<TextBox as='h2' size='md' weight='medium' lineHeight='28px' marginBottom={'24px'}>Team</TextBox>
				{
					(isPermittedManageProjectTeam && teamData?.total > 0) &&
					<Button
						label={'Assign Talent'}
						gap='12px'
						withIcon={true}
						padding='7px 35px'
						flexDirection='row-reverse'
						handleClick={() => setShowModal(true)}
						fontSize='16px'
					/>
				}
			</FlexBox>
			<GridBox as='ul' id='clients-list' gridTemplateColumns='repeat(3, minmax(260px, 1fr))' gridColumnGap='16px'
							 gridRowGap='16px'>
				{teamData?.items.map((item) => (
					<CardInfo
						parentName={'Talent'}
						key={item._id}
						{...item}
						handleDelete={handleDelete}
						handleClick={handleClick}
						isPermittedDelete={isPermittedManageProjectTeam}
						descriptionBlock={<Tag tagName={item?.role}>{item?.role}</Tag>}
						secondaryDescriptionBlock={<SecondDescriptionForCard rate={item?.internal_daily_rate || 0}/>}
						widthLogo={'60px'}
						heightLogo={'60px'}
					/>
				))}
			</GridBox>
			{teamData?.items.length > 0 &&
				<CustomPagination
					totalItems={teamData?.items_count}
					pageSize={teamData?.page_size}
					page={teamData?.page}
					onPageChange={onPageChange}
					width={'calc(100% - 260px)'}
					position={'fixed'}
				/>}
		</>
	)
}

export default TeamList;