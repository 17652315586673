import styled from 'styled-components';
import {keyframes} from 'styled-components'

const opaque = keyframes`
	0% {
		opacity: 0.1;
	}
	40% {
		opacity: 1;
	}
	80% {
		opacity: 0.1;
	}
	100% {
		opacity: 0.1;
	}
`;

export const LoaderWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${({width}) => width};
	height: ${({height}) => height};
	position: ${({position}) => position};
	top: 0;
	left: 0;
	z-index: 3;
	background: ${({background}) => background};
`;
export const CircleLoader = styled.svg`
  position: relative;
  width: auto;
  height: auto;

  path {
    -webkit-animation: 1.6s ${opaque} ease-in-out infinite both;
    animation: 1.6s ${opaque} ease-in-out infinite both;
  }

  path:nth-child(8) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  path:nth-child(6) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

	path:nth-child(4) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  path:nth-child(2) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }

  path:nth-child(7) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  path:nth-child(5) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }

  path:nth-child(3) {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
  }
`;
