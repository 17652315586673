import {sumCostOfAllItems, sumItems} from "../utils";
import {reducingNumber} from "../../../../../utils";

const Row = (
	{
		row,
		professionalFees,
		expenses,
		grossProfitMargin,
		profitSharing,
	}
) => {
	//summary professional fees for each talent
	const sumProfessionalFees = reducingNumber(sumCostOfAllItems(professionalFees.filter(item => item?.contractor_id === row?.contractor_id)));
	//summary expenses for each talent
	const sumExpenses = reducingNumber(sumItems(expenses.filter(item => item?.contractor_id === row?.contractor_id), 'price'));
	//summary profit for each talent
	const findProfitSharing = profitSharing.find(item => item?.contractor_id === row?.contractor_id);
	const sumProfit = findProfitSharing ? reducingNumber((findProfitSharing?.percent_from_gross_profit * grossProfitMargin) / 100) : 0;
	const total = reducingNumber(sumProfessionalFees + sumExpenses + sumProfit);
	
	return (
		<tr>
			<td style={{fontWeight: 500}}>
				{row?.name || '-'}
			</td>
			<td
				style={{textAlign: 'right', paddingRight: '16px'}}
			>
				$ {sumProfessionalFees}
			</td>
			<td
				style={{textAlign: 'right', paddingRight: '16px'}}
			>
				$ {sumExpenses}
			</td>
			<td
				style={{textAlign: 'right', paddingRight: '16px'}}
			>
			 $ {sumProfit}
			</td>
			<td
				style={{textAlign: 'right', fontWeight: 500, paddingRight: '16px'}}
			>
				$ {total}
			</td>
		</tr>
	)
};

export default Row;