import {useState} from "react";
import {documentsTableColumns} from "../../pages/Clients/utils";
import {useDispatch, useSelector} from "react-redux";
import commonActions from "../../actions/commonActions";
import {getErrorMessages} from "../../utils";

import CustomPagination from "../Pagination";
import Table from "../Table";
import DocumentTableRow from "./DocumentTableRow";
import ModalWrap from "../ModalWrap";
import ConfirmationModal from "../modals/ConfirmationModal";
import documentsActions from "../../actions/documentsActions";

const DocumentsList = (
	{
		documents,
		getDocuments,
		objectId,
		isPermittedManageDocument,
		objectType,
		typesOfDocuments,
		isPermittedEdit
	}
) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [selectedDocument, setSelectedDocument] = useState(null);
	const {searchInputValue} = useSelector(state => state.commonReducer);
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const handleDeleteDocument = () => {
		dispatch(
			documentsActions.deleteDocument(objectType, objectId, selectedDocument._id)
		).then(() => {
			dispatch(commonActions.setPopupMessage('Document Successfully Deleted', 'success'));
			getDocuments(
				{
					page: documents?.page,
					page_size: documents?.page_size,
					query_str: searchInputValue
				}
			);
		})
			.catch((error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
	};
	const handleUploadNewVersionDocument = (formData, docId) => {
		dispatch(documentsActions.uploadNewVersionOfDocument(
			objectType, objectId, formData, 'other', docId)
		).then(() => {
			dispatch(commonActions.setPopupMessage('Documents Successfully Uploaded', 'success'));
			getDocuments(
				{
					page: documents?.page,
					page_size: documents?.page_size,
					query_str: searchInputValue
				}
			);
		})
			.catch((error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
	};
	return (
		<>
			<Table
				childrenHeader={
					documentsTableColumns.map((column, index) => {
						const isHidden =
							(column.dataIndex === 'delete' && !isPermittedManageDocument)
						;
						return isHidden ? null : <th key={column.key + '-' + index} className={column.key}>
							{column.title}
						</th>
					})
				}
				data={documents.items}
				height={'calc(100vh - 250px)'}
				withHoverEffect={false}
			>
				{
					documents.items.map((item, index) => (
							<DocumentTableRow
								key={item._id + '-' + index}
								doc={item}
								objectId={objectId}
								setShowModal={setShowModal}
								setSelectedDocument={setSelectedDocument}
								isPermittedManageDocument={isPermittedManageDocument}
								handleUpload={handleUploadNewVersionDocument}
								typesOfDocuments={typesOfDocuments}
								objectType={objectType}
								isPermittedEdit={isPermittedEdit}
							/>
						)
					)
				}
			</Table>
			<CustomPagination
				totalItems={documents?.items_count}
				pageSize={documents?.page_size}
				page={documents?.page}
				onPageChange={getDocuments}
				width={'calc(100% - 260px)'}
				position={'fixed'}
			/>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={handleCloseModal}
					children={
						<ConfirmationModal
							handleConfirm={handleDeleteDocument}
							closeModal={handleCloseModal}
							title='Delete Document'
							message={`Are you sure you want to remove the ${selectedDocument?.name}?`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</>
	)
}

export default DocumentsList;