import PropTypes from "prop-types";
import * as S from './styled';
import Select from "../SelectDropdown";

import Checkbox from "../Checkbox";
import Textarea from "../Textarea";
import InputWithMask from "../InputWithMask";
import RegularInput from "./RegularInput";
import SingleCalendar from "../SingleCalendar";
import NumberInput from "./NumberInput";
import InputUploadLogo from "./InputUploadLogo";
import ListOfAttachedDocuments from "../ListOfAttachedDocuments";
import InvoicingType from "../InvoicingType";

const FormInput = (
	{
		name,
		register,
		errors,
		label = '',
		placeholder = '',
		type = 'text' | 'password' | 'select' | 'checkbox' | 'textarea' | 'number' | 'date' | 'file' | 'invoicing_type' | 'documents' | 'text_mask',
		isStaticErrorMessage = false,
		isRequired = false,
		marginTopForError = '24px',
		options,
		control = () => {
		},
		defaultValue = '',
		textTransform = null,
		textTransformLabel = null,
		setValue,
		classNameContainer,
		getValues,
		rows,
		disabled = false,
		mask,
		specialSymbol,
		maxLength = null,
		isMulti = false,
		closeMenuOnSelect = true,
		padding,
		currency = null,
		disabledColor = null,
		disabledInputs,
		setDisabledInputs,
		maxDigitsAfterDot = null,
		clearErrors,
		forbiddenKeys,
		previewDoc,
	}
) => {
	const isFileInput = type === 'file';
	//switch for inputs by type
	const getInputByType = (type) => {
		switch (type) {
			case 'select':
				return <Select
					{...inputProps}
					getValues={getValues}
				/>;
			case 'checkbox':
				delete inputProps.defaultValue
				return <Checkbox
					inputProps={inputProps}
					getValues={getValues}
					defaultValue={defaultValue}
				/>;
			case 'textarea':
				return <Textarea
					label={label}
					props={inputProps}
				/>;
			case 'date':
				return <SingleCalendar props={inputProps} />;
			case 'number':
				return <NumberInput
					props={inputProps}
					disabledInputs={disabledInputs}
					forbiddenKeys={forbiddenKeys}
				/>;
			case 'file':
				return <InputUploadLogo props={inputProps} />;
			case 'invoicing_type':
				return <InvoicingType {...inputProps} setDisabledInputs={setDisabledInputs} />;
			case 'documents':
				return <ListOfAttachedDocuments props={{ ...inputProps, previewDoc }} />
			case 'text_mask':
				return <InputWithMask
					name={name}
					label={label || name}
					defaultValue={defaultValue}
					register={register}
					mask={mask}
					textTransform={textTransform}
					setValue={setValue}
					getValues={getValues}
					error={errors[name]}
					disabled={disabled}
				/>;
			default:
				return <RegularInput
					props={inputProps}
				/>;
		}
	};

	const isInputProjectCode = label === 'Project Code';
	const inputProps = {
		id: name,
		error: errors[name],
		placeholderItem: placeholder,
		...register(name),
		type,
		defaultValue,
		textTransform,
		name,
		label,
		isRequired,
		rows,
		control,
		options,
		setValue,
		classNameContainer,
		getValues,
		disabled,
		isInputProjectCode,
		specialSymbol,
		maxLength,
		isMulti,
		closeMenuOnSelect,
		padding,
		currency,
		disabledColor,
		maxDigitsAfterDot,
		clearErrors,
	};

	return (
		<S.FormInputWrap className={type + ' ' + classNameContainer}>
			{
				errors[name] &&
				<S.ErrorMessage
					marginTopForError={marginTopForError}
					className={classNameContainer}
					isStaticErrorMessage={isStaticErrorMessage}>
					{errors[name].message}
				</S.ErrorMessage>
			}
			{
				getInputByType(type)
			}
			{isFileInput ? null : <S.Label
				htmlFor={name}
				isRequired={isRequired && !disabled}
				textTransform={textTransformLabel ? textTransformLabel : 'capitalize'}
			>
				{label || name}
			</S.Label>}
		</S.FormInputWrap>
	)
}

FormInput.propTypes = {
	name: PropTypes.string.isRequired,
	register: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	type: PropTypes.oneOf(['text', 'password', 'select', 'checkbox', 'textarea', 'number', 'date', 'file', 'invoicing_type', 'documents', 'text_mask']),
	isAbsoluteErrorMessage: PropTypes.bool,
	isRequired: PropTypes.bool,
	options: PropTypes.array,
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
		PropTypes.array,
	]),
	label: PropTypes.string,
}
export default FormInput;