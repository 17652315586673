import {ReactComponent as Icon} from "../../assets/Icons/search.svg";
import {ReactComponent as Close} from "../../assets/Icons/close.svg";
import {Input} from "../FormInput/styled";
import * as S from './styled';
import {useDispatch, useSelector} from "react-redux";
import commonActions from "../../actions/commonActions";

const Search = (
	{
		placeholder = 'Search...',
		width = '263px',
		onPageChange = (e) => {
			console.log(e)
		},
		page_size = 12,
	}
) => {
	const {searchInputValue} = useSelector(state => state.commonReducer);
	const dispatch = useDispatch();
	const handleChange = (e) => {
		dispatch(commonActions.setSearchInputValue(e.target.value));
	};
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			onPageChange({
				page: 1,
				page_size: page_size,
				query_str: searchInputValue
			});
		}
	};
	
	const handleClear = () => {
		dispatch(commonActions.setSearchInputValue(''));
		onPageChange({
			page: 1,
			page_size: page_size,
			query_str: ''
		});
	}
	return (
		<S.SearchInput width={width}>
			<S.SearchIcon as={Icon}/>
			<Input
				as='input'
				type="text"
				padding='8.5px 8px 8.5px 43px'
				placeholder={placeholder}
				value={searchInputValue}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/>
			{searchInputValue &&
			<S.CloseIcon as={Close} onClick={handleClear}/>}
		</S.SearchInput>
	);
}

export default Search;