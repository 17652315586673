import {Outlet, useParams} from "react-router-dom";
import {FlexBox} from "../../../components/commonStyled";

const ActiveClientWrap = () => {
	const {slug: activeClientID} = useParams()
	return (
		<FlexBox as='div' height='100%' width='100%' padding='20px 20px 0' gap='20px'>
			<Outlet
				context={
					[
						activeClientID,
					]
				}
			/>
		</FlexBox>
	)
}

export default ActiveClientWrap;