import moment from "moment/moment";
import * as S from "../../components/CardInfo/styled";
import {FlexBox, TableIconButton, Tag} from "../../components/commonStyled";
import {ReactComponent as Trash} from "../../assets/Icons/trash.svg";
import {ReactComponent as Dots} from "../../assets/Icons/dotsVertical.svg";

import Avatar from "../../components/Avatar";
import TextBox from "../../components/TextBox";
import {usePopperTooltip} from "react-popper-tooltip";
import {useNavigate} from "react-router-dom";
import {getRoleName} from "../../utils";

const TeamMembersTableRow = (
	{
		item,
		setShowModal,
		setSelectedTeamMember,
		isPermittedManageTeamMembers
	}
	) => {
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		trigger: 'click',
		placement: 'left-start',
		interactive: true,
	});
	const navigate = useNavigate();
	const onDelete = () => {
		setShowModal(true);
		setSelectedTeamMember(item);
	}
	return (
		<tr onClick={()=> isPermittedManageTeamMembers ? navigate(`/team/${item._id}`) : null}>
			<td>
				<FlexBox alignItems='center' gap='10px'>
					<Avatar src={item?.logo} name={item?.full_name} width='32px' height='32px' sizeInitials='xsm'/>
					<span className="name">
						{item?.full_name || '-'}
					</span>
				</FlexBox>
			</td>
			<td>{item?.email || '-'}</td>
			<td>{item?.phone_number || '-'}</td>
			<td>
				{item?.created_at ? moment(item?.created_at, 'MM/DD/YYYYTHH:mm:ssZ').format('MM/DD/YYYY') : '-'}
			</td>
			<td>
				<Tag tagName={item?.verified  ? 'verified' : 'unverified'}>{item?.verified ? 'active' : 'invited'}</Tag>
			</td>
			<td>
				<Tag tagName={item?.role}>{getRoleName(item?.role) || '-'}</Tag>
			</td>
			<td className='action' onClick={e => e.stopPropagation()}>
				{isPermittedManageTeamMembers && <TableIconButton ref={setTriggerRef} visible={visible}>
					<Dots/>
				</TableIconButton>}
				{visible && (
					<S.TooltipContainer
						ref={setTooltipRef}
						{...getTooltipProps({className: 'tooltip-container'})}
					>
						<div>
							<S.TooltipItem
								as='button'
								alignItems='center'
								gap='8px'
								onClick={onDelete}
							>
								<Trash/>
								<TextBox as='span' size='sm' lineHeight='20px'>Delete</TextBox>
							</S.TooltipItem>
						</div>
					</S.TooltipContainer>
				)}
			</td>
		</tr>
	)
}

export default TeamMembersTableRow;