import styled from 'styled-components';
import {FlexBox} from "../commonStyled";

export const InputRange = styled.input`
  background: ${({progress}) => `linear-gradient(to right, var(--up-main-dark) 0%, var(--up-main-dark) ${progress}%, var(--up-page-bg) ${progress}%, var(--up-page-bg) 100%)`};
					//linear-gradient(to right, var(--up-main-dark) 0%, var(--up-main-dark) 40%, var(--up-page-bg) 40%, var(--up-page-bg) 100%);
  //border: solid 2px #82CFD0;
  border-radius: 8px;
  height: 7px;
  width: 400px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: var(--up-main-dark);
  }
`;

export const InputRangeButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
`;

export const InfoBlock = styled(FlexBox)`
  background: rgba(23, 33, 53, 0.75);
  padding: 8px 16px;
  border-radius: 4px;
	font-size: 12px;
	line-height: 18px;
	color: var(--up-white);
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
`;

export const PreviewImage = styled.img`
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background: var(--up-blue-p100);
`;