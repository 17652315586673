export const professionalFeesTableColumns = [
	{
		title: 'Team Member',
		dataIndex: 'contractor_name',
		key: 'name',
	},
	{
		title: 'Daily Rate External',
		dataIndex: 'external_daily_rate',
		key: 'external_daily_rate',
		textAlign: 'right',
	},
	{
		title: 'Daily Rate Internal',
		dataIndex: 'internal_daily_rate',
		key: 'internal_daily_rate',
		textAlign: 'right',
	},
	{
		title: 'Of Days Allowed',
		dataIndex: 'of_days_allowed',
		key: 'of_days_allowed',
		textAlign: 'right',
	},
	{
		title: 'Total Contractor Cost',
		dataIndex: 'total_contractor_cost',
		key: 'total_contractor_cost',
		bold: true,
		textAlign: 'right',
	},
	{
		title: '',
		dataIndex: 'action',
		key: 'action trash-icon',
		bold: false
	}
];

export const expensesTableColumns = [
	{
		title: 'Team Member',
		dataIndex: 'contractor_name',
		key: 'name',
	},
	{
		title: 'Expense Type',
		dataIndex: 'expense_type',
		key: 'expense_type',
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
		textAlign: 'right',
		width: '74px'
	},
	// {
	// 	title: 'Report',
	// 	dataIndex: 'report',
	// 	key: 'report',
	// 	textAlign: 'center',
	// },
	{
		title: 'Note',
		dataIndex: 'note',
		key: 'note',
	},
	{
		title: 'Type',
		dataIndex: 'reimbursed',
		key: 'reimbursed',
		// textAlign: 'right',
	},
	{
		title: 'Total',
		dataIndex: 'total',
		key: 'total',
		textAlign: 'right',
	},
	{
		title: '',
		dataIndex: 'action',
		key: 'action trash-icon',
	}
];

export const profitSharingTableColumns = [
	{
		title: 'Team Member',
		dataIndex: 'partner_name',
		key: 'name',
	},
	{
		title: '% Gross Profit',
		dataIndex: 'percent_from_gross_profit',
		key: 'percent_from_gross_profit',
		textAlign: 'right',
	},
	{
		title: 'Total Payment To Partner',
		dataIndex: 'total_payment_to_partner',
		key: 'total_payment_to_partner',
		textAlign: 'right',
	},
	{
		title: '',
		dataIndex: 'action',
		key: 'action trash-icon',
	}
];

export const initialProfessionalFee =
	{
		contractor_id: null,
		internal_daily_rate: 0,
		external_daily_rate: 0,
		name: '',
		of_days_allowed: 0,
	};

export const initialExpense = {
	contractor_id: null,
	name: '',
	expense_type: '',
	price: 0,
	report: null,
	reimbursed: false,
}

export const initialProfitSharing = {
	// partner_id: null,
	percent_from_gross_profit: 0,
	partner_name: '',
}
export const productOfTwoNumbers = (a, b) => {
	return a * b;
}

export const sumCostOfAllItems = (arr, key = 'internal_daily_rate') => {
	return arr.reduce((acc, b) => {
		return acc + productOfTwoNumbers(b[key], b?.of_days_allowed)
	}, 0);
};

export const sumItems = (arr, key = 'of_days_allowed') => {
	return arr.reduce((acc, b) => {
		return acc * 1 + b[key] * 1
	}, 0);
}

export const configStylesForEconomicsTable = {
	width: '100%',
	headerColor: 'var(--up-white)',
	withStickyHeader: false,
	withHoverEffect: false,
	tableBorderRadius: '6px',
	boxShadow: 'none',
	borderSpacing: '0',
	overflow: 'visible',
	headerWithBorder: true,
	border: '1px solid var(--up-page-bg)'
};

export const checkIsNotEditData = (data, initialData) => {
	return JSON.stringify(data) === JSON.stringify(initialData);
};

export const talentsSummaryTableColumns = [
	{
		title: 'Team Member',
		dataIndex: 'contractor_name',
		key: 'name',
	},
	{
		title: 'Professional Fees',
		dataIndex: 'professional_fees',
		key: 'professional_fees',
		textAlign: 'right',
	},
	{
		title: 'Expenses',
		dataIndex: 'expenses',
		key: 'expenses',
		textAlign: 'right',
	},
	{
		title: 'Profit Sharing',
		dataIndex: 'profit_sharing',
		key: 'profit_sharing',
		textAlign: 'right',
	},
	{
		title: 'Total',
		dataIndex: 'total',
		key: 'total',
		bold: true,
		textAlign: 'right',
	},
];
