import * as S from "./styled";
import {getInitials} from "../../utils";
import TextBox from "../TextBox";
import PropTypes from "prop-types";

const Avatar = (
	{
		src = '',
		name = '',
		width = '54px',
		height = '54px',
		sizeInitials= 'sm',
		style = {},
		colorText = 'dark',
		withTooltip = false,
		onClick,
		withHover = false,
	}
) => {
	return (
		<S.AccountInitials
			alignItems='center'
			justifyContent='center'
			width={width}
			height={height}
			style={style}
			title={withTooltip ? name : null}
			onClick={onClick}
			withHover={withHover}
		>
			{
				src ?
					<S.AccountAvatar as='img' src={src} alt='avatar' width={'auto'} height={'auto'} maxWidth={'100%'}/>
					:
						<TextBox as='span' color={colorText} size={sizeInitials}>{name ? getInitials(name) : 'NN'}</TextBox>
			}
		</S.AccountInitials>
	)
};
Avatar.propTypes = {
	src: PropTypes.string,
	name: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
export default Avatar;