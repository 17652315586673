import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import invoicingActions from "../../../../actions/invoicingActions";
import Header from "./Header";
import Loader from "../../../../components/Loader";
import NoData from "../../../../components/NoData";
import Table from "./Table";

const Invoicing = (
	{
		projectId
	}
) => {
	const dispatch = useDispatch();
	const {invoicingData, invoicingDataLoading} = useSelector(state => state.invoicingTabReducer);
	const {projectData} = useSelector(state => state.projectsPageReducer);
	const invoicingType = projectData?.invoicing_type;
	useEffect(() => {
		dispatch(invoicingActions.getInvoices(projectId));
	}, [projectId, dispatch]);
	if (invoicingDataLoading) return <Loader position={'absolute'} width={'100%'} height={'100%'}/>;
	const handleAddInvoice = () => {
		dispatch(invoicingActions.addInvoice(projectId));
	};
	return (
		<>
			<Header
				invoicingType={invoicingType}
				handleAddInvoice={handleAddInvoice}
			/>
			{invoicingData?.items?.length > 0 ?
				<Table
					invoices={invoicingData?.items}
					isFixedNumber={invoicingType === 'fixed_number'}
					projectId={projectId}
				/>
				:
				<NoData
					isPermittedButton={false}
					text='You have no Invoice yet'
					isInvoicing={true}
					maxHeight={'400px'}
				/>
			}
		</>
	)
}

export default Invoicing