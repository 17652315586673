import styled, {css} from "styled-components";
import {FlexBox} from "../commonStyled";

export const AccountAvatar = styled.img`
  max-width: ${props => props.maxWidth || '100%'};
`;

export const AccountInitials = styled(FlexBox)`
	border-radius: 50%;
	background-color: var(--up-blue-p100);
	border:	1px solid var(--up-light-blue-p400);
	width: ${props => props.width || '54px'};
	min-width: ${props => props.width || '54px'};
	height: ${props => props.height || '54px'};
	overflow: hidden;
	
	${props => props.withHover && css`
	cursor: pointer;
	&:hover {
		border-color: var(--up-main-info);
		z-index: 2;
  }
	`}
`;