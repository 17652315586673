import {ButtonClose} from "../../components/ModalWrap/styled";
import ModalSuccessfully from "../../components/modals/modalSuccessfully";
import TextBox from "../../components/TextBox";
import {FlexBox} from "../../components/commonStyled";
import GroupInputs from "../../components/GroupInputs";
import * as CS from "../../components/commonStyled";
import Button from "../../components/Button";
import {useDispatch, useSelector} from "react-redux";
import profileActions from "../../actions/profileActions";
import {ReactComponent as IconClose} from '../../assets/Icons/close.svg';
import {inputsAddTeamMember, schemaAddTeamMember} from "../Profile/utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const ModalAddTeamMember = (
	{
		handleCancel
	}
) => {
	const {addNewTeamMemberSuccess, teamMembers} = useSelector(state => state.profilePageReducer);
	const {searchInputValue} = useSelector(state => state.commonReducer);
	const dispatch = useDispatch();
	const {register, handleSubmit, formState: {errors}, control, setValue, getValues} = useForm({
		resolver: yupResolver(schemaAddTeamMember)
	});
	const getTeamMembersAfterCreate= () => {
		dispatch(profileActions.getTeamMembers({
			page: 1,
			page_size: teamMembers?.page_size || 12,
			query_str: searchInputValue
		}));
		handleCancel();
		dispatch(profileActions.resetAddTeamMemberSuccess());
	};
	const onSubmit = (data) => {
		dispatch(profileActions.addTeamMember(data));
	};
	
	return (
		<>
			<ButtonClose onClick={handleCancel}><IconClose/></ButtonClose>
			{addNewTeamMemberSuccess ?
				<ModalSuccessfully handleClick={getTeamMembersAfterCreate} title='Successfully '
													 message='Team Member Successfully Added'/>
				: <><TextBox as='h2' size='md' weight='medium' marginBottom='24px'>Add Team Member</TextBox>
					<FlexBox as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'>
						<GroupInputs
							inputs={inputsAddTeamMember}
							errors={errors}
							register={register}
							control={control}
							fullWidthInput={true}
							gap='17px'
							marginTopForError='20px'
							setValue={setValue}
							getValues={getValues}
						/>
						<CS.FlexBox justifyContent='space-between' width='100%'>
							<Button type='cancel' label='Cancel' handleClick={handleCancel} ghost={true}/>
							<Button type='send' label='Save'/>
						</CS.FlexBox>
					</FlexBox></>}
		</>
	)
}

export default ModalAddTeamMember;