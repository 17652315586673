import {Container} from "../../components/commonStyled";
import Header from "./Header";
import TeamMembersTable from "./TeamMembersTable";
import CustomPagination from "../../components/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import profileActions from "../../actions/profileActions";
import Loader from "../../components/Loader";

const TeamMembersList = () => {
	const dispatch = useDispatch();
	const {teamMembers, teamMembersLoading} = useSelector(state => state.profilePageReducer);
	useEffect(() => {
		dispatch(profileActions.getTeamMembers());
	}, [dispatch]);
	
	const onPageChange = (data) => {
		dispatch(profileActions.getTeamMembers(data));
	};
	
	if (teamMembersLoading) return <Loader width={'100%'} height={'100%'}/>;
	return (
		<div style={{padding:'20px 20px 0', width: '100%', height: '100%'}}>
			<Container overflow='hidden' padding='24px 24px 0'>
				<Header/>
				{teamMembers?.total &&
					<>
						<TeamMembersTable
							data={teamMembers?.items}
						/>
					</>
				}
			</Container>
			<CustomPagination
				totalItems={teamMembers?.items_count}
				pageSize={teamMembers?.page_size}
				page={teamMembers?.page}
				onPageChange={onPageChange}
			/>
		</div>
	)
}

export default TeamMembersList;