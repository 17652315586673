import {pages} from "../../utils";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import * as S from './styled';

import {ReactComponent as Logo} from "../../assets/Icons/Logo-light.svg";

import LogOut from "../LogOut";
import {FlexBox} from "../commonStyled";

const NavMenu = () => {
	const {isContractor, userData} = useSelector(state => state.commonReducer);
	const userRole = userData?.role;
	
	const pagesToRender = isContractor ? pages[1] : pages[0];
	return (
		<S.NavMenu as='nav' flexDirection='column'>
			<S.NavLogo>
				<Logo/>
			</S.NavLogo>
			<S.NavContent width='100%' flexDirection='column' justifyContent='space-between'>
				<S.NavList as='ul' flexDirection='column' gap='40px' width='100%'>
					{
						pagesToRender.map((page) => {
							const isUnavailable = page?.unavailableRoles?.includes(userRole);
								return (
									!isUnavailable &&
									<li key={page.name}>
										<S.NavLink as={NavLink} to={page.path}>
											<FlexBox as='span' justifyContent={'center'} width={'24px'} style={{minWidth: '24px'}}>
												{page.icon}
											</FlexBox>
											{page.name}
										</S.NavLink>
									</li>
								)
							}
						)
					}
				</S.NavList>
				<LogOut/>
			</S.NavContent>
		</S.NavMenu>
	);
}

export default NavMenu;