import {SectionWithBg} from "../components/commonStyled";
import {ReactComponent as BannerImg} from "../assets/img/notFoundBannerImg.svg";
import Button from "../components/Button";
import {useNavigate} from "react-router-dom";

const NotFoundPage = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate('/');
	}
	return (
		<SectionWithBg gap='13%'>
			<BannerImg/>
			<Button
				label={'Back to Home Page'}
				handleClick={handleClick}
			/>
		</SectionWithBg>
	)
}
export default NotFoundPage;