import {FlexBox} from "../../../../components/commonStyled";
import TextBox from "../../../../components/TextBox";
import {reducingNumber} from "../../../../utils";

const SecondDescriptionForCard = ({ rate }) => {
	return (
		<FlexBox width='100%' gap={'12px'} alignItems='center' flexWrap={'wrap'}>
			<TextBox as={'h3'} color={'#7B7171'} size={'xsm'}>
				Daily rate:
				<TextBox as={'span'} color={'var(--up-main-dark)'} size={'xsm'} style={{marginLeft: '4px'}}>
					$ {rate || 0}
				</TextBox>
			</TextBox>
			<TextBox as={'h3'} color={'#7B7171'} size={'xsm'}>
				Hourly rate:
				<TextBox as={'span'} color={'var(--up-main-dark)'} size={'xsm'} style={{marginLeft: '4px'}}>
					$ {reducingNumber(rate / 8 || 0)}
				</TextBox>
			</TextBox>
		</FlexBox>
	)
}

export default SecondDescriptionForCard;