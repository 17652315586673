import {FormInputWrap, Input, SpecialSymbol} from "../../../../../components/FormInput/styled";
import {TableIconButton} from "../../../../../components/commonStyled";
import {ReactComponent as Trash} from "../../../../../assets/Icons/trash.svg";
import {ReactComponent as Arrow} from "../../../../../assets/Icons/arrowDropdown.svg";
import {sumItems} from "../utils";
import CustomSelect from "../../../../../components/SelectDropdown/CustomSelect";
import {useEffect, useMemo, useState} from "react";
import {handleKeyDown, reducingNumber} from "../../../../../utils";


const Row = (
	{
		row,
		editMode,
		index,
		handleDeleteItem,
		grossProfitMargin,
		setProfitSharing,
		profitSharing,
		talentsNames
	}
)=> {
	const filterTalents = useMemo(() => {
		const talentsIds = profitSharing?.map(item => item?.contractor_id);
		return talentsNames?.filter(talent => !talentsIds.includes(talent._id) || talent._id === row?.contractor_id);
	}, [profitSharing, talentsNames, row?.contractor_id]);
	const [talents, setTalents] = useState(filterTalents);
	
	useEffect(() => {
		setTalents(filterTalents);
	}, [filterTalents, profitSharing]);
	const handleSelectItem = (value, index) => {
		const newProfitSharing = JSON.parse(JSON.stringify(profitSharing));
		newProfitSharing[index].contractor_id = value._id;
		newProfitSharing[index].name = value.full_name;
		setProfitSharing(newProfitSharing);
	};
	const handleChangesPercent = (e, index, fieldName) => {
		const value = e.target.value * 1;
		if (isNaN(value)) return;
		const newProfitSharing = JSON.parse(JSON.stringify(profitSharing));
		const itemsWithoutTarget = newProfitSharing.filter((item, i) => i !== index);
		if (value > (100 - sumItems(itemsWithoutTarget, 'percent_from_gross_profit'))) return;
		if (fieldName === 'percent_from_gross_profit') {
			newProfitSharing[index].total_payment = reducingNumber((grossProfitMargin * value / 100));
		}
		newProfitSharing[index][fieldName] = value;
		setProfitSharing(newProfitSharing);
	};
	
	return (
		<tr>
			<td style={{overflow: 'visible'}}>
				<CustomSelect
					name={'contactors'}
					ref={null}
					options={talents}
					defaultValue={row?.contractor_id}
					icon={<Arrow/>}
					marginRight='0px'
					fontSize='12px'
					height='28px'
					handleOnChange={(value) => handleSelectItem(value, index)}
					isSearchable={false}
					sizeIcon='6px'
					disabled={!editMode}
				/>
			</td>
			<td>
				<FormInputWrap>
					<Input
						value={row?.percent_from_gross_profit * 1 || ''}
						type="text"
						onChange={(e) => handleChangesPercent(e, index, 'percent_from_gross_profit')}
						disabled={!editMode}
						placeholder={'0'}
						textAlign={'right'}
						padding={'10.5px 20px 10.5px 10px'}
						placeholderColor={'var(--up-main-dark)'}
						pattern="\d*"
						onKeyDown={handleKeyDown}
					/>
					<SpecialSymbol left={'calc(100% - 20px)'}>%</SpecialSymbol>
				</FormInputWrap>
			
			</td>
			<td style={{fontWeight: '500', textAlign: 'right'}} className='with-bg'>
				$ {reducingNumber((row?.percent_from_gross_profit * grossProfitMargin) / 100)}
			</td>
			<td style={{textAlign: 'center', width: '34px'}} className='action trash-icon with-bg'>
				<TableIconButton disabled={!editMode} onClick={()=>handleDeleteItem(index)}>
					<Trash />
				</TableIconButton>
			</td>
		</tr>
	)
}

export default Row;