import {Outlet} from "react-router-dom";
import * as CS from '../../components/commonStyled';
import {Navigate} from "react-router-dom";
import {ReactComponent as LogoLight} from "../../assets/Icons/Logo-light.svg";

const AuthorizationLayout = () => {
	const accessToken = localStorage.getItem('access_token');
	if (accessToken) {
		return <Navigate to="/" />;
	}
	return (
		<CS.SectionWithBg gap='30px'>
			<LogoLight/>
			<Outlet/>
		</CS.SectionWithBg>
	)
}

export default AuthorizationLayout;