import React, { forwardRef, useEffect, useState, useCallback } from "react";
import { FlexBox } from "../commonStyled";
import { Controller } from "react-hook-form";
import { RadioButtonWrapper } from "../RadioButton/styled";



const InvoicingType = forwardRef(
  ({
    setValue,
    name,
    defaultValue,
    getValues,
    control,
    disabled,
    setDisabledInputs,
  }, ref) => {
    const handleDisabledInputs = useCallback((value) => {
      if (value === 'once_a_month' || !value) {
        setDisabledInputs((state) => [...state, 'invoicing_fixed_number']);
      } else {
        setDisabledInputs((state) => state.filter(input => input !== 'invoicing_fixed_number'));
      }
    }, [setDisabledInputs]);

    const [type, setType] = useState(defaultValue || null);

    useEffect(() => {
      if (defaultValue) {
        setValue(name, type);
      }
    }, [defaultValue, setValue, name, type, getValues, handleDisabledInputs]);

    useEffect(() => {
      handleDisabledInputs(defaultValue);
    }, [defaultValue, handleDisabledInputs]);

    const handleChangeType = (value, field) => {
      field.onChange(value);
      setType(value);
      handleDisabledInputs(value);
    };

    return (
      <FlexBox
        as={'fieldset'}
        gap={'24px'}
        alignItems={'center'}
        style={{ border: 'none' }}
        margin={'12px 0 0'}
      >
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <div style={{ display: 'flex', gap: '1rem', minWidth: '10rem' }}>
              <RadioButtonWrapper checked={type === 'once_a_month'} disabled={disabled}>
                <input
                  {...field}
                  disabled={disabled}
                  type="radio"
                  value={'once_a_month'}
                  checked={type === 'once_a_month'}
                  onChange={() => handleChangeType('once_a_month', field)}
                />
                Once A Month
              </RadioButtonWrapper>

            </div>
          )}
        />
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <RadioButtonWrapper checked={type === 'fixed_number'} disabled={disabled}>
                <input
                  {...field}
                  type="radio"
                  value={'fixed_number'}
                  checked={type === 'fixed_number'}
                  onChange={() => handleChangeType('fixed_number', field)}
                  disabled={disabled}
                />
                Fixed Number
              </RadioButtonWrapper>
            </>
          )}
        />
      </FlexBox>
    );
  }
);

export default InvoicingType;
