import {useEffect, useRef, useState} from 'react';
import {SingleDatePicker} from "react-dates";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import * as S from "../CalendarRange/styled";
import {renderDay} from "../../utils";
import moment from "moment/moment";
import {CalendarRangeWrapper} from "../CalendarRange/styled";
import {MonthInfoPanel} from "./styled";
import {ReactComponent as ButtonIcon} from "../../assets/Icons/calendarButton.svg";
import {ReactComponent as InputIcon} from "../../assets/Icons/calendarIcon.svg";

const Calendar = (
	{
		defaultValue,
		name,
		disabled,
		error,
		initialFormFields,
		getValues,
		withoutIcon = false,
		padding= '10.5px 12px 10.5px 40px',
		handleChange = (value) => value,
		inputWidth = '100%',
		background = 'var(--up-white)',
		transform = 'translateY(-20px)',
		readOnly=false,
		isCalendarHookForm = true,
	}
) => {
	const [reloadCalendar, setReloadCalendar] = useState(false);
	const [date, setDate] = useState(null);
	const [focused, setFocused] = useState(false);
	const calendarRef = useRef(null);
	const onDateChange = (date, field) => {
		setDate(date);
		//it's necessary for react-hook-form
		if (field) {
			field.onChange(moment(date).format('M/D/Y'))
		}
		handleChange(date);
	}
	useEffect(() => {
		if (defaultValue && defaultValue !== 'Invalid date') {
			setDate(moment(defaultValue, 'MM/DD/YYYYTHH:mm:ssZ'));
		} else {
			if (isCalendarHookForm) {
				const value = name === 'end_date' ? moment().add(1, 'day') : moment();
				setDate(value);
			} else {
				setDate(null);
			}
		}
	}, [defaultValue, name, isCalendarHookForm]);
	const renderCalendarInfo = () => {
		const selectedDate = date ? moment(date).format('MMMM DD YYYY') : 'Invalid date';
		const isInvalidDate = selectedDate === 'Invalid date';
		return (
			<MonthInfoPanel className="MonthInfoPanel" isInvalidDate={isInvalidDate}>
				{
					isInvalidDate ?
						'Date format should be MM/DD/YYYY' :
						selectedDate
				}
			</MonthInfoPanel>
		);
	};
	const isOutsideRange = (date) => {
		return getValues && date.isBefore(getValues('start_date'));
	};

	const onClose = () => {
		if (!date) {
			//it's necessary to clear the value in react-dates if user has set invalid date and clicked outside
			setDate(null)
			setReloadCalendar(true)
			setTimeout(() => {
				setReloadCalendar(false)
			}, 0)
		}
	};
	
	return (
		<CalendarRangeWrapper
			width='360'
			widthMonth='320'
			position='static'
			singleCalendar={'true'}
			error={error}
			padding={padding}
			inputWidth={inputWidth}
			background={background}
			transform={transform}
			readOnly={readOnly}
			ref={calendarRef}
		>
			{reloadCalendar ? null :<SingleDatePicker
				date={date}
				onDateChange={(date) => onDateChange(date, initialFormFields)}
				focused={focused}
				onFocusChange={({focused}) => setFocused(focused)}
				navPrev={<S.NavButtonWrap><ButtonIcon/></S.NavButtonWrap>}
				navNext={<S.NavButtonWrap next={true}><ButtonIcon/></S.NavButtonWrap>}
				customInputIcon={withoutIcon ? null : <InputIcon/>}
				daySize={38}
				horizontalMargin={40}
				numberOfMonths={1}
				renderCalendarInfo={!readOnly && renderCalendarInfo}
				renderDayContents={renderDay}
				isOutsideRange={name === 'start_date' ? () => false : isOutsideRange}
				disabled={disabled}
				placeholder='MM/DD/YYYY'
				onClose={isCalendarHookForm ? ()=>null : onClose}
				readOnly={readOnly}
			/>}
		</CalendarRangeWrapper>
	);
}

export default Calendar;