import TextBox from "../../../../components/TextBox";
import {FlexBox, StatusDropdown} from "../../../../components/commonStyled";
import CustomSelect from "../../../../components/SelectDropdown/CustomSelect";
import {useDispatch, useSelector} from "react-redux";
import timeLogsActions from "../../../../actions/timeLogsActions";

const Header = ({projectId})=> {
	const dispatch = useDispatch();
	const {selectedFilterOption, filterOptions} = useSelector(state => state.timeLogsTabReducer);
	const applyClientFilter = (data) => {
		dispatch(timeLogsActions.setFilters(data));
		dispatch(timeLogsActions.getTimeLogsData(projectId));
	}
	return (
		<FlexBox justifyContent={'space-between'} alignItems={'center'} margin={'0 0 30px'}>
			<TextBox as='h1' size='md' weight='medium' color='dark' lineHeight='28px'>
				Reporting
			</TextBox>
			<StatusDropdown width='210px'>
				<CustomSelect
					name={'invoice_id'}
					ref={null}
					options={filterOptions}
					isSearchable={false}
					classNameContainer={`status`}
					isMulti={true}
					handleOnChange={(value) => console.log(value)}
					handleOnBlur={applyClientFilter}
					closeMenuOnSelect={false}
					placeholder='Select Invoices'
					optionSelectedFromReducer={selectedFilterOption?.values}
					customHeight='36px'
				/>
			</StatusDropdown>
		</FlexBox>
	);
}

export default Header;