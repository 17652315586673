import {useRef, useState, useEffect, useCallback} from "react";
import {Container} from "../commonStyled";
import Tab from "./Tab";
import {
	CarouselButton,
	CarouselButtonShadow,
	CarouselButtonWrapper,
	CarouselButtonWrapperRight,
	CarouselWrap, TabsWrap
} from "./styled";
import {ReactComponent as ButtonIcon} from "../../assets/Icons/chevronRight.svg";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const Tabs = (
	{
		tabs,
		children,
		areTabsDisabled,
		positionTabs = 'static',
	}
) => {
	const tabsContainerRef = useRef(null);
	const [tabsWidth, setTabsWidth] = useState(0);
	const [isActiveNextButton, setIsActiveNextButton] = useState(false);
	const [isActivePrevButton, setIsActivePrevButton] = useState(false);
	const [translateX, setTranslateX] = useState(0);
	const resizeTimeoutRef = useRef(null);
	const {customSizeOfModal, activeTab, hiddenScroll} = useSelector(state => state.commonReducer);
	const handleResize = useCallback(() => {
		clearTimeout(resizeTimeoutRef.current);
		resizeTimeoutRef.current = setTimeout(() => {
			if (tabsContainerRef?.current) {
				setTabsWidth(tabsContainerRef?.current.offsetWidth);
				setIsActiveNextButton(false);
				setIsActivePrevButton(false);
				setTranslateX(0);
			}
		}, 500);
	}, []);
	
	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);
	
	const handleNextButton = () => {
		if (tabsContainerRef.current) {
			//Get the first hidden tab from the right
			const childrenOfTabsContainer = Array.from(tabsContainerRef.current.children);
			const firstHiddenTab = Array.from(childrenOfTabsContainer[1].children).find(tab => tab.dataset.hiddenright === 'true');
			if (firstHiddenTab) {
				const rightPositionOfFirstHiddenTab = firstHiddenTab.getBoundingClientRect().right;
				const rightPositionOfTabs = tabsContainerRef?.current.getBoundingClientRect().right;
				const newTranslate = translateX - (rightPositionOfFirstHiddenTab + 40 - rightPositionOfTabs);
				setTranslateX(newTranslate);
				setIsActiveNextButton(Array.from(childrenOfTabsContainer[1].children).filter(tab => tab.dataset.hiddenright === 'true').length > 1);
			}
		}
	};
	const handlePrevButton = () => {
		if (tabsContainerRef.current) {
			//Get the first hidden tab from the left
			const childrenOfTabsContainer = Array.from(tabsContainerRef.current.children);
			const lastHiddenTab = Array.from(childrenOfTabsContainer[1].children).reverse().find(tab => tab.dataset.hiddenleft === 'true');
			if (lastHiddenTab) {
				const leftPositionOfLastHiddenTab = lastHiddenTab.getBoundingClientRect().left;
				const leftPositionOfTabs = tabsContainerRef?.current.getBoundingClientRect().left;
				const newTranslate = translateX + (leftPositionOfTabs - leftPositionOfLastHiddenTab + 40);
				setTranslateX(newTranslate > 0 ? 0 : newTranslate);
				setIsActivePrevButton(Array.from(childrenOfTabsContainer[1].children).filter(tab => tab.dataset.hiddenleft === 'true').length > 1);
			}
		}
	}
	const shouldShowCarouselButtons = tabsContainerRef.current?.scrollWidth > tabsContainerRef.current?.clientWidth;
	return (
		<>
			<CarouselWrap ref={tabsContainerRef} alignItems='flex-end'>
				{
					shouldShowCarouselButtons &&
						<CarouselButtonWrapper
							alignItems='flex-end'
							areTabsDisabled={areTabsDisabled}
						>
							<CarouselButton as='button' rotate={'true'} disabled={!isActivePrevButton || areTabsDisabled} onClick={handlePrevButton}>
								<ButtonIcon/>
							</CarouselButton>
							<CarouselButtonShadow/>
						</CarouselButtonWrapper>
				}
				
				<TabsWrap as='ul' gap='4px' alignItems='flex-end' translateX={translateX}>
					{tabs.map((tab, index) => (
							<Tab
								key={tab.name + index}
								tab={tab}
								activeTab={activeTab}
								index={index}
								tabsWidth={tabsWidth}
								setIsActiveNextButton={setIsActiveNextButton}
								setIsActivePrevButton={setIsActivePrevButton}
								tabsContainerRef={tabsContainerRef}
								translateX={translateX}
								handlePrevButton={handlePrevButton}
								handleNextButton={handleNextButton}
								areTabsDisabled={areTabsDisabled}
							/>
						)
					)}
				</TabsWrap>
				{
					shouldShowCarouselButtons &&
						<CarouselButtonWrapperRight alignItems='flex-end'>
							<CarouselButtonShadow/>
							<CarouselButton as='button' disabled={!isActiveNextButton || areTabsDisabled} onClick={handleNextButton}>
								<ButtonIcon/>
							</CarouselButton>
						</CarouselButtonWrapperRight>
				}
			</CarouselWrap>
			
			<Container
				height={tabs[activeTab]?.height ||'auto'}
				maxHeight={'calc(100% - 40px)'}
				borderRadius={shouldShowCarouselButtons ? '0 0 6px 6px' : '0 6px 6px 6px'}
				borderColor={areTabsDisabled ? 'var(--up-main-blue)' : 'var(--up-light-blue-p400)'}
				position={positionTabs}
				width={tabs[activeTab]?.width || '100%'}
				style={{...customSizeOfModal, overflowY: hiddenScroll ? 'hidden' :tabs[activeTab]?.overflowY || 'auto'}}
				padding={tabs[activeTab]?.padding}
			>
				{children[activeTab]}
			</Container>
		</>
	)
}

Tabs.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
	}
	)).isRequired,
	children: PropTypes.arrayOf(PropTypes.element).isRequired,
	areTabsDisabled: PropTypes.bool
}

export default Tabs;