import {useState} from "react";
import Expenses from "../Economics/Expenses";
import {useDispatch, useSelector} from "react-redux";
import {initialExpenseTimeLog} from "./utils";
import timeLogsActions from "../../../../actions/timeLogsActions";
import commonActions from "../../../../actions/commonActions";
import {getErrorMessages} from "../../../../utils";
import ModalWrap from "../../../../components/ModalWrap";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import {usePermission} from "../../../../hooks/usePermission";

const ExpensesWrapper = (
	{
		expenses,
		projectId,
		timeLogId
	}
) => {
	const dispatch = useDispatch();
	const {talentsNames} = useSelector(state => state.talentsPageReducer);
	const [expensesState, setExpensesState] = useState(expenses?.length > 0 ? expenses : [initialExpenseTimeLog]);
	const [showModal, setShowModal] = useState(false);
	const [activeRowIndex, setActiveRowIndex] = useState(null);
	const isPermittedEditExpenses = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']);
	const isPermittedDeleteExpenses = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']);
	const updateState = (data, indexRow) => {
		const cloneExpenses = [...expensesState];
		cloneExpenses[indexRow] = data;
		setExpensesState(cloneExpenses);
	};
	
	const deleteItemFromState = (indexRow) => {
		const cloneExpenses = [...expensesState];
		cloneExpenses.splice(indexRow, 1);
		setExpensesState(cloneExpenses);
	};
	const createNewExpense = (data, indexRow) => {
		if (!data?.contractor_id || !data?.expense_type) return;
		const payload = {
			contractor_id: data?.contractor_id,
			expense_type: data?.expense_type,
			amount: data?.amount,
		};
		dispatch(timeLogsActions.addTimeLogExpense(projectId, timeLogId, payload))
			.then((response) => {
				updateState(response.data, indexRow);
			})
			.catch((error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			})
	};
	
	const updateExpense = (data, indexRow, key) => {
		dispatch(timeLogsActions.editTimeLogExpense(projectId, timeLogId, data?._id, {[key]: data[key]}))
			.then((response) => {
				updateState(response.data, indexRow);
			})
			.catch((error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			})
	}
	const handleSaveData = (data, indexRow, keyField) => {
		if (data?._id) {
			updateExpense(data, indexRow, keyField);
		} else {
			createNewExpense(data, indexRow, keyField);
		}
	};
	
	const handleDeleteExpense = () => {
		if (expensesState[activeRowIndex]?._id) {
			dispatch(timeLogsActions.deleteTimeLogExpense(projectId, timeLogId, expensesState[activeRowIndex]?._id))
				.then(() => {
					deleteItemFromState(activeRowIndex);
				})
				.catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				})
		} else {
			deleteItemFromState(activeRowIndex);
		}
		handleCloseModal();
	};
	
	const handleCloseModal = () => {
		setShowModal(false);
		setActiveRowIndex(null);
	};
	
	return (
		<>
			<Expenses
				expenses={expensesState}
				setExpenses={setExpensesState}
				editMode={true}
				talentsNames={talentsNames}
				withoutReimbursed={true}
				titleSize={'sm'}
				withButtonDeleteAll={false}
				handleSaveData={handleSaveData}
				newExpense={initialExpenseTimeLog}
				keyIsAmount={true}
				setShowModalForDelete={setShowModal}
				isTimeLogsPage={true}
				setActiveRowIndex={setActiveRowIndex}
				isPermittedEdit={isPermittedEditExpenses}
				isPermittedDelete={isPermittedDeleteExpenses}
			/>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={handleCloseModal}
					children={
						<ConfirmationModal
							handleConfirm={handleDeleteExpense}
							closeModal={handleCloseModal}
							title='Delete Expense'
							message={`Are you sure you want to delete expense?`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</>
	)
}

export default ExpensesWrapper;