import { useLayoutEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import talentsActions from "../../../actions/talentsActions";
import { Container, FlexBox, ImgWithAbsolutePosition } from "../../../components/commonStyled";
import { ReactComponent as Cat } from "../../../assets/Icons/activeTalentCatNew.svg";
import GeneralInformation from "./GeneralInformation";
import Loader from "../../../components/Loader";

const ActiveTalent = (
	{
		isProjectsTalent = false
	}
) => {
	const { talentsData, talentDetails, talentDetailsLoading } = useSelector(state => state.talentsPageReducer);
	const { hiddenScroll } = useSelector(state => state.commonReducer);
	const { talentId, projectId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isTalentExist = talentsData?.items?.find(talent => talent._id === talentId);
	const [editMode, setEditMode] = useState(false);
	const { isPermittedManageTalent } = useOutletContext();

	useLayoutEffect(() => {
		if (talentId) {
			dispatch(talentsActions.getTalentDetails(talentId, projectId, isProjectsTalent));
		}
	}, [dispatch, isTalentExist, navigate, talentId, projectId, isProjectsTalent]);

	return (
		<FlexBox padding='20px 20px 0' height='100%' style={{ marginRight: isProjectsTalent ? 'auto' : null }}>
			<Container
				height={'calc(100% - 10px)'}
				width={'827px'}
				borderColor={editMode ? 'var(--up-main-info)' : 'var(--up-main-light-grey)'}
				padding='0 24px 24px'
				overflow={hiddenScroll ? 'hidden' : 'auto'}
			>
				{talentDetailsLoading || !talentDetails ?
					<Loader position='relative' width='100%' height='calc(100vh - 140px)' /> :
					<GeneralInformation
						editMode={editMode}
						setEditMode={setEditMode}
						talentDetails={talentDetails}
						isPermittedManageTalent={isPermittedManageTalent}
						isProjectsTalent={isProjectsTalent}
						projectId={projectId}
						previewDoc
					/>}
			</Container>
			<ImgWithAbsolutePosition
				as={Cat}
				top={'45px'}
				right={'auto'}
				left={'510px'}
				width='154px'
				height='90px'
				mirror={'true'}
				zindex={'12'}
			/>
		</FlexBox>
	)
}

export default ActiveTalent;