import styled from "styled-components";
import {FlexBox} from "../../components/commonStyled";

export const UploadWrapper = styled(FlexBox)`
  border-radius: 16px;
  border: 2px dashed #1B91B6;
  background: ${props => props?.isDragOver ? 'rgba(27, 145, 182, 0.30)' : 'rgba(27, 145, 182, 0.05)'};
	height: ${props => props?.height};
	cursor: ${props => props?.disabled ? 'not-allowed' : 'pointer'};
	pointer-events: ${props => props?.disabled ? 'none' : 'auto'}
	margin-top: ${props => props?.marginTop};
	width: 100%;
`;

export const NoDataWrapper = styled.div`
	margin-top: 80px;
	@media (max-height: 900px) {
		margin-top: 0;
  }
`;

export const RowWithIcon = styled(FlexBox)`
	svg {
		width: 24px;
		height: 24px;
  }
`;