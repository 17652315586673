import styled from 'styled-components';
import {FlexBox} from "../commonStyled";

export const NavMenu = styled(FlexBox)`
	height: 100vh;
	width: 260px;
  background: linear-gradient(180deg, #152047 0%, #013474 100%);
  border-radius: 0 10px 0 0;
`;

export const NavContent = styled(FlexBox)`
	flex: 1 1 auto;
	`;

export const NavLogo = styled.div`
	padding: 20px 0 100px 15px;
	
	@media (max-width: 1500px) {
		padding-bottom: 80px;
  }
`;

export const NavList = styled(FlexBox)`
  padding: 0 20px;

	li {
		width: 100%;
	}
`;

export const NavLink = styled.a`
  color: var(--up-blue-p100);
  text-decoration: none;
  display: flex;
  gap: 20px;
  align-items: center;
	font-size: 20px;
  transition: all .15s ease-in 0s;

  svg {
    path {
      fill: var(--up-blue-p100);
    }
  }
	&:not(.active):hover {
		color: var(--up-light-blue-p400);
    svg {
			path {
				fill: var(--up-light-blue-p400);
      }
		}
  }

  &.active {
    color: var(--up-secondary--light-blue);
		font-weight: 500;
    svg {
      path {
        fill: var(--up-secondary--light-blue);
      }
    }
  }
`;