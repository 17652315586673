import {CheckBox} from "./styled";
import {useState, useEffect} from "react";

const Checkbox = (
	{
		inputProps,
		defaultValue,
		label,
		isReactHookForm = true,
		handleChangeParentState,
		isReverse = false,
		padding = '0'
	}
)=> {
	const {setValue, name, id} = inputProps;
	const [checked, setChecked] = useState(typeof defaultValue === "boolean" ? defaultValue : true);
	useEffect(()=> {
		if (typeof defaultValue === "boolean") {
			if (isReactHookForm) setValue(name, defaultValue);
			setChecked(defaultValue);
		}
	}, [defaultValue, setValue, name, isReactHookForm])
	
	const onChange = () => {
		const updatedChecked = !checked;
		setChecked(updatedChecked);
		if (isReactHookForm) {
			setValue(name, updatedChecked);
		} else {
			handleChangeParentState()
		}
	}
	return (
		<>
			<CheckBox
				type="checkbox"
				{...inputProps}
				checked={checked}
				onChange={onChange}
				isReverse={isReverse}
				padding={padding}
			/>
			{label && <label htmlFor={id}>{label}</label>}
		</>
	)
}

export default Checkbox;