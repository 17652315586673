import {useState} from "react";
import {FlexBox} from "../commonStyled";
import {UploadWrapper} from "../../pages/Clients/styled";
import {ReactComponent as UploadIcon} from "../../assets/Icons/feather_upload-cloud.svg";

import TextBox from "../TextBox";
import {useDispatch} from "react-redux";
import commonActions from "../../actions/commonActions";
const maxSize = 100 * 1024 * 1024;
const UploadContainerWithDrugAndDrop = (
	{
		uploadInputRef,
		actionOfParentComponent,
		marginTop = '24px',
		height = '190px',
		dataWithoutFormatting = false,
		disabled = false,
		title = 'Upload file',
	}
) => {
	const [isDragOver, setIsDragOver] = useState(false);
	const dispatch = useDispatch();
	const onDragOver = (e) => {
		e.preventDefault();
		if (disabled) return;
		if (!isDragOver) setIsDragOver(true);
	};
	const onDrop = (e) => {
		e.preventDefault();
		if (disabled) return;
		setIsDragOver(false);
		const arrayOfFiles = Array.from(e.dataTransfer.files);
		const formData = new FormData();
		let namesOfTooBigFiles = [];
		for (let i = 0; i < arrayOfFiles.length; i++) {
			if (arrayOfFiles[i]?.size > maxSize) {
				namesOfTooBigFiles.push(arrayOfFiles[i]?.name);
			} else {
				formData.append('files', arrayOfFiles[i]);
			}
		}
		if (namesOfTooBigFiles.length) {
			dispatch(commonActions.setPopupMessage(
				`The following files are too big: ${namesOfTooBigFiles.join(', ')}. The maximum file size is 100MB.`,
				'error'
			))
		}
		if (formData.getAll('files').length === 0) return;
		setTimeout(() => {
			actionOfParentComponent(dataWithoutFormatting ? e : formData);
		}, 2000);
	}
	return (
		<UploadWrapper
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
			gap='20px'
			onClick={() => uploadInputRef?.current.click()}
			onDragOver={onDragOver}
			onDragLeave={() => setIsDragOver(false)}
			onDrop={onDrop}
			isDragOver={isDragOver}
			marginTop={marginTop}
			height={height}
			disabled={disabled}
		>
			<UploadIcon/>
			<FlexBox justifyContent='center' alignItems='center' flexDirection='column'>
				<TextBox lineHeight='20px' as={'h3'}>{title}</TextBox>
				<TextBox color={'var(--up-main-grey)'} lineHeight='20px'>You can upload or drag and drop</TextBox>
			</FlexBox>
		</UploadWrapper>
	)
}

export default UploadContainerWithDrugAndDrop;