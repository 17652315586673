import {useLayoutEffect, useEffect} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import commonActions from "./actions/commonActions";
import MainLayout from "./pages/MainLayout";
import Loader from "./components/Loader";

function App() {
	const dispatch = useDispatch();
	const {mainLoader} = useSelector((state) => state.commonReducer);
	const {authLoading} = useSelector((state) => state.authReducer);
	const {pathname} = useLocation();
	const isAuthPage = pathname.includes('/auth');
	const accessToken = localStorage.getItem('access_token');
	
	useLayoutEffect(() => {
		if (isAuthPage) return
		dispatch(commonActions.getUserSettings());
	}, [dispatch, isAuthPage]);
	
	const handleStorageChange = (event) => {
		if (event.key === 'logoutSignal' || event.key === 'loginSignal') {
			window.location.reload();
			localStorage.removeItem('logoutSignal');
			localStorage.removeItem('loginSignal');
		}
	};
	
	useEffect(() => {
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);
	if (!accessToken && !isAuthPage && !authLoading) {
		return <Navigate to="/auth/login"/>;
	}
	if (mainLoader) {
		return <Loader/>
	}
	return (
			<MainLayout/>
  );
}

export default App;
