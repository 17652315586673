import {useDispatch, useSelector} from "react-redux";
import {useLayoutEffect} from "react";
import clientsActions from "../../../../actions/clientsActions";

import Loader from "../../../../components/Loader";
import NoData from "../../../../components/NoData";
import CustomPagination from "../../../../components/Pagination";
import {NoDataWrapper} from "../../styled";
import TableContacts from "./TableContacts";

const ContactsList = (
	{
		activeClientID,
		isPermittedForNotes,
		isPermittedManageContact,
		setShowModal
	}
) => {
	const {
		clientContacts,
		clientContactsLoading,
	} = useSelector(state => state.clientsPageReducer);
	
	const dispatch = useDispatch();
	
	useLayoutEffect(() => {
		dispatch(clientsActions.getClientContacts(activeClientID));
	}, [activeClientID, dispatch]);
	const onPageChange = (data) => {
		dispatch(clientsActions.getClientContacts(activeClientID, data));
	};
		if (clientContactsLoading) return <Loader width={'100%'} height={'calc(100vh - 200px)'}/>
	return (
		<>
			{
				clientContacts?.items?.length > 0 ?
					<>
						<TableContacts
							contacts={clientContacts}
							isPermittedForNotes={isPermittedForNotes}
							isPermittedManageContact={isPermittedManageContact}
							setShowModalAddContact={setShowModal}
							activeClientID={activeClientID}
						/>
						{clientContacts?.items.length > 0 &&
						<CustomPagination
							totalItems={clientContacts?.items_count}
							pageSize={clientContacts?.page_size}
							page={clientContacts?.page}
							onPageChange={onPageChange}
						/>}
					</>
					:
					<NoDataWrapper>
						<NoData
							isPermittedButton={isPermittedManageContact}
							handleClick={() => setShowModal(true)}
							buttonText='Contact'
							text='You have no Contacts yet'
							margin={'0'}
						/>
					</NoDataWrapper>
			}
		</>
	)
}

export default ContactsList;