import * as yup from "yup";
import {validationSchemaForPhoneNumber, validationSchemaForRequiredField} from "../../utils";

export const inputsSetUpAllUser = [
	{
		label: 'Full Name',
		name: 'full_name',
		type: 'text',
		required: true,
	},
	{
		label: 'Business Name',
		name: 'business_name',
		type: 'text',
		required: false,
	},
	{
		label: 'Business Address',
		name: 'address',
		type: 'text',
		required: true,
	},
	{
		label: 'Phone Number',
		name: 'phone_number',
		type: 'text_mask',
		required: false,
		mask: '+9 (999) 999-9999',
	}
];

export const inputsSetUpContractor = [
	[
		{
			label: 'Full Name',
			name: 'full_name',
			type: 'text',
			required: true,
		},
		// {
		// 	name: 'Last Name',
		// 	type: 'text',
		// 	required: true,
		// },
		{
			label: 'Linkedin',
			name: 'linkedin_account',
			type: 'text',
			required: false,
		},
		{
			label: 'Address',
			name: 'address',
			type: 'text',
			required: true,
		},
		
		{
			label: 'Phone Number',
			name: 'phone_number',
			type: 'text_mask',
			required: true,
			mask: '+9 (999) 999-9999',
		},
	],
	[
		{
			label: 'Business Name',
			name: 'business_name',
			type: 'text',
			required: true,
		},
		{
			label: 'Payment Number',
			name: 'payment_number',
			type: 'text',
			required: true,
		},
		{
			label: 'Bank Name',
			name: 'bank_name',
			type: 'text',
			required: true,
		},
		{
			label: 'Payment Name',
			name: 'payment_name',
			type: 'text',
			required: true,
		},
	]
];

export const schemaSetUpAllUser = yup.object().shape({
	'full_name': validationSchemaForRequiredField('Full Name'),
	// 'business_name': validationSchemaForRequiredField('Business Name'),
	'address': validationSchemaForRequiredField('Business Address'),
	'phone_number': validationSchemaForPhoneNumber(),
});

export const schemaSetUpContractor = yup.object().shape({
	'full_name': validationSchemaForRequiredField('Full Name'),
	// 'Last Name': validationSchemaForRequiredField('Last Name'),
	'address': validationSchemaForRequiredField('Address'),
	'phone_number': validationSchemaForPhoneNumber(true),
	'business_name': validationSchemaForRequiredField('Business Name'),
	'bank_name': validationSchemaForRequiredField('Bank Name'),
	// 'Linkedin': validationSchemaForRequiredField('Linkedin'),
	'payment_number': validationSchemaForRequiredField('Payment Number'),
	'payment_name': validationSchemaForRequiredField('Payment Name'),
});