import {ReactComponent as MainImg} from "../assets/img/comingSoon.svg";
import {ComingSoonPageWrap, TitleWithGradient} from "../components/commonStyled";

const ComingSoonPage = () => {
	return (
		<ComingSoonPageWrap
			alignItems='center'
			justifyContent='space-around'
			flexDirection='column'
			gap='50px'
			padding={'5% 0 0'}
		>
			<MainImg/>
			<TitleWithGradient
				size={'lg'}
				as={'h2'}
			>
				Coming Soon
			</TitleWithGradient>
		</ComingSoonPageWrap>
	)
}

export default ComingSoonPage;