const initialState = {
	popupMessage: null,
	userData: null,
	userDataLoading: false,
	needOnBoarding: false,
	isContractor: false,
	mainLoader: false,
	searchInputValue: '',
	setUpLoading: false,
	navTitle: [],
	customSizeOfModal: null,
	activeTab: 0,
	hiddenScroll: false,
};

export default function commonReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_POPUP_MESSAGE": {
			return {
				...state,
				popupMessage: action.payload
			}
		}
		case "SET_USER_DATA": {
			return {
				...state,
				userData: action.payload,
				userDataLoading: false,
			}
		}
		case "SET_USER_DATA_LOADING": {
			return {
				...state,
				userDataLoading: action.payload
			}
		}
		case "SET_NEED_ON_BOARDING": {
			return {
				...state,
				needOnBoarding: action.payload
			}
		}
		case "SET_IS_CONTRACTOR": {
			return {
				...state,
				isContractor: action.payload
			}
		}
		case "SET_MAIN_LOADER": {
			return {
				...state,
				mainLoader: action.payload
			}
		}
		case "SET_SEARCH_INPUT_VALUE": {
			return {
				...state,
				searchInputValue: action.payload
			}
		}
		case "SET_UP_LOADING": {
			return {
				...state,
				setUpLoading: action.payload
			}
		}
		case "SET_NAV_TITLE": {
			return {
				...state,
				navTitle: action.payload
			}
		}
		case "SET_CUSTOM_SIZE_OF_MODAL": {
			return {
				...state,
				customSizeOfModal: action.payload
			}
		}
		case "SET_ACTIVE_TAB": {
			return {
				...state,
				activeTab: action.payload
			}
		}
		case "SET_HIDDEN_SCROLL": {
			return {
				...state,
				hiddenScroll: action.payload
			}
		}
		default: return state;
	}
}