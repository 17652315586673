import Form from "./Form";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import profileActions from "../../actions/profileActions";
import Loader from "../../components/Loader";
import {Container} from "../../components/commonStyled";

const ProfileWrap = () => {
	const dispatch = useDispatch();
	const {profileData, profileDataLoading} = useSelector(state => state.profilePageReducer);
	const {userData} = useSelector(state => state.commonReducer);
	const {_id} = userData;
	useEffect(() => {
		dispatch(profileActions.getProfileData(_id));
	}, [dispatch, _id]);
	
	if (profileDataLoading || !profileData) return <Loader width={'100%'} height={'calc(100% - 72px)'}/>
	return (
		<section style={{padding: '20px 20px 0', position: 'relative', height: 'calc(100% - 71px)'}}>
			<Container style={{position: 'static', height: 'auto', width: '826px'}}>
				<Form profileData={profileData} userId={_id}/>
			</Container>
		</section>
	)
}

export default ProfileWrap;