import {useSelector} from "react-redux";
import {useState} from "react";
import {useOutletContext} from "react-router-dom";
import {ReactComponent as Cat} from "../../assets/Icons/talentCat.svg";

import TalentsWrapper from "./components/TalentsWrapper";
import NoData from "../../components/NoData";
import ModalWrap from "../../components/ModalWrap";
import ModalCreateNewTalent from "./components/ModalCreateNewTalent";

const TalentsList = () => {
	const {talentsData, createNewTalentLoading} = useSelector(state => state.talentsPageReducer);
	const [showModal, setShowModal] = useState(false);
	const {isPermittedManageTalent} = useOutletContext();
	
	return (
		<>
			{
				talentsData?.total > 0 ?
					<TalentsWrapper
						talentsData={talentsData}
						isPermittedManageTalent={isPermittedManageTalent}
						setShowModal={setShowModal}
					/> :
					<NoData
						isPermittedButton={isPermittedManageTalent}
						handleClick={() => setShowModal(true)}
						buttonText='Invite Talent'
						text='You have no Talent yet'
					/>
			}
			{
				showModal &&
				<ModalWrap
					close={() => setShowModal(false)}
					width='560px'
					children={<ModalCreateNewTalent close={() => setShowModal(false)}/>}
					icon={<Cat/>}
					leftPositionIcon={'80%'}
					loading={createNewTalentLoading}
				>
				</ModalWrap>
			}
		</>
	)
}

export default TalentsList;