import {authenticatedRequest} from "../APIGateway/gateway";
import {defaultQueryForGetData, getErrorMessages, queryString} from "../utils";
import commonActions from "./commonActions";

const profileActions = {
	getProfileData(userId, isTeamMember) {
		return (dispatch) => {
			dispatch({
				type: 'SET_PROFILE_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: isTeamMember ? 'GetTeamMemberData' : 'GetProfileData',
				method: 'get',
				paths: [userId],
			}).then((response) => {
					dispatch({
						type: 'SET_PROFILE_DATA',
						payload: response.data,
					});
					dispatch({
						type: 'SET_PROFILE_DATA_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_PROFILE_DATA_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	editProfileData(userId, data, isTeamMember) {
		return dispatch => {
			dispatch({
				type: 'SET_EDIT_PROFILE_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: isTeamMember ? 'EditTeamMemberData' :'EditProfileData',
				method: 'patch',
				paths: [userId],
				data: data,
			}).then((response) => {
					dispatch({
						type: 'SET_PROFILE_DATA',
						payload: response.data,
					});
					dispatch(commonActions.setPopupMessage(`${isTeamMember ? 'Team member' : 'Profile'} data updated successfully`, 'success'));
					dispatch(commonActions.updateUserSettings());
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_EDIT_PROFILE_DATA_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	getTeamMembers(queries = defaultQueryForGetData) {
		return dispatch => {
			dispatch({
				type: 'SET_TEAM_MEMBERS_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetTeamMembers',
				method: 'get',
				queryParams: queryString(queries) + '&sort_by=full_name',
			}).then((response) => {
					dispatch({
						type: 'SET_TEAM_MEMBERS',
						payload: response.data,
					});
					dispatch({
						type: 'SET_TEAM_MEMBERS_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_TEAM_MEMBERS_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	addTeamMember(data) {
		return dispatch => {
			dispatch({
				type: 'SET_ADD_NEW_TEAM_MEMBER_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'InviteUser',
				method: 'post',
				data: data,
			}).then(() => {
					dispatch({
						type: 'SET_ADD_NEW_TEAM_MEMBER_SUCCESS',
						payload: true,
					});
					dispatch({
						type: 'SET_ADD_NEW_TEAM_MEMBER_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_ADD_NEW_TEAM_MEMBER_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	resetAddTeamMemberSuccess() {
		return dispatch => {
			dispatch({
				type: 'SET_ADD_NEW_TEAM_MEMBER_SUCCESS',
				payload: false,
			});
		}
	},
	deleteTeamMember(userId) {
		return (dispatch, getState) => {
			const {teamMembers} = getState().profilePageReducer;
			const {searchInputValue} = getState().commonReducer;
			dispatch({
				type: 'SET_TEAM_MEMBERS_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'DeleteUser',
				method: 'delete',
				paths: [userId],
			}).then(() => {
					dispatch(this.getTeamMembers(
						{
							page: teamMembers?.page || 1,
							page_size: teamMembers?.page_size || 12,
							query_str: searchInputValue || '',
						}
					));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_TEAM_MEMBERS_LOADING',
						payload: false,
					});
				}
			);
		}
	}
}

export default profileActions;