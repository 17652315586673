import {Outlet} from "react-router-dom";
import {usePermission} from "../../../hooks/usePermission";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import projectsActions from "../../../actions/projectsActions";
import commonActions from "../../../actions/commonActions";

const ActiveProjectWrapper = ()=> {
	const isPermittedManageTalent = usePermission(
		['super_admin', 'company_admin', 'contract_specialist', 'controller', 'staffer']
	);
	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			dispatch(projectsActions.setProjectData({}));
			dispatch(commonActions.setActiveTab(0));
		}
	}, [dispatch]);
	return (
		<Outlet
			context={
				{isPermittedManageTalent}
			}
		/>
	);
}

export default ActiveProjectWrapper;