import * as S from './styled';
import {useRef} from "react";
// import {AddNewItemWrap} from "./styled";

const CustomOption = (props) => {
	const {data, selectOption, children, getStyles, innerRef, isFocused, isSelected} = props;
	const isPagination = props?.selectProps?.name === 'pagination';
	const optionRef= useRef(null);
	// const [addNewValue, setAddNewValue] = useState('');
	// const inputRef = useRef(null);
	// const handleChange = (e) => {
	// 	setAddNewValue(e.target.value);
	// };
	// const handleFocus = () => {
	// 	inputRef?.current?.focus();
	// }
	const handleSelectOption = (e) => {
		e.preventDefault();
		e.stopPropagation();
		selectOption(data);
	}
	// if (props?.data?.value === 'add new') return <AddNewItemWrap
	// 	data-test='option'
	// 	ref={optionRef}
	// 	isSelected={isSelected}
	// 	alignItems='center'
	// 	isFocused={isFocused}
	// 	innerRef={innerRef}
	// 	onClick={e=>handleSelectOption(e, true)}
	// 	style={{position: 'sticky', bottom: '2px', zIndex: '99'}}
	// >
	// 			<input onKeyDown={event => event.stopPropagation()} ref={inputRef} value={addNewValue} onChange={handleChange} type="text" placeholder={'add new'} style={{ width: '100%'}}/>
	// 		</AddNewItemWrap>
	return <S.CustomOption
		data-test='option'
		ref={optionRef}
		style={getStyles('option', props)}
		isSelected={isSelected}
		alignItems='center'
		isFocused={isFocused}
		innerRef={innerRef}
		onClick={handleSelectOption}
	>
		<div
			className={props?.data?.value || ''}
			style={{textAlign: isPagination ? 'center' : 'left', textTransform: data?.textTransform || 'none', lineHeight: '18px'}}
		>
			{children}
		</div>
	</S.CustomOption>
};

export default CustomOption;