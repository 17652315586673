import styled, {css} from "styled-components";
import {FlexBox} from "../../components/commonStyled";

export const GroupButtons = styled(FlexBox)`
  background: var(--up-white);
	position: sticky;
	bottom: 0;
	padding: 23px 0 0 0;
	z-index: 1;

  ${(props) => {
    if (!props.withoutShadow) {
      return css`
        &:before {
          content: '';
          position: absolute;
          height: calc(100% + 24px);
          width: calc(100% + 48px);
          left: -24px;
          top: 0;
          background: #FFFFFF;
          border: 1px solid var(--up-page-bg);
          box-shadow: 2px 0px 6px 3px rgba(42, 118, 207, 0.1);
          border-radius: 6px;
          z-index: -1;
        }
      `;
    }
  }}
`;

export const ButtonEdit = styled(FlexBox)`
	cursor: pointer;
  width: 36px;
  height: 36px;
  background: ${props => props.editMode ? 'var(--up-main-blue)' : (props?.background || 'var(--up-blue-p100)')};
  border-radius: 50%;
	border: ${props => !props.editMode && props.border ? '1px solid var(--up-light-blue-p400)' : 'none' };
	svg {
		pointer-events: all;
		path {
			stroke: ${props => props.editMode ? 'var(--up-white)' : (props?.strokeColor || '#003E8D')};
    }
	}
	
	&:hover {
		background: var(--up-main-blue);
		svg {
			path {
				stroke: var(--up-white);
			}
		}
  }
`;

export const PaymentType = styled(FlexBox)`
	padding: 0 8px;
  background: var(--up-blue-p100);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
	color: var(--up-main-blue);
	text-transform: capitalize;
`;

export const TabsWrap = styled.div`
  flex: 1 1;
	height: calc(100% - 18px);
	max-width: ${props => props.width ? props.width : 'calc(100% - 292px)'};
`;