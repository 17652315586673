import {useState} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import {FlexBox, StatusDropdown, TableIconButton} from "../../../../components/commonStyled";
import {statusOptionsInvoicing} from "./utils";
import {formatTitle, handleKeyDown} from "../../../../utils";
import {Input, InputWrap, SpecialSymbol} from "../../../../components/FormInput/styled";
import {ReactComponent as Trash} from "../../../../assets/Icons/trash.svg";

import CustomSelect from "../../../../components/SelectDropdown/CustomSelect";
import invoicingActions from "../../../../actions/invoicingActions";
import Calendar from "../../../../components/SingleCalendar/Calendar";
import PinUpload from "../../../../components/PinUpload";
import {usePermission} from "../../../../hooks/usePermission";
import * as S from "../../../../components/FormInput/styled";

const Row = (
	{
		row,
		isFixedNumber,
		handleDelete,
		projectId
	}
) => {
	const [rowState, setRowState] = useState(row);
	const dispatch = useDispatch();
	const isPermittedEditInvoice = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']);
	const isPermittedDeleteInvoice = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']);
	
	const handleChangeDate = (value, key) => {
		const newRowState = JSON.parse(JSON.stringify(rowState));
		newRowState[key] = value ? moment(value).format('MM/DD/YYYY') : null;
		setRowState(newRowState);
		if (value) handleApply(key, moment(value).format('M/D/Y'));
	};
	
	const handleChange = (e, key) => {
		const newRowState = JSON.parse(JSON.stringify(rowState));
		const value = e.target.value;
		if (value?.includes('.') && value?.split('.')[1]?.length > 2) return;
		if (value.split('.')[0]?.length > 10) return;
		newRowState[key] = value;
		setRowState(newRowState);
	};
	
	const handleChangeStatus = ({value}) => {
		const newRowState = JSON.parse(JSON.stringify(rowState));
		newRowState.status = value;
		setRowState(newRowState);
		handleApply('status', value);
	}
	
	const additionalValidation = {
		'number': !rowState?.number,
		'amount': rowState?.amount === '',
	}
	const handleApply = (key, value) => {
		const payload = {
			[key]: value || rowState[key],
		}
		if (additionalValidation[key]) return;
		dispatch(invoicingActions.editInvoice(rowState.project_id, rowState._id, payload)).then((res) => {
			if (!res) {
				setRowState(row);
			}
		})
	};
	
	const handleReloadPage = () => {
		dispatch(invoicingActions.getInvoices(projectId));
	};

	return (
		<tr>
			<td style={{position: "relative", overflow: 'visible'}}>
				<Input
					value={rowState?.number || ''}
					type={'number'}
					onChange={(e) => handleChange(e, 'number')}
					placeholder={''}
					placeholderColor={'var(--up-main-dark)'}
					padding={'7.5px 8px'}
					width={'105px'}
					disabled={!isPermittedEditInvoice}
					onBlur={() => handleApply('number')}
					onKeyDown={(e)=>handleKeyDown(e,  ['e', 'E', '-', '.', ',', 'ArrowUp', 'ArrowDown'])}
					error={!rowState?.number}
					onWheel={(e) => e.target.blur()}
				/>
				{!rowState?.number && <S.ErrorMessage left={'8px'}>
					Invoice № is required
				</S.ErrorMessage>}
			</td>
			<td style={{textTransform: 'capitalize'}}>
				{formatTitle(rowState?.type) || '-'}
			</td>
			{!isFixedNumber && <td>
				{moment(rowState?.invoice_date, 'MM/DD/YYYYTHH:mm:ssZ').subtract(1, 'month').format('MMMM') || '-'}
			</td>}
			<td style={{overflow: 'visible'}}>
				<StatusDropdown width='130px' className={rowState?.status}>
					<CustomSelect
						name={'status'}
						ref={null}
						options={statusOptionsInvoicing}
						defaultValue={rowState?.status || 'draft'}
						handleOnChange={(value) => handleChangeStatus(value)}
						isSearchable={false}
						classNameContainer={`status`}
						sizeIcon={'16px'}
						customHeight={'34px'}
						disabled={!isPermittedEditInvoice}
					/>
				</StatusDropdown>
			</td>
			<td style={{overflow: 'visible'}}>
				<Calendar
					initialFormFields={null}
					defaultValue={rowState?.invoice_date}
					name={'invoice_date'}
					getValues={null}
					disabled={!isPermittedEditInvoice}
					withoutIcon={true}
					padding={'7px 8.5px'}
					handleChange={(value) => handleChangeDate(value, 'invoice_date')}
					inputWidth={'110px'}
					background={'none'}
					transform={'translate(-125px, -20px)'}
					isCalendarHookForm={false}
					// readOnly={true}
				/>
			</td>
			<td style={{overflow: 'visible'}}>
				<Calendar
					initialFormFields={null}
					defaultValue={rowState?.due_date || null}
					name={'due_date'}
					getValues={null}
					disabled={!isPermittedEditInvoice}
					withoutIcon={true}
					padding={'7.5px 8px'}
					handleChange={(value) => handleChangeDate(value, 'due_date')}
					inputWidth={'110px'}
					background={'none'}
					transform={'translate(-125px, -20px)'}
					isCalendarHookForm={false}
					// readOnly={true}
				/>
			</td>
			<td style={{minWidth: '100px', width: '150px', position: 'relative', overflow: 'visible'}}>
				<InputWrap>
					<Input
						value={rowState?.amount}
						type={'number'}
						onChange={(e) => handleChange(e, 'amount')}
						placeholder={''}
						placeholderColor={'var(--up-main-dark)'}
						padding={'7.5px 8px 7.5px 20px'}
						fontWeight={'500'}
						onBlur={()=>handleApply('amount')}
						onKeyDown={handleKeyDown}
						disabled={!isPermittedEditInvoice}
						error={rowState?.amount === ''}
						onWheel={(e) => e.target.blur()}
					/>
					<SpecialSymbol fontWeight={'500'} left={'8px'}>$</SpecialSymbol>
				</InputWrap>
				{rowState?.amount === '' && <S.ErrorMessage left={'8px'}>
					Amount is required
				</S.ErrorMessage>}
			</td>
			<td onClick={e => e.stopPropagation()}>
				<PinUpload
					isActive={rowState?.documents?.length > 0}
					isUploadNewVersionOfDocument={rowState?.documents?.length > 0}
					handleReloadPage={handleReloadPage}
					accept={'application/pdf'}
					typeDoc={'expense_report'}
					objectType={'invoices'}
					data={rowState}
					uploadOnlyOneFile={rowState?.documents?.length === 0}
					documents={rowState?.documents || []}
					disabled={!isPermittedEditInvoice}
				/>
			</td>
			<td
				style={{textAlign: 'center', width: '50px'}}
				className='action trash-icon with-bg'
			>
				<FlexBox
					alignItems={'center'}
					gap={'12px'}
					justifyContent={'center'}
				>
					<>
						{isPermittedDeleteInvoice && <TableIconButton title='Delete invoice' onClick={handleDelete}>
							<Trash/>
						</TableIconButton>}
					</>
				</FlexBox>
			</td>
		</tr>
	)
}
export default Row;