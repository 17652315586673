import { useRef } from "react";
import { PinIconWrapper } from "../commonStyled";
import FileUploaderInput from "../FileUploaderInput";
import { ReactComponent as PinIcon } from "../../assets/Icons/PinIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/Icons/eye.svg";
import { ReactComponent as Download } from "../../assets/Icons/download.svg";
import { ReactComponent as Upload } from "../../assets/Icons/upload.svg";
import { ReactComponent as Check } from "../../assets/Icons/check.svg";
import documentsActions from "../../actions/documentsActions";
import commonActions from "../../actions/commonActions";
import { downloadFile, getErrorMessages } from "../../utils";
import { useDispatch } from "react-redux";
import * as S from "../CardInfo/styled";
import TextBox from "../TextBox";
import { usePopperTooltip } from "react-popper-tooltip";

const PinUpload = ({
  isActive,
  isUploadNewVersionOfDocument = false,
  accept = "*",
  typeDoc = "",
  handleReloadPage,
  data,
  objectType,
  isMultiple = false,
  uploadOnlyOneFile = false,
  documents = [],
  disabled = false,
}) => {
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  console.log(inputRef);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: "click",
      placement: "left-start",
      interactive: true,
    });
  const handleUpload = (formData, docType) => {
    if (isUploadNewVersionOfDocument) {
      const foundReplaceableDocument = documents.find(
        (document) => document.type === docType
      );
      dispatch(
        documentsActions.uploadNewVersionOfDocument(
          objectType,
          data._id,
          formData,
          docType,
          foundReplaceableDocument._id
        )
      )
        .then(() => {
          dispatch(
            commonActions.setPopupMessage(
              "Documents Successfully Uploaded",
              "success"
            )
          );
          handleReloadPage();
        })
        .catch((error) => {
          dispatch(
            commonActions.setPopupMessage(getErrorMessages(error), "error")
          );
        });
    } else {
      dispatch(
        documentsActions.uploadDocuments(
          objectType,
          data._id,
          formData,
          docType
        )
      )
        .then(() => {
          dispatch(
            commonActions.setPopupMessage(
              "Documents Successfully Uploaded",
              "success"
            )
          );
          handleReloadPage();
        })
        .catch((error) => {
          dispatch(
            commonActions.setPopupMessage(getErrorMessages(error), "error")
          );
        });
    }
  };

  const handlePreviewClick = (docType, path) => {
    window.open(path, "", "width=800,height=800,scrollbars=yes,resizable=yes");
  };

  return (
    <>
      {
        <>
          <FileUploaderInput
            accept={accept}
            ref={inputRef}
            reducerAction={(data) => handleUpload(data, typeDoc)}
            multiple={isMultiple}
            uploadOnlyOneFile={uploadOnlyOneFile}
          />
          {isActive ? (
            <S.uploadedButton ref={disabled ? null : setTriggerRef}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Check style={{ marginRight: "4px" }} />
                Uploaded
              </div>
            </S.uploadedButton>
          ) : (
            <PinIconWrapper
              active={isActive}
              ref={disabled ? null : setTriggerRef}
              title={
                documents.length > 0
                  ? documents[0].name + "." + (documents[0]?.format || "")
                  : "Upload Document"
              }
              disabled={disabled}
            >
              <PinIcon />
            </PinIconWrapper>
          )}
        </>
      }
      {visible && (
        <S.TooltipContainer
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
          width={"200px"}
        >
          <div>
            <S.TooltipItem
              as="button"
              alignItems="center"
              gap="8px"
              onClick={() => inputRef?.current?.click()}
            >
              <Upload />
              <TextBox as="span" size="sm" lineHeight="20px">
                {isUploadNewVersionOfDocument
                  ? "Upload New Version"
                  : "Upload Document"}
              </TextBox>
            </S.TooltipItem>

            {documents.length > 0 && (
              <S.TooltipItem
                as="button"
                alignItems="center"
                gap="8px"
                onClick={() =>
                  handlePreviewClick(documents[0]?.format, documents[0]?.src)
                }
              >
                <EyeIcon />
                <TextBox as="span" size="sm" lineHeight="20px">
                  Preview
                </TextBox>
              </S.TooltipItem>
            )}

            {documents.length > 0 && (
              <S.TooltipItem
                as="button"
                alignItems="center"
                gap="8px"
                onClick={() =>
                  downloadFile(documents[0].src, documents[0].name)
                }
              >
                <Download />
                <TextBox as="span" size="sm" lineHeight="20px">
                  Download
                </TextBox>
              </S.TooltipItem>
            )}
          </div>
        </S.TooltipContainer>
      )}
    </>
  );
};

export default PinUpload;
