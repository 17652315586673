import {memo} from "react";
import {formatTitle} from "../../../../utils";
import {FlexBox, StickyHeader} from "../../../../components/commonStyled";
import EconomicsType from "./EconomicsType";
import {ReactComponent as SecondDownload} from "../../../../assets/Icons/download2.svg";
import {ReactComponent as Pen} from "../../../../assets/Icons/pen.svg";

import TextBox from "../../../../components/TextBox";
import Button from "../../../../components/Button";
import {useDispatch} from "react-redux";
import economicsActions from "../../../../actions/economicsActions";
import {ButtonEdit} from "../../styled";
import {usePermission} from "../../../../hooks/usePermission";

const Header = memo((
	{
		projectId,
		payment_type,
		isStaffingModel,
		handleCancelCreate,
		editMode,
		setEditMode,
	}
) => {
	const dispatch = useDispatch();
	const handleDownload = () => {
		dispatch(economicsActions.downloadEconomicsFile(projectId, payment_type));
	}
	const isPermittedUpdateEconomics = usePermission(
		['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']
	);
	const onEditMode = () => {
		setEditMode(true);
	}
	return (
		<StickyHeader justifyContent={'space-between'} alignItems={'center'} paddingBottom={'10px'}>
			<TextBox as='h1' size='md' weight='medium' color='dark' lineHeight='28px'>
				Economics
				<TextBox
					as='span'
					size='md'
					weight='medium'
					color='var(--up-secondary--light-blue)'
					lineHeight='28px'
					style={{
						marginLeft: '8px',
						textTransform: 'capitalize'
					}}
				>
					({isStaffingModel ? 'Staffing' : formatTitle(payment_type)})
				</TextBox>
			</TextBox>
			<FlexBox alignItems='center' gap='12px'>
				<EconomicsType
					type={payment_type}
					projectId={projectId}
				/>
				<Button
					withIcon={true}
					padding={'7px'}
					icon={<SecondDownload/>}
					handleClick={handleDownload}
				/>
				{
					(isPermittedUpdateEconomics) &&
					<ButtonEdit
						as='button'
						onClick={editMode ? handleCancelCreate : onEditMode}
						editMode={editMode}
						alignItems='center'
						justifyContent='center'
					>
						<Pen/>
					</ButtonEdit>
				}
			</FlexBox>
		</StickyHeader>
	)
})

export default Header;