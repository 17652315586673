import {useRef, useState} from "react";
import * as S from "../CardInfo/styled";
import moment from "moment/moment";
import {usePopperTooltip} from "react-popper-tooltip";
import {FlexBox, StatusDropdown, TableIconButton} from "../commonStyled";
import {convertSize, downloadFile, getIconForFile, statusOfDocumentOptions} from "../../utils";
import {ReactComponent as Download} from "../../assets/Icons/download.svg";
import {ReactComponent as Dots} from "../../assets/Icons/dotsVertical.svg";
import {ReactComponent as Trash} from "../../assets/Icons/trash.svg";
import {ReactComponent as Upload} from "../../assets/Icons/upload.svg";

import {RowWithIcon} from "../../pages/Clients/styled";
import FileUploaderInput from "../FileUploaderInput";
import CustomSelect from "../SelectDropdown/CustomSelect";
import TextBox from "../TextBox";
import Calendar from "../SingleCalendar/Calendar";
import {useDispatch} from "react-redux";
import documentsActions from "../../actions/documentsActions";

const DocumentTableRow = (
	{
		doc,
		setSelectedDocument,
		setShowModal,
		isPermittedManageDocument,
		handleUpload,
		typesOfDocuments,
		objectType,
		objectId,
		isPermittedEdit
	}
) => {
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		trigger: 'click',
		placement: 'left-start',
		interactive: true,
	});
	const inputUploadRef = useRef(null);
	const [docState, setDocState] = useState(doc);
	const dispatch = useDispatch();
	const onDelete = () => {
		setSelectedDocument(doc);
		setShowModal(true);
	};
	const handleChangeDate = (value, key) => {
		const newDocState = JSON.parse(JSON.stringify(docState));
		newDocState[key] = value ? moment(value).format('MM/DD/YYYY') : null;
		setDocState(newDocState);
		if (value) handleApply(key, moment(value).format('M/D/Y'));
	};
	const handleChaneSelect = ({value}, key) => {
		const newDocState = JSON.parse(JSON.stringify(docState));
		newDocState[key] = value;
		setDocState(newDocState);
		handleApply(key, value);
	};
	
	const handleApply = (key, value) => {
		const payload = {
			[key]: value,
		}
		dispatch(documentsActions.editDocument(objectType, objectId, docState._id, payload)).then((res) => {
			console.log('res', res);
		}
		)
	}
	return (
		<tr>
			<td title={docState?.name}>
				<RowWithIcon alignItems='center' gap='10px'>
					{getIconForFile(docState.format)}
					<span className="name" style={{lineHeight: '20px'}}>{docState.name}</span>
				</RowWithIcon>
			</td>
			<td style={{overflow: 'visible', width: '142px'}}>
				<Calendar
					initialFormFields={null}
					defaultValue={docState?.start_date || docState?.created_at}
					name={'start_date'}
					getValues={null}
					disabled={!isPermittedEdit}
					withoutIcon={true}
					padding={'7px 8.5px'}
					handleChange={(value) => handleChangeDate(value, 'start_date')}
					inputWidth={'110px'}
					background={'none'}
					transform={'translate(-125px, -20px)'}
					isCalendarHookForm={false}
					// readOnly={true}
				/>
			</td>
			<td style={{overflow: 'visible', width: '142px'}}>
				<Calendar
					initialFormFields={null}
					defaultValue={docState?.expiration_date}
					name={'expiration_date'}
					getValues={null}
					disabled={!isPermittedEdit}
					withoutIcon={true}
					padding={'7px 8.5px'}
					handleChange={(value) => handleChangeDate(value, 'expiration_date')}
					inputWidth={'110px'}
					background={'none'}
					transform={'translate(-125px, -20px)'}
					isCalendarHookForm={false}
					// readOnly={true}
				/>
			</td>
			<td style={{overflow: 'visible', width: '170px', minWidth: '160px'}}>
				<CustomSelect
					name={'type'}
					ref={null}
					options={typesOfDocuments}
					defaultValue={docState.type}
					handleOnChange={(value) => handleChaneSelect(value, 'type')}
					isSearchable={false}
					sizeIcon={'16px'}
					customHeight={'34px'}
					disabled={!isPermittedEdit}
					// closeMenuOnSelect={false}
				/>
			</td>
			<td style={{overflow: 'visible', width: '170px'}}>
				<StatusDropdown width='130px' className={docState?.status}>
					<CustomSelect
						name={'status'}
						ref={null}
						options={statusOfDocumentOptions}
						defaultValue={docState?.status || 'draft'}
						handleOnChange={(value) => handleChaneSelect(value, 'status')}
						isSearchable={false}
						classNameContainer={`status`}
						sizeIcon={'16px'}
						customHeight={'34px'}
						disabled={!isPermittedEdit}
					/>
				</StatusDropdown>
			</td>
			<td className='static-small-width'>{convertSize(docState.size)}</td>
			<td className='static-small-width'>{docState?.format.toUpperCase()}</td>
			<td className='action' style={{width: '40px', textAlign: 'center'}}>
				<TableIconButton onClick={() => downloadFile(docState.src, docState.name)}>
					<Download/>
				</TableIconButton>
			</td>
			{isPermittedManageDocument && <td className='action' style={{width: '40px'}}>
				<TableIconButton ref={setTriggerRef} visible={visible}>
					<Dots/>
				</TableIconButton>
				{visible && (
					<S.TooltipContainer
						ref={setTooltipRef}
						{...getTooltipProps({className: 'tooltip-container'})}
					>
						<FlexBox flexDirection={'column'}>
							<S.TooltipItem
								as='button'
								alignItems='center'
								gap='8px'
								onClick={() => inputUploadRef?.current.click()}
							>
								<Upload/>
								<TextBox as='span' size='sm' lineHeight='20px'>
									Upload New <br/> Version
								</TextBox>
								<FileUploaderInput
									multiple={false}
									ref={inputUploadRef}
									reducerAction={(data) => handleUpload(data, docState._id)}
								/>
							</S.TooltipItem>
							<S.TooltipItem
								as='button'
								alignItems='center'
								gap='8px'
								onClick={onDelete}
							>
								<Trash/>
								<TextBox as='span' size='sm' lineHeight='20px'>Delete</TextBox>
							</S.TooltipItem>
						</FlexBox>
					</S.TooltipContainer>
				)}
			</td>}
		</tr>
	)
}

export default DocumentTableRow;