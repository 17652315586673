import {useLayoutEffect, useRef, useCallback} from "react";
import {usePermission} from "../../hooks/usePermission";
import {useDispatch, useSelector} from "react-redux";
import {FlexBox} from "../commonStyled";
import FileUploaderInput from "../FileUploaderInput";
import {defaultQueryForGetData, getErrorMessages} from "../../utils";
import commonActions from "../../actions/commonActions";
import documentsActions from "../../actions/documentsActions";

import Button from "../Button";
import Search from "../Search";
import DocumentsList from "./DocumentsList";
import Loader from "../Loader";
import UploadContainerWithDrugAndDrop from "../UploadContainerWithDrugAndDrop";
import NoData from "../NoData";
import TextBox from "../TextBox";

const DocumentsWrapper = (
	{
		objectId,
		objectType = 'client_companies',
		isPermittedEdit = true
	}
) => {
	const isPermittedManageDocument = usePermission(
		['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']
	);
	const dispatch = useDispatch();
	const uploadInput = useRef(null);
	const {documents, documentsLoading, typesOfDocuments, typesOfDocumentsLoading} = useSelector(state => state.documentsTabReducer);
	const {searchInputValue} = useSelector(state => state.commonReducer);
	
	const getDocuments = useCallback((data) => {
		dispatch(documentsActions.getDocuments(objectType, objectId, data));
	}, [dispatch, objectId, objectType]);
	
	useLayoutEffect(() => {
		dispatch(documentsActions.getTypesOfDocuments());
		getDocuments(defaultQueryForGetData);
	}, [objectId, dispatch, getDocuments]);
	
	const handleUpload = (formData) => {
		dispatch(documentsActions.uploadDocuments(objectType, objectId, formData, 'other'))
			.then(() => {
				dispatch(commonActions.setPopupMessage('Documents Successfully Uploaded', 'success'));
				getDocuments(
					{
						page: documents?.page,
						page_size: documents?.page_size,
						query_str: searchInputValue
					}
				);
			})
			.catch((error) => {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			});
		
	};
	
	if (documentsLoading || typesOfDocumentsLoading) return <Loader width={'100%'} height={'calc(100vh - 200px)'}/>;
	return (
		<>
			<FileUploaderInput
				ref={uploadInput}
				reducerAction={handleUpload}
			/>
			<FlexBox justifyContent='space-between' alignItems='center' margin='0 0 10px'>
				<TextBox as='h2' size='md' weight='medium'>Documents</TextBox>
				<FlexBox alignItems='center' gap='12px'>
					{documents?.total > 0 && <Search
						onPageChange={getDocuments}
						page={documents?.page}
						page_size={documents?.page_size}
						placeholder='Search by Name'
					/>}
					{
						(isPermittedManageDocument) &&
						<>
							<Button
								label={'Document'}
								gap='12px'
								withIcon={true}
								padding='7px 35px'
								flexDirection='row-reverse'
								handleClick={() => uploadInput?.current?.click()}
								fontSize='16px'
							/>
						</>
					}
				</FlexBox>
			</FlexBox>
			{
				documents?.total > 0 ?
					<DocumentsList
						documents={documents}
						getDocuments={getDocuments}
						objectId={objectId}
						isPermittedManageDocument={isPermittedManageDocument}
						objectType={objectType}
						typesOfDocuments={typesOfDocuments}
						isPermittedEdit={isPermittedEdit}
					/>
					:
					(
						isPermittedManageDocument ?
							<UploadContainerWithDrugAndDrop
								uploadInputRef={uploadInput}
								actionOfParentComponent={handleUpload}
							/>
							:
							<NoData
								isPermittedButton={false}
								buttonText='Contact'
								text='You have no Documents yet'
								margin={'0'}
							/>
					)
			}
		</>
	)
}

export default DocumentsWrapper;