import {useState} from "react";
import {talentsTableColumns} from "../utils";
import {useDispatch} from "react-redux";
import talentsActions from "../../../actions/talentsActions";

import Table from "../../../components/Table";
import TalentsTableRow from "./TalentsTableRow";
import ModalWrap from "../../../components/ModalWrap";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import {useNavigate} from "react-router-dom";

const TalentsTable = (
	{
		data,
		isPermittedManageTalent
	}
) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedTalent, setSelectedTalent] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const handleDeleteTalent = () => {
		dispatch(talentsActions.deleteTalent(selectedTalent._id));
		handleCloseModal();
	};
	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedTalent(null);
	};
	
	const handleClick = (id) => {
		navigate(`/talents/${id}`);
	};
	return (
		<>
			<Table
				childrenHeader={
					talentsTableColumns.map((column, index) => (
						<th key={column.key + '-' + index} className={column.key}>
							{column.title}
						</th>
					))
				}
				data={data}
				withHoverEffect={isPermittedManageTalent}
			>
				{
					data.map((talent, index) => (
						<TalentsTableRow
							key={talent.id + '-' + index}
							talent={talent}
							setShowModal={setShowModal}
							setSelectedTalent={setSelectedTalent}
							handleClick={handleClick}
							isPermittedManageTalent={isPermittedManageTalent}
						/>
					))
				}
			</Table>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={handleCloseModal}
					children={
						<ConfirmationModal
							handleConfirm={handleDeleteTalent}
							closeModal={handleCloseModal}
							title='Delete Talent'
							message={`Are you sure you want to remove the ${selectedTalent?.full_name}? All the related data will be lost.`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</>
	);
}

export default TalentsTable;