import {TableIconButton, StatusDropdown} from "../../../components/commonStyled";
import {statusOptions} from "../../../utils";
import {ReactComponent as Dots} from "../../../assets/Icons/dotsVertical.svg";
import {ReactComponent as Trash} from "../../../assets/Icons/trash.svg";
import * as S from "../../../components/CardInfo/styled";
import {usePopperTooltip} from "react-popper-tooltip";
import {useDispatch} from "react-redux";

import TextBox from "../../../components/TextBox";
import CustomSelect from "../../../components/SelectDropdown/CustomSelect";
import projectsActions from "../../../actions/projectsActions";
import PinUpload from "../../../components/PinUpload";
import moment from "moment";
import TeamCell from "../../../components/TeamCell";
import {usePermission} from "../../../hooks/usePermission";

const ProjectTableRow = (
	{
		project,
		handleClick,
		onChangeStatus,
		setShowModal,
		setSelectedProject,
		projectsData,
		searchInputValue,
		isPermittedCreateNewProject
	}
) => {
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		trigger: 'click',
		interactive: true,
		placement: 'left-start',
	});
	const dispatch = useDispatch();
	const isPermittedEditProject = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']);
	const onDelete = () => {
		setShowModal(true);
		setSelectedProject(project);
	};
	const isDocumentPresent = (type) => {
		return project?.documents?.some(document => document?.type === type);
	};
	const handleReloadPage = () => {
		dispatch(projectsActions.getProjectsData({
			page: 1,
			page_size: projectsData.page_size,
			query_str: searchInputValue
		}));
	};
	return <tr onClick={() => handleClick(project._id)}>
		<td style={{fontWeight: '500'}} title={project.name}>{project.name}</td>
		<td title={project.client_company_name}>{project.client_company_name}</td>
		{/*<td title={project.code}>{project.code}</td>*/}
		<td>{moment(project?.start_date).format('MM/DD/YYYY')}</td>
		<td>{moment(project?.end_date).format('MM/DD/YYYY')}</td>
		<td onClick={e => e.stopPropagation()} style={{minWidth: '60px'}}>
			<PinUpload
				isActive={isDocumentPresent('msa')}
				isUploadNewVersionOfDocument={isDocumentPresent('msa')}
				handleReloadPage={handleReloadPage}
				typeDoc={'msa'}
				objectType={'projects'}
				data={project}
				uploadOnlyOneFile={false}
				isMultiple={!isDocumentPresent('msa')}
				documents={project?.documents?.filter(doc => doc.type === 'msa')}
				disabled={!isPermittedCreateNewProject}
			/>
		</td>
		<td onClick={e => e.stopPropagation()} style={{minWidth: '60px'}}>
			<PinUpload
				isActive={isDocumentPresent('po')}
				isUploadNewVersionOfDocument={isDocumentPresent('po')}
				handleReloadPage={handleReloadPage}
				typeDoc={'po'}
				objectType={'projects'}
				data={project}
				uploadOnlyOneFile={false}
				isMultiple={!isDocumentPresent('po')}
				documents={project?.documents?.filter(doc => doc.type === 'po')}
				disabled={!isPermittedEditProject}
			/>
		</td>
		<td onClick={e => e.stopPropagation()} style={{minWidth: '60px'}}>
			<PinUpload
				isActive={isDocumentPresent('sow')}
				isUploadNewVersionOfDocument={isDocumentPresent('sow')}
				handleReloadPage={handleReloadPage}
				typeDoc={'sow'}
				objectType={'projects'}
				data={project}
				uploadOnlyOneFile={false}
				isMultiple={!isDocumentPresent('sow')}
				documents={project?.documents?.filter(doc => doc.type === 'sow')}
				disabled={!isPermittedEditProject}
			/>
		</td>
		<td style={{overflow: 'visible'}}>
			<StatusDropdown width='145px' className={project.status}>
				<CustomSelect
					name={'status'}
					ref={null}
					options={statusOptions}
					defaultValue={project.status || 'draft'}
					handleOnChange={(value) => onChangeStatus(value, project._id)}
					isSearchable={false}
					classNameContainer={`status`}
					sizeIcon={'16px'}
					customHeight={'34px'}
					disabled={!isPermittedEditProject}
				/>
			</StatusDropdown>
		</td>
		<td style={{width: '166px'}}>
			<TeamCell
				team={project?.contractors}
				projectData={project}
			/>
		</td>
		{/*<td title={project?.invoice_date || '-'}>*/}
		{/*	{project?.invoice_date || '-'}*/}
		{/*</td>*/}
		{/*<td title={project?.invoices_status || '-'}>*/}
		{/*	{project?.invoices_status || '-'}*/}
		{/*</td>*/}
		<td className='action' onClick={e => e.stopPropagation()}>
			{isPermittedCreateNewProject && project.status !== 'archived' && <TableIconButton ref={setTriggerRef} visible={visible}>
				<Dots/>
			</TableIconButton>}
			{visible && (
				<S.TooltipContainer
					ref={setTooltipRef}
					{...getTooltipProps({className: 'tooltip-container'})}
				>
					<div>
						<S.TooltipItem
							as='button'
							alignItems='center'
							gap='8px'
							onClick={onDelete}
							
						>
							<Trash/>
							<TextBox as='span' size='sm' lineHeight='20px'>Archive</TextBox>
						</S.TooltipItem>
					</div>
				</S.TooltipContainer>
			)}
		</td>
	</tr>
}

export default ProjectTableRow;