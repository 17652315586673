const initialState = {
	documents: null,
	documentsLoading: false,
	typesOfDocuments: null,
	typesOfDocumentsLoading: false,
};

export default function documentsTabReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_DOCUMENTS": {
			return {
				...state,
				documents: action.payload,
				documentsLoading: false,
			}
		}
		case "SET_DOCUMENTS_LOADING": {
			return {
				...state,
				documentsLoading: action.payload
			}
		}
		case "SET_TYPES_OF_DOCUMENTS": {
			return {
				...state,
				typesOfDocuments: action.payload,
				typesOfDocumentsLoading: false,
			}
		}
		case "SET_TYPES_OF_DOCUMENTS_LOADING": {
			return {
				...state,
				typesOfDocumentsLoading: action.payload
			}
		}
		default: return state;
	}
}