import bgImage from "../../assets/img/bgNoClients.png";
import invoicingBgImage from "../../assets/img/invoicingCat.svg";
import {FlexBox} from "../commonStyled";

import TextBox from "../TextBox";
import Button from "../Button";
import {NavLink} from "react-router-dom";
import {NoDataImg} from "./styled";

const NoData = (
	{
		isPermittedButton,
		handleClick,
		text = 'You have no Client Company yet',
		margin = '80px 0 0',
		buttonText = 'Client Company',
		isNavButton = false,
		navLink = '',
		height = '60vh',
		maxHeight = '512px',
		icon = true,
		isInvoicing = false,
		subText = ''
	}
) => {
	return (
		<FlexBox flexDirection='column' alignItems='center' justifyContent='center' margin={margin} width={'100%'}>
			<NoDataImg as='img' src={isInvoicing ? invoicingBgImage :bgImage} alt="bgImage" height={height} maxHeight={maxHeight}/>
			<FlexBox flexDirection='column' alignItems='center' justifyContent='center' gap='40px'>
				<TextBox as='h2' size='md'>{text}</TextBox>
				{isPermittedButton &&
				(isNavButton ?
					<NavLink to={navLink}>
						<Button
							label={buttonText}
							gap='12px'
							padding='7px 35px'
							flexDirection='row-reverse'
							fontSize='16px'
							minWidth='234px'
							withIcon={icon}
						/>
					</NavLink>
					:
					<Button
						label={buttonText}
						gap='12px'
						withIcon={icon}
						padding='7px 35px'
						flexDirection='row-reverse'
						handleClick={handleClick}
						fontSize='16px'
						minWidth='234px'
					/>)
				}
			</FlexBox>
		</FlexBox>
	)
}

export default NoData;