import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  inputsCreateNewContact,
  schemaCreateNewContact,
} from "../../pages/Clients/utils";
import clientsActions from "../../actions/clientsActions";
import { FlexBox } from "../commonStyled";
import * as CS from "../commonStyled";

import GroupInputs from "../GroupInputs";
import TextBox from "../TextBox";
import Button from "../Button";
import { isEmptyForm } from "../../utils";
import { ButtonClose } from "../ModalWrap/styled";
import { ReactComponent as IconClose } from "../../assets/Icons/close.svg";
import ConfirmationModal from "./ConfirmationModal";
import ModalWrap from "../ModalWrap";

const CreateNewClientContact = ({ closeModal, isPermittedForNotes, slug }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaCreateNewContact),
  });
  const dispatch = useDispatch();
  const [showWarningModal, setShowWarningModal] = useState(false);

  const onSubmit = (data) => {
    dispatch(clientsActions.createNewContact(data, slug));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (isEmptyForm(getValues())) {
      closeModal();
    } else {
      setShowWarningModal(true);
    }
  };

  return (
    <>
      <ButtonClose onClick={handleCancel}>
        <IconClose />
      </ButtonClose>
      {showWarningModal && (
        <ModalWrap
          paddingTop="75px"
          close={() => setShowWarningModal(false)}
          children={
            <ConfirmationModal
              handleConfirm={closeModal}
              closeModal={() => setShowWarningModal(false)}
              title="Close the creation process"
              message={`All new data won’t be stored. Are you sure you want to proceed?`}
              buttonText="Yes, Close"
            />
          }
        />
      )}
      <>
        <TextBox as="h2" size="md" weight="medium" marginBottom="24px">
          Add Contact
        </TextBox>
        <FlexBox
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          flexDirection="column"
          gap="24px"
          overflow="auto"
        >
          <GroupInputs
            inputs={
              isPermittedForNotes
                ? inputsCreateNewContact
                : inputsCreateNewContact.filter(
                    (input) => input.name !== "notes"
                  )
            }
            errors={errors}
            register={register}
            control={control}
            fullWidthInput={true}
            gap="17px"
            marginTopForError="20px"
            setValue={setValue}
            getValues={getValues}
          />
          <CS.FlexBox justifyContent="space-between" width="100%">
            <Button
              type="cancel"
              label="Cancel"
              handleClick={handleCancel}
              ghost={true}
            />
            <Button type="send" label="Save" />
          </CS.FlexBox>
        </FlexBox>
      </>
    </>
  );
};

export default CreateNewClientContact;
