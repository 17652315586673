const initialState = {
	projectsData: null,
	projectsDataLoading: false,
	clientsNames: null,
	clientsNamesLoading: false,
	projectDataLoading: false,
	activeClientName: null,
	projectsCodes: null,
	projectsCodesLoading: false,
	projectsCreateLoading: false,
	projectData: {},
	clientFilter: null,
	statusFilter: null,
	teamData: null,
	teamDataLoading: false,
	assignContractorSuccess: false,
	assignContractorLoading: false,
	sortBy: null,
};

export default function projectsPageReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_PROJECTS_DATA": {
			return {
				...state,
				projectsData: action.payload,
				projectsDataLoading: false,
			}
		}
		case "SET_PROJECTS_DATA_LOADING": {
			return {
				...state,
				projectsDataLoading: action.payload
			}
		}
		case "SET_CLIENTS_NAMES_LOADING": {
			return {
				...state,
				clientsNamesLoading: action.payload
			}
		}
		case "SET_CLIENTS_NAMES": {
			return {
				...state,
				clientsNames: action.payload,
				clientsNamesLoading: false,
			}
		}
		case "SET_PROJECT_DATA_LOADING": {
			return {
				...state,
				projectDataLoading: action.payload
			}
		}
		case "SET_ACTIVE_CLIENT_NAME": {
			return {
				...state,
				activeClientName: action.payload
			}
		}
		case "SET_PROJECTS_CODES": {
			return {
				...state,
				projectsCodes: action.payload,
				projectsCodesLoading: false,
			}
		}
		case "SET_PROJECTS_CODES_LOADING": {
			return {
				...state,
				projectsCodesLoading: action.payload
			}
		}
		case "SET_PROJECTS_CREATE_LOADING": {
			return {
				...state,
				projectsCreateLoading: action.payload
			}
		}
		case "SET_PROJECT_DATA": {
			return {
				...state,
				projectDataLoading: false,
				projectData: action.payload,
			}
		}
		case "SET_CLIENT_FILTER": {
			return {
				...state,
				clientFilter: action.payload,
			}
		}
		case "SET_STATUS_FILTER": {
			return {
				...state,
				statusFilter: action.payload,
			}
		}
		case "SET_TEAM_DATA": {
			return {
				...state,
				teamData: action.payload,
				teamDataLoading: false,
			}
		}
		case "SET_TEAM_DATA_LOADING": {
			return {
				...state,
				teamDataLoading: action.payload
			}
		}
		case "SET_ASSIGN_CONTRACTOR_SUCCESS": {
			return {
				...state,
				assignContractorSuccess: action.payload
			}
		}
		case "SET_ASSIGN_CONTRACTOR_LOADING": {
			return {
				...state,
				assignContractorLoading: action.payload
			}
		}
		case "SET_PROJECTS_SORT_BY": {
			return {
				...state,
				sortBy: action.payload
			}
		}
		default: return state;
	}
}