import * as S from "./styled";
import * as CS from "../../components/commonStyled";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useToken} from "../../hooks/useToken";
import PropTypes from "prop-types";
import {Navigate} from "react-router-dom";

import {ReactComponent as Cat} from "../../assets/Icons/cat1.svg";
import {schemaNewPassword} from "./utils";

import Button from "../../components/Button";
import TextBox from "../../components/TextBox";
import FormInput from "../../components/FormInput";
import {useDispatch, useSelector} from "react-redux";
import authActions from "../../actions/authActions";

const NewPasswordForm = ({title = 'New Password', isInvite = false, successMessage='Password was changed'}) => {
	const {token, loading} = useToken(isInvite);
	const {passwordSuccessfullyChanged} = useSelector(state => state.authReducer);
	const {register, handleSubmit, formState: {errors}} = useForm({
		resolver: yupResolver(schemaNewPassword)
	});
	const dispatch = useDispatch();
	
	const onSubmit = ({password}) => {
		const payload = {
			password: password,
			confirm_password: password,
			token,
		}
		dispatch(authActions.setNewPassword(payload, successMessage));
	};
	if ((!token && !loading) || passwordSuccessfullyChanged) {
		return <Navigate to={'/auth/login'}/>;
	}
	return (
		<S.Form onSubmit={handleSubmit(onSubmit)}>
			<TextBox size={'lg'} as={'h2'} color='dark'>
				{title}
			</TextBox>
			<FormInput register={register} errors={errors} name={'password'} type='password' isStaticErrorMessage={true} />
			<FormInput register={register} errors={errors} name={'confirm Password'} type='password'/>
			<Button label='Submit' type="submit" fullWidth={true} padding='12px 15px'/>
			<CS.ImgWithAbsolutePosition as={Cat} top={'63px'} right={'103px'}/>
		</S.Form>
	)
}

NewPasswordForm.propTypes = {
	title: PropTypes.string,
	isInvite: PropTypes.bool
}

export default NewPasswordForm;