import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {inputsAssignContractor, schemaAssignContractor} from "./utils";
import {ButtonClose} from "../../../../components/ModalWrap/styled";
import {FlexBox} from "../../../../components/commonStyled";
import {useDispatch, useSelector} from "react-redux";
import * as CS from "../../../../components/commonStyled";
import {ReactComponent as IconClose} from "../../../../assets/Icons/close.svg";


import GroupInputs from "../../../../components/GroupInputs";
import TextBox from "../../../../components/TextBox";
import Button from "../../../../components/Button";
import ModalSuccessfully from "../../../../components/modals/modalSuccessfully";
import projectsActions from "../../../../actions/projectsActions";
import Loader from "../../../../components/Loader";

const AssignContractor = (
	{
		closeModal,
		projectId
	}
) => {
	const {assignContractorSuccess, assignContractorLoading} = useSelector(state => state.projectsPageReducer);
	const {register, handleSubmit, formState: {errors}, control, setValue, getValues} = useForm({
		resolver: yupResolver(schemaAssignContractor)
	});
	const dispatch = useDispatch();
	
	const onSubmit = (data) => {
		const payload = {
			limitation_hours: data?.limitation_hours * 1 || 0,
			daily_rate: data?.external_daily_rate * 1 || 0,
		}
		const contractorId = data?.contractor?._id;
		dispatch(projectsActions.addContractorToTeam(payload, projectId, contractorId));
	};
	const getTeamDataAfterCreate = () => {
		closeModal();
		dispatch(projectsActions.resetSuccessAssignContractor());
		dispatch(projectsActions.getTeamData(projectId));
	}
	return (
		<>
			{
				assignContractorLoading && <Loader position={'absolute'} width={'100%'} height={'100%'} background={'var(--up-white)'}/>
			}
			<ButtonClose onClick={closeModal}><IconClose/></ButtonClose>
			{
				assignContractorSuccess ?
				<ModalSuccessfully handleClick={getTeamDataAfterCreate} title='Successfully '
													 message='Talent has been successfully assigned'/>
				:
				<>
					<TextBox as='h2' size='md' weight='medium' marginBottom='24px'>Assign Talent</TextBox>
					<FlexBox as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'>
						<GroupInputs
							inputs={inputsAssignContractor}
							errors={errors}
							register={register}
							control={control}
							fullWidthInput={true}
							gap='17px'
							marginTopForError='20px'
							setValue={setValue}
							getValues={getValues}
						/>
						<CS.FlexBox justifyContent='space-between' width='100%'>
							<Button type='cancel' label='Cancel' handleClick={closeModal} ghost={true}/>
							<Button type='send' label='Save'/>
						</CS.FlexBox>
					</FlexBox></>}
		</>
	);
}

export default AssignContractor;