import {FlexBox} from "../commonStyled";
import Avatar from "../Avatar";
import {usePopperTooltip} from "react-popper-tooltip";
import * as S from "../CardInfo/styled";
import TextBox from "../TextBox";
import {useDispatch} from "react-redux";
import commonActions from "../../actions/commonActions";
import {useNavigate} from "react-router-dom";
import {TooltipItem} from "../CardInfo/styled";

const TeamCell = ({team, maxVisibleItems = 5, projectData}) => {
	const showCountOfHiddenItems = team?.length > maxVisibleItems;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		placement: 'bottom-start',
		trigger: 'click',
		interactive: true,
	});
	const handleClickItem = (event, contractor) => {
		event.stopPropagation();
		dispatch(commonActions.setActiveTab(1));
		navigate(`/projects/${projectData._id}/${contractor._id}`);
	};
	
	return (
		<>
			{
				team?.length ?
					<FlexBox
						style={{overflow: 'hidden', maxWidth: '144px'}}
					>
						{
							team?.map((contractor, index) => {
								return index < (showCountOfHiddenItems ? maxVisibleItems - 1 : maxVisibleItems) &&
									<Avatar
										key={index}
										style={{marginLeft: index ? '-4px' : 0}}
										src={contractor?.logo}
										name={contractor?.full_name || 'No Name'}
										width={'32px'}
										height={'32px'}
										sizeInitials={'xsm'}
										withTooltip={true}
										withHover={true}
										onClick={(e) => handleClickItem(e, contractor)}
									/>
							})
						}
						{
							showCountOfHiddenItems && <div
								ref={setTriggerRef}
								onClick={e => e.stopPropagation()}
								style={
									{
										marginLeft: '-4px',
									}
								}
							>
								<Avatar
									style={
										{
											background: 'var(--up-main-info)',
											color: 'var(--up-white)',
											borderColor: 'var(--up-main-info)',
											cursor: 'pointer',
										}
									}
									colorText={'var(--up-white)'}
									src={''}
									name={`+ ${team?.length - maxVisibleItems}`}
									width={'32px'}
									height={'32px'}
									sizeInitials={'xsm'}
								/>
							</div>
						}
					</FlexBox> :
					'-'
			}
			{visible && (
				<S.TooltipContainer
					ref={setTooltipRef}
					{...getTooltipProps({className: 'tooltip-container'})}
				>
					<div>
						{
							team?.map((contractor, index) => {
								return index >= maxVisibleItems &&
									<TooltipItem
										key={index}
										alignItems='center'
										gap='8px'
										onClick={(e) => handleClickItem(e, contractor)}
									>
										<Avatar
											src={contractor?.logo}
											name={contractor?.full_name || 'No Name'}
											width={'32px'}
											height={'32px'}
											sizeInitials={'xsm'}
										/>
										<TextBox as='span' size='sm' lineHeight='20px'>{contractor?.full_name || 'No Name'}</TextBox>
									</TooltipItem>
							})
						}
					
					</div>
				</S.TooltipContainer>
			)}
		</>
	)
}

export default TeamCell;