import {TableIconButton} from "../../../../components/commonStyled";
import {usePopperTooltip} from "react-popper-tooltip";
import * as S from "../../../../components/CardInfo/styled";
import {ReactComponent as Dots} from "../../../../assets/Icons/dotsVertical.svg";
import {ReactComponent as Download} from "../../../../assets/Icons/download.svg";
import {ReactComponent as Trash} from "../../../../assets/Icons/trash.svg";
import {useDispatch} from "react-redux";

import TextBox from "../../../../components/TextBox";
import clientsActions from "../../../../actions/clientsActions";

const TableRowContacts = (
	{
		contact,
		isPermittedForNotes,
		handleClick,
		setSelectedContact,
		setShowModal,
		isPermittedManageContact,
		activeClientID
	}
) => {
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		trigger: 'click',
		placement: 'left-start',
		interactive: true,
	});
	const dispatch = useDispatch();
	const onDelete = () => {
		setSelectedContact(contact);
		setShowModal(true);
	};
	
	const exportContact = (e) => {
		e.stopPropagation();
		dispatch(clientsActions.exportContact(activeClientID, contact._id))
	};
	const note = contact?.notes || '-';
	const replaceImgToText = note.replace(/<img[^>]*>/g, ' (image)');
	return (
		<tr onClick={() => handleClick(contact._id)}>
			<td style={{fontWeight: '500'}} title={contact?.name || '-'}>{contact?.name || '-'}</td>
			<td title={contact?.position || '-'}>{contact?.position || '-'}</td>
			<td title={contact?.email || '-'}>{contact?.email || '-'}</td>
			<td title={contact?.phone_number || '-'}>{contact?.phone_number || '-'}</td>
			{isPermittedForNotes && <td className='paragraph'>
				{/*<div title={contact?.notes || '-'} dangerouslySetInnerHTML={{ __html: note }} />*/}
				<TextBox
					as='div'
					dangerouslySetInnerHTML={{ __html: replaceImgToText }}
					withTooltip={true}
					widthTooltip={'400px'}
					heightTooltip={'250px'}
					paddingTooltip={'12px'}
					backgroundTooltip={'var(--up-white)'}
					className='paragraph-block'
					displayArrow={'none'}
					lineHeight={'20px'}
					childrenTooltip={<span dangerouslySetInnerHTML={{ __html: note }} />}
				/>
			</td>}
			<td className='action' style={{textAlign: 'center'}} onClick={exportContact}>
				<TableIconButton>
					<Download/>
				</TableIconButton>
			</td>
			{isPermittedManageContact && <td className='action' onClick={e => e.stopPropagation()}>
				<TableIconButton ref={setTriggerRef} visible={visible}>
					<Dots/>
				</TableIconButton>
				{visible && (
					<S.TooltipContainer
						ref={setTooltipRef}
						{...getTooltipProps({className: 'tooltip-container'})}
					>
						<div>
							<S.TooltipItem
								as='button'
								alignItems='center'
								gap='8px'
								onClick={onDelete}
							>
								<Trash/>
								<TextBox as='span' size='sm' lineHeight='20px'>Delete</TextBox>
							</S.TooltipItem>
						</div>
					</S.TooltipContainer>
				)}
			</td>}
		</tr>
	);
}

export default TableRowContacts;