import * as yup from "yup";
import {
  industryOptions,
  validationSchemaForClientCode,
  validationSchemaForEmail,
  validationSchemaForPhoneNumber,
  validationSchemaForRequiredField,
} from "../../utils";
import { ReactComponent as GeneralIcon } from "../../assets/Icons/Assessment.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/Icons/Dashboard.svg";
import { ReactComponent as ClientContactsIcon } from "../../assets/Icons/SupervisorAccount.svg";

export const inputsCreateNewClient = [
  {
    label: "Company Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Company Code",
    name: "code",
    type: "text",
    required: true,
    textTransform: "uppercase",
    maxLength: 4,
  },
  {
    label: "Industry",
    placeholder: "Select industry",
    name: "industry",
    type: "select",
    required: false,
    options: industryOptions,
  },

  {
    label: "Phone Number",
    name: "phone_number",
    type: "text_mask",
    required: false,
    mask: "+9 (999) 999-9999",
  },
];

export const inputsCreateNewContact = [
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    required: true,
    customWidth: "1 1 calc(50% - 11px)",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    required: true,
    customWidth: "1 1 calc(50% - 11px)",
  },
  {
    label: "Email Address",
    name: "email",
    type: "text",
    required: true,
    customWidth: "1 1 calc(50% - 11px)",
  },
  {
    label: "Position",
    placeholder: "Select position",
    name: "position",
    type: "text",
    required: false,
    customWidth: "1 1 calc(50% - 11px)",
  },

  {
    label: "Phone Number",
    name: "phone_number",
    type: "text_mask",
    required: false,
    mask: "+9 (999) 999-9999",
    customWidth: "1 1 calc(50% - 11px)",
  },
  {
    label: "Notes",
    name: "notes",
    type: "textarea",
    required: false,
    padding: "10.5px 12px",
    customWidth: "100%",
    rows: 5,
    isHidden: false,
  },
];

export const schemaCreateNewClient = yup.object().shape({
  name: validationSchemaForRequiredField("Company Name"),
  code: validationSchemaForClientCode(),
  // 'Business Address': validationSchemaForRequiredField('Business Address'),
  phone_number: validationSchemaForPhoneNumber(),
});

export const schemaCreateNewContact = yup.object().shape({
  first_name: validationSchemaForRequiredField("First Name"),
  last_name: validationSchemaForRequiredField("Last Name"),
  email: validationSchemaForEmail(),
  phone_number: validationSchemaForPhoneNumber(),
  position: yup
    .string()
    .transform((value) => (typeof value === "object" ? value?._id : value)),
});

export const inputsClientDetails = [
  {
    label: "Logo",
    name: "logo",
    type: "file",
    required: false,
    customWidth: "100%",
  },
  {
    label: "Company Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Phone Number",
    name: "phone_number",
    type: "text_mask",
    required: false,
    mask: "+9 (999) 999-9999",
  },
  {
    label: "Email Address",
    name: "email",
    type: "text",
    required: true,
  },
  {
    label: "Industry",
    placeholder: "Select industry",
    name: "industry",
    type: "select",
    required: false,
    options: industryOptions,
  },
  {
    label: "Country",
    name: "country",
    type: "text",
    required: false,
  },
  {
    label: "City",
    name: "city",
    type: "text",
    required: false,
  },
  {
    label: "State",
    name: "state",
    type: "text",
    required: false,
  },
  {
    label: "Street",
    name: "street",
    type: "text",
    required: false,
    customWidth: "1 1 calc(25% - 36px)",
  },
  {
    label: "ZIP Code",
    name: "zip_code",
    type: "number",
    required: false,
    customWidth: "1 1 calc(25% - 36px)",
  },
  {
    label: "Website",
    name: "website",
    type: "text",
    required: false,
  },
  {
    label: "Notes",
    name: "note",
    type: "textarea",
    required: false,
    customWidth: "100%",
    rows: 4,
  },
];

export const schemaClientDetails = yup.object().shape({
  name: validationSchemaForRequiredField("Company Name"),
  phone_number: validationSchemaForPhoneNumber(),
  email: validationSchemaForEmail(),
  industry: yup
    .string()
    .transform((value) => (typeof value === "object" ? value?.value : value)),
  website: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .matches(
      /^(https?:\/\/)?(www\.)[a-z0-9-]+(\.[a-z]{2,6})+([/\w.-]*)*\/?$/,
      "Please enter the correct link format"
    )
    .nullable(),
});

export const clientsTabs = [
  {
    name: "Profile",
    icon: <GeneralIcon />,
    width: "828px",
    padding: "0 24px 24px",
  },
  {
    name: "Documents",
    icon: <DocumentsIcon />,
  },
  {
    name: "Contacts",
    icon: <ClientContactsIcon />,
    height: "100%",
  },
];

export const contactsTableColumns = [
  {
    title: "Full Name",
    dataIndex: "name",
    key: "name",
    bold: true,
  },
  {
    title: "Position",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    bold: false,
  },
  {
    title: "",
    dataIndex: "delete",
    key: "action",
    bold: false,
  },
];

export const documentsTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
  },
  {
    title: "Expiration Date",
    dataIndex: "expiration_date",
    key: "expiration_date",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size static-small-width",
  },
  {
    title: "Format",
    dataIndex: "format",
    key: "format static-small-width",
  },
  {
    title: "",
    dataIndex: "download",
    key: "action",
  },
  {
    title: "",
    dataIndex: "delete",
    key: "action",
  },
];
