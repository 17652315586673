import {FlexBox} from "../../../../components/commonStyled";
import RadioButton from "../../../../components/RadioButton";
import {useDispatch} from "react-redux";
import projectsActions from "../../../../actions/projectsActions";
import {memo} from "react";

const EconomicsType = memo((
	{
		type,
		projectId,
	}
) => {
	const dispatch = useDispatch();
	const handleChangeType = (value) => {
		dispatch(projectsActions.updateProject({payment_type: value}, projectId, true));
	}
	return (
		<FlexBox gap={'20px'}>
			<RadioButton
				label='Fixed Fee'
				name='fixed_fee'
				value='fixed_fee'
				selectedValue={type}
				handleChangeType={handleChangeType}
			/>
			<RadioButton
				label={'Staffing'}
				name='staffing_model'
				value='staffing_model'
				selectedValue={type}
				handleChangeType={handleChangeType}
			/>
		</FlexBox>
	)
})
export default EconomicsType;