import {validationSchemaForEmail, validationSchemaForPhoneNumber, validationSchemaForRequiredField} from "../../utils";
import * as yup from "yup";
import {ReactComponent as GeneralIcon} from "../../assets/Icons/Assessment.svg";
import {ReactComponent as TeamIcon} from "../../assets/Icons/AccountCircle.svg";

export const profileInputs = [
	{
		label: 'Logo',
		name: 'logo',
		type: 'file',
		required: false,
		customWidth: '100%',
	},
	{
		label: 'Full Name',
		name: 'full_name',
		type: 'text',
		required: true,
		customWidth: 'calc(50% - 8px)',
	},
	{
		label: 'Email',
		name: 'email',
		type: 'text',
		disabled: true,
		customWidth: 'calc(50% - 8px)',
	},
	{
		label: 'Role',
		name: 'role',
		type: 'select',
		required: true,
		placeholder: 'Select Role',
		customWidth: 'calc(50% - 8px)',
		options: [
			{
				value: 'super_admin',
				label: 'UpRamp Admin',
			},
			{
				value: 'company_admin',
				label: 'Company Admin',
			},
			// {
			// 	value: 'client',
			// 	label: 'Client',
			// },
			{
				value: 'consultant',
				label: 'Consultant',
			},
			{
				value: 'staffer',
				label: 'Staffer',
			},
			{
				value: 'project_coordinator',
				label: 'Project Coordinator',
			},
			{
				value: 'controller',
				label: 'Controller',
			},
			{
				value: 'contract_specialist',
				label: 'Contracts Expert',
			},
			{
				value: 'partner',
				label: 'Partner',
			},
		],
	},
	{
		label: 'Phone Number',
		name: 'phone_number',
		type: 'text_mask',
		required: false,
		mask: '+9 (999) 999-9999',
		customWidth: 'calc(50% - 8px)',
	},
];

export const profileInputsValidationSchema = yup.object().shape({
	full_name: validationSchemaForRequiredField('Full Name'),
	role: yup
		.string()
		.transform(value => (typeof value === 'object' ? value?.value : value)),
	phone_number: validationSchemaForPhoneNumber(),
});

export const profileTabs = [
	{
		name: 'Profile',
		icon: <TeamIcon/>,
		width: '828px',
		padding: '0 24px 24px',
	},
	{
		name: 'Team members',
		icon: <GeneralIcon/>,
		height: 'calc(100% - 40px)',
	},
];

export const inputsAddTeamMember = [
	{
		label: 'Full Name',
		name: 'full_name',
		type: 'text',
		required: true,
	},
	{
		label: 'Role',
		name: 'role',
		type: 'select',
		required: true,
		placeholder: 'Select Role',
		options: [
			{
				value: 'super_admin',
				label: 'UpRamp Admin',
			},
			{
				value: 'company_admin',
				label: 'Company Admin',
			},
			// {
			// 	value: 'client',
			// 	label: 'Client',
			// },
			{
				value: 'consultant',
				label: 'Consultant',
			},
			{
				value: 'controller',
				label: 'Controller',
			},
			{
				value: 'contract_specialist',
				label: 'Contracts Expert',
			},
			{
				value: 'staffer',
				label: 'Staffer',
			},
			{
				value: 'project_coordinator',
				label: 'Project Coordinator',
			},
			{
				value: 'partner',
				label: 'Partner',
			},
		],
	},
	{
		label: 'Email',
		name: 'email',
		type: 'text',
		required: true,
	},
	
	{
		label: 'Phone Number',
		name: 'phone_number',
		type: 'text_mask',
		required: false,
		mask: '+9 (999) 999-9999',
	}
];

export const schemaAddTeamMember = yup.object().shape({
	full_name: validationSchemaForRequiredField('Full Name'),
	role: yup
		.string()
		.required('Role is required')
		.transform(value => (typeof value === 'object' ? value?.value : value)),
	email: validationSchemaForEmail(),
});

export const teamMembersTableColumns = [
	{
		title: 'Full Name',
		dataIndex: 'full_name',
		key: 'name',
		bold: true
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Phone Number',
		dataIndex: 'phone_number',
		key: 'phone_number',
	},
	{
		title: 'Date Added',
		dataIndex: 'date_added',
		key: 'date_added',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Role',
		dataIndex: 'role',
		key: 'role',
	},
	{
		title: '',
		dataIndex: 'action',
		key: 'action',
		bold: false
	}
];
