import styled from "styled-components";

export const SearchInput = styled.div`
	position: relative;
	width: ${({width}) => width};
	`;

export const SearchIcon = styled.svg`
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
`;

export const CloseIcon = styled.svg`
	position: absolute;
	right: 6px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	
	path {
		fill: var(--up-main-light-grey);
	}
`;