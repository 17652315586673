import {
	configStylesForEconomicsTable,
	initialProfessionalFee,
	professionalFeesTableColumns,
	sumCostOfAllItems,
	sumItems
} from "../utils";
import {EconomicsTableWrapper} from "../styled";

import Table from "../../../../../components/Table";
import Button from "../../../../../components/Button";
import Row from "./Row";
import TextBox from "../../../../../components/TextBox";
import {memo} from "react";
import {TotalTd} from "../../../../../components/Table/styled";
import {reducingNumber} from "../../../../../utils";

const ProfessionalFees = memo((
	{
		professionalFees,
		setProfessionalFees,
		editMode,
		talentsNames,
		isStaffingModel
	}
) => {
	const handleDeleteItem = (index) => {
		if (professionalFees.length === 1) return setProfessionalFees([initialProfessionalFee]);
		setProfessionalFees(state => state.filter((row, i) => i !== index));
	};
	
	const handleUpdateStateRow = (data)=> {
		setProfessionalFees(data);
	}
	
	return (
		<EconomicsTableWrapper
			tdBackground={'var(--up-main-grayish)'}
			isStaffingModel={isStaffingModel}
			widthColumn={isStaffingModel ? '19%' : '25%'}
		>
			<TextBox
				size={'xxmd'}
				weight={'medium'}
				lineHeight={'28px'}
				margin={'0 0 12px'}
				as={'h2'}
			>
				Professional Fees
			</TextBox>
				<Table
					childrenHeader={
						professionalFeesTableColumns.map((column, index) => {
							if (!isStaffingModel && column.key === 'external_daily_rate') return null;
							return <th key={column.key + '-' + index} className={column.key}
									style={{textAlign: column?.textAlign || 'left'}}>
								{column.title}
							</th>
						})
					}
					data={professionalFees}
					{...configStylesForEconomicsTable}
				>
					{
						professionalFees.map((row, index) => (
							<Row
								key={row._id + '-' + index}
								row={row}
								index={index}
								editMode={editMode}
								handleDeleteItem={handleDeleteItem}
								handleUpdateStateRow={handleUpdateStateRow}
								talentsNames={talentsNames}
								professionalFees={professionalFees}
								isStaffingModel={isStaffingModel}
							/>
						))
					}
					<tr>
						<td style={{position: 'relative', overflow: 'visible'}}>
							<Button
								label={'Team Member'}
								gap='12px'
								withIcon={true}
								padding='10px 35px'
								flexDirection='row-reverse'
								handleClick={() => setProfessionalFees(state => [...state, initialProfessionalFee])}
								fontSize='14px'
								ghost={true}
								style={{margin: '5px 16px', position: 'absolute', left: '0', top: '0'}}
								width={'192px'}
								sizeIcon={'16px'}
								disabled={!editMode || professionalFees.length === talentsNames.length}
							/>
						</td>
					</tr>
					<tr className='with-border-radius'>
						<td className={'with-bg'}></td>
						{isStaffingModel && <td className={'with-bg'}></td>}
						<td className={'with-bg'}></td>
						<TotalTd className={'with-bg'}
								style={{textAlign: 'right'}}>
							Total Days: {sumItems(professionalFees)}
						</TotalTd>
						<TotalTd className={'with-bg'}
								style={{textAlign: 'right', fontWeight: '500'}}>
							Total: $ {reducingNumber(sumCostOfAllItems(professionalFees))}
						</TotalTd>
						<td className={'with-bg action trash-icon'}></td>
					</tr>
				</Table>
		</EconomicsTableWrapper>
	)
})

export default ProfessionalFees;