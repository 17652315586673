import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {domain} from "../APIGateway/gateway";
const passwordRecoveryTokenVerify = domain + "/api/v1/auth/password-recovery/token-verify";

export const useToken = (isInvite) => {
	const [token, setToken] = useState(null);
	const [loading, setLoading] = useState(true);
	const {search} = useLocation();
	
	useEffect(() => {
		const token = search?.split("=")[1];
		if (token) {
			if (isInvite) {
				axios
					.post(passwordRecoveryTokenVerify, {
							'token': `${token}`
					})
					.then((response) => {
						setToken(response.data.token);
						setLoading(false);
					})
					.catch(() => {
						setToken(null);
						setLoading(false);
					});
			} else {
				setToken(token);
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	}, [search, isInvite]);
	
	return {token, loading};
};
