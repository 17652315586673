import CustomSelect from "../../../../../components/SelectDropdown/CustomSelect";
import {Input} from "../../../../../components/FormInput/styled";
import {TableIconButton} from "../../../../../components/commonStyled";
import {ReactComponent as Arrow} from "../../../../../assets/Icons/arrowDropdown.svg";
import {ReactComponent as Trash} from "../../../../../assets/Icons/trash.svg";

import CheckBox from "../../../../../components/Checkbox";
import {handleKeyDown} from "../../../../../utils";

const Row = (
	{
		index,
		row,
		editMode,
		talentsNames,
		expensesCategories,
		setExpenses,
		expenses,
		withoutReimbursed,
		handleSaveData,
		keyIsAmount,
		setShowModalForDelete,
		isTimeLogsPage,
		setActiveRowIndex,
		isPermittedEdit,
		isPermittedDelete
	}
)=> {
	const handleSelectItemContractor = (value, index) => {
		const newExpenses = JSON.parse(JSON.stringify(expenses));
		newExpenses[index].contractor_id = value._id;
		newExpenses[index].name = value.full_name;
		setExpenses(newExpenses);
		handleSaveData(newExpenses[index], index, 'contractor_id');
	};
	const handleSelectItemExpensesCategories = ({value}, index) => {
		const newExpenses = JSON.parse(JSON.stringify(expenses));
		newExpenses[index].expense_type = value;
		setExpenses(newExpenses);
		handleSaveData(newExpenses[index], index, 'expense_type');
	};
	
	const handleChanges = (e, index, fieldName, maxValueLength = 5, isNumber = true) => {
		let value = e.target.value;
		if (isNumber) {
			if (value?.includes('.') && value?.split('.')[1]?.length > 2) return;
		}
		if (value.split('.')[0]?.length > maxValueLength) return;
		const newExpenses = JSON.parse(JSON.stringify(expenses));
		newExpenses[index][fieldName] = isNumber ? value * 1 : value;
		setExpenses(newExpenses);
	};
	
	const handleChangeReimbursed = (index) => {
		const newExpenses = JSON.parse(JSON.stringify(expenses));
		newExpenses[index].reimbursed = !newExpenses[index].reimbursed;
		setExpenses(newExpenses);
	};
	
	const handleDeleteItem = (index) => {
		if (isTimeLogsPage) {
			setShowModalForDelete(true);
			setActiveRowIndex(index);
		} else {
			setExpenses(state => state.filter((row, i) => i !== index));
		}
	};
	
	return (
		<tr>
			<td style={{overflow: 'visible', width: '18%'}} >
				<CustomSelect
					name={'contactors'}
					ref={null}
					options={talentsNames}
					defaultValue={row?.contractor_id}
					icon={<Arrow/>}
					marginRight='0px'
					fontSize='12px'
					height='28px'
					handleOnChange={(value) => handleSelectItemContractor(value, index)}
					isSearchable={false}
					sizeIcon='6px'
					disabled={!editMode || !isPermittedEdit}
				/>
			</td>
			<td style={{overflow: 'visible', width: '18%'}}>
				<CustomSelect
					name={'expensesCategories'}
					ref={null}
					options={expensesCategories}
					defaultValue={row?.expense_type}
					icon={<Arrow/>}
					marginRight='0px'
					fontSize='12px'
					height='28px'
					handleOnChange={(value) => handleSelectItemExpensesCategories(value, index)}
					isSearchable={false}
					sizeIcon='6px'
					disabled={!editMode || !isPermittedEdit}
					textTransform={'capitalize'}
				/>
			</td>
			<td style={{textAlign: 'right', maxWidth: '74px', width: '74px'}}>
				<Input
					value={keyIsAmount ? row?.amount || '' : row?.price || ''}
					type={'number'}
					textAlign={'right'}
					onChange={(e) => handleChanges(e, index, keyIsAmount ? 'amount' :'price', 6)}
					disabled={!editMode || !isPermittedEdit}
					placeholder={'0'}
					placeholderColor={'var(--up-main-dark)'}
					onBlur={()=>handleSaveData(row, index, keyIsAmount ? 'amount' :'price')}
					onKeyDown={handleKeyDown}
					onWheel={(e) => e.target.blur()}
				/>
			</td>
			<td style={{width: '260px'}} title={row?.note || ''}>
				<Input
					value={row?.note || ''}
					type={'text'}
					onChange={(e) => handleChanges(e, index, 'note', 50, false)}
					disabled={!editMode || !isPermittedEdit}
					placeholderColor={'var(--up-main-dark)'}
					onBlur={()=>handleSaveData(row, index, 'note')}
				/>
			</td>
			{!withoutReimbursed && <td style={{ width: '10%'}}>
				{row?.expense_type === 'sales_commission' || row?.expense_type === 'consulting_fees' ? null :
					<CheckBox
						inputProps={
							{
								setValue: () => null,
								id: `reimbursed-${index}`,
								disabled: !editMode || !isPermittedEdit,
							}
						}
						defaultValue={row?.reimbursed}
						label={'Reimbursed'}
						isReactHookForm={false}
						handleChangeParentState={() => handleChangeReimbursed(index)}
						padding='0 0 0 12px'
					/>}
			</td>}
			<td style={{textAlign: 'right', fontWeight: '500', width: '10%'}} className={'with-bg'}>
				$ {keyIsAmount ? row?.amount || 0 : row?.price || 0}
			</td>
			<td style={{textAlign: 'center', width: '34px'}} className='action trash-icon with-bg'>
				{isPermittedDelete && <TableIconButton disabled={!editMode} onClick={() => handleDeleteItem(index)}>
					<Trash/>
				</TableIconButton>}
			</td>
		</tr>
	)
}

export default Row;