import styled, {css} from "styled-components";

export const RadioButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  line-height: 15px;
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  margin: ${({margin}) => margin || '0'};
  
  input {
    display: none;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
    background: var(--up-page-bg);
  }
	
  ${({disabled}) => !disabled && css`
    &:hover {
      &:before {
        border: 1px solid var(--up-main-blue);
      }
    }
  `}

  ${({checked, disabled}) => checked && !disabled && css`
			&:hover {
        &:before {
          border: 4px solid var(--up-secondary-blue);
        }
      }
  `}

  ${({checked}) => checked && css`
    &:before {
      border: 4px solid var(--up-main-blue);
      background: var(--up-white);
    }
  `}
`;