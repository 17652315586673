import styled from 'styled-components';
import {FlexBox} from "../commonStyled";

export const StepsWrap = styled(FlexBox)`
	width: auto;
	margin-top: ${({marginTop}) => marginTop || '0'};
`;

export const Step = styled(FlexBox)`
	color: ${({isActive, isPassed}) => isActive || isPassed ? 'var(--up-main-blue)' : 'var(--up-main-grey)'};
	span {
		font-size: 16px;
		color: currentColor;
		font-weight: 500;
	}
`;

export const StepNumber = styled(FlexBox)`
  width: 69px;
  height: 69px;
	font-size: 24px;
	color: currentColor;
	border: 1px dashed currentColor;
	border-radius: 50%;
	cursor: pointer;
	background-color: ${({isActive, isPassed}) => isActive || isPassed ? 'var(--up-blue-p100)' : 'var(--up-white)'};
`;
