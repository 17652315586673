import {useDispatch} from "react-redux";
import {useRef, useState, useLayoutEffect, useCallback} from "react";
import AvatarEditor from "react-avatar-editor";
import {FlexBox} from "../commonStyled";
import {InfoBlock, InputRange, InputRangeButton, PreviewImage} from "./styled";
import {acceptableLogoFormats, maxSizeLogo} from "../../utils";
import commonActions from "../../actions/commonActions";
import {ReactComponent as Plus} from "../../assets/Icons/plus.svg";
import {ReactComponent as Minus} from "../../assets/Icons/minus.svg";
import {ReactComponent as Move} from "../../assets/Icons/move.svg";

import TextBox from "../TextBox";
import Loader from "../Loader";
import Button from "../Button";
import axios from "axios";

const LogoCrop = (
	{
		src,
		handleCloseModal,
		setValue,
		name,
		setImage,
		setSrc
	}
) => {
	const [imageForAvatarEditor, setImageForAvatarEditor] = useState(src);
	const [previewImage, setPreviewImage] = useState(null);
	const [scale, setScale] = useState(50);
	const [loading, setLoading] = useState(false);
	const avatarEditorRef = useRef(null);
	const inputRef = useRef(null);
	const dispatch = useDispatch();
	
	const fetchDataURL = useCallback(async () => {
		if (src) {
			// Check if src is already a valid data URI
			if (src.startsWith("data:image")) {
				setImageForAvatarEditor(src);
				//we need to wait for the image because AvatarEditor sets bad image in first render
				setTimeout(() => {
					setPreviewImage(src);
				}, 100);
			} else {
				// Fetch the image as a Blob using Axios
				try {
					const response = await axios.get(src + (src.startsWith("blob") ? '' :"?random=test"),
						{responseType: 'blob', crossOrigin: 'anonymous'}
					);
					const blob = response.data;
					const reader = new FileReader();
					
					reader.onloadend = () => {
						const base64String = reader.result;
						setImageForAvatarEditor(base64String);
						//we need to wait for the image because AvatarEditor sets bad image in first render
						setTimeout(() => {
							setPreviewImage(base64String);
						}, 100);
					};
					
					reader.readAsDataURL(blob);
				} catch (error) {
					dispatch(commonActions.setPopupMessage('Error fetching the image', 'error'));
				}
			}
		}
	}, [src, dispatch]);
	
	useLayoutEffect(() => {
		setLoading(true);
		dispatch(commonActions.setHiddenScroll(true));
		fetchDataURL().then(() => {
			setLoading(false);
		});
		return () => {
			dispatch(commonActions.setHiddenScroll(false));
		}
	}, [fetchDataURL, dispatch]);
	
	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file.size > maxSizeLogo) {
			dispatch(commonActions.setPopupMessage('File size is too big', 'error'));
		} else if (!acceptableLogoFormats.includes(file?.type)) {
			dispatch(commonActions.setPopupMessage('File format is not acceptable', 'error'));
		} else {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImageForAvatarEditor(reader.result)
				//we need to wait for the image because AvatarEditor sets bad image in first render
				setTimeout(() => {
					setPreviewImage(reader.result)
				}, 100)
			};
			if (file) {
				reader.readAsDataURL(file);
			}
		}
	};
	const handleAvatarChange = () => {
		const canvas = avatarEditorRef.current.getImageScaledToCanvas();
		const editedImage = canvas.toDataURL();
		setPreviewImage(editedImage);
	};
	
	const handleMinus = (e) => {
		e.preventDefault();
		if (scale > 10) {
			setScale(scale - 2.5);
		}
	};
	const handlePlus = (e) => {
		e.preventDefault();
		if (scale < 100) {
			setScale(scale + 2.5);
		}
	};
	const handleRemove = (e) => {
		e.preventDefault();
		setImageForAvatarEditor(null);
		//we need to wait for the image because AvatarEditor sets bad image in first render
		setTimeout(() => {
			setPreviewImage(null)
		}, 100)
	};
	
	const handleUpload = (e) => {
		e.preventDefault();
		inputRef.current.click();
	};
	
	const handleSave = (e) => {
		e.preventDefault();
		setValue(name, previewImage);
		setImage(previewImage);
		setSrc(previewImage);
		handleCloseModal(e);
	};
	
	return (
		<div>
			<TextBox as='h2' size='md' weight='medium' marginBottom='24px'>Edit Logo</TextBox>
			<FlexBox
				justifyContent={'space-between'}
				margin='0 0 16px'
				alignItems='center'
			>
				{previewImage && <PreviewImage
					src={previewImage || src}
					alt="Preview"
					crossOrigin="anonymous"
				/>}
				<input
					ref={inputRef}
					style={{display: 'none'}}
					type="file"
					onChange={handleImageChange}
					accept="image/png, image/jpg, image/jpeg, image/svg+xml, image/svg"
				/>
				<FlexBox
					as='div'
					alignItems='center'
					gap='24px'
				>
					<Button
						label={'Upload Logo'}
						onClick={handleUpload}
						ghost={true}
					/>
					<Button
						label={'Remove Logo'}
						onClick={handleRemove}
						ghost={true}
					/>
				</FlexBox>
			</FlexBox>
			<FlexBox
				justifyContent={'center'}
				margin='0 0 24px'
			>
				{loading ?
					<Loader position='static' width={'400px'} height={'400px'}/>
					: <AvatarEditor
						image={imageForAvatarEditor}
						ref={avatarEditorRef}
						width={400}
						height={400}
						border={[75, 1]}
						color={[129, 142, 156, 0.5]}
						scale={scale / 50}
						rotate={0}
						borderRadius={200}
						onImageChange={handleAvatarChange}
						crossOrigin="anonymous"
					/>}
				<InfoBlock
					as='div'
					alignItems='center'
					gap='8px'
					justifyContent='center'
				>
					<Move/>
					Drag to Reposition
				</InfoBlock>
			</FlexBox>
			<FlexBox
				justifyContent={'center'}
				alignItems='center'
				margin='0 0 24px'
				gap='16px'
			>
				<InputRangeButton onClick={handleMinus}>
					<Minus/>
				</InputRangeButton>
				<InputRange
					id="typeinp"
					type="range"
					min="10"
					max="100"
					value={scale}
					onChange={(e) => setScale(e.target.value * 1)}
					step="2.5"
					progress={scale - 5}
				/>
				<InputRangeButton onClick={handlePlus}>
					<Plus/>
				</InputRangeButton>
			</FlexBox>
			<FlexBox
				as='div'
				alignItems='center'
				gap='24px'
				justifyContent='space-between'
			>
				<Button
					label={'Cancel'}
					onClick={handleCloseModal}
					ghost={true}
				/>
				<Button
					label={'Save Changes'}
					onClick={handleSave}
				/>
			</FlexBox>
		</div>
	);
}

export default LogoCrop;