import { useState } from "react";
import { contactsTableColumns } from "../../utils";
import { FlexBox } from "../../../../components/commonStyled";
import { useNavigate } from "react-router-dom";

import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import TableRowContacts from "./TableRowContacts";
import ModalWrap from "../../../../components/ModalWrap";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import { useDispatch } from "react-redux";
import clientsActions from "../../../../actions/clientsActions";
import commonActions from "../../../../actions/commonActions";
import TextBox from "../../../../components/TextBox";

const TableContacts = ({
  contacts,
  isPermittedForNotes,
  isPermittedManageContact,
  setShowModalAddContact,
  activeClientID,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const handleClick = (id) => {
    navigate(`${id}`);
    dispatch(
      commonActions.setCustomSizeOfModal({ width: "827px", height: "auto" })
    );
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedContact(null);
  };
  const handleDeleteContact = () => {
    dispatch(clientsActions.deleteContact(activeClientID, selectedContact._id));
  };

  // const handleExportAll = () => {
  //   dispatch(clientsActions.exportAllContacts(activeClientID));
  // };

  return (
    <>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 10px"
      >
        <TextBox as="h2" size="md" weight="medium">
          Contacts
        </TextBox>
        <FlexBox alignItems="center" gap="12px">
          {isPermittedManageContact && contacts?.total > 0 && (
            <Button
              label={"Contact"}
              gap="12px"
              withIcon={true}
              padding="7px 35px"
              flexDirection="row-reverse"
              handleClick={() => setShowModalAddContact(true)}
              fontSize="16pxs"
              // minWidth='234px'
            />
          )}
        </FlexBox>
      </FlexBox>
      <Table
        childrenHeader={contactsTableColumns.map((column, index) => {
          const isHidden =
            (column.key === "notes" && !isPermittedForNotes) ||
            (column.dataIndex === "delete" && !isPermittedManageContact);
          return isHidden ? null : (
            <th key={column.key + "-" + index} className={column.key}>
              {column.title}
            </th>
          );
        })}
        data={contacts.items}
      >
        {contacts.items.map((contact, index) => (
          <TableRowContacts
            key={contact._id + "-" + index}
            contact={contact}
            isPermittedForNotes={isPermittedForNotes}
            handleClick={handleClick}
            setSelectedContact={setSelectedContact}
            setShowModal={setShowModal}
            isPermittedManageContact={isPermittedManageContact}
            activeClientID={activeClientID}
          />
        ))}
      </Table>
      {showModal && (
        <ModalWrap
          paddingTop="75px"
          close={handleCloseModal}
          children={
            <ConfirmationModal
              handleConfirm={handleDeleteContact}
              closeModal={handleCloseModal}
              title="Delete Contact"
              message={`Are you sure you want to remove the ${selectedContact?.name}?`}
              buttonText="Yes, Delete"
            />
          }
        />
      )}
    </>
  );
};

export default TableContacts;
