import {FlexBox} from "../commonStyled";
import TextBox from "../TextBox";
import {AddressText} from "./styled";

const CardInfoDescription = (
	{
		website,
		state,
		city,
		zip_code,
		country,
		street,
		phone_number,
	}
) => {
	const allAddressValuesAreEmpty = !street && !city && !state && !zip_code && !country;
	const address = allAddressValuesAreEmpty ? '-' :
		<>
			{(street || '') + ' '}
			{(city || '') + ' '}
			{(state || '') + ' '}
			{(zip_code || '') + ' '}
			{country || ''}
		</>;
	const linkToWebsite = website ? website.includes('http') ? website : 'https://' + website : null;
	
	return (
		<FlexBox flexDirection='column' gap='6px' width={'100%'} overflow={'hidden'}>
			<FlexBox gap='6px'>
				<TextBox as='span' color='var(--up-main-grey)' size='xsm' lineHeight='18px'>Phone:</TextBox>
				<TextBox as='span' size='xsm' lineHeight='18px'>{phone_number || '-'}</TextBox>
			</FlexBox>
			<FlexBox gap='6px' width={'100%'} overflow={'hidden'}>
				<TextBox as='span' color='var(--up-main-grey)' size='xsm' lineHeight='18px'>Address:</TextBox>
				<AddressText
					as='span'
					size='xsm'
					lineHeight='18px'
				>
					{address}
				</AddressText>
			</FlexBox>
			<FlexBox gap='6px' width={'100%'} overflow={'hidden'}>
				<TextBox as='span' color='var(--up-main-grey)' size='xsm' lineHeight='18px'>Website:</TextBox>
				<TextBox
					as='span'
					size='xsm'
					lineHeight='18px'
					onClick={e => e.stopPropagation()}
					style={
						{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}
					}
				>
					{website ?
						<a href={linkToWebsite} target='_blank' rel="noreferrer" style={{fontSize: 'inherit'}}>{website}</a> : '-'}
				</TextBox>
			</FlexBox>
		</FlexBox>
	);
}

export default CardInfoDescription;