import styled, {css} from 'styled-components';

const getFontSize = (size) => {
	switch (size) {
		case 'xxxxl':
			return '68px';
		case 'xxxl':
			return '48px';
		case 'xxl':
			return '36px';
		case 'xl':
			return '30px';
		case 'lg':
			return '26px';
		case 'md':
			return '20px';
		case 'xmd':
			return '18px';
		case 'xxmd':
			return '16px';
		case 'sm':
			return '14px';
		case 'xsm':
			return '12px';
		default:
			return '14px';
	}
};

const getColor = (color) =>
	color === 'dark' ? 'var(--up-main-dark)' : color;

export const TextBox = styled.span`
  font-size: ${({size, customSize}) => customSize ? customSize : getFontSize(size)};
  color: ${({color}) => getColor(color)};
  margin: ${({margin}) => margin};
  font-weight: ${({weight}) => weight === 'medium' ? 500 : 400};
	text-transform: ${({textTransform})=> textTransform};
	margin-bottom: ${({marginBottom})=> marginBottom};
	text-align: ${({textAlign})=> textAlign};
	line-height: ${({lineHeight})=> lineHeight};
	width: ${({width})=> width};
  ${(props) => {
    if (props.withEllipsis) {
      return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
				width: 100%;
			`;
    }
  }}
`;
