import {useState} from "react";
import {FlexBox} from "../../../../../components/commonStyled";
import TextBox from "../../../../../components/TextBox";
import Button from "../../../../../components/Button";
import HeaderTitle from "../../../../../components/HeaderTitle";
import ProfitSharingTable from "./ProfitSharingTable";
import {memo} from "react";
import ModalWrap from "../../../../../components/ModalWrap";
import ConfirmationModal from "../../../../../components/modals/ConfirmationModal";

const ProfitSharing = memo((
	{
		editMode,
		profitSharing,
		setProfitSharing,
		grossProfitMargin,
		talentsNames
	}
) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<FlexBox
			flexDirection={'column'}
			padding={'0 12px'}
			width={'100%'}
		>
			<FlexBox
				justifyContent='space-between'
				alignItems='center'
				width={'100%'}
				marginBottom={'0 0 8px'}
			>
				<TextBox
					size={'xxmd'}
					weight={'medium'}
					lineHeight={'28px'}
					as={'h2'}
				>
					Profit Sharing
				</TextBox>
				<Button
					label={'Delete Profit'}
					ghost={true}
					handleClick={() =>setShowModal(true)}
					disabled={!editMode}
				/>
			</FlexBox>
			<FlexBox gap={'20px'} width={'100%'} alignItems={'center'}>
				<ProfitSharingTable
					profitSharing={profitSharing}
					setProfitSharing={setProfitSharing}
					editMode={editMode}
					grossProfitMargin={grossProfitMargin}
					talentsNames={talentsNames}
				/>
				<FlexBox justifyContent='center' flex={'1 1'}>
					<HeaderTitle
						label='Project Profit '
						disabled={true}
						value={grossProfitMargin}
						justifyContent={'center'}
						inputFontSize={'48px'}
						maxWidth={'none'}
					/>
				</FlexBox>
			</FlexBox>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={()=>setShowModal(false)}
					children={
						<ConfirmationModal
							handleConfirm={() => setProfitSharing([])}
							closeModal={()=>setShowModal(false)}
							title='Delete Profit Sharing'
							message={`Are you sure you want to delete all profit sharing?`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</FlexBox>
	)
})

export default ProfitSharing