import {FlexBox} from "../commonStyled";
import {ReactComponent as Icon} from "../../assets/Icons/successfully.svg";

import TextBox from "../TextBox";
import Button from "../Button";
import {ReactComponent as IconClose} from "../../assets/Icons/close.svg";
import {ButtonClose} from "../ModalWrap/styled";

const ModalSuccessfully = ({ handleClick, title, message }) => {
	return (
		<>
			<ButtonClose onClick={handleClick}><IconClose/></ButtonClose>
			<FlexBox flexDirection='column' gap='24px' padding='18px 0 0' alignItems='center'>
				<Icon/>
				<FlexBox flexDirection='column' gap='8px' alignItems='center'>
					<TextBox as='h2' size='md' weight='medium' lineHeight='28px'>{title}</TextBox>
					<TextBox as='p' size='sm' lineHeight='20px'>{message}</TextBox>
				</FlexBox>
				<Button label='Got it' handleClick={handleClick}/>
			</FlexBox>
		</>
	)
}

export default ModalSuccessfully;