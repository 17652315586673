import {
	Container,
	FlexBox,
	ImgWithAbsolutePosition,
	StatusDropdown,
} from "../../components/commonStyled";
import {ReactComponent as Cat} from "../../assets/Icons/allProjectsCat.svg";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import projectsActions from "../../actions/projectsActions";

// import DatePicker from "../../components/CalendarRange";
import Search from "../../components/Search";
import Button from "../../components/Button";
import CustomPagination from "../../components/Pagination";
import ProjectsTable from "./components/ProjectsTable";
import CustomSelect from "../../components/SelectDropdown/CustomSelect";
import {statusOptions} from "../../utils";
import TextBox from "../../components/TextBox";

const ProjectsList = ({isPermittedCreateNewProject}) => {
	const {projectsData, clientsNames, clientFilter, statusFilter, sortBy} = useSelector(state => state.projectsPageReducer);
	const {searchInputValue} = useSelector(state => state.commonReducer);
	const dispatch = useDispatch();
	const onPageChange = (data) => {
		dispatch(projectsActions.getProjectsData(data));
	};
	
	const applyClientFilter = (data) => {
		dispatch(projectsActions.setClientFilter(data));
		dispatch(projectsActions.getProjectsData({
			page: 1,
			page_size: projectsData.page_size,
			query_str: searchInputValue
		}));
	};
	
	const applyStatusFilter = (data) => {
		dispatch(projectsActions.setStatusFilter(data));
		dispatch(projectsActions.getProjectsData({
			page: 1,
			page_size: projectsData.page_size,
			query_str: searchInputValue
		}));
	};
	
	return (
		<FlexBox as='div' flexDirection='column' height='100%' width='100%' padding='20px 20px 0'>
			<Container overflow='hidden'>
				<FlexBox justifyContent='space-between' alignItems='flex-start' margin='0 0 24px'>
					<TextBox as='h2' size='md' weight='medium' lineHeight='35px'>Projects</TextBox>
					<FlexBox gap='12px' flexWrap='wrap-reverse' justifyContent='flex-end'>
						<FlexBox gap='12px'>
							<StatusDropdown width='210px'>
								<CustomSelect
									name={'client_company_id'}
									ref={null}
									options={clientsNames}
									isSearchable={false}
									classNameContainer={`status`}
									isMulti={true}
									handleOnChange={(value) => console.log(value)}
									handleOnBlur={applyClientFilter}
									closeMenuOnSelect={false}
									placeholder='Filter by Client'
									optionSelectedFromReducer={clientFilter?.values}
									customHeight='36px'
								/>
							</StatusDropdown>
							<StatusDropdown width='210px'>
								<CustomSelect
									name={'status'}
									ref={null}
									options={statusOptions}
									isSearchable={false}
									classNameContainer={`status`}
									isMulti={true}
									handleOnChange={(value) => console.log(value)}
									closeMenuOnSelect={false}
									placeholder='Filter by Status'
									handleOnBlur={applyStatusFilter}
									optionSelectedFromReducer={statusFilter?.values}
									customHeight='36px'
								/>
							</StatusDropdown>
						</FlexBox>
						<FlexBox gap='12px'>
							<Search
								onPageChange={onPageChange}
								page={projectsData?.page}
								page_size={projectsData?.page_size}
								placeholder='Search by Project Name'
							/>
							{isPermittedCreateNewProject &&
							<NavLink to='/projects/create-project' style={{textDecoration: 'none'}}>
								<Button
									label='Project'
									gap='12px' withIcon={true}
									padding='7px 34px'
									flexDirection='row-reverse'
									fontSize='16px'
								/>
							</NavLink>
							}
						</FlexBox>
					</FlexBox>
				</FlexBox>
				{/*<FlexBox margin='0 0 8px'>*/}
					{/*<DatePicker/>*/}
				{/*</FlexBox>*/}
				<ProjectsTable
					projectsData={projectsData}
					searchInputValue={searchInputValue}
					sortBy={sortBy}
					isPermittedCreateNewProject={isPermittedCreateNewProject}
				/>
			</Container>
			<ImgWithAbsolutePosition as={Cat} top={'42px'} right={'265px'}/>
			{projectsData?.items.length > 0 &&
			<CustomPagination
				totalItems={projectsData?.items_count}
				pageSize={projectsData?.page_size}
				page={projectsData?.page}
				onPageChange={onPageChange}
			/>}
		</FlexBox>
	)
}

export default ProjectsList;