import { useState } from "react";
import { BlockWithAbsolutePosition, BorderBox, FlexBox, TableIconButton } from "../commonStyled";
import { downloadFile, getIconForFile } from "../../utils";
import * as S from "../CardInfo/styled";
import { DocumentWrapper } from "./styled";
import { usePopperTooltip } from "react-popper-tooltip";
import { ReactComponent as Dots } from "../../assets/Icons/dotsVertical.svg";
import { ReactComponent as Trash } from "../../assets/Icons/trash.svg";
import { ReactComponent as Download } from "../../assets/Icons/download.svg";

import TextBox from "../TextBox";
import ModalWrap from "../ModalWrap";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useDispatch } from "react-redux";
import documentsActions from "../../actions/documentsActions";
import commonActions from "../../actions/commonActions";
const Document = (
	{
		item,
		format,
		parentId,
		scope,
		disabled,
		setUploadedDocuments,
		isOldDocument,
		setDocuments,
		newDocuments,
		updateFormState,
		userName,
		dateUploaded,
		previewDoc
	}
) => {
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		trigger: 'click',
		placement: 'top-end',
		interactive: true,
	});

	const handleDeleteOldDocument = () => {
		dispatch(documentsActions.deleteDocument(scope, parentId, item._id)).then(
			() => {
				dispatch(commonActions.setPopupMessage('Document Successfully Deleted', 'success'));
				setShowModal(false);
				setUploadedDocuments(state => state.filter(doc => doc._id !== item._id));
			}
		)
			.catch(
				() => {
					setShowModal(false);
				}
			)
	};

	const handleDeleteNewDocument = () => {
		const filteredDocuments = newDocuments.filter(doc => doc?.encoded_file !== item?.encoded_file);
		setDocuments(filteredDocuments);
		updateFormState(filteredDocuments);
		setShowModal(false);
	}
	const handleDeleteDocument = (e) => {
		e.preventDefault();
		if (isOldDocument) {
			handleDeleteOldDocument();
		} else {
			handleDeleteNewDocument();
		}
	};

	const handleCloseModal = (e) => {
		e.preventDefault();
		setShowModal(false);
	};

	const handleOpenModal = (e) => {
		e.preventDefault();
		setShowModal(true);
	}

	const handlePreviewClick = (path) => {
		if (path && previewDoc) {
			window.open(path, "", "width=800,height=800,scrollbars=yes,resizable=yes");
		}
	};

	console.log('item', item)
	return (
		<>
			<DocumentWrapper
				as={'li'}
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
				gap='6px'
				width='100px'
				onClick={e => e.stopPropagation()}
			>
				{disabled ? null : <BlockWithAbsolutePosition
					top={'6px'}
					right={'6px'}
					onClick={e => e.stopPropagation()}
					width={'24px'}
					height={'24px'}
				>
					<TableIconButton ref={setTriggerRef} visible={visible} onClick={e => e.preventDefault()}>
						<Dots />
					</TableIconButton>
				</BlockWithAbsolutePosition>}
				<BorderBox
					width='100%'
					justifyContent='center'
					padding='19px 0'
					cursor='pointer'
					boxShadow={'var(--main-box-shadow)'}
					boxShadowHover={'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'}
					onClick={() => handlePreviewClick(item?.src)}
				>
					{getIconForFile(format)}
				</BorderBox>
				<FlexBox
					flexDirection='column'
					width='100%'
				>
					<TextBox
						as={'h4'}
						withEllipsis={true}
						title={item?.name}
						color={'var(--up-main-grey)'}
						lineHeight={'20px'}
						textAlign={'center'}
					>
						{item?.name || item?.filename}
					</TextBox>
					<TextBox
						as={'h4'}
						title={item?.name}
						color={'var(--up-main-light-grey)'}
						lineHeight={'20px'}
						textAlign={'center'}
						size={'xsm'}
					>
						{dateUploaded + ' by ' + userName}
					</TextBox>
				</FlexBox>

				{visible && (
					<S.TooltipContainer
						ref={setTooltipRef}
						{...getTooltipProps({ className: 'tooltip-container' })}
					>
						{isOldDocument && <div>
							<S.TooltipItem
								alignItems='center'
								gap='8px'
								onClick={() => downloadFile(item?.src, item?.name)}
							>
								<Download />
								<TextBox as='span' size='sm' lineHeight='20px'>Download</TextBox>
							</S.TooltipItem>
						</div>}
						<div>
							<S.TooltipItem
								as='button'
								alignItems='center'
								gap='8px'
								onClick={handleOpenModal}
							>
								<Trash />
								<TextBox as='span' size='sm' lineHeight='20px'>Delete</TextBox>
							</S.TooltipItem>
						</div>
					</S.TooltipContainer>
				)}
			</DocumentWrapper>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={handleCloseModal}
					children={
						<ConfirmationModal
							handleConfirm={handleDeleteDocument}
							closeModal={handleCloseModal}
							title='Delete Document'
							message={`Are you sure you want to remove the ${item?.name || item?.filename}?`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</>
	);
}

export default Document;