import { combineReducers } from "redux";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import clientsPageReducer from "./clientsPageReducer";
import projectsPageReducer from "./projectsPageReducer";
import talentsPageReducer from "./talentsPageReducer";
import documentsTabReducer from "./documentsTabReducer";
import economicsTabReducer from "./economicsTabReducer";
import invoicingTabReducer from "./invoicingTabReducer";
import profilePageReducer from "./profilePageReducer";
import timeLogsTabReducer from "./timeLogsTabReducer";

const Reducers = combineReducers({
	authReducer,
	commonReducer,
	clientsPageReducer,
	projectsPageReducer,
	talentsPageReducer,
	documentsTabReducer,
	economicsTabReducer,
	invoicingTabReducer,
	profilePageReducer,
	timeLogsTabReducer,
});
export default Reducers;