import commonActions from "./commonActions";
import {exportDataToComputer, getErrorMessages} from "../utils";
import {authenticatedRequest} from "../APIGateway/gateway";

const economicsActions = {
	getEconomicsData(projectId, type = 'staffing_model') {
		return dispatch => {
			dispatch({
				type: 'SET_ECONOMICS_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetEconomicsData',
				method: 'get',
				paths: [projectId, type?.replace('_', '-')],
			}).then((response) => {
					dispatch({
						type: 'SET_ECONOMICS_DATA',
						payload: response.data,
					});
					dispatch({
						type: 'SET_ECONOMICS_DATA_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_ECONOMICS_DATA_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	getExpensesCategories(projectId) {
		return dispatch => {
			dispatch({
				type: 'SET_EXPENSES_CATEGORIES_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetExpensesCategories',
				method: 'get',
				paths: [projectId],
			}).then((response) => {
					dispatch({
						type: 'SET_EXPENSES_CATEGORIES',
						payload: response.data?.expense_types.map((item) => {
							return {
								value: item,
								label: item.replace('_', ' '),
								textTransform: 'capitalize',
							}
						}) || null,
					});
					dispatch({
						type: 'SET_EXPENSES_CATEGORIES_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_EXPENSES_CATEGORIES_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	updateEconomicsData(projectId, type, data) {
		return dispatch => {
			dispatch({
				type: 'SET_ECONOMICS_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'UpdateEconomicsData',
				method: 'post',
				paths: [projectId, type?.replace('_', '-')],
				data: data,
			}).then((response) => {
					dispatch({
						type: 'SET_ECONOMICS_DATA',
						payload: response.data,
					});
					dispatch(commonActions.setPopupMessage('Economics data updated successfully', 'success'));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_ECONOMICS_DATA_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	downloadEconomicsFile(projectId, type) {
		return dispatch => {
			authenticatedRequest({
				url: 'DownloadEconomicsFile',
				method: 'get',
				paths: [projectId, type?.replace('_', '-')],
				isBlob: true,
			}).then((response) => {
				exportDataToComputer(response);
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
				}
			);
		}
	}
}

export default economicsActions;