import {FlexBox} from "../../../../components/commonStyled";
import TextBox from "../../../../components/TextBox";
import Button from "../../../../components/Button";
import {formatTitle} from "../../../../utils";
import {usePermission} from "../../../../hooks/usePermission";
const Header = ({invoicingType, handleAddInvoice})=> {
	const isPermittedAddInvoice = usePermission(
		['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']
	);
	return (
		<FlexBox justifyContent={'space-between'} alignItems={'center'} margin={'0 0 30px'}>
			<TextBox as='h1' size='md' weight='medium' color='dark' lineHeight='28px'>
				Invoicing
				<TextBox
					as='span'
					size='md'
					weight='medium'
					color='var(--up-secondary--light-blue)'
					lineHeight='28px'
					style={{
						marginLeft: '8px',
						textTransform: 'capitalize'
					}}
				>
					({formatTitle(invoicingType)})
				</TextBox>
			</TextBox>
			{isPermittedAddInvoice && <Button
				label={'Add Invoice'}
				gap='12px'
				withIcon={true}
				padding='7px 35px'
				flexDirection='row-reverse'
				handleClick={handleAddInvoice}
				fontSize='16px'
			/>}
		</FlexBox>
	)
}

export default Header;