import {FlexBox} from "../../../../../components/commonStyled";
import HeaderTitle from "../../../../../components/HeaderTitle";
import {sumItems} from "../utils";
import {memo} from "react";
import {reducingNumber} from "../../../../../utils";

const SumBlockOfExpenses = memo(({expenses, total}) => {
	const sumCommissions = reducingNumber(sumItems(expenses.filter(item => item.expense_type === 'sales_commission' || item?.expense_type === 'consulting_fees'), 'price'));
	const sumReimbursed = reducingNumber(sumItems(expenses.filter(item => item.reimbursed && item.expense_type !== 'sales_commission' && item.expense_type !== 'consulting_fees'), 'price'));
	const sumUnReimbursed = reducingNumber(sumItems(expenses.filter(item => !item.reimbursed && item.expense_type !== 'sales_commission' && item.expense_type !== 'consulting_fees'), 'price'));
	return (
		<FlexBox
			justifyContent='space-between'
			alignItems='center'
			width={'100%'}
			padding={'20px'}
			style={{
				background: 'var(--up-white)',
				borderRadius: '6px',
				border: '1px solid var(--up-light-blue-p400)',
				boxShadow: 'var(--main-box-shadow)',
			}}
		>
			<HeaderTitle
				label='Commissions / Referral Fees'
				disabled={true}
				value={sumCommissions}
			/>
			<HeaderTitle
				label='Reimbursed Expenses'
				disabled={true}
				value={sumReimbursed}
			/>
			<HeaderTitle
				label='Unreimbursed Expenses '
				disabled={true}
				value={sumUnReimbursed}
			/>
			<HeaderTitle
				label='Total'
				disabled={true}
				value={total}
			/>
		</FlexBox>
	)
})

export default SumBlockOfExpenses;