import * as S from './styled';
import {ReactComponent as LoaderSvg} from "../../assets/Icons/loader.svg";

const Loader = (
	{
		height = '100vh',
		width = '100vw',
		position = 'static',
		background = 'none',
	}
) => {
	return (
		<S.LoaderWrap height={height} width={width} position={position} background={background}>
			<S.CircleLoader as={LoaderSvg}/>
		</S.LoaderWrap>
	)
}

export default Loader;