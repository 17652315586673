import {useState} from "react";
import {useSelector} from "react-redux";
import {FlexBox} from "../../../../../components/commonStyled";
import {
	configStylesForEconomicsTable,
	expensesTableColumns, initialExpense,
	sumItems
} from "../utils";
import {EconomicsTableWrapper} from "../styled";

import TextBox from "../../../../../components/TextBox";
import Button from "../../../../../components/Button";
import Table from "../../../../../components/Table";
import Row from "./Row";
import {memo} from "react";
import ModalWrap from "../../../../../components/ModalWrap";
import ConfirmationModal from "../../../../../components/modals/ConfirmationModal";
import {TotalTd} from "../../../../../components/Table/styled";
import {reducingNumber} from "../../../../../utils";

const Expenses = memo((
	{
		expenses,
		setExpenses,
		editMode,
		talentsNames,
		withoutReimbursed = false,
		titleSize = 'xxmd',
		withButtonDeleteAll = true,
		handleSaveData = (data, indexRow, keyField) => console.log(data, indexRow, keyField),
		newExpense = initialExpense,
		keyIsAmount = false,
		setShowModalForDelete,
		isTimeLogsPage = false,
		setActiveRowIndex,
		isPermittedEdit = true,
		isPermittedDelete = true,
	}
) => {
	const {expensesCategories} = useSelector(state => state.economicsTabReducer);
	const [showModal, setShowModal] = useState(false);
	return (
		<FlexBox
			flexDirection='column'
			gap='12px'
			width='100%'
		>
			<FlexBox
				justifyContent='space-between'
				alignItems='center'
				width={'100%'}
				marginBottom={'0 0 8px'}
			>
				<TextBox
					size={titleSize}
					weight={'medium'}
					lineHeight={'28px'}
					as={'h2'}
				>
					Expenses
				</TextBox>
				{withButtonDeleteAll && <Button
					label={'Delete Expenses'}
					ghost={true}
					handleClick={() => setShowModal(true)}
					disabled={!editMode}
				/>}
			</FlexBox>
			<EconomicsTableWrapper
				tdBackground={'var(--up-main-grayish)'}
				padding={'0'}
				widthColumn={'auto'}
			>
				<Table
					childrenHeader={
						expensesTableColumns.map((column, index) => {
							if (withoutReimbursed && column.key === 'reimbursed') return null;
							return <th key={column.key + '-' + index} className={column.key}
												 style={{textAlign: column?.textAlign || 'left', width: column?.width}}>
								{column.title}
							</th>
						})
					}
					data={expenses}
					{...configStylesForEconomicsTable}
				>
					{
						expenses.map((row, index) => (
							<Row
								key={row._id + '-' + index}
								row={row}
								index={index}
								editMode={editMode}
								setExpenses={setExpenses}
								expenses={expenses}
								talentsNames={talentsNames}
								expensesCategories={expensesCategories}
								withoutReimbursed={withoutReimbursed}
								handleSaveData={handleSaveData}
								keyIsAmount={keyIsAmount}
								setShowModalForDelete={setShowModalForDelete}
								isTimeLogsPage={isTimeLogsPage}
								setActiveRowIndex={setActiveRowIndex}
								isPermittedEdit={isPermittedEdit}
								isPermittedDelete={isPermittedDelete}
							/>
						))
					}
					<tr>
						<td style={{position: 'relative', overflow: 'visible'}}>
							<Button
								label={'Add Expense'}
								gap='12px'
								withIcon={true}
								padding='10px 35px'
								flexDirection='row-reverse'
								handleClick={() => setExpenses(state => [...state, newExpense])}
								fontSize='16px'
								ghost={true}
								style={{margin: '5px 16px', position: 'absolute', left: '0', top: '0'}}
								width={'200px'}
								sizeIcon={'16px'}
								disabled={!editMode || !isPermittedEdit}
							/>
						</td>
					</tr>
					<tr className='with-border-radius'>
						<td className={'with-bg'}></td>
						<td className={'with-bg'}></td>
						<td className={'with-bg'}></td>
						<td className={'with-bg'}></td>
						{!withoutReimbursed && <td className={'with-bg'}></td>}
						<TotalTd className={'with-bg'}
										 style={{position: 'relative'}}>
							Total: $ {reducingNumber(sumItems(expenses, keyIsAmount ? 'amount' : 'price'))}
						</TotalTd>
						<td className={'with-bg'}></td>
					</tr>
				</Table>
			</EconomicsTableWrapper>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={() => setShowModal(false)}
					children={
						<ConfirmationModal
							handleConfirm={() => setExpenses([])}
							closeModal={() => setShowModal(false)}
							title='Delete Expenses'
							message={`Are you sure you want to delete all expenses?`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</FlexBox>
	)
});

export default Expenses;