import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Link} from "react-router-dom";
import * as S from "./styled";
import * as CS from "../../components/commonStyled";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import authActions from "../../actions/authActions";
import {validationSchemaForEmail} from "../../utils";
import {ReactComponent as Cat} from "../../assets/Icons/cat1.svg";

import TextBox from "../../components/TextBox";
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import {useEffect} from "react";

const schema = yup.object().shape({
	email: validationSchemaForEmail(),
});

const RecoveryPassword = () => {
	const {register, handleSubmit, formState: {errors}} = useForm({
		resolver: yupResolver(schema)
	});
	const dispatch = useDispatch();
	const {
		requestForPasswordRecoveryWasSent,
		requestForPasswordRecoveryError
	} = useSelector(state => state.authReducer);
	const onSubmit = (data) => {
		const email = data.email;
		dispatch(authActions.requestToRecoveryPassword({email}));
	};
	
	useEffect(() => {
		return () => {
			dispatch(authActions.resetRequestToRecoveryPassword());
		}
	}, [dispatch]);
	
	return (
		<S.Form onSubmit={handleSubmit(onSubmit)}>
			<TextBox size={'lg'} as={'h2'} color='dark'>
				Password Recovery
			</TextBox>
			<TextBox as='h3' size='sm'>Please enter your email, and we will send you further instructions.</TextBox>
			<FormInput register={register} errors={errors} name={'email'}/>
			{requestForPasswordRecoveryWasSent && <TextBox as='p' size='sm'>
				Email sent. Please check your email to reset your password.
			</TextBox>}
			{requestForPasswordRecoveryError &&
			<TextBox as='p' size='sm' color='var(--up-main-red)'>{requestForPasswordRecoveryError}</TextBox>}
			<Button
				label={requestForPasswordRecoveryWasSent ? 'Submit Again' : 'Submit'}
				type="submit"
				fullWidth={true}
				padding='12px 15px'
			/>
			<S.Link as={Link} to={'/auth/login'} textalign={'center'} fontSize='14px'>Back to Sign In</S.Link>
			<CS.ImgWithAbsolutePosition as={Cat} top={'63px'} right={'103px'}/>
		</S.Form>
	);
}

export default RecoveryPassword;