import * as S from './styled';
import {ReactComponent as Arrow} from "../../assets/Icons/arrowDropdown.svg";
import {ReactComponent as ArrowPagination} from "../../assets/Icons/arrowPagination.svg";

import CustomSelect from "../SelectDropdown/CustomSelect";
import {FlexBox} from "../commonStyled";
import Pagination from "react-js-pagination";

import TextBox from "../TextBox";
import {useSelector} from "react-redux";

const CustomPagination = (
	{
		totalItems = 0,
		onPageChange,
		pageSize = 12,
		page = 1,
		options = [
			{value: 12, label: 12},
			{value: 24, label: 24},
			{value: 36, label: 36},
			{value: 48, label: 48}
		],
		position = 'absolute',
		width = '100%'
	}
) => {
	const {searchInputValue} = useSelector(state => state.commonReducer);
	
	const handlePageChange = (pageNumber) => {
		onPageChange({
			page: pageNumber,
			page_size: pageSize,
			query_str: searchInputValue
		});
	};
	const handlePageSizeChange = ({value}) => {
		onPageChange({
			page: 1,
			page_size: value,
			query_str: searchInputValue
		});
	};
	
	return (
		<S.CustomPagination alignItems='center' justifyContent='space-between' padding='0 20px' position={position} width={width}>
			<div>
				<TextBox as='span' color='var(--up-main-grey)' size='xsm'>
					{page * pageSize - (pageSize - 1)} - {(page * pageSize > totalItems ? totalItems : page * pageSize) + ' '}
					out of
					{' ' + totalItems}
				</TextBox>
			</div>
			<S.Paginate>
				<Pagination
					classNamePrefix="custom-pagination"
					activePage={page}
					itemsCountPerPage={pageSize}
					totalItemsCount={totalItems}
					pageRangeDisplayed={3}
					onChange={handlePageChange}
					className='pagination-bar'
					firstPageText=''
					lastPageText=''
					prevPageText={<S.ArrowPagination><ArrowPagination/></S.ArrowPagination>}
					nextPageText={<S.ArrowPagination rotate={'true'}><ArrowPagination/></S.ArrowPagination>}
				/>
			</S.Paginate>
			<FlexBox alignItems='center' gap='10px'>
				<S.PaginationSelectDropdown>
					<CustomSelect
						name={'pagination'}
						ref={null}
						options={options}
						defaultValue={pageSize.toString()}
						menuPlacement={'top'}
						icon={<Arrow/>}
						marginRight='0px'
						fontSize='12px'
						height='28px'
						handleOnChange={handlePageSizeChange}
						isSearchable={false}
						sizeIcon='6px'
					/>
				</S.PaginationSelectDropdown>
				<TextBox as='span' color='var(--up-main-grey)' size='xsm'>
					per page
				</TextBox>
			</FlexBox>
		</S.CustomPagination>
	)
}

export default CustomPagination;