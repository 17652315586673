import {useState} from "react";
import {configStylesForEconomicsTable} from "../Economics/utils";
import {EconomicsTableWrapper} from "../Economics/styled";
import {timeLogsTableColumns} from "./utils";

import TextBox from "../../../../components/TextBox";
import Table from "../../../../components/Table";
import TimeLogRow from "./TimeLogRow";
import {hoursToHoursAndMinutes, reducingNumber} from "../../../../utils";

const TimeLog = (
	{
		timeLog = [],
		projectId,
		timeLogId
	}
) => {
	const [timeLogsState, setTimeLogsState] = useState(timeLog);
	const isEmpty = timeLogsState?.length === 0;
	return (
		<>
			<TextBox
				as={'h3'}
				weight='medium'
				color='dark'
				lineHeight='20px'
			>
				Time Log
			</TextBox>
			<EconomicsTableWrapper
				tdBackground={'var(--up-main-grayish)'}
				padding={'0'}
				paddingTd={'15px 16px'}
				// widthColumn={withoutReimbursed ? '24%' : '19%'}
			>
				<Table
					childrenHeader={
						timeLogsTableColumns.map((column, index) => {
							return <th key={column.key + '-' + index} className={column.key}
												 style={{textAlign: column?.textAlign || 'left'}}>
								{column.title}
							</th>
						})
					}
					data={timeLogsState}
					{...configStylesForEconomicsTable}
					noDataText={'You have no team members'}
					heightBanner={'20vh'}
				>
					{
						timeLogsState.map((row, index) => (
							<TimeLogRow
								key={row?.name + index}
								timeLogsState={timeLogsState}
								index={index}
								timeLog={row}
								setTimeLogState={setTimeLogsState}
								projectId={projectId}
								timeLogId={timeLogId}
							/>
						))
					}
					{!isEmpty && <tr className='with-border-radius'>
						<td className={'with-bg'}></td>
						<td className={'with-bg'} style={{position: "relative"}}>
							<TextBox
								as={'h3'}
								weight='medium'
								color='dark'
								lineHeight='20px'
								style={{
									position: 'absolute',
									top: '50%',
									left: '16px',
									transform: 'translateY(-50%)',
									textAlign: 'right'
								}}
							>
								Total
								Time: {hoursToHoursAndMinutes(timeLogsState?.reduce((acc, item) => acc + item?.logged_hours, 0))} h
							</TextBox>
						</td>
						<td className={'with-bg'}></td>
						<td className={'with-bg'}></td>
						<td className={'with-bg'} style={{position: "relative"}}>
							<TextBox
								as={'h3'}
								weight='medium'
								color='dark'
								lineHeight='20px'
								style={{
									position: 'absolute',
									top: '50%',
									right: '16px',
									transform: 'translateY(-50%)',
									textAlign: 'right'
								}}
							>
								Total Cost: $ {reducingNumber(timeLogsState?.reduce((acc, item) => acc + reducingNumber(item?.daily_rate / 8) * item?.logged_hours, 0))}
							</TextBox>
						</td>
					</tr>}
				</Table>
			</EconomicsTableWrapper>
		</>
	);
}

export default TimeLog;