import * as S from './styled';
import * as CS from "../../components/commonStyled";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {inputsSetUpAllUser, schemaSetUpAllUser} from "./utils";
import {useDispatch} from "react-redux";

import Button from "../../components/Button";
import GroupInputs from "../../components/GroupInputs";
import commonActions from "../../actions/commonActions";

const SetUpAllUsers = ({userData}) => {
	const {register, handleSubmit, formState: {errors}, control, setValue, getValues} = useForm({
		resolver: yupResolver(schemaSetUpAllUser)
	});
	
	const dispatch = useDispatch();
	
	const onSubmit = (data) => {
		dispatch(commonActions.setUpUserData(data));
	};
	
	return (
		<S.FormWrap as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'>
			<GroupInputs
				inputs={inputsSetUpAllUser}
				errors={errors}
				register={register}
				control={control}
				defaultData={userData || {}}
				setValue={setValue}
				getValues={getValues}
			/>
			<CS.FlexBox justifyContent='flex-end' width='100%'>
				<Button type='send' label='Save'/>
			</CS.FlexBox>
		</S.FormWrap>
	)
}

export default SetUpAllUsers;