import * as yup from "yup";
// import {ReactComponent as DashboardIcon} from "./assets/Icons/Widgets.svg";
import { ReactComponent as ProjectIcon } from "./assets/Icons/projects.svg";
import { ReactComponent as TalentIcon } from "./assets/Icons/talents.svg";
import { ReactComponent as ClientIcon } from "./assets/Icons/clients.svg";
// import {ReactComponent as PaymentIcon} from "./assets/Icons/payment.svg";
// import {ReactComponent as InvoiceIcon} from "./assets/Icons/invoice.svg";
import { ReactComponent as ProfileIcon } from "./assets/Icons/profile.svg";
import moment from "moment";

import { ReactComponent as OtherIcon } from "./assets/Icons/otherType.svg";
import { ReactComponent as ImgIcon } from "./assets/Icons/imgType.svg";
import { ReactComponent as XlsIcon } from "./assets/Icons/xlsType.svg";
import { ReactComponent as DocIcon } from "./assets/Icons/docType.svg";
import { ReactComponent as PDFIcon } from "./assets/Icons/pdfType.svg";
import { ReactComponent as Team } from "./assets/Icons/team.svg";
import * as xlsx from "xlsx";

export const phoneNumberRegex = /^\+\d \(\d{3}\) \d{3}-\d{4}$/;
export const codeClientRegex = /^[a-zA-Z0-9]{4,}$/;

export const validationSchemaForRequiredField = (fieldName) => {
  return yup.string().required(fieldName + " is required");
};
export const validationSchemaForClientCode = () => {
  return yup
    .string()
    .required("Client Code is required")
    .min(2, "Client Code must be at least 2 characters long")
    .max(4, "Client Code must not be longer than 4 characters")
    .test(
      "is-valid-input",
      "Client Code can contain only numbers and letters",
      (value) => {
        const validInputRegex = /^[a-zA-Z0-9]{2,}$/;
        return validInputRegex.test(value);
      }
    );
};
export const validationSchemaForPhoneNumber = (required) => {
  return yup
    .string()
    .test("phone-number", "Phone number is required", (value) => {
      return !(required && (!value || value.trim() === ""));
    })
    .test("phone-number", "Invalid phone number", (value) => {
      if (!value || value.trim() === "") {
        return true;
      }
      return phoneNumberRegex.test(value);
    });
};

export const validationSchemaForCode = () => {
  return yup.string().required("Code is required");
};

export const validationSchemaForPassword = () => {
  return yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(128, "Password must not be longer than 128 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~|\\-])/,
      "Password must include at least 1 lower case character, 1 upper case character, 1 number, and 1 special character"
    );
};

export const validationSchemaForRepeatPassword = () => {
  return yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords do not match");
};

export const validationSchemaForEmail = () => {
  return yup
    .string()
    .required("Email is required")
    .test("is-email", "Please enter a valid email address", (value) => {
      const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/;
      return emailRegex.test(value);
    });
};

export const pages = [
  [
    // {
    // 	name: 'Task Dashboard',
    // 	path: '/task-dashboard',
    // 	icon: <DashboardIcon/>,
    // },
    {
      name: "Clients",
      path: "/clients",
      icon: <ClientIcon />,
    },
    {
      name: "Projects",
      path: "/projects",
      icon: <ProjectIcon />,
      unavailableRoles: ["staffer"],
    },
    {
      name: "Talents",
      path: "/talents",
      icon: <TalentIcon />,
    },
    // {
    // 	name: 'Payments',
    // 	path: '/payments',
    // 	icon: <PaymentIcon/>,
    // },
    // {
    // 	name: 'Invoices',
    // 	path: '/invoices',
    // 	icon: <InvoiceIcon/>,
    // },
    {
      name: "Team",
      path: "/team",
      icon: <Team />,
      unavailableRoles: [
        "consultant",
        "staffer",
        "project_coordinator",
        "controller",
        "contract_specialist",
        "partner",
      ],
    },
    {
      name: "Profile",
      path: "/profile",
      icon: <ProfileIcon />,
    },
  ],
  [
    {
      name: "Projects",
      path: "/projects",
      icon: <ProjectIcon />,
    },
    {
      name: "Profile",
      path: "/profile",
      icon: <ProfileIcon />,
    },
  ],
];

export const getInitials = (name) => {
  const names = name.split(" ");
  return (
    (names[0]?.substring(0, 1).toUpperCase() || "") +
    (names[1]?.substring(0, 1).toUpperCase() || "")
  );
};

export const defaultQueryForGetData = {
  page: 1,
  page_size: 12,
  query_str: "",
  // sort_by: []
};

export const queryString = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
};

export const getRoleName = (role) => {
  switch (role) {
    case "super_admin":
      return "UpRamp Admin";
    case "company_admin":
      return "Company Admin";
    case "project_coordinator":
      return "Project Coordinator";
    case "contract_specialist":
      return "Contracts Expert";
    default:
      return role;
  }
};

export const calendarRanges = [
  {
    startDate: moment(),
    endDate: moment().add(7, "day"),
    key: "7 days",
  },
  {
    startDate: moment(),
    endDate: moment().add(30, "day"),
    key: "30 days",
  },
  {
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
    key: "Month",
  },
  {
    startDate: moment().startOf("month"),
    endDate: moment().add(2, "months").endOf("month"),
    key: "3 Months",
  },
  {
    startDate: moment().startOf("month"),
    endDate: moment().add(5, "months").endOf("month"),
    key: "6 Months",
  },
  {
    startDate: moment().startOf("month"),
    endDate: moment().endOf("year"),
    key: "Year",
  },
];

export const statusOptions = [
  {
    value: "in_progress",
    label: "In Progress",
    _id: "in_progress",
  },
  {
    value: "draft",
    label: "Draft",
    _id: "draft",
  },
  {
    value: "closed",
    label: "Closed",
    _id: "closed",
  },
  {
    value: "completed",
    label: "Completed",
    _id: "completed",
  },
  {
    value: "archived",
    label: "Archived",
    _id: "archived",
  },
];

export const generateNextCode = (codes, activeClientCode) => {
  if (!codes || codes.length === 0) return `${activeClientCode}-01`;
  const sortedCodes = codes.sort((a, b) => {
    const codeA = parseInt(a.code.split("-")[1]);
    const codeB = parseInt(b.code.split("-")[1]);
    return codeA - codeB;
  });

  const lastCode = sortedCodes[sortedCodes.length - 1].code;
  const codeLetters = lastCode.split("-")[0];
  const lastNumber = parseInt(lastCode.split("-")[1]);

  const nextNumber = lastNumber + 1;
  return `${codeLetters}-${nextNumber.toString().padStart(2, "0")}`;
};

export const splitCodeValue = (code) => {
  return code.split("-");
};

export const renderDay = (day) => {
  return (
    <div className="custom-day-cell">
      <div className="day-number">{day.format("D")}</div>
    </div>
  );
};

export const getErrorMessages = (error) => {
  const status = error?.response?.status;
  const messages = error?.response?.data?.errors;
  let text = "";
  if (!messages) return null;
  if (status === 422) {
    Object.keys(messages)?.map((key) => {
      return (text = text + ` ${messages[key][0]}`);
    });
  } else {
    if (typeof messages[0] === "string") return (text = messages[0]);
    messages[0]?.map((item) => {
      return (text = text + `${item.msg}`);
    });
  }
  return text;
};

export const getFilterQuery = (model, filters) => {
  if (filters.length === 0) return "";
  const filterSpec = filters.map((item) => {
    return {
      model: model,
      field: item.field,
      value: item.values.map((value) => {
        return value?._id || value.value;
      }),
    };
  });
  return `&filter_spec=${JSON.stringify(filterSpec)}`;
};

export const areArraysEqual = (array1 = [], array2 = []) => {
  if (!array1 && !array2) {
    return true;
  } else if (!array1 || !array2) {
    return false;
  } else {
    return (
      array1.every(
        (item, index) => JSON.stringify(item) === JSON.stringify(array2[index])
      ) && array1.length === array2.length
    );
  }
};

//check if the form data is changed
export const isFormChanged = (initialValues, values) => {
  const keys = Object.keys(initialValues);
  let isChanged = false;
  keys.forEach((key) => {
    if (key === "documents") {
      if (initialValues[key]?.length > 0) {
        isChanged = true;
      }
    } else if (
      typeof initialValues[key] === "object" &&
      initialValues[key] !== null
    ) {
      if (initialValues[key]?._id !== values[key]) {
        isChanged = true;
      }
    } else if (key === "logo") {
      if (initialValues[key] && initialValues[key] !== values[key]) {
        isChanged = true;
      }
    } else if (typeof initialValues[key] === "undefined") {
      if (values[key]) {
        isChanged = true;
      }
    } else if (key.includes("date")) {
      if (
        initialValues[key] !==
        moment(values[key], "MM/DD/YYYYTHH:mm:ssZ").format("M/D/Y")
      ) {
        isChanged = true;
      }
    } else {
      if (initialValues[key] !== values[key]) {
        isChanged = true;
      }
    }
  });
  return isChanged;
};

export const industryOptions = [
  {
    value: "IT",
    label: "IT",
    _id: "IT",
  },
  {
    value: "Finance",
    label: "Finance",
    _id: "Finance",
  },
  {
    value: "Marketing",
    label: "Marketing",
    _id: "Marketing",
  },
  {
    value: "Sales",
    label: "Sales",
    _id: "Sales",
  },
  {
    value: "Other",
    label: "Other",
    _id: "Other",
  },
];

export const positionOptions = [
  {
    value: "CEO/Owner",
    label: "CEO/Owner",
    _id: "CEO/Owner",
  },
  {
    value: "VP",
    label: "VP",
    _id: "VP",
  },
  {
    value: "Project/Delivery Manager",
    label: "Project/Delivery Manager",
    _id: "Project/Delivery Manager",
  },
  {
    value: "HR",
    label: "HR",
    _id: "HR",
  },
  {
    value: "Support Manager",
    label: "Support Manager",
    _id: "Support Manager",
  },
  {
    value: "Accountant",
    label: "Accountant",
    _id: "Accountant",
  },
  {
    value: "Controller",
    label: "Controller",
    _id: "Controller",
  },
];

export const acceptableLogoFormats = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
  "image/svg",
];

export const fetchImageFromUrlAsBase64 = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const isEmptyForm = (values, arrayOfExceptionKeys = []) => {
  const keys = Object.keys(values);
  let isEmpty = true;
  keys.forEach((key) => {
    if (values[key] && !arrayOfExceptionKeys.includes(key)) {
      isEmpty = false;
    }
  });
  return isEmpty;
};

export const getIconForFile = (format) => {
  if (format === "pdf") {
    return <PDFIcon className="format-icon" />;
  } else if (format === "doc") {
    return <DocIcon className="format-icon" />;
  } else if (format === "xlsx" || format === "xls") {
    return <XlsIcon className="format-icon" />;
  } else if (
    format === "jpg" ||
    format === "png" ||
    format === "jpeg" ||
    format === "svg"
  ) {
    return <ImgIcon className="format-icon" />;
  } else {
    return <OtherIcon className="format-icon" />;
  }
};

export function isInteger(number) {
  return number % 1 === 0;
}

export const convertSize = (size) => {
  if (size < 1000) {
    return size + " B";
  } else if (size < 1000000) {
    return (size / 1000).toFixed(0) + " KB";
  } else if (size < 1000000000) {
    let value = (size / 1000000).toFixed(1);
    return (isInteger(value) ? value * 1 : value) + " MB";
  } else {
    let value = (size / 1000000000).toFixed(1);
    return (isInteger(value) ? value * 1 : value) + " GB";
  }
};

export const getCurrency = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    default:
      return "$";
  }
};

export const talentStatusFilterOptions = [
  {
    value: "available",
    label: "Available",
    _id: "available",
  },
  {
    value: "booked",
    label: "Booked",
    _id: "booked",
  },
  {
    value: "busy",
    label: "Busy",
    _id: "busy",
  },
  {
    value: "break",
    label: "Break",
    _id: "break",
  },
];
export const talentStatusOptions = talentStatusFilterOptions.filter(
  (option) => option.value !== "coming_soon"
);

export const maxSizeLogo = 5 * 1024 * 1024; // 5 mb

export const formDataToArray = async (formData, docType = "cv") => {
  const dataArray = [];

  for (const [name, file] of formData.entries()) {
    const reader = new FileReader();
    console.log(name);
    const fileObjectPromise = new Promise((resolve) => {
      reader.onload = function (event) {
        const base64Content = event.target.result;

        const fileObject = {
          encoded_file: base64Content,
          filename: file.name,
          document_type: docType,
        };

        resolve(fileObject);
      };
    });

    reader.readAsDataURL(file);

    const fileObject = await fileObjectPromise;
    dataArray.push(fileObject);
  }

  return dataArray;
};

export const dataToXlxAndDownload = (data) => {
  const rows = data.split("\n");
  const header = rows.shift();
  const headers = header.split(",");
  const contacts = rows.map((row) => {
    const values = row.split(",");
    const contact = {};
    headers.forEach((header, index) => {
      contact[header] = values[index];
    });
    return contact;
  });
  const wb = xlsx.utils.book_new();
  const ws = xlsx.utils.json_to_sheet(contacts);
  xlsx.utils.book_append_sheet(wb, ws, "Contacts");
  xlsx.writeFile(wb, "contacts.xlsx");
};

export const formatTitle = (title) => {
  return title?.replaceAll("_", " ") || " ";
};

export const downloadFile = (src, name) => {
  fetch(src)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.click();
      URL.revokeObjectURL(url);
    });
};

export const reducingNumber = (number, numberOfCharactersAfterPeriod = 2) => {
  if (isNaN(number)) return 0;
  return isInteger(number)
    ? number
    : parseFloat(number.toFixed(numberOfCharactersAfterPeriod));
};

export const hoursToHoursAndMinutes = (hours) => {
  const hoursInt = parseInt(hours);
  const minutes = reducingNumber((hours - hoursInt) * 60, 0);
  return `${hoursInt}:${minutes < 10 ? "0" + minutes : minutes}`;
};

export const handleKeyDown = (
  e,
  listOfForbiddenKeys = ["e", "E", "-", "ArrowUp", "ArrowDown"]
) => {
  if (listOfForbiddenKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const statusOfDocumentOptions = [
  {
    value: "draft",
    label: "Draft",
    _id: "draft",
  },
  {
    value: "assigned",
    label: "Assigned",
    _id: "assigned",
  },
  {
    value: "voided",
    label: "Voided",
    _id: "voided",
  },
  {
    value: "executed",
    label: "Executed",
    _id: "executed",
  },
  {
    value: "active",
    label: "Active",
    _id: "active",
  },
];

export const exportDataToComputer = (response) => {
  const url = window.URL.createObjectURL(new Blob([response?.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();
}

export const invoicingDayOptions = [
  {
    value: 1,
    label: '1st of the month',
    _id: '1st',
  },
  {
    value: 15,
    label: '15th of the month',
    _id: '15th',
  },
  {
    value: 30,
    label: 'End of the month',
    _id: 'end',
  }
]