import ClientsList from "./ClientsList";
import NoData from "../../components/NoData";
import ModalWrap from "../../components/ModalWrap";
import CreateNewClientModal from "../../components/modals/CreateNewClientModal";
import {ReactComponent as IconCat} from "../../assets/Icons/crateElCat.svg";
import {useSelector} from "react-redux";
import {useState} from "react";
import {usePermission} from "../../hooks/usePermission";

const ClientsWrapper = () => {
	const {clientsData, createNewClientLoading} = useSelector(state => state.clientsPageReducer);
	const [showModal, setShowModal] = useState(false);
	const isPermittedCreateNewClient = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner']);
	return (
		<>
			{
				clientsData?.total > 0 ?
					<ClientsList
						clientsData={clientsData}
						setShowModal={setShowModal}
						clients={clientsData.items}
						isPermittedCreateNewClient={isPermittedCreateNewClient}
					/> :
					<NoData isPermittedButton={isPermittedCreateNewClient} handleClick={() => setShowModal(true)}/>
			}
			{showModal &&
			<ModalWrap
				children={<CreateNewClientModal closeModal={() => setShowModal(false)}/>}
				close={() => setShowModal(false)}
				icon={<IconCat/>}
				leftPositionIcon='calc(100% - 80px)'
				topPositionIcon='25px'
				loading={createNewClientLoading}
			/>}
		</>
	)
}

export default ClientsWrapper;