import {FlexBox, Link, TableIconButton, Tag} from "../../../components/commonStyled";
import {getCurrency, reducingNumber} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import {usePopperTooltip} from "react-popper-tooltip";
import talentsActions from "../../../actions/talentsActions";
import * as S from "../../../components/CardInfo/styled";
import {ReactComponent as Trash} from "../../../assets/Icons/trash.svg";
import {ReactComponent as Dots} from "../../../assets/Icons/dotsVertical.svg";

import Avatar from "../../../components/Avatar";
import TextBox from "../../../components/TextBox";
import PinUpload from "../../../components/PinUpload";

const TalentsTableRow = (
	{
		talent,
		setSelectedTalent,
		setShowModal,
		handleClick,
		isPermittedManageTalent
	}
) => {
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		trigger: 'click',
		placement: 'left-start',
		interactive: true,
	});
	const {talentsData} = useSelector(state => state.talentsPageReducer);
	const {searchInputValue} = useSelector(state => state.commonReducer);
	const dispatch = useDispatch();
	const cvIsAvailable = () => {
		return talent?.documents?.find(doc => doc.type === 'cv')
	};
	const handleReloadPage = () => {
		dispatch(talentsActions.getTalentsData({
			page: talentsData?.page || 1,
			page_size: talentsData?.page_size || 12,
			query_str: searchInputValue
		}));
	}
	const onDelete = () => {
		setShowModal(true);
		setSelectedTalent(talent);
	};
	//check if link has http or https and if not add https
	const linkToLinkedIn = talent?.linkedin_account ? talent?.linkedin_account?.includes('http') ? talent?.linkedin_account : 'https://' + talent?.linkedin_account : null;
	return (
		<tr onClick={() => isPermittedManageTalent ? handleClick(talent?._id) : null}>
			<td>
				<FlexBox alignItems='center' gap='10px'>
					<Avatar src={talent?.logo} name={talent?.full_name} width='32px' height='32px' sizeInitials='xsm'/>
					<span className="name" style={{lineHeight: '20px'}}>
						{talent?.full_name || '-'}
					</span>
				</FlexBox>
			</td>
			<td onClick={talent?.linkedin_account ? e => e.stopPropagation() : null}>
				<Link
					as={'a'}
					href={linkToLinkedIn || '-'}
					rel="noreferrer"
					target={'_blank'}
					alignItems='center'
					style={{pointerEvents: talent?.linkedin_account ? 'all' : 'none', height: '100%', cursor: 'pointer'}}
				>
					{talent?.linkedin_account || '-'}
				</Link>
			</td>
			<td>{talent?.email || '-'}</td>
			<td>
				{talent?.internal_hourly_rate ?
					getCurrency(talent?.currency) + ' ' + reducingNumber(talent?.internal_hourly_rate) : '-'}
			</td>
			<td>
				{talent?.internal_daily_rate ?
					getCurrency(talent?.currency) + ' ' + reducingNumber(talent?.internal_daily_rate) : '-'}
			</td>
			<td onClick={e => e.stopPropagation()}>
				<PinUpload
					isActive={cvIsAvailable()}
					isUploadNewVersionOfDocument={cvIsAvailable()}
					handleReloadPage={handleReloadPage}
					typeDoc={'cv'}
					objectType={'contractors'}
					data={talent}
					uploadOnlyOneFile={talent?.documents?.length === 0}
					documents={talent?.documents || []}
				/>
			</td>
			<td>
				<Tag tagName={talent?.status}>{talent?.status.replace('_', ' ') || '-'}</Tag>
			</td>
			<td className='action' onClick={e => e.stopPropagation()}>
				{isPermittedManageTalent && <TableIconButton ref={setTriggerRef} visible={visible}>
					<Dots/>
				</TableIconButton>}
				{visible && (
					<S.TooltipContainer
						ref={setTooltipRef}
						{...getTooltipProps({className: 'tooltip-container'})}
					>
						<div>
							<S.TooltipItem
								as='button'
								alignItems='center'
								gap='8px'
								onClick={onDelete}
							>
								<Trash/>
								<TextBox as='span' size='sm' lineHeight='20px'>Delete</TextBox>
							</S.TooltipItem>
						</div>
					</S.TooltipContainer>
				)}
			</td>
		</tr>
	);
}

export default TalentsTableRow;