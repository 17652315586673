import {authenticatedRequest} from "../APIGateway/gateway";
import commonActions from "./commonActions";
import {defaultQueryForGetData, getErrorMessages, queryString} from "../utils";

const invoicingActions = {
	getInvoices(projectId, payload = defaultQueryForGetData) {
		return (dispatch) => {
			dispatch({
				type: 'SET_INVOICING_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'GetInvoices',
				method: 'get',
				paths: [projectId],
				queryParams: queryString({...payload, page_size: 10000})
			}).then((response) => {
					dispatch({
						type: 'SET_INVOICING_DATA',
						payload: response.data,
					});
					dispatch({
						type: 'SET_INVOICING_DATA_LOADING',
						payload: false,
					});
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_INVOICING_DATA_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	addInvoice(projectId) {
		return (dispatch) => {
			dispatch({
				type: 'SET_INVOICING_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: 'AddInvoice',
				method: 'post',
				paths: [projectId],
			}).then(() => {
					dispatch(this.getInvoices(projectId));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_INVOICING_DATA_LOADING',
						payload: false,
					});
				}
			);
		}
	},
	editInvoice(projectId, invoiceId, payload) {
		return async (dispatch) => {
			try {
				await authenticatedRequest({
					url: `EditInvoice`,
					method: 'patch',
					paths: [projectId, invoiceId],
					data: payload,
				});
				return true;
			} catch (error) {
				dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
			}
		}
	},
	deleteInvoice(projectId, invoiceId) {
		return (dispatch) => {
			dispatch({
				type: 'SET_INVOICING_DATA_LOADING',
				payload: true,
			});
			authenticatedRequest({
				url: `DeleteInvoice`,
				method: 'delete',
				paths: [projectId, invoiceId],
			}).then(() => {
					dispatch(this.getInvoices(projectId));
				}
			).catch((error) => {
					dispatch(commonActions.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_INVOICING_DATA_LOADING',
						payload: false,
					});
				}
			);
		}
	}
}

export default invoicingActions;