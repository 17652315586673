import {useState} from "react";
import {useParams} from "react-router-dom";
import {TabsWrap} from "../../Projects/styled";
import {clientsTabs} from "../utils";

import Tabs from "../../../components/Tabs";
import Profile from "./Profile";
import ContactsWrapper from "./Contacts/ContactsWrapper";
import {useSelector} from "react-redux";
import DocumentsWrapper from "../../../components/DocumentsTab/DocumentsWrapper";

const ActiveClientTabs = () => {
	const {slug} = useParams();
	const [editMode, setEditMode] = useState(false);
	const {editModeContact} = useSelector(state => state.clientsPageReducer);
	
	return (
			<TabsWrap width='100%'>
				<Tabs
					tabs={clientsTabs}
					areTabsDisabled={editMode || editModeContact}
				>
					<Profile
						editMode={editMode}
						setEditMode={setEditMode}
						slug={slug}
					/>
					<DocumentsWrapper objectId={slug} objectType='client_companies'/>
					<ContactsWrapper activeClientID={slug}/>
				</Tabs>
			</TabsWrap>
	)
}

export default ActiveClientTabs;