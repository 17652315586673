import * as S from '../FormInput/styled';
import {withHookFormMask} from 'use-mask-input';
import {useEffect} from "react";

const InputWithMask = (
	{
		name,
		mask = '+9 (999) 999-9999',
		defaultValue = '',
		register,
		textTransform,
		error,
		disabled,
		setValue
	}
) => {
	useEffect(() => {
		if (defaultValue) {
			setValue(name, defaultValue);
		}
	}, [defaultValue, setValue, name]);
	return (
		<S.FormInputWrap>
			<S.InputWrap>
				<S.Input
					textTransform={textTransform}
					defaultValue={defaultValue}
					{...withHookFormMask(register(name),[mask])}
					error={error}
					disabled={disabled}
				/>
			</S.InputWrap>
		</S.FormInputWrap>
	);
};

export default InputWithMask;
