import styled from 'styled-components';
import { css } from 'styled-components';
import {FlexBox} from "../../components/commonStyled";

export const Container = styled.div`
  width: 1200px;
  min-height: 700px;
  height: calc(100vh - 220px);
  background: var(--up-white);
  padding-top: 100px;
  border: 1px solid var(--up-main-light-grey);
  box-shadow: 0 6px 6px 3px var(--up-container-box-shadow);
  border-radius: 10px;
  position: absolute;
`;

export const LogoWrap = styled.div`
	position: absolute;
	top: 20px;
	left: 15px;
	z-index: 2;
	`;

export const FormWrap = styled(FlexBox)`
	margin-left: 205px;
	margin-right: 205px;
	margin-top: 70px;
	`;

export const FormInputBox = styled.div`
  ${({ fullWidthInput, customWidth, margin }) => css`
		flex: ${customWidth || (fullWidthInput ? '1 1 100%' : '1 1 calc(50% - 10px)')};
		max-width: ${customWidth || (fullWidthInput ? '100%' : 'calc(50% - 10px)')};
		min-width: ${customWidth || (fullWidthInput ? '100%' : 'calc(50% - 10px)')};
		margin: ${margin || '0'};
	`};
`;