import styled, {css} from 'styled-components';
import {FlexBox} from "../commonStyled";

export const FormInputWrap = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: ${({width}) => width ? width : '100%'};
  position: relative;

  &.small-input {
    margin-top: 23px;

    input {
      width: 40px;
      padding-left: 3px;
      padding-right: 3px;
			text-align: center;
    }

    label {
      display: none;
    }
  }

  &.checkbox {
    flex-direction: row;
    align-items: center;
    gap: 10px;

    input {
      margin: 0;
    }

    > div {
      width: auto;
    }

    label {
      margin-bottom: 0;
      color: var(--up-main-dark);
    }
  }
`;

export const InputWrap = styled.div`
  width: 100%;
  position: relative;

  span {
    color: ${({disabledColor}) => disabledColor ? disabledColor : 'var(--up-main-dark)'};
  }
`;

export const ShowPasswordButton = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Code = styled(FlexBox)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  span {
    color: var(--up-main-grey);
  }

  .tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
    border-radius: 6px;
    background: var(--up-page-bg);
  }
`;

export const Input = styled.input`
  width: ${({width}) => width || '100%'};
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  font-weight: ${({fontWeight}) => fontWeight || '400'};
  padding: ${({padding}) => padding ? padding : '10.5px 12px'};
  border: 1px solid var(--up-main-light-grey);
  border-radius: 4px;
  transition: all .15s ease-in 0s;
  text-transform: ${({textTransform}) => textTransform};
  color: ${({isInputProjectCode}) => isInputProjectCode ? 'transparent' : 'var(--up-main-dark)'};
  text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};

  &::placeholder {
    color: ${({placeholderColor}) => placeholderColor ? placeholderColor : 'var(--up-main-grey)'};
  }

  &:disabled {
    cursor: text;
    border-color: var(--up-page-bg);
    background: transparent;
    color: ${({disabledColor}) => disabledColor};

    &.code {
      cursor: not-allowed;
    }
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      outline: none;
      border-color: var(--up-main-blue);
    }

    ${(props) => {
      if (props.error) {
        return css`
          border-color: var(--up-main-red);
        `;
      }
    }}
  }

  &[type='checkbox'] {
    width: 18px;
    height: 18px;

    &:checked {
      + label {
        color: red;
      }
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
`;
export const Label = styled.label`
  color: var(--up-main-grey);
  text-transform: ${({textTransform}) => textTransform || 'capitalize'};
  margin-bottom: 4px;
  line-height: 20px;
  cursor: text;

  span {
    padding: 4px 6px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:after {
    content: ${({isRequired}) => isRequired ? '" *"' : '""'};
    color: var(--up-main-red)
  }

  &.draft {
    span {
      background: var(--up-main-light-grey);
      color: var(--up-main-dark);
    }
  }

  &.assigned,
  &.in_progress {
    span {
      background: var(--up-main-light-green);
      color: var(--up-main-green);
    }
  }

  &.voided,
  &.closed {
    span {
      background: var(--up-main-light-red);
      color: var(--up-main-dark-red);
    }
  }
	&.completed,
  &.executed {
    span {
      background: #FFD390;
      color: #B0722F;
    }
  }
`;
export const ErrorMessage = styled.span`
  margin-top: ${({marginTopForError}) => marginTopForError};
  color: var(--up-main-red);
  font-size: 12px;
  position: ${({isStaticErrorMessage}) => isStaticErrorMessage ? 'static' : 'absolute'};
  top: 47px;
  left: ${({left}) => left || '0'};

  &.small-input {
    right: 0;
    width: 30vw;
    left: auto;
    text-align: right;
    margin: 0;
  }
`;

export const SpecialSymbol = styled.span`
  position: absolute;
  left: ${({left}) => left || '12px'};
  top: 53%;
  transform: translateY(-50%);
  font-weight: ${({fontWeight}) => fontWeight || '400'};
	color: ${({color}) => color || 'var(--up-main-dark)'};
`;

export const InputFileWrap = styled(FlexBox)`
  width: 100%;
  position: relative;
`;
export const InputFile = styled.input`
  width: 0;
	display: none;

  &:disabled {
    + label {
      cursor: default;
    }
  }

  &:not(:disabled) {
    &:focus {
      + label {
        .label-text {
          color: var(--up-main-blue-hover);
        }
      }
    }

    + label {
      &:hover {
        .label-text {
          color: var(--up-main-blue-hover);
        }
      }
    }
  }
`;

export const InputFileLabel = styled(FlexBox)`
  cursor: pointer;

  * {
    cursor: inherit;
  }

  img {
    width: auto;
    max-width: 100%;
  }

  svg {
    path {
      fill: var(--up-main-info);
    }
  }

  .label-text {
    color: var(--up-main-info);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: underline;
  }

  .label-description {
    padding: 4px 8px;
    background: var(--up-main-grayish);
    font-size: 10px;
    line-height: 12px;
  }
`;

export const Logo = styled(FlexBox)`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: var(--up-blue-p100);

  img {
    width: auto;
    max-width: 100%;
  }
`;
