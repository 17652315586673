import * as S from "./styled";
import {forwardRef, useState, useEffect, useCallback} from "react";
import {useDispatch} from "react-redux";
import projectsActions from "../../actions/projectsActions";

import Option from "./Option";
import DropdownArrowIcon from "./DropdownArrowIcon";
import OptionWithCheckbox from "./OptionWithCheckbox";
import ValueContainer from "./ValueContainer";
import {areArraysEqual} from "../../utils";

const CustomSelect = forwardRef((
	{
		name = 'select',
		options = [
			{value: 'option1', label: 'Option 1'},
			{value: 'option2', label: 'Option 2'},
			{value: 'option3', label: 'Option 3'},
		],
		field = {},
		defaultValue,
		menuPlacement = 'auto',
		placeholder = 'Select',
		icon,
		handleOnChange = null,
		isSearchable = true,
		classNameContainer = ' ',
		setValue,
		disabled = false,
		error,
		sizeIcon,
		customHeight,
		isMulti = false,
		closeMenuOnSelect = true,
		handleOnBlur = (e)=> {console.log('blur======>', e)},
		optionSelectedFromReducer = null,
		textTransform = 'none',
	}, ref) => {
	const dispatch = useDispatch();
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	//it's necessary in case when we pass defaultValue for select
	const findOption =
		options?.find(
			option => option?.value?.toString() === defaultValue?.toString() ||
				option?._id?.toString() === defaultValue?.toString()
		);
	
	const [valueState, setValueState] = useState(findOption);
	const [optionSelected, setOptionSelected] = useState(optionSelectedFromReducer);

	useEffect(() => {
		if (options?.length > 0) {
			setValueState(findOption || '');
		}
	}, [findOption, options]);
	
	const onChange = (value) => {
		field.onChange(value);
		setValueState(value);
		if (name === 'client_company_id') {
			dispatch(projectsActions.setActiveClientName(value));
		}
		if (name === 'contractor') {
			setValue('internal_daily_rate', value?.internal_daily_rate);
		}
	};
	
	const onChangeMulti = (selected) => {
		setOptionSelected(selected);
	}
	
	const onMenuOpen = () => {
		setMenuIsOpen(true);
	};
	
	const onMenuClose = () => {
		setMenuIsOpen(false)
	};
	
	const selectComponents = {
		Option: isMulti ? OptionWithCheckbox : Option,
		IndicatorSeparator: () => null,
		DropdownIndicator: () => disabled ? null :
			<DropdownArrowIcon menuIsOpen={menuIsOpen} icon={icon} size={sizeIcon} customHeight={customHeight}/>,
	};
	if (isMulti) {
		selectComponents.ValueContainer = ValueContainer;
	}
	
	const onBlur = useCallback(() => {
		if (optionSelected || optionSelectedFromReducer) {
			if (!areArraysEqual(optionSelected, optionSelectedFromReducer)) {
				handleOnBlur({
					field: name,
					values: optionSelected,
				});
			}
		}
	}, [handleOnBlur, name, optionSelected, optionSelectedFromReducer]);
	
	//this useEffect is necessary in case when we remove
	// option from select and menu is closed, and we need to call getProjects again
	useEffect(() => {
		if (optionSelectedFromReducer?.length !== optionSelected?.length && !menuIsOpen) {
			onBlur();
		}
	}, [optionSelected, menuIsOpen, optionSelectedFromReducer, onBlur]);
	return (
		<S.SelectContainer
			onClick={e => e.stopPropagation()}
			className={`container-select ${classNameContainer} ${valueState?.value} ${disabled ? ' disabled' : ''}`}
			isMulti={isMulti}
			textTransform={textTransform}
		>
			<S.CustomSelect
				{...field}
				classNamePrefix="custom-select"
				name={name}
				options={options}
				styles={S.customStyles}
				hideSelectedOptions={false}
				components={selectComponents}
				menuIsOpen={menuIsOpen}
				onMenuOpen={onMenuOpen}
				onMenuClose={onMenuClose}
				onClick={e => e.stopPropagation()}
				value={isMulti? optionSelected : valueState}
				menuPlacement={menuPlacement}
				placeholder={placeholder}
				onChange={isMulti ? onChangeMulti : handleOnChange ? handleOnChange : onChange}
				isSearchable={isSearchable}
				isDisabled={disabled}
				autoFocus={false}
				error={error}
				ref={ref}
				customHeight={customHeight}
				isMulti={isMulti}
				closeMenuOnSelect={closeMenuOnSelect}
				onBlur={isMulti ? onBlur : null}
				textTransform={valueState?.textTransform || textTransform}
				handleCloseMenu={onMenuClose}
			/>
		</S.SelectContainer>
	)
});

export default CustomSelect;