import axios from "axios";
import { AddressMap } from "./addressMap";

export const domain =
  process.env.REACT_APP_API_DOMAIN || "https://api.upramp.io";

export function authenticatedRequest({
  url,
  method,
  data,
  headers = {},
  queryParams = {},
  paths,
  isBlob = false,
}) {
  const apiURL = `${domain}${AddressMap(paths)[url]}`;
  const accessToken = localStorage.getItem("access_token");
  const urlWithQueryParams = new URL(apiURL);
  const searchParams = new URLSearchParams(queryParams);

  headers["Authorization"] = `Bearer ${accessToken}`;
  urlWithQueryParams.search = searchParams.toString();

  const requestConfig = {
    url: urlWithQueryParams.toString(),
    method,
    data,
    headers,
    responseType: isBlob ? "blob" : "json",
  };

  return axios(requestConfig).catch((error) => {
    if (error.response && error.response.status === 401 && url !== "LogIn") {
      return refreshToken().then(() => {
        const newAccessToken = localStorage.getItem("access_token");
        headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(requestConfig);
      });
    } else {
      throw error;
    }
  });
}

function refreshToken() {
  const payload = {
    refresh_token: localStorage.getItem("refresh_token"),
    token_type: "Bearer",
  };
  return axios
    .post(`${domain}${AddressMap()["RefreshToken"]}`, { ...payload })
    .then((response) => {
      localStorage.setItem("access_token", response.data.access_token);
    })
    .catch((error) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      throw error;
    });
}
