import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {profileInputs, profileInputsValidationSchema} from "./utils";
import {FlexBox, ImgWithAbsolutePosition,} from "../../components/commonStyled";
import {ButtonEdit, GroupButtons} from "../Projects/styled";
import {useDispatch, useSelector} from "react-redux";
import profileActions from "../../actions/profileActions";
import {ReactComponent as Pen} from "../../assets/Icons/pen.svg";
import {ReactComponent as Cat} from "../../assets/Icons/activeTalentCat.svg";

import GroupInputs from "../../components/GroupInputs";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import ModalWrap from "../../components/ModalWrap";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import TextBox from "../../components/TextBox";
// import {getRoleName} from "../../utils";

const Form = (
	{
		profileData,
		userId,
		imgPosition = {top: '100px', left: '480px'},
		isTeamMember = false,
		hasPermission = true,
	}
) => {
	const dispatch = useDispatch();
	const [inputs, setInputs] = useState(profileInputs);
	const {
		register,
		handleSubmit,
		formState: {errors},
		control,
		setValue,
		getValues,
		reset
	} = useForm({
		resolver: yupResolver(profileInputsValidationSchema)
	});
	const [showModal, setShowModal] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const {editProfileDataLoading} = useSelector(state => state.profilePageReducer);
	const onSubmit = (data) => {
		const payload = {
			logo: data?.logo,
			full_name: data?.full_name,
		}
		if (isTeamMember) {
			payload.role = data?.role;
			payload.phone_number = data?.phone_number;
		}
		dispatch(profileActions.editProfileData(userId, payload, isTeamMember));
		setEditMode(false);
	};
	
	const handleMoveFromEditMode = (e) => {
		e.preventDefault();
		const stateValues = getValues();
		if (stateValues?.logo !== undefined || stateValues?.full_name !== profileData?.full_name) {
			setShowModal(true);
		} else {
			setEditMode(false);
		}
	};
	
	const handleConfirm = (e) => {
		e.preventDefault();
		setShowModal(false);
		setEditMode(false);
		reset();
		dispatch(profileActions.getProfileData(userId));
	}
	useEffect(() => {
		setInputs(state => isTeamMember ? profileInputs :
			state.filter(item => item.name !== 'role' && item.name !== 'phone_number'));
	}, [isTeamMember]);
	return (
		<>
			<FlexBox justifyContent={'space-between'} margin={'20px 0 0'}>
				<TextBox as='h1' size='lg' color='dark' lineHeight='28px'>
					{profileData?.full_name}
				</TextBox>
				<FlexBox alignItems='center' gap='12px'>
					{hasPermission && <ButtonEdit
						as='button'
						onClick={editMode ? handleMoveFromEditMode : () => setEditMode(!editMode)}
						editMode={editMode}
						alignItems='center'
						justifyContent='center'
						style={{position: 'relative', zIndex: 3}}
					>
						<Pen/>
					</ButtonEdit>}
					{/*{isTeamMember ? <Tag tagName={profileData?.role}>{getRoleName(profileData?.role)}</Tag> : null}*/}
				</FlexBox>
			</FlexBox>
			<FlexBox margin={'30px 0 0'} as='form' onSubmit={handleSubmit(onSubmit)} flexDirection='column' gap='24px'
							 position='static'>
				{editProfileDataLoading &&
					<Loader position='absolute' width='100%' height='100%' background={'var(--up-white)'}/>}
				<GroupInputs
					inputs={inputs}
					errors={errors}
					register={register}
					control={control}
					gap='12px 12px'
					marginTopForError='20px'
					setValue={setValue}
					getValues={getValues}
					defaultData={profileData}
					disabled={!editMode}
				/>
				{(editMode) && <GroupButtons justifyContent='space-between' width='100%' withoutShadow={'true'}>
					<Button type='cancel' label='Cancel' ghost={true} handleClick={handleMoveFromEditMode}/>
					<Button type='send' label='Save'/>
				</GroupButtons>}
			</FlexBox>
			<ImgWithAbsolutePosition
				as={Cat}
				top={imgPosition?.top}
				right={'auto'}
				left={imgPosition?.left}
				width='154px'
				height='154px'
				mirror={'true'}
				zindex={'3'}
			/>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					children={
						<ConfirmationModal
							handleConfirm={handleConfirm}
							closeModal={() => setShowModal(false)}
							title={'Close the profile editing mode'}
							message={
								'All changes you added will be lost. Do you want to proceed?'
							}
							buttonText='Yes, Close'
						/>
					}
					close={() => setShowModal(false)}
				/>}
		</>
	)
}

export default Form;