import {authenticatedRequest} from "../APIGateway/gateway";
import {getErrorMessages} from "../utils";
// import {push} from "connected-react-router";

const commonActions = {
	setPopupMessage(message, type) {
		return {
			type: 'SET_POPUP_MESSAGE',
			payload: {
				message: message,
				type: type
			}
		}
	},
	
	closePopupMessage() {
		return {
			type: 'SET_POPUP_MESSAGE',
			payload: null
		}
	},
	
	setSearchInputValue(value) {
		return {
			type: 'SET_SEARCH_INPUT_VALUE',
			payload: value
		}
	},
	
	setUpUserData(data) {
		return dispatch => {
			dispatch({
				type: 'SET_UP_LOADING',
				payload: true
			});
			authenticatedRequest({
				url: 'SetupUser',
				method: 'post',
				data
			}).then(() => {
				dispatch({
					type: 'SET_UP_LOADING',
					payload: false
				});
				dispatch(this.setPopupMessage('Set up was successful', 'success'));
				dispatch(this.getUserSettings())
			}).catch((error) => {
					dispatch(this.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_UP_LOADING',
						payload: false
					});
				}
			);
		}
	},
	
	setUpContactorData(data) {
		return dispatch => {
			dispatch({
				type: 'SET_UP_LOADING',
				payload: true
			});
			authenticatedRequest({
				url: 'SetupContractor',
				method: 'post',
				data
			}).then(() => {
				dispatch({
					type: 'SET_UP_LOADING',
					payload: false
				});
				dispatch(this.setPopupMessage('Set up was successful', 'success'));
				dispatch(this.getUserSettings())
			}).catch((error) => {
					dispatch(this.setPopupMessage(getErrorMessages(error), 'error'));
					dispatch({
						type: 'SET_UP_LOADING',
						payload: false
					});
				}
			);
		}
	},
	
	getUserSettings() {
		return dispatch => {
			dispatch({
				type: 'SET_MAIN_LOADER',
				payload: true
			});
			authenticatedRequest({
				url: 'GetUserData',
				method: 'get',
			}).then((response) => {
					dispatch({
						type: 'SET_NEED_ON_BOARDING',
						payload: !response.data.onboarding
					})
					dispatch({
						type: 'SET_IS_CONTRACTOR',
						payload: response.data.role === 'contractor'
					});
					dispatch({
						type: 'SET_MAIN_LOADER',
						payload: false
					});
					dispatch({
						type: 'SET_USER_DATA',
						payload: response.data
					})
				}
			).catch(() => {
				dispatch({
					type: 'SET_MAIN_LOADER',
					payload: false
				});
			});
		}
	},
	updateUserSettings() {
		return dispatch => {
			dispatch({
				type: 'SET_USER_DATA_LOADING',
				payload: true
			});
			authenticatedRequest({
				url: 'GetUserData',
				method: 'get',
			}).then((response) => {
					dispatch({
						type: 'SET_USER_DATA',
						payload: response.data
					})
				dispatch({
					type: 'SET_EDIT_PROFILE_DATA_LOADING',
					payload: false,
				});
				}
			).catch(() => {
				dispatch({
					type: 'SET_EDIT_PROFILE_DATA_LOADING',
					payload: false,
				});
				dispatch({
					type: 'SET_USER_DATA_LOADING',
					payload: false
				});
			});
		}
	},
	setNavTitle(data) {
		return {
			type: 'SET_NAV_TITLE',
			payload: data
		}
	},
	setCustomSizeOfModal(data) {
		return dispatch => {
			dispatch({
				type: 'SET_CUSTOM_SIZE_OF_MODAL',
				payload: data
			})
		}
	},
	setActiveTab(tabIndex) {
		return dispatch => {
			dispatch({
				type: 'SET_ACTIVE_TAB',
				payload: tabIndex
			})
		}
	},
	setHiddenScroll(value) {
		return dispatch => {
			dispatch({
				type: 'SET_HIDDEN_SCROLL',
				payload: value
			})
		}
	}
}

export default commonActions;