import styled from "styled-components";
import check from "../../assets/Icons/check.svg";

export const CheckBox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
	&:disabled {
		+ label {
      cursor: text;
    }
	}
	+ label {
		cursor: pointer;
    margin: ${props => props.padding};
  }
  &:not(:disabled) {
    &:focus {
      &:checked {
        + label {
          &:before {
            border-color: var(--up-secondary-blue);
            background: var(--up-secondary-blue);
          }
        }
      }
      + label {
        &:before {
          border-color: var(--up-main-info);
        }
      }
    }
		+ label {
      &:hover,
      &:focus{
        &:before {
          border-color: var(--up-main-info);
        }
      }
    }
		&:checked {
			+ label {
        &:hover,
        &:focus {
          &:before {
            border-color: var(--up-secondary-blue);
            background: var(--up-secondary-blue);
          }
        }
      }
    }
	}
	
	&:checked {
		+ label {
			&:before {
				background-color: var(--up-main-info);
				border-color: var(--up-main-info);
      }
			&:after {
				opacity: 1;
      }
    }
  }
	
	+ label {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: ${props => props.isReverse ? 'row-reverse' : 'row'};
		
		&:before {
			content: '';
			display: inline-block;
			width: 18px;
			min-width: 18px;
			height: 18px;
			border: 1px solid var(--up-page-bg);
			border-radius: 4px;
			margin: ${props => props.isReverse ? '0 0 0 8px' : '0 8px 0 0'};
			background: var(--up-page-bg);
    }
		
		&:after {
			content: '';
			display: inline-block;
			width: 18px;
			min-width: 18px;
			height: 18px;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: ${props => props.isReverse ? 'auto' : '0'};
			right: ${props => props.isReverse ? '0' : 'auto'};
			transform: translateY(-50%);
      background-image: url(${check});
			background-repeat: no-repeat;
			background-position: center;
			opacity: 0;
			transition: opacity .2s ease-in-out;
			z-index: 1;
    }
  }
`;