import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  activeTalentInputs,
  schemaActiveTalentInputs,
} from "./activeTalentUtils";
import { ButtonEdit, GroupButtons } from "../../Projects/styled";
import { FlexBox, StickyHeader, Tag } from "../../../components/commonStyled";
import talentsActions from "../../../actions/talentsActions";
import { ReactComponent as Pen } from "../../../assets/Icons/pen.svg";

import TextBox from "../../../components/TextBox";
import GroupInputs from "../../../components/GroupInputs";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import ModalWrap from "../../../components/ModalWrap";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { isFormChanged } from "../../../utils";
const GeneralInformation = ({
  editMode,
  talentDetails,
  isPermittedManageTalent,
  setEditMode,
  isProjectsTalent,
  projectId,
  previewDoc,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schemaActiveTalentInputs),
  });
  const [showModal, setShowModal] = useState(false);
  const { updateTalentDetailsLoading } = useSelector(
    (state) => state.talentsPageReducer
  );
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(
      talentsActions.editTalent(
        talentDetails._id,
        data,
        true,
        projectId,
        isProjectsTalent
      )
    );
    setEditMode(false);
  };

  const handleEditMode = (e) => {
    if (editMode) {
      handleCancel(e);
    } else {
      setEditMode(true);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (isFormChanged(getValues(), talentDetails)) {
      setShowModal(true);
    } else {
      setEditMode(false);
    }
  };

  const handleConfirm = () => {
    reset();
    dispatch(
      talentsActions.getTalentDetails(
        talentDetails?._id,
        projectId,
        isProjectsTalent
      )
    );
    setShowModal(false);
    setEditMode(false);
  };

  console.log(talentDetails);
  return (
    <>
      <StickyHeader justifyContent={"space-between"}>
        <TextBox
          as="h1"
          size="md"
          weight="medium"
          color="dark"
          lineHeight="28px"
        >
          {talentDetails?.full_name}
        </TextBox>
        <FlexBox alignItems="center" gap="12px">
          {isPermittedManageTalent && (
            <ButtonEdit
              as="button"
              onClick={handleEditMode}
              editMode={editMode}
              alignItems="center"
              justifyContent="center"
              style={{ position: "relative", zIndex: 3 }}
            >
              <Pen />
            </ButtonEdit>
          )}
          <Tag tagName={talentDetails?.status}>
            {talentDetails?.status.replace("_", " ") || "-"}
          </Tag>
        </FlexBox>
      </StickyHeader>
      <FlexBox
        margin={"30px 0 0"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        flexDirection="column"
        gap="24px"
        position="static"
      >
        {updateTalentDetailsLoading && (
          <Loader
            position="absolute"
            width="100%"
            height="100%"
            background={"var(--up-white)"}
          />
        )}
        <GroupInputs
          inputs={activeTalentInputs}
          errors={errors}
          register={register}
          control={control}
          gap="12px 12px"
          marginTopForError="20px"
          setValue={setValue}
          getValues={getValues}
          defaultData={talentDetails}
          disabled={!editMode}
          previewDoc={previewDoc}
        />
        {editMode && (
          <GroupButtons justifyContent="space-between" width="100%">
            <Button
              type="cancel"
              label="Cancel"
              ghost={true}
              handleClick={handleCancel}
            />
            <Button type="send" label="Save" />
          </GroupButtons>
        )}
      </FlexBox>
      {showModal && (
        <ModalWrap
          paddingTop="75px"
          children={
            <ConfirmationModal
              handleConfirm={handleConfirm}
              closeModal={() => setShowModal(false)}
              title={"Close the editing process"}
              message={
                "All changes you added will be lost. Do you want to proceed?"
              }
              buttonText="Yes, Close"
            />
          }
          close={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default GeneralInformation;
