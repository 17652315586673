import {useDispatch, useSelector} from "react-redux";
import {useLayoutEffect} from "react";
import economicsActions from "../../../../actions/economicsActions";
import talentsActions from "../../../../actions/talentsActions";

import Invoice from "./Invoice";
import Header from "./Header";
import Loader from "../../../../components/Loader";
import timeLogsActions from "../../../../actions/timeLogsActions";
import NoData from "../../../../components/NoData";
import {NoDataWrapper} from "../../../Clients/styled";

const TimeLogs = ({projectId}) => {
	const dispatch = useDispatch();
	const {talentsNamesLoading, talentsNames} = useSelector(state => state.talentsPageReducer);
	const {timeLogsData, timeLogsDataLoading} = useSelector(state => state.timeLogsTabReducer);
	
	useLayoutEffect(() => {
		dispatch(economicsActions.getExpensesCategories(projectId));
		dispatch(talentsActions.getTalentsNames(true, projectId));
		dispatch(timeLogsActions.getTimeLogsData(projectId));
		return () => {
			dispatch(timeLogsActions.setFilters(null));
		}
	}, [projectId, dispatch]);
	
	if (talentsNamesLoading || timeLogsDataLoading) return <Loader width={'100%'} height={'100%'}/>
	return (
		<>
			<Header projectId={projectId}/>
			{timeLogsData?.items.length > 0 && talentsNames?.length > 0
				?
				<Invoice data={timeLogsData} projectId={projectId}/>
				:
				<NoDataWrapper>
					<NoData margin={'0'} text={talentsNames?.length === 0 ? 'You Have No Team Members Yet' : 'You Have No Time Logs Yet'}/>
				</NoDataWrapper>
			}
		</>
	);
}

export default TimeLogs;