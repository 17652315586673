import styled, { css } from 'styled-components';
import bgLogin from "../assets/img/loginBg.jpg";
import { TextBox } from "./TextBox/styled";

const getTagColors = (tagName) => {
  switch (tagName) {
    case 'consultant':
    case 'break':
      return {
        color: 'var(--up-main-dark-red)',
        background: '#FFA9BE',
      }
    case 'busy':
    case 'controller':
    case 'unverified':
      return {
        color: '#B0722F',
        background: '#FFD390',
      }
    case 'booked':
    case 'contract_specialist':
      return {
        color: 'var(--up-main-success)',
        background: '#D6FBF9',
      }
    case 'available':
    case 'company_admin':
      return {
        color: '#006279',
        background: '#82E5FF',
      }
    case 'verified':
    case 'coming_soon':
    case 'contractor':
    case 'project_coordinator':
      return {
        color: 'var(--up-main-green)',
        background: 'var(--up-main-light-green)',
      }
    case 'employee':
    case 'staffer': {
      return {
        color: '#2768CC',
        background: 'var(--up-light-blue-p400)',
      }
    }
    case 'super_admin': {
      return {
        color: '#11368A',
        background: '#C1B5FF',
      }
    }
    case 'partner':
      return {
        color: '#a432a8',
        background: '#fdc9ff',
      }
    default:
      return {
        color: 'var(--up-main-grey)',
        background: 'var(--up-main-light-grey)',
      }
  }
}
export const FlexBox = styled.div`
  width: ${({ width }) => width || 'auto'};
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: ${({ gap }) => gap || '0'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  position: ${({ position }) => position || 'relative'};
  padding: ${({ padding }) => padding || '0'};
  cursor: ${({ cursor }) => cursor || 'auto'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  overflow: ${({ overflow }) => overflow};
	flex: ${({ flex }) => flex};
`;

export const GridBox = styled.div`
  width: ${({ width }) => width || 'auto'};
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns || '1fr'};
  grid-template-rows: ${({ gridTemplateRows }) => gridTemplateRows || '1fr'};
  grid-column-gap: ${({ gridColumnGap }) => gridColumnGap || '0'};
  grid-row-gap: ${({ gridRowGap }) => gridRowGap || '0'};
`;

export const SectionWithBg = styled.section`
  height: 100vh;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ gap }) => gap || '0'};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${({ bgImg }) => bgImg ? `url(${bgImg})` : `url(${bgLogin})`};
    background-size: cover;
    z-index: -1;
  }
`;

export const ImgWithAbsolutePosition = styled.svg`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  transform: translate(100%, -100%) ${({ mirror }) => mirror ? 'scaleX(-1)' : 'scaleX(1)'};
	width: ${({ width }) => width || 'auto'};
	height: ${({ height }) => height || 'auto'};
  z-index: ${({ zindex }) => zindex || '2'};
`;

export const Container = styled.div`
  //margin: 20px 20px 0;
  padding: ${({ padding }) => padding || '24px'};
  background-color: var(--up-white);
  border: 1px solid var(--up-light-blue-p400);
  border-color: ${({ borderColor }) => borderColor || 'var(--up-light-blue-p400)'};
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};
  height: ${({ height }) => height || 'calc(100% - 45px)'};
  max-height: ${({ maxHeight }) => maxHeight || 'auto'};
  overflow: ${({ overflow }) => overflow || 'auto'};
  width: ${({ width }) => width || '100%'};
  position: ${({ position }) => position || 'relative'};
  z-index: ${({ zIndex }) => zIndex || 'auto'};
	margin: ${({ margin }) => margin};
	min-width: ${({ minWidth }) => minWidth};
`;

export const TitleWithGradient = styled(TextBox)`
  background: linear-gradient(180deg, #152047 0%, #013474 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Tooltip = styled.div`
  color: var(--up-white);
  background: ${({ backgroundTooltip }) => backgroundTooltip || 'var(--up-main-grey)'};
  padding: ${({ paddingTooltip }) => paddingTooltip || '4px 12px'};
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  border: none;

	&.tooltip-container {
		width: auto;
		max-width: ${({ widthTooltip }) => widthTooltip || 'auto'};
		height: auto;
		max-height: ${({ heightTooltip }) => heightTooltip || 'none'};
		overflow: ${({ overflow }) => overflow || 'auto'};
		//top: -5px!important;
	}
  .tooltip-arrow {
		display: ${({ displayArrow }) => displayArrow || 'block'};
    left: 50% !important;
    transform: translateX(-50%) !important;

    &:after {
      border-color: transparent transparent var(--up-main-grey) transparent !important;
      border-width: 0 0.25rem 0.4rem 0.25rem !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    &:before {
      content: none !important;
      border-width: 0 0.25rem 0.4rem 0.25rem !important;
    }
  }
`;

export const PinIconWrapper = styled.div`
	cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  svg {
    path {
      stroke: ${({ active }) => active ? 'var(--up-main-green)' : 'var(--up-main-grey)'};
    }
  }
`;

export const StatusDropdown = styled.div`
  width: ${({ width }) => width || 'auto'};
`;

export const TableIconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
	
	&:disabled {
		cursor: not-allowed;
  }

  svg {
		width: ${({ size }) => size || '20px'};
		height: ${({ size }) => size || '20px'};
		min-width: ${({ size }) => size || '20px'};
    path {
      ${(props) => {
    if (props.strokeSVG) {
      return css`
            stroke: ${({ visible }) => visible ? 'var(--up-main-info)' : 'var(--up-main-grey)'};
          `;
    } else {
      return css`
          fill: ${({ visible }) => visible ? 'var(--up-main-info)' : 'var(--up-main-grey)'};
          `
    }
  }}
    }
  }
	&:not(:disabled):hover {
    svg {
      path {
        ${(props) => {
    if (props.strokeSVG) {
      return css`
            stroke: var(--up-main-info);
          `;
    } else {
      return css`
              fill: var(--up-main-info);
          `
    }
  }}
      }
    }
  }
`;

export const ComingSoonPageWrap = styled(FlexBox)`
  @media (max-width: 1520px) {
    gap: 30px;
    padding-top: 30px;
  }

  svg {
    width: 33vw;
  }

  h2 {
    font-size: 64px;
    line-height: 80px;
    @media (max-width: 1520px) {
      font-size: 48px;
      line-height: 64px;
    }
  }
`;

export const Tag = styled.span`
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	padding: 4px 6px;
  border-radius: 6px;
	text-transform: capitalize;
  ${({ tagName }) => css(getTagColors(tagName))};
`;


export const InputFile = styled.input`
	display: none;
	&:disabled {
		cursor: not-allowed;
  }
`;

export const BorderBox = styled(FlexBox)`
	border-width: ${({ borderWidth }) => borderWidth || '1px'};
	border-style: ${({ borderStyle }) => borderStyle || 'solid'};
	border-color: ${({ borderColor }) => borderColor || 'var(--up-main-light-grey)'};
	border-radius: ${({ borderRadius }) => borderRadius || '6px'};
	padding: ${({ padding }) => padding || '0'};
	box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  &:hover {
    box-shadow: ${({ boxShadowHover }) => boxShadowHover || 'none'};
  }
`;

export const BlockWithAbsolutePosition = styled.div`
	position: absolute;
	top: ${({ top }) => top};
	right: ${({ right }) => right};
	left: ${({ left }) => left};
	bottom: ${({ bottom }) => bottom};
	width: ${({ width }) => width || 'auto'};
	height: ${({ height }) => height || 'auto'};
	z-index: ${({ zIndex }) => zIndex || '1'};
	* {
		width: 100%;
		height: 100%;
	}
`;

export const StickyHeader = styled(FlexBox)`
	top: 0;
	z-index: 9;
	position: sticky;
	background: var(--up-white);
	padding-top: 24px;
	padding-bottom: ${({ paddingBottom }) => paddingBottom || '0'};
`;

export const AccordionHeader = styled(FlexBox)`
	cursor: pointer;
	svg {
		width: 24px;
		height: 24px;
		min-width: 24px;
    transition: all 0.3s ease-in-out;
		transform: ${({ isOpened }) => isOpened === 'true' ? 'rotate(180deg)' : 'rotate(0)'};
	}
`;

export const Link = styled(FlexBox)`
	&:hover {
		text-decoration: underline;
  }
`;

export const IconWrap = styled.div`
	svg {
		width: ${({ width }) => width};
		height: ${({ height }) => height};
		min-width: ${({ width }) => width};
		path {
			fill: ${({ fill }) => fill};
		}
	}
`;