import * as S from './styled';
import PropTypes from "prop-types";
import {Tooltip} from "../commonStyled";
import {usePopperTooltip} from "react-popper-tooltip";

const TextBox = (
	{
		children,
		childrenTooltip,
		color = 'dark',
		size = 'sm',
		weight = 'normal',
		capitalize = 'none',
		lineHeight = '1',
		withTooltip = false,
		width = 'auto',
		withEllipsis = false,
		customSize = '',
		margin = '0',
		widthTooltip = 'auto',
		heightTooltip = 'auto',
		backgroundTooltip = 'var(--up-main-grey)',
		paddingTooltip = '4px 12px',
		displayArrow = 'block',
		...props
	},
) => {
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
		getArrowProps,
	} = usePopperTooltip({
		placement: 'bottom-start',
		interactive: true,
		trigger: 'hover',
	});
	return <>
		<S.TextBox
			{...props}
			ref={setTriggerRef}
			lineHeight={lineHeight}
			size={size}
			color={color}
			weight={weight}
			capitalize={capitalize}
			withTooltip={withTooltip}
			width={width}
			withEllipsis={withEllipsis}
			customSize={customSize}
			margin={margin}
		>
			{children}
		</S.TextBox>
		{(withTooltip && visible) && (
			<Tooltip
				widthTooltip={widthTooltip}
				heightTooltip={heightTooltip}
				ref={setTooltipRef}
				backgroundTooltip={backgroundTooltip}
				paddingTooltip={paddingTooltip}
				displayArrow={displayArrow}
				{...getTooltipProps({className: 'tooltip-container'})}
			>
				<div {...getArrowProps({className: 'tooltip-arrow'})} />
				{childrenTooltip}
			</Tooltip>
		)}
	</>
};

TextBox.propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	size: PropTypes.string,
	weight: PropTypes.string,
	capitalize: PropTypes.string,
	lineHeight: PropTypes.string,
	withTooltip: PropTypes.bool,
	width: PropTypes.string,
	withEllipsis: PropTypes.bool,
	customSize: PropTypes.string,
	childrenTooltip: PropTypes.node,
}

export default TextBox;
