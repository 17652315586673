import {FlexBox} from "../../../../../components/commonStyled";
import {
	configStylesForEconomicsTable,
	initialProfitSharing,
	profitSharingTableColumns,
	sumItems
} from "../utils";
import {EconomicsTableWrapper} from "../styled";

import Table from "../../../../../components/Table";
import Row from "./Row";
import Button from "../../../../../components/Button";
import {TotalTd} from "../../../../../components/Table/styled";
import {reducingNumber} from "../../../../../utils";

const ProfitSharingTable = (
	{
		profitSharing,
		setProfitSharing,
		editMode,
		grossProfitMargin,
		talentsNames
	}
) => {
	const handleDeleteItem = (index) => {
		setProfitSharing(state => state.filter((row, i) => i !== index));
	};
	const remainingProfitSharing = 100 - sumItems(profitSharing, 'percent_from_gross_profit');
	console.log('profitSharing', profitSharing)
	return (
		<FlexBox
			flexDirection={'column'}
			width={'60%'}
		>
			<EconomicsTableWrapper
				tdBackground={'var(--up-main-grayish)'}
				padding={'0'}
				widthColumn={'31%'}
			>
				<Table
					childrenHeader={
						profitSharingTableColumns.map((column, index) => {
							return <th key={column.key + '-' + index} className={column.key}
												 style={{textAlign: column?.textAlign || 'left'}}>
								{column.title}
							</th>
						})
					}
					data={profitSharing}
					{...configStylesForEconomicsTable}
				>
					{
						profitSharing.map((row, index) => (
							<Row
								key={index}
								row={row}
								index={index}
								editMode={editMode}
								handleDeleteItem={handleDeleteItem}
								setProfitSharing={setProfitSharing}
								grossProfitMargin={grossProfitMargin}
								profitSharing={profitSharing}
								talentsNames={talentsNames}
							/>
						))
					}
					<tr>
						<td style={{position: 'relative', overflow: 'visible'}}>
							<Button
								label={'Team Member'}
								gap='12px'
								withIcon={true}
								padding='10px 35px'
								flexDirection='row-reverse'
								handleClick={() => setProfitSharing(state => [...state, initialProfitSharing])}
								fontSize='16px'
								ghost={true}
								style={{margin: '5px 16px', position: 'absolute', left: '0', top: '0'}}
								width={'192px'}
								sizeIcon={'16px'}
								disabled={!editMode || talentsNames?.length === profitSharing?.length}
							/>
						</td>
					</tr>
					<tr className='with-border-radius'>
						<td className={'with-bg'}>
						</td>
						<TotalTd className={'with-bg'}>
							Remaining: {remainingProfitSharing} %
						</TotalTd>
						<TotalTd className={'with-bg'}>
							Total: $ {reducingNumber(sumItems(profitSharing, 'total_payment'))}
						</TotalTd>
						<td className={'with-bg'}></td>
					</tr>
				</Table>
			</EconomicsTableWrapper>
		</FlexBox>
	)
}

export default ProfitSharingTable