import * as S from './styled';
import {TableIconButton, FlexBox} from "../commonStyled";
import {useState} from "react";
import {ReactComponent as Dots} from "../../assets/Icons/dotsVertical.svg";
import {ReactComponent as Trash} from "../../assets/Icons/trash.svg";
import {usePopperTooltip} from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import Avatar from "../Avatar";
import TextBox from "../TextBox";
import ModalWrap from "../ModalWrap";
import ConfirmationModal from "../modals/ConfirmationModal";


const CardInfo = (
	{
		name,
		full_name,
		logo,
		_id,
		handleClick,
		handleDelete,
		parentName = 'Client Company',
		descriptionBlock = null,
		secondaryDescriptionBlock = null,
		isPermittedDelete = false,
		widthLogo = '80px',
		heightLogo = '80px',
	}
) => {
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		trigger: 'click',
		placement: 'bottom-end',
		interactive: true,
	});
	const [showModal, setShowModal] = useState(false);
	
	const handleOpenDeleteModal = () => {
		setShowModal(true);
	};
	
	const handleCloseModal = () => {
		setShowModal(false);
	};
	
	const onDelete = () => {
		handleDelete(_id);
		handleCloseModal();
	};
	const itemName = name || full_name;
	return (
		<>
			<S.CardInfo as='li' gap='16px' onClick={() => handleClick(_id)}>
				<Avatar src={logo} name={itemName} width={widthLogo} height={heightLogo} sizeInitials='md'/>
				<div style={{width: `calc(100% - (${widthLogo} + 16px))`}}>
					<FlexBox justifyContent='space-between' alignItems='center' width='100%' overflow='hidden'>
						<TextBox
							as='h2'
							size='md'
							marginBottom='10px'
							weight='medium'
							width='calc(100% - 30px)'
							withEllipsis={true}
							title={itemName}
							lineHeight={'24px'}
						>
							{itemName}
						</TextBox>
						{isPermittedDelete &&
							<TableIconButton data-id={_id} ref={setTriggerRef} visible={visible} onClick={e => e.stopPropagation()}>
								<Dots/>
							</TableIconButton>}
					</FlexBox>
					{descriptionBlock}
				</div>
				{secondaryDescriptionBlock}
			</S.CardInfo>
			{visible && (
				<S.TooltipContainer
					ref={setTooltipRef}
					{...getTooltipProps({className: 'tooltip-container'})}
				>
					<div>
						<S.TooltipItem
							as='button'
							alignItems='center'
							gap='8px'
							onClick={handleOpenDeleteModal}
						>
							<Trash/>
							<TextBox as='span' size='sm' lineHeight='20px'>Delete</TextBox>
						</S.TooltipItem>
					</div>
				</S.TooltipContainer>
			)}
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={handleCloseModal}
					children={
						<ConfirmationModal
							handleConfirm={onDelete}
							closeModal={handleCloseModal}
							title={'Delete ' + parentName}
							message={`Are you sure you want to remove ${itemName}?`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</>
	)
}

export default CardInfo;