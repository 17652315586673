import * as S from './styled';
import {Fragment} from "react";
import {ReactComponent as Arrow} from "../../assets/Icons/ArrowStep.svg";

const Steps = (
	{
		steps = ['Enter your profile information', 'Tell us about your business'],
		activeStep = 0,
		marginTop = '0',
		handleNextStep = () => {},
		handlePrevStep = () => {},
	}
) => {
	
	return (
		<S.StepsWrap alignItems='center' justifyContent='center' gap='14px' marginTop={marginTop}>
			{steps.map((step, index) => {
				return (
					<Fragment key={index}>
						<S.Step alignItems='center' gap='17px' isActive={index === activeStep} isPassed ={index < activeStep}>
							<S.StepNumber
								isActive={index === activeStep}
								isPassed ={index < activeStep}
								onClick={activeStep > index ? handlePrevStep :(e)=>handleNextStep(e, index)}
								alignItems='center'
								justifyContent='center'
							>
								{index + 1}
							</S.StepNumber>
							<span>{step}</span>
						</S.Step>
						{index !== steps.length - 1 && <Arrow/>}
					</Fragment>
				)
			}
			)}
		</S.StepsWrap>
	);
}

export default Steps;