import {memo} from "react";
import {Container, FlexBox, GridBox} from "../../components/commonStyled";
import clientsActions from "../../actions/clientsActions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import CardInfo from "../../components/CardInfo";
import CustomPagination from "../../components/Pagination";
import Search from "../../components/Search";
import Button from "../../components/Button";
import NoData from "../../components/NoData";
import CardInfoDescription from "../../components/CardInfo/CardInfoDescription";
import TextBox from "../../components/TextBox";

const ClientsList = memo((
		{
			setShowModal,
			clients = [],
			isPermittedCreateNewClient = false,
			clientsData,
		}
	) => {
		const dispatch = useDispatch();
		const navigate = useNavigate();
		
		const {page, page_size, items_count} = clientsData;
		
		const onPageChange = (data) => {
			dispatch(clientsActions.getClientsList(data));
		};
		const handleDelete = (id) => {
			dispatch(clientsActions.deleteClient(id));
		};
		const handleClick = (id) => {
			dispatch(clientsActions.getClientDetails(id));
			navigate(`/clients/${id}`);
		};
		
		return (
			<FlexBox as='div' flexDirection='column' height='100%' width='100%' padding='20px 20px 0'>
				<Container>
					<FlexBox justifyContent='space-between' alignItems='center' margin='0 0 24px'>
						<TextBox as='h2' size='md' weight='medium'>All Clients</TextBox>
						<FlexBox gap='12px'>
							<Search
								onPageChange={onPageChange}
								page={page}
								page_size={page_size}
								placeholder='Search by Name'
							/>
							{isPermittedCreateNewClient &&
							<Button
								label='Client Company'
								gap='12px' withIcon={true}
								padding='7px 34px'
								flexDirection='row-reverse'
								handleClick={() => setShowModal(true)}
								fontSize='16px'
							/>}
						</FlexBox>
					</FlexBox>
					{
						items_count === 0
							?
							<NoData text='There are no such results ' margin='0'/>
							:
							<GridBox as='ul' id='clients-list' gridTemplateColumns='repeat(3, minmax(280px, 1fr))' gridColumnGap='16px'
											 gridRowGap='16px'>
								{clients.map((item) => (
									<CardInfo
										key={item._id}
										{...item}
										handleDelete={handleDelete}
										handleClick={handleClick}
										descriptionBlock={<CardInfoDescription {...item}/>}
										isPermittedDelete={isPermittedCreateNewClient}
									/>
								))}
							</GridBox>
					}
				</Container>
				{items_count > 0 &&
				<CustomPagination
					totalItems={items_count}
					pageSize={page_size}
					page={page}
					onPageChange={onPageChange}
				/>}
			</FlexBox>
		);
	}
)

export default ClientsList;