import styled, {css} from "styled-components";
import {FlexBox} from "../commonStyled";
import TextBox from "../TextBox";

export const CalendarRangeWrapper = styled.div`
  ${(props) => {
    if (props.calendarRange) {
      return css`
        bottom: -10px;
        transform: translateY(100%);
        left: 0;
        position: ${({position}) => position || 'absolute'};
        width: ${props => props.width}px;
        border: 1px solid var(--up-blue-p100);
        box-shadow: var(--main-box-shadow);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 20px;
        z-index: 2;
        background-color: var(--up-white);
      `;
    }
  }}
    //singleCalendar
  ${(props) => {
    if (props.singleCalendar) {
      return css`
        .SingleDatePicker_picker {
					z-index: 2;
					transform: ${props => props.transform};
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${({readOnly}) => readOnly ? '' : '60px 20px 20px'};
          background: var(--up-white);
          border: ${({readOnly}) => readOnly ? '' : '1px solid var(--up-main-info)'};
          box-shadow: var(--main-box-shadow);
          border-radius: 6px;
					
          .DayPickerKeyboardShortcuts_buttonReset {
            display: none;
          }

          .CalendarDay__selected {
            .day-number {
              border-radius: 2px;
            }
          }
        }

        .SingleDatePicker {
          width: 100%;

          .SingleDatePickerInput {
            width: 100%;
            border: none;

            .DateInput {
              width: 100%;
              background: ${({background}) => background};
            }
            background: ${({background}) => background};
          }
        }
				
				table {
					tr {
						&:hover {
							background: none;
						}
					}
				}

        .DateInput_input {
          width: ${({inputWidth}) => inputWidth};
          font-size: 14px;
          font-family: 'Rubik', sans-serif;
          font-weight: 400;
          padding: ${({padding}) => padding ? padding : '10.5px 12px'};
          border: 1px solid var(--up-main-light-grey);
          border-radius: 4px;
          transition: all .15s ease-in 0s;
          text-transform: ${({textTransform}) => textTransform};
          color: ${({isInputProjectCode}) => isInputProjectCode ? 'transparent' : 'var(--up-main-dark)'};
          line-height: 1;

          &__disabled {
            cursor: text;
            border-color: var(--up-page-bg);
            background: var(--up-white);
            font-style: normal;
					}

          &:not(.DateInput_input__disabled) {
            &:focus,
            &:hover {
              outline: none;
              border-color: var(--up-main-blue);
            }

            ${(props) => {
              if (props.error) {
                return css`
                  border-color: var(--up-main-red);
                `;
              }
            }}
          }
        }
				.SingleDatePickerInput_calendarIcon {
          position: absolute;
					top: 50%;
					transform: translateY(-50%);
					z-index: 1;
					padding: 0;
					margin: 0;
					left: 12px;
				}
				.CalendarMonthGrid_month__horizontal {
					width: 320px;
				}
      `;
    }
  }}
  .DateInput_fang {
    display: none;
  }

  .DateRangePicker {
    left: -4px;

    &_picker {
      ${(props) => {
        if (props.calendarRange) {
          return css`
            position: static;
          `;
        }
      }}
    }
  }

  .DateInput,
  .DateRangePickerInput_arrow {
    ${(props) => {
      if (props.calendarRange) {
        return css`
          display: none;
        `;
      }
    }}
  }

  .CalendarDay__selected_span {
    &:first-child {
      .custom-day-cell {
        border-radius: 2px 0 0 2px;
      }
    }

    &:last-child {
      .custom-day-cell {
        border-radius: 0 2px 2px 0;
      }
    }
  }

  .CalendarDay {
    height: 36px !important;
    padding: 0 !important;

    .custom-day-cell {
      height: 32px;
      padding-left: 3px;
      padding-right: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;

      div {
        color: inherit;
        font-size: 16px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
      }
    }

    &:hover {
      background: none;
      border: none;

      &:not(.CalendarDay__selected_span, .CalendarDay__selected_start, .CalendarDay__selected_end, .CalendarDay__hovered_span, .CalendarDay__blocked_out_of_range) {
        .custom-day-cell {
          > div {
            background: var(--up-blue-p100);
            border-radius: 2px;
          }
        }
      }
    }

    &:not([aria-disabled="true"]) {
      color: var(--up-main-blue);

    }

    &__default {
      border: none;
    }

    &__today {
      border: none;

      &:not(&.CalendarDay__selected_end, &.CalendarDay__selected_start) {
        .custom-day-cell {
          > div {
            border: 1px solid var(--up-main-blue);
            border-radius: 2px;
          }
        }
      }
    }

    &__selected,
    &__hovered {
      background: none;

      .custom-day-cell {
        > div {
          background-color: var(--up-main-blue)!important;
        }
      }

      color: var(--up-white) !important;

      &_span {
        background: none;

        .custom-day-cell {
          background-color: var(--up-light-blue-p400);
        }
      }

      &_start {
        .custom-day-cell {
          > div {
            border-radius: 2px 0 0 2px;

            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: -3px;
              width: 3px;
              height: 100%;
              background-color: var(--up-light-blue-p400);
              z-index: -1;
            }
          }
        }
      }

      &_end {
        .custom-day-cell {
          > div {
            border-radius: 0 2px 2px 0;

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: -3px;
              width: 3px;
              height: 100%;
              background-color: var(--up-light-blue-p400);
              z-index: -1;
            }
          }
        }
      }
    }
  }
	
  .DayPicker_weekHeaders {
    .DayPicker_weekHeader {
      &_li {
        * {
          font-size: 16px;
          color: var(--up-main-grey);
        }
      }
    }
  }

  .CalendarMonth_caption {
    strong {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--up-main-blue);
    }
  }

  .CalendarMonth_caption {
    padding-bottom: 54px;
    padding-top: 28px;
  }

  .DayPicker {
    width: ${({widthMonth}) => widthMonth}px !important;

    > div {
      > div {
        width: ${({widthMonth}) => widthMonth}px !important;
      }
    }

    &_weekHeader {
      padding-left: 24px !important;
      padding-right: 0 !important;

      &:last-child {
        left: auto !important;
        right: 0 !important;
        padding-left: 0 !important;
        padding-right: 24px !important;
      }
    }
  }

  .DayPicker_transitionContainer {
    min-height: 328px !important;
    width: ${({widthMonth}) => widthMonth}px !important;
  }

  .DayPicker_weekHeaders__horizontal {
    margin-left: 0;
  }

  .DayPicker_weekHeader {
    transform: translateY(14px);
  }

  .DayPicker__withBorder {
    box-shadow: none;
  }

  .DayPickerNavigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 24px;
    width: 100%;
  }

  .CalendarMonth {
    border: 1px solid var(--up-main-light-grey);
    border-radius: 6px;
    min-height: 328px !important;
    padding: 0 24px !important;
  }

  .CalendarMonthGrid {
    display: flex;
    gap: 20px;
  }

  .CalendarMonthGrid__horizontal {
    left: 0 !important;
  }
`;

export const NavButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transform: ${props => props.next ? 'rotate(180deg)' : 'rotate(0deg)'};
  margin: ${props => props.next ? '0 24px 0 0' : '0 0 0 24px'};
`;

export const RangesBlock = styled(FlexBox)`
  width: 192px;
  border: 1px solid var(--up-main-light-grey);
  border-right: none;
  border-radius: 6px 0 0 6px;
  height: 328px;
  padding: 24px;
  background: transparent;
  position: relative;
  z-index: 2;
`;

export const RangesItem = styled(TextBox)`
  cursor: pointer;
  color: ${({active}) => active ? 'var(--up-main-blue)' : 'inherit'};

  &:hover {
    color: var(--up-main-blue);
  }
`;


export const DatesButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;