const initialState = {
	profileData: null,
	profileDataLoading: false,
	editProfileDataLoading: false,
	teamMembers: null,
	teamMembersLoading: false,
	addNewTeamMemberLoading: false,
	addNewTeamMemberSuccess: false,
}

export default function profilePageReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_PROFILE_DATA": {
			return {
				...state,
				profileData: action.payload,
				profileDataLoading: false,
			}
		}
		case "SET_PROFILE_DATA_LOADING": {
			return {
				...state,
				profileDataLoading: action.payload
			}
		}
		case "SET_EDIT_PROFILE_DATA_LOADING": {
			return {
				...state,
				editProfileDataLoading: action.payload
			}
		}
		case "SET_TEAM_MEMBERS": {
			return {
				...state,
				teamMembers: action.payload,
				teamMembersLoading: false,
			}
		}
		case "SET_TEAM_MEMBERS_LOADING": {
			return {
				...state,
				teamMembersLoading: action.payload
			}
		}
		case "SET_ADD_NEW_TEAM_MEMBER_LOADING": {
			return {
				...state,
				addNewTeamMemberLoading: action.payload
			}
		}
		case "SET_ADD_NEW_TEAM_MEMBER_SUCCESS": {
			return {
				...state,
				addNewTeamMemberSuccess: action.payload
			}
		}
		default:
			return state;
	}
}