import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

export const usePermission = (allowedRoles) => {
	const [isPermitted, setPermitted] = useState(false);
	const {userData} = useSelector(state => state.commonReducer);
	const userRole = userData?.role;
	useEffect(() => {
		setPermitted(allowedRoles.includes(userRole))
	}, [allowedRoles, userRole]);

	return isPermitted;
}