import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import projectsActions from "../../../../actions/projectsActions";
import { useParams } from "react-router-dom";

import Loader from "../../../../components/Loader";
import GeneralInformation from "./GeneralInformation";

const GeneralTabContent = ({ editMode, setEditMode, isCreateProject }) => {
  const { clientsNames, projectData, projectDataLoading } = useSelector(
    (state) => state.projectsPageReducer
  );
  const { projectId } = useParams();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (!clientsNames) {
      dispatch(projectsActions.getClientsNames());
    }
  }, [dispatch, clientsNames]);

  useLayoutEffect(() => {
    if (!isCreateProject) {
      dispatch(projectsActions.getProjectData(projectId));
    }
  }, [dispatch, isCreateProject, projectId, clientsNames]);
  console.log(projectData);
  return (
    <>
      {projectDataLoading || clientsNames?.length === 0 ? (
        <Loader position="relative" width="100%" height="calc(100vh - 140px)" />
      ) : (
        <GeneralInformation
          editMode={editMode}
          setEditMode={setEditMode}
          clientsNames={clientsNames}
          isCreateProject={isCreateProject}
          projectData={projectData}
          projectID={projectId}
        />
      )}
    </>
  );
};

export default GeneralTabContent;
