import {useState} from "react";
import {Container, FlexBox} from "../../../components/commonStyled";
import {projectsTabs} from "../utils";

import Tabs from "../../../components/Tabs";
import GeneralTabContent from "./GeneralTab/GeneralTabContent";
import {TabsWrap} from "../styled";
import {useParams} from "react-router-dom";
import TeamTab from "./TeamTab";
import DocumentsWrapper from "../../../components/DocumentsTab/DocumentsWrapper";
import Economics from "./Economics";
import {useSelector} from "react-redux";
import Invoicing from "./Invoicing";
import TimeLogs from "./TimeLogs";
import {usePermission} from "../../../hooks/usePermission";

const ActiveProject = () => {
	const {projectId} = useParams();
	const [editMode, setEditMode] = useState(false);
	const isCreateProject = projectId === 'create-project';
	const {projectData, projectDataLoading} = useSelector(state => state.projectsPageReducer);
	const isPermittedEditDocuments = usePermission(['super_admin', 'company_admin', 'contract_specialist', 'controller', 'partner', 'project_coordinator']);
	
	return (
		<FlexBox as='div' height='100%' width='100%' padding='20px 20px 0' gap='20px'>
			<TabsWrap width='100%'>
				<Tabs
					tabs={projectsTabs}
					areTabsDisabled={isCreateProject || editMode || projectDataLoading}
					positionTabs='relative'
				>
						<GeneralTabContent
							editMode={editMode}
							setEditMode={setEditMode}
							isCreateProject={isCreateProject}
						/>
						<TeamTab projectId={projectId}/>
						<Economics
							projectId={projectId}
							projectData={projectData}
							editMode={editMode}
							setEditMode={setEditMode}
						/>
						<TimeLogs
							projectId={projectId}
						/>
						<Invoicing
							projectId={projectId}
						/>
						{/*<div>Payments</div>*/}
					<DocumentsWrapper
						objectId={projectId}
						objectType='projects'
						isPermittedEdit={isPermittedEditDocuments}
					/>
					{/*<div>Client Contacts</div>*/}
				</Tabs>
			</TabsWrap>
			<Container style={{width: '292px', display: 'none'}} height={'calc(100% - 18px)'}>
				Activity
			</Container>
		</FlexBox>
	);
}

export default ActiveProject;