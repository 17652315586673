import {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as S from './styled';
import * as CS from "../../components/commonStyled";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import authActions from "../../actions/authActions";

import {ReactComponent as Cat} from "../../assets/Icons/cat1.svg";
import {schemaSignIn} from "./utils";

import Button from "../../components/Button";
import TextBox from "../../components/TextBox";
import FormInput from "../../components/FormInput";


const SignIn = () => {
	const dispatch = useDispatch();
	const {passwordSuccessfullyChanged} = useSelector(state => state.authReducer);
	const {register, handleSubmit, formState: {errors}} = useForm({
		resolver: yupResolver(schemaSignIn)
	});
	
	const onSubmit = (data) => {
		dispatch(authActions.login({
			username: data.email,
			password: data.password
		}));
	};
	
	useEffect(() => {
		if (passwordSuccessfullyChanged) {
			dispatch(authActions.passwordSuccessfullyChanged(false));
		}
	}, [passwordSuccessfullyChanged, dispatch]);
	
	return (
		<S.Form onSubmit={handleSubmit(onSubmit)}>
			<TextBox size={'lg'} as={'h2'} color='dark'>
				Sign In
			</TextBox>
			<FormInput register={register} errors={errors} name={'email'}/>
			<FormInput register={register} errors={errors} name={'password'} type='password' isStaticErrorMessage={true} isPasswordInput={true}/>
			<S.Link as={Link} to={'/auth/recovery-password'} textalign={'right'} bold={'true'}>Forgot password?</S.Link>
			<Button label='Sign In' type="submit" fullWidth={true} padding='12px 15px'/>
			<CS.ImgWithAbsolutePosition as={Cat} top={'63px'} right={'103px'}/>
		</S.Form>
	);
};

export default SignIn;
