import * as S from "./styled";
import {useEffect, useRef, useState, useCallback} from "react";
import {useDispatch} from "react-redux";
import commonActions from "../../actions/commonActions";

const Tab = (
	{
		tab,
		activeTab,
		index,
		tabsWidth,
		setIsActivePrevButton,
		setIsActiveNextButton,
		tabsContainerRef,
		translateX,
		handlePrevButton,
		handleNextButton,
		areTabsDisabled
	}
) => {
	const tabRef = useRef(null);
	const [isHiddenLeft, setIsHiddenLeft] = useState(false);
	const [isHiddenRight, setIsHiddenRight] = useState(false);
	const dispatch = useDispatch();
	const determineIfTabIsHidden = useCallback(() => {
		const tabCoords = tabRef?.current.getBoundingClientRect();
		const tabsContainerCoords = tabsContainerRef?.current.getBoundingClientRect();
		
		//Determine if tab position is more left than the left side of the tabs container
		const tabIsHiddenLeft = (tabCoords.left - tabsContainerCoords.left) < 0;
		
		//Determine if tab position is more right than the right side of the tabs container
		const tabIsHiddenRight = (tabsContainerCoords.right - (tabCoords.left + tabCoords.width)) < 0;
		
		//Set the state of the tab
		setIsHiddenLeft(tabIsHiddenLeft);
		setIsHiddenRight(tabIsHiddenRight);
		
		//Set the state of the carousel buttons
		if (tabIsHiddenLeft) {
			setIsActivePrevButton(true);
		}
		if (tabIsHiddenRight) {
			setIsActiveNextButton(true);
		}
	}, [setIsActiveNextButton, setIsActivePrevButton, tabRef, tabsContainerRef]);
	
	useEffect(() => {
		//Set timeout to wait for the tab to be rendered and finish the animation
		setTimeout(() => {
			if (tabRef?.current) {
				determineIfTabIsHidden();
			}
		}, 400);
	}, [tabsWidth, translateX, determineIfTabIsHidden]);
	
	const handleTabClick = () => {
		if (areTabsDisabled) return;
		dispatch(commonActions.setActiveTab(index));
		if (isHiddenLeft) {
			handlePrevButton();
		}
		if (isHiddenRight) {
			handleNextButton();
		}
	}
	
	return (
		<>
			{
				<S.Tab
					as='li'
					onClick={handleTabClick}
					alignItems='center'
					gap='6px'
					active={activeTab === index}
					ref={tabRef}
					data-hiddenleft={isHiddenLeft}
					data-hiddenright={isHiddenRight}
					disabled={areTabsDisabled}
				>
					{tab?.icon && tab.icon}
					<span style={{lineHeight: '18px'}}>{tab.name}</span>
				</S.Tab>
			}
		</>
	)
}

export default Tab;