import {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {TextareaWrapper} from "./styled";

const Textarea = ({props}) => {
	const {setValue, name, defaultValue, disabled} = props;
	const [editorHtml, setEditorHtml] = useState(defaultValue || '');
	const [isFocused, setIsFocused] = useState(false);
	const reactQuillRef = useRef(null);
	useEffect(()=> {
		if (setValue) {
			if (defaultValue) {
				setValue(name, defaultValue);
			} else {
				setValue(name, null);
			}
		}
	}, [defaultValue, setValue, name]);
	const handleChange = (html) => {
		setEditorHtml(html);
		setValue(name, html);
	};
	
	const handleFocus = () => {
		setIsFocused(true);
	};
	
	const handleBlur = () => {
		setIsFocused(false);
	};
	
	return (
		<TextareaWrapper disabled={disabled} isFocused={isFocused}>
			<ReactQuill
				ref={reactQuillRef}
				theme={'snow'}
				onChange={handleChange}
				value={editorHtml}
				modules={Textarea.modules}
				formats={Textarea.formats}
				bounds={'.app'}
				placeholder={props.placeholder}
				readOnly={disabled}
				onFocus={disabled ? null : handleFocus}
				onBlur={handleBlur}
			/>
		</TextareaWrapper>
	);
};

Textarea.modules = {
	toolbar: [
		// [{ 'font': [] }],
		[{ size: [] }],
		['bold', 'italic', 'underline'],
		[{ 'list': 'ordered' }, { 'list': 'bullet' }],
		['link'],
		[{ 'clean': 'remove formatting' }],
	],
	clipboard: {
		matchVisual: false,
	},
};

Textarea.formats = [
	'header', 'size',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link', 'image', 'video',
];

Textarea.propTypes = {
	placeholder: PropTypes.string,
};

export default Textarea;
