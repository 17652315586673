import moment from "moment";
import {useEffect} from "react";
import {Controller} from "react-hook-form";
import Calendar from "./Calendar";
const SingleCalendar = ({props}) => {
	const {setValue, name, getValues, error, defaultValue, disabled, control} = props;
	
	useEffect(() => {
		if (defaultValue) {
			setValue(name, moment(defaultValue, 'MM/DD/YYYYTHH:mm:ssZ').format('M/D/Y'));
		} else {
			const value = name === 'end_date' ? moment().add(1, 'day') : moment();
			setValue(name, value);
		}
	}, [defaultValue, name, setValue]);
	
	return (
		<Controller
			control={control}
			name={name}
			render={({field}) => (
				<Calendar
					initialFormFields={field}
					defaultValue={defaultValue}
					name={name}
					getValues={getValues}
					disabled={disabled}
					error={error}
				/>
			)}
		/>
	)
}

export default SingleCalendar;