import {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {projectsTableColumns} from "../utils";
import {useDispatch} from "react-redux";
import projectsActions from "../../../actions/projectsActions";

import Table from "../../../components/Table";
import ProjectTableRow from "./ProjectTableRow";
import ModalWrap from "../../../components/ModalWrap";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import SortArrows from "../../../components/SortArrows";

const ProjectsTable = (
	{
		projectsData,
		searchInputValue,
		sortBy,
		isPermittedCreateNewProject
	}
) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const itemsRef = useRef({});
	
	const handleClick = (id) => {
		navigate(`/projects/${id}`);
	};
	
	const onChangeStatus = (value, id) => {
		console.log( id)
		if(value.value === 'archived') {
			setSelectedProject(projectsData.items.find(project => project._id === id));
			setShowModal(true);
			return;
		}
		dispatch(projectsActions.updateProjectStatus(
			{status: value.value},
			id
		))
	};
	
	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedProject(null);
	};
	
	const handleDeleteProject = () => {
		dispatch(projectsActions.deleteProject(selectedProject._id));
		setSelectedProject(null);
		handleCloseModal();
	};
	
	const handleSort = (key) => {
		dispatch(projectsActions.sortByProjects(key === sortBy ? '-' + key : key));
		dispatch(projectsActions.getProjectsData({
			page: projectsData.page,
			page_size: projectsData.page_size,
			query_str: searchInputValue,
		}
		));
	}
	
	return (
		<>
			<Table
				childrenHeader={
					projectsTableColumns.map((column, index) => (
						<th
							key={column.key + '-' + index}
							className={column.key}
							onClick={()=>handleSort(column?.sortKey)}
						>
							<span
								className={column?.sortKey === sortBy || '-'+column?.sortKey === sortBy ? 'active' : ''}
							>
								{column.title}
								{column?.sortKey && <SortArrows asc={sortBy === column?.sortKey} desc={sortBy=== '-'+column?.sortKey}/>}
							</span>
						</th>
					))
				}
				data={projectsData.items}
			>
				{
					projectsData.items.map((project, index) => (
						<ProjectTableRow
							key={project.id + '-' + index}
							project={project}
							handleClick={handleClick}
							onChangeStatus={onChangeStatus}
							setShowModal={setShowModal}
							setSelectedProject={setSelectedProject}
							itemsRef={itemsRef}
							projectsData={projectsData}
							searchInputValue={searchInputValue}
							isPermittedCreateNewProject={isPermittedCreateNewProject}
						/>
					))
				}
			</Table>
			{showModal &&
			<ModalWrap
				paddingTop='75px'
				close={handleCloseModal}
				children={
					<ConfirmationModal
						handleConfirm={handleDeleteProject}
						closeModal={handleCloseModal}
						title='Archive Project'
						message={`Are you sure you want to archive the ${selectedProject?.name}?`}
						buttonText='Yes, Archive'
					/>
				}
			/>}
		</>
	)
}

export default ProjectsTable;