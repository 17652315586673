import { useState } from "react";
import {teamMembersTableColumns} from "../Profile/utils";
import Table from "../../components/Table";
import TeamMembersTableRow from "./TeamMembersTableRow";
import ModalWrap from "../../components/ModalWrap";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {useDispatch} from "react-redux";
import profileActions from "../../actions/profileActions";
import {usePermission} from "../../hooks/usePermission";

const TeamMembersTable = ({ data }) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedTeamMember, setSelectedTeamMember] = useState(null);
	const dispatch = useDispatch();
	const isPermittedManageTeamMembers = usePermission(['super_admin', 'company_admin']);
	
	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedTeamMember(null);
	};
	
	const handleDeleteTeamMember = () => {
		dispatch(profileActions.deleteTeamMember(selectedTeamMember._id));
		handleCloseModal();
	}
	return (
		<>
			<Table
				childrenHeader={
					teamMembersTableColumns.map((column, index) => (
						<th key={column.key + '-' + index} className={column.key}>
							{column.title}
						</th>
					))
				}
				data={data}
				withHoverEffect={isPermittedManageTeamMembers}
			>
				{
					data.map((item)=> {
						return <TeamMembersTableRow
							key={item._id}
							item={item}
							setSelectedTeamMember={setSelectedTeamMember}
							setShowModal={setShowModal}
							isPermittedManageTeamMembers={isPermittedManageTeamMembers}
						/>
					})
				}
			</Table>
			{showModal &&
				<ModalWrap
					paddingTop='75px'
					close={handleCloseModal}
					children={
						<ConfirmationModal
							handleConfirm={handleDeleteTeamMember}
							closeModal={handleCloseModal}
							title='Delete Team Member'
							message={`Are you sure you want to remove the ${selectedTeamMember?.full_name}? All the related data will be lost.`}
							buttonText='Yes, Delete'
						/>
					}
				/>}
		</>
	)
}

export default TeamMembersTable;