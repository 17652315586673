import {useEffect, useMemo, useState} from "react";
import {ReactComponent as Arrow} from "../../../../../assets/Icons/arrowDropdown.svg";
import {ReactComponent as Trash} from "../../../../../assets/Icons/trash.svg";

import CustomSelect from "../../../../../components/SelectDropdown/CustomSelect";
import {Input} from "../../../../../components/FormInput/styled";
import {TableIconButton} from "../../../../../components/commonStyled";
import {handleKeyDown, reducingNumber} from "../../../../../utils";

const Row = (
	{
		row,
		editMode,
		index,
		handleDeleteItem,
		handleUpdateStateRow,
		talentsNames,
		professionalFees,
		isStaffingModel
	}
) => {
	const filterTalents = useMemo(() => {
		const talentsIds = professionalFees?.map(professionalFee => professionalFee?.contractor_id);
		return talentsNames?.filter(talent => !talentsIds.includes(talent._id) || talent._id === row?.contractor_id);
	}, [professionalFees, talentsNames, row?.contractor_id]);
	const [talents, setTalents] = useState(filterTalents);
	
	useEffect(() => {
		setTalents(filterTalents);
	}, [filterTalents, professionalFees]);
	
	const handleSelectItem = (value, index) => {
		const newProfessionalFees = JSON.parse(JSON.stringify(professionalFees));
		newProfessionalFees[index].contractor_id = value._id;
		newProfessionalFees[index].name = value.full_name;
		newProfessionalFees[index].internal_daily_rate = value?.internal_daily_rate || 0;
		newProfessionalFees[index].external_daily_rate = value?.external_daily_rate || 0;
		handleUpdateStateRow(newProfessionalFees);
	};
	const handleChanges = (e, index, fieldName) => {
		const value = e.target.value;
		if (value?.includes('.') && value?.split('.')[1]?.length > 2) return;
		if (value.split('.')[0]?.length > 5) return;
		const newProfessionalFees = JSON.parse(JSON.stringify(professionalFees));
		newProfessionalFees[index][fieldName] = value;
		handleUpdateStateRow(newProfessionalFees);
	}
	return (
		<tr>
			<td style={{overflow: 'visible'}}>
				<CustomSelect
					name={'contactors'}
					ref={null}
					options={talents}
					defaultValue={row?.contractor_id}
					icon={<Arrow/>}
					marginRight='0px'
					fontSize='12px'
					height='28px'
					handleOnChange={(value) => handleSelectItem(value, index)}
					isSearchable={false}
					sizeIcon='6px'
					disabled={!editMode}
				/>
			</td>
			{isStaffingModel && <td style={{textAlign: 'right'}}>
				<Input
					value={row?.external_daily_rate || ''}
					type={'number'}
					textAlign={'right'}
					onChange={(e) => handleChanges(e, index, 'external_daily_rate', 5)}
					disabled={!editMode}
					placeholder={'0'}
					placeholderColor={'var(--up-main-dark)'}
					onKeyDown={handleKeyDown}
					onWheel={(e) => e.target.blur()}
				/>
			</td>}
			<td style={{textAlign: 'right'}}>
				<Input
					value={row?.internal_daily_rate || ''}
					type={'number'}
					textAlign={'right'}
					onChange={(e) => handleChanges(e, index, 'internal_daily_rate', 5)}
					disabled={!editMode}
					placeholder={'0'}
					placeholderColor={'var(--up-main-dark)'}
					onKeyDown={handleKeyDown}
					onWheel={(e) => e.target.blur()}
				/>
			</td>
			<td style={{textAlign: 'right'}}>
				<Input
					value={row?.of_days_allowed || ''}
					placeholder={'0'}
					type={'number'}
					textAlign={'right'}
					onChange={(e) => handleChanges(e, index, 'of_days_allowed', 4)}
					disabled={!editMode}
					placeholderColor={'var(--up-main-dark)'}
					onKeyDown={(e)=>handleKeyDown(e,  ['e', 'E', '-', '.', ',', 'ArrowUp', 'ArrowDown'])}
					onWheel={(e) => e.target.blur()}
				/>
			</td>
			<td style={{textAlign: 'right', fontWeight: '500'}} className={'with-bg'}>
				$ {reducingNumber(row?.internal_daily_rate * row?.of_days_allowed)}
			</td>
			<td style={{textAlign: 'center', width: '50px'}} className='action trash-icon with-bg'>
				<TableIconButton disabled={!editMode} onClick={() => handleDeleteItem(index)}>
					<Trash/>
				</TableIconButton>
			</td>
		</tr>
	)
}

export default Row;